// import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useContext from "../../context/StateProvider";
// import ActionTypes from "../../utils/actionTypes";
import facepopService from "../../services/facepop.service";

import { toast } from "react-toastify";
import FacepopWidget from "../Dashboard/FacePopUp/component/FacepopWidget";
import Loader from "../Loader/Loader";
// import Loader from "../Loader/Loader";

function StandAloneFacePop() {
  const [{ user, currentPlugin }, dispatch] = useContext();

  const params = useParams();
  const [data, setData] = useState({});
  const [videoUrl, setVideoUrl] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

  useEffect(() => {
    const getFunnelData = async () => {
      try {
        const response = await facepopService.standAloneData(
          params.pluginToken,
        );
        if (response.success) {
          console.log("get facepop data: ", response);
          setData(response.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("get facepop data error: ", response);
          setError(true);
          toast.error(response.status);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        toast.error(err.message);
      }
    };

    getFunnelData();
  }, []);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {" "}
            You have entered a broken link!{" "}
          </h3>
        </div>
      </div>
    );
  }
  return (
    <>
      <header className="py-6 px-4">
        <div className="mx-auto w-14 md:w-24">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
        </div>
      </header>
      <div className="min-h-screen flex items-center justify-center">
        {data ? <FacepopWidget data={data} /> : <h1>Facepop not found</h1>}
      </div>
    </>
  );
}

export default StandAloneFacePop;
