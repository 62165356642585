import React, { useEffect, useRef, useState } from "react";
import { MdCancel } from "react-icons/md";

import { MyConstextApi } from "../../../../context/ContextApi";
import { IconArrowRight } from "./../icons/RightArrow";
import AuthorInfo from "./AuthorInfo";
import PlayerController from "./PlayerController";
import VideoComponent from "./VideoComponent";
import VideoInfo from "./VideoInfo";
import VideoProgressBar from "./VideoProgressbar";

const FacepopWidget = ({ data }) => {
  const playVideoRef = useRef();

  const {
    redirectUrl,
    newWindow,
    bgColor2: exBgColor2,
    bgColor3: exBgColor3,
    redirectButtonText,
    redirectButtonHeight,
    redirectButtonUpdate,
    redirecticonIndex,
    redirectUrl3,
    redirectButtonHeight3,
    redirectButtonText3,
    redirectButtonUpdate3,
    bgColor4: exBgColor4,
    bgColor5: exBgColor5,
    redirecticonIndex3,

    textInputType,

    textInputPlaceHolder,

    textInputbgColor,

    textInputTextColor,

    textInputUpdate,
  } = MyConstextApi();

  const [isToggle, setIsToggle] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [isMuted, setMutedd] = useState(false);

  const selectIcon = IconArrowRight[redirecticonIndex];
  const selectIcon2 = IconArrowRight[redirecticonIndex3];

  useEffect(() => {
    const videoElement = playVideoRef.current;

    if (!videoElement) {
      console.error("Video element not found");
      return;
    }

    const updateProgress = () => {
      const progress = (videoElement.currentTime / videoElement.duration) * 100;
      console.log("Current progress:", progress);
      setVideoProgress(progress);
    };

    videoElement.addEventListener("timeupdate", updateProgress);

    return () => {
      videoElement.removeEventListener("timeupdate", updateProgress);
    };
  }, [playVideoRef, videoProgress]);

  const handleProgressBarClick = (e) => {
    e.stopPropagation();

    const progressBar = e.currentTarget;
    const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
    const percentageClicked = (clickPosition / progressBar.clientWidth) * 100;
    const newTime = (percentageClicked / 100) * playVideoRef.current.duration;

    playVideoRef.current.currentTime = newTime;
    setVideoProgress(percentageClicked);
  };

  const onPlayHandler = () => {
    const video = playVideoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsToggle(false);
      setIsPlaying(true);
    }
  };

  const onCloseHandler = () => {
    const video = playVideoRef.current;

    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    }
    setIsToggle(true);
  };

  const onToggleMute = (e) => {
    e.stopPropagation();
    const video = playVideoRef.current;
    setMutedd(!isMuted);
    video.volume = isMuted ? 1 : 0;
  };

  // console.log("widget data: ", data);

  return (
    <>
      <div
        // onClick={onPlayHandler}
        style={{
          marginInline: `${data.facePopStyle.horizontalMargin}px`,
          marginBlock: `${data.facePopStyle.verticalMargin}px`,
          width: `${
            isToggle ? data.toggleStyle.width : data.playerStyle.width
          }px`,
          transition: "all 0.3s ease 0s",
        }}
        className={`fixed bottom-2 z-50 ${
          isToggle && "hover:scale-105"
        } cursor-pointer ${
          data.facePopStyle.position === "left"
            ? "left-2"
            : data.facePopStyle.position === "right"
            ? "right-2"
            : "left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        }

          `}
      >
        <div className="relative w-full">
          <VideoComponent
            playVideoRef={playVideoRef}
            video={data.videoURL}
            thumbnail={data.thumbnail}
            isToggled={isToggle}
            shadow={data.facePopStyle.shadow}
            isplaying={isPlaying}
            playerHeight={data.playerStyle.height}
            height={data.toggleStyle.height}
            position={data.playerPosition}
            radious={data.toggleStyle.cornerRadius}
            playAgain={data.behavior}
            onPlayHandler={onPlayHandler}
            bgColor9={data.toggleColor.playIcon}
          />

          {data.toggleStyle.showCloseIcon && !isToggle && (
            <div className="absolute right-0 top-0 z-[99999]">
              <button onClick={onCloseHandler}>
                <MdCancel
                  style={{
                    fontSize: "35px",
                    color: data.toggleColor.clsIconColor,
                  }}
                />
              </button>
            </div>
          )}

          {!isToggle && (
            <div className="absolute w-full bottom-5 left-2 flex justify-center items-center">
              <div className="w-3/4">
                <VideoInfo
                  videoTitle={data.videoTitle}
                  videoDesc={data.videDesc}
                  redirectUrl={redirectUrl}
                  bgColor={data.generalSettings.bgColor}
                  bgColor2={data.generalSettings.descColor}
                  redirectButtonText={redirectButtonText}
                  newWindow={newWindow}
                  redirectButtonHeight={redirectButtonHeight}
                  exBgColor3={exBgColor3}
                  exBgColor2={exBgColor2}
                  selectIcon={selectIcon}
                  redirectButtonUpdate={redirectButtonUpdate}
                  redirectButtonUpdate3={redirectButtonUpdate3}
                  textInputUpdate={textInputUpdate}
                  redirectButtonText3={redirectButtonText3}
                  exBgColor5={exBgColor5}
                  exBgColor4={exBgColor4}
                  selectIcon2={selectIcon2}
                  redirectButtonHeight3={redirectButtonHeight3}
                  redirectUrl3={redirectUrl3}
                  textInputType={textInputType}
                  textInputPlaceHolder={textInputPlaceHolder}
                  textInputbgColor={textInputbgColor}
                  textInputTextColor={textInputbgColor}
                />
              </div>

              <div className="w-1/4">
                {/* Control Buttons (Play, Mute, Volume) */}
                <PlayerController
                  playerController={data.playerStyle.showControls}
                  isplaying={isPlaying}
                  onPlayHandler={onPlayHandler}
                  onToggleMute={onToggleMute}
                  isMuted={isMuted}
                />
              </div>
            </div>
          )}

          {!isToggle && (
            <VideoProgressBar
              progress={videoProgress}
              handleProgressBar={handleProgressBarClick}
            />
          )}

          {!isToggle && (
            <AuthorInfo
              authorName={data.authorName}
              authorOccupation={data.occupation}
              imageUrl2={data.authorImg}
              bgColor7={data.playerColor.authorName}
              bgColor8={data.playerColor.authorOccup}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FacepopWidget;
