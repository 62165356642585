import React from 'react';
import Select from 'react-select';

function CustomSelect({ options, value, onChange }) {
  return (
    <div className="p-2 m-2 rounded-md outline-non">
      <Select
        options={options}
        value={value}
        onChange={onChange}
        isClearable={true}
        // className="p-2 m-2"
      />
    </div>
  );
}

export default CustomSelect;
