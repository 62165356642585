const mortgageRates = function () {
  const mortgageRateToken = localStorage.getItem('mortgageRatesToken');

  const initMortgageRatesWidget = function (el) {
    var d = document.createElement('div');
    d.classList.add('mtge-rates_embeded');
    d.setAttribute('id', 'mtge-rates_embeded');
    d.setAttribute(
      'data-sitekey',
      `${mortgageRateToken}`
      // 'XJ6n-ZGT9eagR54P1R_Or6ujgJLc3IWFTl8bb9u8HU8'
    );
    el.appendChild(d);

    var qs = document.createElement('script');
    qs.src = `https://mortgage.shadman-software-development.website/static/js/morgage-rates.js`;
    qs.defer = false;

    document.head.appendChild(qs);

    var l1 = document.createElement('link');
    l1.setAttribute('rel', 'stylesheet');
    l1.setAttribute('type', 'text/css');
    l1.setAttribute(
      'href',
      `https://mortgage.shadman-software-development.website/static/css/morgage-rates.css`
    );
    document.head.appendChild(l1);
  };

  const el = this;

  if (typeof document.currentScript == 'undefined') {
    const scriptTag = document.createElement('script');
    scriptTag.onload = function () {
      initMortgageRatesWidget(el);
    };
    scriptTag.src = `https://mortgage.shadman-software-development.website/static/js/morgage-rates.js`;
    document.body.appendChild(scriptTag);
  } else {
    initMortgageRatesWidget(el);
  }
};

export default mortgageRates;
