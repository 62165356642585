import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import { currencyWithCommas } from '../../utils/functions';
import ActionTypes from '../../utils/actionTypes';
import { usePlacesWidget } from 'react-google-autocomplete';
import GoogleMapReact from 'google-map-react';
import { FadeIn } from 'react-slide-fade-in';
import ViewAd from './ViewAd';

import adminService from '../../services/adminBulder.service';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import ModalWrapper from '../Modal';
import ModalLoader from './../Loader/ModalLoader';
import ModalMain from '../ModalMain';
import AdblockerDetect from './AdBlcoker';
import MortgageRates from './calcFunnels/MortgageRates/MortgageRates';
import CheckHomeValue from './calcFunnels/checkHomeValue/CheckHomeValue';

ChartJS.register(ArcElement, Tooltip, Legend);

const calculateMortgage = ({
  home_price,
  down_payment,
  interest_rate,
  term,
  start_time,
  pmi,
  taxes,
  insurance,
  hoa,
  extra_payments,
}) => {
  const calculatedValues = {
    home_price,
    monthly: {
      principal_interest: 0,
      extra_payments: 0,
      pmi: 0,
      taxes: 0,
      insurance: 0,
      hoa: 0,
      total: 0,
    },
    total: {
      down_payment: 0,
      principal: 0,
      ptihoa: 0,
      interest: 0,
      extra_payments: 0,
      value: 0,
    },
    schedule: [],
  };

  // monthly
  let p = home_price - down_payment;
  let r = interest_rate / 100 / 12;
  let n = term * 12;
  let m = p * ((r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1));

  calculatedValues.monthly.principal_interest = m;
  calculatedValues.monthly.extra_payments = extra_payments.monthly;
  calculatedValues.monthly.pmi = (pmi * 2.5) / 30;
  calculatedValues.monthly.taxes = (taxes * 2.5) / 30;
  calculatedValues.monthly.insurance = (insurance * 2.5) / 30;
  calculatedValues.monthly.hoa = hoa;
  calculatedValues.monthly.total =
    m +
    extra_payments.monthly +
    calculatedValues.monthly.pmi +
    calculatedValues.monthly.taxes +
    calculatedValues.monthly.insurance +
    hoa;

  // amortization schedule
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let total_interest = 0;
  let end = false;

  for (let i = 0; i < n && !end; i++) {
    let start_month = start_time.month.split('-');
    let start_year = Number(start_time.year);

    let payment_date, interest, total_monthly, ptihoa, balance, principal;

    if (i === 0) {
      payment_date = start_month[0] + ' ' + start_year;
      interest = p * r;
      total_monthly = calculatedValues.monthly.total;
      ptihoa =
        calculatedValues.monthly.pmi +
        calculatedValues.monthly.taxes +
        calculatedValues.monthly.insurance +
        hoa;
      balance = p - (total_monthly - interest - ptihoa);
      principal = total_monthly - interest - ptihoa;
    } else {
      payment_date =
        months[(Number(start_month[1]) + i - 1) % 12] +
        ' ' +
        (start_year + Math.floor((Number(start_month[1]) + i - 1) / 12));
      interest = calculatedValues.schedule[i - 1].balance * r;
      total_monthly = calculatedValues.monthly.total;
      ptihoa =
        calculatedValues.monthly.pmi +
        calculatedValues.monthly.taxes +
        calculatedValues.monthly.insurance +
        hoa;

      if ((calculatedValues.schedule[i - 1].balance * 100) / home_price < 80) {
        total_monthly -= calculatedValues.monthly.pmi;
        ptihoa =
          calculatedValues.monthly.taxes +
          calculatedValues.monthly.insurance +
          hoa;
      }

      balance =
        calculatedValues.schedule[i - 1].balance -
        (total_monthly - interest - ptihoa);
      principal = total_monthly - interest - ptihoa;

      if (calculatedValues.schedule[i - 1].balance <= principal) {
        total_monthly = calculatedValues.schedule[i - 1].balance + interest;
        balance = 0;
        principal = calculatedValues.schedule[i - 1].balance;
        end = true;
      }
    }

    if (
      extra_payments.yearly.start_month === payment_date.split(' ')[0] &&
      extra_payments.yearly.value > 0
    ) {
      if (
        calculatedValues.schedule[i - 1].balance < extra_payments.yearly.value
      ) {
        total_monthly = calculatedValues.schedule[i - 1].balance + interest;
        principal = calculatedValues.schedule[i - 1].balance;
        balance = 0;
        calculatedValues.total.extra_payments += extra_payments.yearly.value;
        end = true;
      } else {
        total_monthly += extra_payments.yearly.value;
        principal += extra_payments.yearly.value;
        balance -= extra_payments.yearly.value;
        calculatedValues.total.extra_payments += extra_payments.yearly.value;
      }
    }

    calculatedValues.total.extra_payments += extra_payments.monthly;
    calculatedValues.total.ptihoa += ptihoa;
    calculatedValues.total.interest += interest;

    total_interest += interest;
    calculatedValues.schedule.push({
      date: payment_date,
      balance: balance,
      payment: total_monthly,
      principal: principal,
      interest: interest,
      totalInterest: total_interest,
      ptihoa: ptihoa,
    });
  }

  // total
  calculatedValues.total.down_payment = down_payment;
  calculatedValues.total.principal =
    home_price - down_payment - calculatedValues.total.extra_payments;
  calculatedValues.total.value =
    down_payment +
    calculatedValues.total.principal +
    calculatedValues.total.interest +
    calculatedValues.total.extra_payments +
    calculatedValues.total.ptihoa;

  return calculatedValues;
};

function Preview({
  pluginLogo,
  leadName,
  leadLink,
  behaviour,
  backgroundColor,
  titleColor,
  pluginTitle,
  textColor,
  buttonColor,
  buttonTextColor,
  varbiage,
  homeSearchPage,
  calenderBookingPage,
  showEmailPaymentDetails,
  showViewProperties,
  showPreQualified,
  showCheckHomeValues,
}) {
  const [{ user, currentPlugin, calc_inputs }, dispatch] = useContext();
  const [hasExtraPayments, setHasExtraPayments] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showLeads, setShowLeads] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showZestimates, setShowZestimates] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});

  const [zestimatesLocation, setZestimatesLocation] = useState(null);

  const [rawData, setRawData] = useState({});

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [activatedAds, setActivatedAds] = useState({});
  const [showAd, setShowAd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clearTimer, setClearTimer] = useState(false);

  const handleCalculate = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    const data = {
      home_price: Number(formData.get('home_price')) || 0,
      down_payment: Number(formData.get('down_payment')) || 0,
      interest_rate: Number(formData.get('interest_rate')) || 0,
      term: Number(formData.get('term')) || 0,
      start_time: {
        month: formData.get('start_month'),
        year: formData.get('start_year'),
      },
      pmi: Number(formData.get('pmi')) || 0,
      taxes: Number(formData.get('taxes')) || 0,
      insurance: Number(formData.get('insurance')) || 0,
      hoa: Number(formData.get('hoa')) || 0,
      extra_payments: {
        monthly: hasExtraPayments
          ? Number(formData.get('extra_monthly')) || 0
          : 0,
        yearly: {
          value: hasExtraPayments
            ? Number(formData.get('extra_yearly')) || 0
            : 0,
          start_month: formData.get('extra_yearly-month'),
        },
      },
    };

    dispatch({ type: ActionTypes.SET_CALCULATOR_INPUTS, payload: data });

    if (data.home_price < data.down_payment) {
      alert("Down Payment can't be greater than Home Price");
      return;
    }

    setCalculatedData(calculateMortgage(data));
    // setShowResult(true);
    if (user.accountType === 'Pro' || user.accountType === 'Admin') {
      setShowAd(false);

      setShowResult(true);
    } else {
      setShowAd(true);
      loadAvtivatedAs();
    }

    let uuid = new Date().getTime();
    setUniqueId(uuid);

    axios({
      url: `https://api.ipify.org/?format=json`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIpAddress(resData.ip);
        return axios({
          url: `https://api.ipgeolocation.io/ipgeo`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            apiKey: 'e5180b6fffa242478beafff1a2e579cb',
            fields: 'city,country_name',
            output: 'json',
            ip: resData.ip,
          },
        });
      })
      .then((res) => {
        const resData = res.data;

        axios({
          url: `/api/plugin/newsletter/add/${currentPlugin.token}`,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            ref: uuid,
            ipAddress: resData.ip,
            liveAddress: resData.city + ', ' + resData.country_name,
            noAttachement: true,
            userInputs: data,
          },
        });
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
        // toast.error(error.message || status, {});
      });

    // setTimeout(() => {
    //   if (
    //     showEmailPaymentDetails ||
    //     showViewProperties ||
    //     showPreQualified ||
    //     showCheckHomeValues
    //   ) {
    //     setShowLeads(true);
    //   }
    // }, 5000);
  };

  useEffect(() => {
    // Fetch templates from the server when the component mounts
    if (user.accountType !== 'Admin' && user.accountType !== 'Pro' && showAd) {
      loadAvtivatedAs();
    }
  }, [user, showAd]);

  const loadAvtivatedAs = async () => {
    try {
      const response = await adminService.getActivatedAd();
      const data = response.data;
      if (response.success) {
        console.log('load all activate ads: ', data);
        setActivatedAds(data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error loading templates:', error);
    }
  };

  // useEffect(() => {
  //   console.log('from state', activatedAds);
  // }, [activatedAds]);

  const pluginLogoUrl = useMemo(() => {
    if (currentPlugin.logo.allowed && pluginLogo) {
      return typeof pluginLogo === 'string'
        ? pluginLogo
        : typeof pluginLogo === 'object'
        ? URL.createObjectURL(pluginLogo)
        : '/images/logo.png';
    } else {
      return '/images/logo.png';
    }
  }, [pluginLogo]);

  const handleReset = () => {
    let uuid = new Date().getTime();
    setUniqueId(uuid);
    axios({
      url: `https://api.ipify.org/?format=json`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIpAddress(resData.ip);
        return axios({
          url: `https://api.ipgeolocation.io/ipgeo`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            apiKey: 'deb9a79bf3024e94865209fd35da0521',
            fields: 'city,country_name',
            output: 'json',
            ip: resData.ip,
          },
        });
      })
      .then((res) => {
        const resData = res.data;

        axios({
          url: `/api/plugin/newsletter/add/${currentPlugin.token}`,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            ref: uuid,
            ipAddress: resData.ip,
            liveAddress: resData.city + ', ' + resData.country_name,
            noAttachement: true,
            userInputs: calc_inputs,
          },
        });
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleCloseAd = () => {
    setActivatedAds({});
    setShowAd(false);
    setShowResult(true);
  };

  const handleWarningClose = () => {
    setShowAd(false);
    setShowResult(false);
  };

  useEffect(() => {
    let timer;
    console.log('timer: ', clearTimer);
    const leadTimer = () => {
      if (
        (showEmailPaymentDetails ||
          showViewProperties ||
          showPreQualified ||
          showCheckHomeValues) &&
        showResult
      ) {
        setShowLeads(true);
      }
    };

    if (showResult && !clearTimer) {
      timer = setTimeout(leadTimer, 5000);

      return () => {
        clearTimeout(timer);
      };
    } else if (clearTimer) {
      clearTimeout(timer);
    }
  }, [clearTimer, showResult, clearTimer]);

  return (
    <div id="mcw_preview" className="mcw_preview">
      {/* <AdblockerDetect user={user} /> */}
      <div
        id=""
        className="mtge-calc_container mtge-calc_container-shadow mtge-calc_show overflow-hidden"
        style={{
          background: backgroundColor,
          color: textColor,
        }}
      >
        <div className="mtge-calc_widget-header">
          {behaviour === 'withMinimize' ||
          behaviour === 'displayAsAFlexiFrame' ? (
            <svg
              id="mtge-calc-close"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '24px', height: '24px' }}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15"
              />
            </svg>
          ) : (
            <div></div>
          )}

          <a onClick={() => setShowShareOptions(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: '20px', height: '20px' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
              />
            </svg>
          </a>
        </div>

        <div className="mtge-calc_title">
          <img
            className="mr-2 w-6 h-6 object-cover"
            src={pluginLogoUrl}
            alt="Logo"
            onError={({ currentTarget }) => {
              console.log(currentTarget);
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/logo.png';
            }}
          />

          <h1 style={{ color: titleColor }}>{pluginTitle}</h1>
        </div>

        <form
          id="mtge-calc_form"
          className="mtge-calc_fields_container"
          onSubmit={handleCalculate}
        >
          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Home Price</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                required
                id="mtge-home_price"
                type="number"
                name="home_price"
                step=".01"
                defaultValue="350000"
              />
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Down Payment</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                id="mtge-down_payment"
                type="number"
                name="down_payment"
                step=".01"
                defaultValue="52000"
              />
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Interest Rate</div>
            <div className="mtge-calc_field-input">
              <input
                required
                id="mtge-interest_rate"
                type="number"
                name="interest_rate"
                step=".01"
                min="0"
                max="100"
                defaultValue="4.05"
              />
              <span className="mtge-calc_symbol"> % </span>
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Mortgage Term</div>
            <div className="mtge-calc_field-input">
              <input
                required
                id="mtge-term"
                type="number"
                name="term"
                defaultValue="30"
              />
              <span className="mtge-calc_symbol"> years </span>
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Start Date</div>
            <div className="mtge-calc_field-input mtge-calc_field-select">
              <div className="mtge-calc_selector">
                <select name="start_month" required>
                  <option value="Jan-1">Jan</option>
                  <option value="Feb-2">Feb</option>
                  <option value="Mar-3">Mar</option>
                  <option value="Apr-4">April</option>
                  <option value="May-5">May</option>
                  <option value="Jun-6">Jun</option>
                  <option value="Jul-7">Jul</option>
                  <option value="Aug-8">Aug</option>
                  <option value="Sep-9">Sep</option>
                  <option value="Oct-10">Oct</option>
                  <option value="Nov-11">Nov</option>
                  <option value="Dec-12">Dec</option>
                </select>
              </div>
              <div className="mtge-calc_selector">
                <select name="start_year" required>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">PMI</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                id="mtge-pmi"
                type="number"
                name="pmi"
                step=".01"
                defaultValue="1881.24"
              />
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Taxes</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                id="mtge-taxes"
                type="number"
                name="taxes"
                step=".01"
                defaultValue="4375"
              />
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">Insurance</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                id="mtge-insurance"
                type="number"
                name="insurance"
                step=".01"
                defaultValue="1225"
              />
            </div>
          </div>

          <div className="mtge-calc_field">
            <div className="mtge-calc_field-label">HOA</div>
            <div className="mtge-calc_field-input">
              <span className="mtge-calc_symbol"> $ </span>
              <input
                id="mtge-hoa"
                type="number"
                name="hoa"
                step=".01"
                defaultValue="0"
              />
            </div>
          </div>

          <button
            className="mtge-calc_extra_payment-btn"
            type="button"
            onClick={() => setHasExtraPayments((prev) => !prev)}
          >
            {hasExtraPayments ? 'Remove extra payments' : 'Add extra payments'}
          </button>

          <div className="mtge-calc_extra_container">
            <div
              className={`mtge-calc_field mtge-calc_extra ${
                hasExtraPayments ? '' : 'mtge-calc_extra-closed'
              } `}
            >
              <div className="mtge-calc_field-label">To monthly</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-extra_monthly"
                  type="number"
                  name="extra_monthly"
                  step=".01"
                  defaultValue="0"
                />
              </div>
            </div>

            <div
              className={`mtge-calc_field mtge-calc_extra ${
                hasExtraPayments ? '' : 'mtge-calc_extra-closed'
              } `}
            >
              <div className="mtge-calc_field-label">Extra yearly</div>
              <div className="mtge-calc_field-input">
                <div
                  className="flex-1"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className="mtge-calc_symbol"> $ </span>
                  <input
                    id="mtge-extra_yearly"
                    type="number"
                    name="extra_yearly"
                    step=".01"
                    defaultValue="0"
                  />
                </div>
                <div className="mtge-calc_field-select flex-1">
                  <div className="mtge-calc_selector">
                    <select name="extra_yearly-month">
                      <option value="Jan">Jan</option>
                      <option value="Feb">Feb</option>
                      <option value="Mar">Mar</option>
                      <option value="Apr">April</option>
                      <option value="May">May</option>
                      <option value="Jun">Jun</option>
                      <option value="Jul">Jul</option>
                      <option value="Aug">Aug</option>
                      <option value="Sep">Sep</option>
                      <option value="Oct">Oct</option>
                      <option value="Nov">Nov</option>
                      <option value="Dec">Dec</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            className="mtge-calc_calculate-btn"
            type="submit"
            style={{ color: buttonTextColor, background: buttonColor }}
          >
            Calculate
          </button>
        </form>

        {user.accountType !== 'Pro' && user.accountType !== 'Admin' ? (
          <>
            {showAd && activatedAds.activate ? (
              <ViewAd
                adsData={activatedAds}
                loading={loading}
                onClose={handleCloseAd}
                warningClose={handleWarningClose}
              />
            ) : (
              <>
                {showResult && (
                  <ResultModal
                    monthly={calculatedData.monthly}
                    total={calculatedData.total}
                    schedule={calculatedData.schedule}
                    setShowResult={setShowResult}
                    setShowShareModal={setShowShareModal}
                    setCalculatedData={setCalculatedData}
                    setShowContactModal={setShowContactModal}
                    setShowLeads={setShowLeads}
                    setClearTimer={setClearTimer}
                  />
                )}

                {showLeads && (
                  <Leads
                    home_price={calculatedData.home_price}
                    rawData={rawData}
                    leadName={leadName}
                    leadLink={leadLink}
                    calculatedData={calculatedData}
                    onClose={() => setShowLeads(false)}
                    homeSearchPage={homeSearchPage}
                    calenderBookingPage={calenderBookingPage}
                    setShowZestimates={setShowZestimates}
                    setZestimatesLocation={setZestimatesLocation}
                    zestimatesLocation={zestimatesLocation}
                    uniqueId={uniqueId}
                    handleReset={handleReset}
                    showEmailPaymentDetails={showEmailPaymentDetails}
                    showViewProperties={showViewProperties}
                    showPreQualified={showPreQualified}
                    showCheckHomeValues={showCheckHomeValues}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {showResult && (
              <ResultModal
                monthly={calculatedData.monthly}
                total={calculatedData.total}
                schedule={calculatedData.schedule}
                setShowResult={setShowResult}
                setShowShareModal={setShowShareModal}
                setCalculatedData={setCalculatedData}
                setShowContactModal={setShowContactModal}
                setShowLeads={setShowLeads}
                setClearTimer={setClearTimer}
              />
            )}

            {showLeads && (
              <Leads
                home_price={calculatedData.home_price}
                rawData={rawData}
                leadName={leadName}
                leadLink={leadLink}
                calculatedData={calculatedData}
                onClose={() => setShowLeads(false)}
                homeSearchPage={homeSearchPage}
                calenderBookingPage={calenderBookingPage}
                setShowZestimates={setShowZestimates}
                setZestimatesLocation={setZestimatesLocation}
                zestimatesLocation={zestimatesLocation}
                uniqueId={uniqueId}
                handleReset={handleReset}
                showEmailPaymentDetails={showEmailPaymentDetails}
                showViewProperties={showViewProperties}
                showPreQualified={showPreQualified}
                showCheckHomeValues={showCheckHomeValues}
              />
            )}
          </>
        )}

        {showShareModal && (
          <ShareModal
            calculatedData={calculatedData}
            setShowShareModal={setShowShareModal}
          />
        )}

        {showContactModal && (
          <ContactUs
            onClose={() => setShowContactModal(false)}
            calculatedData={calculatedData}
            homeSearchPage={homeSearchPage}
            calenderBookingPage={calenderBookingPage}
            uniqueId={uniqueId}
            handleReset={handleReset}
          />
        )}

        {showZestimates && (
          <Zestimates
            onClose={() => setShowZestimates(false)}
            location={zestimatesLocation}
          />
        )}

        {showShareOptions && (
          <ShareModalOptions
            pluginTitle={pluginTitle}
            onClose={() => setShowShareOptions(false)}
          />
        )}
      </div>
      <div className="mtge-calc_discloser-container">
        <div className="mtge-calc_discloser">
          {varbiage.map((dt, idx) => {
            if (dt.text) {
              return (
                <a key={idx} target="_blank" href={dt.link}>
                  {dt.text}
                </a>
              );
            }
          })}
        </div>
        <div className="mtge-calc_discloser">
          <a target="_blank" href="#terms">
            Terms & Conditions
          </a>
        </div>
        <div className="mtge-calc_discloser">
          <a target="_blank" href="http://www.SavageSales.com">
            Provided by Savage Sales
          </a>
        </div>
      </div>
    </div>
  );
}

function ResultModal({
  monthly,
  total,
  schedule,
  setShowResult,
  setShowShareModal,
  setShowContactModal,
  setCalculatedData,
  setShowLeads,
  setClearTimer,
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    let total_amount =
      total.principal +
      total.interest +
      total.down_payment +
      total.extra_payments;

    const data = {
      labels: [
        'Total interest paid',
        'Down payment',
        'Extra payments',
        'Total principal paid',
      ],
      datasets: [
        {
          data: [
            ((total.interest / total_amount) * 100).toFixed(0),
            ((total.down_payment / total_amount) * 100).toFixed(0),
            ((total.extra_payments / total_amount) * 100).toFixed(0),
            ((total.principal / total_amount) * 100).toFixed(0),
          ],
          backgroundColor: [
            'rgb(255 144 26 / 85%)',
            'rgb(244 49 91 / 85%)',
            'rgb(255 196 46 / 85%)',
            'rgb(35 182 185 / 86%)',
          ],
          hoverOffset: 1,
        },
      ],
    };

    setData(data);
  }, []);

  return (
    <div className="mtge-calc_modal-wrapper-result mtge-calc_modal-wrapper-show slide-in modal-scroll">
      <div className="mtge-calc_modal-overlay"></div>
      <div
        className="mtge-calc_modal modal-scroll"
        id="mtge-calc_modal-result"
        onClick={() => setShowResult(false)}
      >
        <div
          className="mtge-calc_modal_wrapper"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            id="mtge-calc_result-close"
            className="mtge-calc_modal-close"
            onClick={() => {
              setShowResult(false);
              setClearTimer(false);
            }}
          >
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <table className="mtge-calc_table">
            <tbody>
              <tr>
                <td>Monthly Principal &amp; Interest </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.principal_interest)}
                </td>
              </tr>
              <tr>
                <td>Monthly Extra Payment </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.extra_payments)}
                </td>
              </tr>
              <tr>
                <td>PMI </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.pmi)}
                </td>
              </tr>
              <tr>
                <td>Taxes </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.taxes)}
                </td>
              </tr>
              <tr>
                <td>Insurance </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.insurance)}
                </td>
              </tr>
              <tr>
                <td>HOA </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.hoa)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>MONTHLY PAYMENT </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(monthly.total)}
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="mtge-calc_chart_container">
            <div className="mtge-calc_dogout-chart">
              {data && (
                <Doughnut
                  data={data}
                  options={{
                    responsive: true,
                    cutout: '70%',
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (Item) =>
                            Item.label + ' ' + Item.formattedValue + '%',
                        },
                      },
                    },
                    animation: {
                      onComplete: function (e) {
                        let base64 =
                          e.chart.toBase64Image && e.chart.toBase64Image();
                        if (base64) {
                          setCalculatedData((prev) => ({
                            ...prev,
                            chart: base64,
                          }));
                        }
                      },
                    },
                  }}
                />
              )}
            </div>
            <table className="mtge-calc_table">
              <tbody>
                <tr>
                  <td>Down payment </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.down_payment)}
                  </td>
                </tr>
                <tr>
                  <td>Extra payments </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.extra_payments)}
                  </td>
                </tr>
                <tr>
                  <td>Total principal paid </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.principal)}
                  </td>
                </tr>
                <tr>
                  <td>Total interest paid </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.interest)}
                  </td>
                </tr>
                <tr>
                  <td>PMI, Taxes, Insurance, HOA </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.ptihoa)}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>TOTAL OF ALL PAYMENTS </td>
                  <td className="mtge-calc_text-right">
                    ${currencyWithCommas(total.value)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="mtge-calc_amortization">
            <h2 className="mtge-calc_amortization-title">
              Amortization Schedule
            </h2>
            <div className="mtge-calc_amortization-schedule">
              <table className="mtge-calc_table mtge-calc_table-schedule">
                <thead>
                  <tr>
                    <th>Payment date </th>
                    <th>Payment</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Total Interest </th>
                    <th>PMI, Taxes, Ins, HOA </th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.map((dt, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{dt.date}</td>
                        <td className="mtge-calc_text-center">
                          ${currencyWithCommas(dt.payment)}
                        </td>
                        <td className="mtge-calc_text-center">
                          ${currencyWithCommas(dt.principal)}
                        </td>
                        <td className="mtge-calc_text-center">
                          ${currencyWithCommas(dt.interest)}
                        </td>
                        <td className="mtge-calc_text-center">
                          ${currencyWithCommas(dt.totalInterest)}
                        </td>
                        <td className="mtge-calc_text-center">
                          ${currencyWithCommas(dt.ptihoa)}
                        </td>
                        <td className="mtge-calc_text-right">
                          ${currencyWithCommas(dt.balance)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={() => setShowShareModal(true)}
              className="mtge-calc_share-btn"
            >
              {' '}
              Send with E-mail{' '}
            </button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                onClick={() => setShowContactModal(true)}
                className="mtge-calc_share-btn"
                style={{ marginRight: '8px' }}
              >
                Contact Us
              </button>
              <button
                onClick={() => {
                  setShowLeads(true);
                  setClearTimer(true);
                }}
                className="mtge-calc_share-btn"
              >
                Menu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Leads({
  uniqueId,
  onClose,
  leadName,
  leadLink,
  rawData,
  home_price,
  calculatedData,
  homeSearchPage,
  calenderBookingPage,
  setShowZestimates,
  setZestimatesLocation,
  zestimatesLocation,
  handleReset,
  showEmailPaymentDetails,
  showViewProperties,
  showPreQualified,
  showCheckHomeValues,
}) {
  const [{ currentPlugin }, dispatch] = useContext();
  const [leadType, setLeadType] = useState('');
  const [currentScreen, setCurrentSreen] = useState('init-1');
  const [prevScreen, setPrevSreen] = useState(null);

  const [redirect, setRedirect] = useState(true);

  const handleEmailReport = (e, type) => {
    if (type) {
      setLeadType(type);
    } else {
      setLeadType('Email Payment Details');
    }
    setRedirect(false);
    setPrevSreen('init-1');
    setCurrentSreen('leadForm');
  };
  const handleViewProperties = (e, type) => {
    if (type) {
      setLeadType(type);
    } else {
      setLeadType('View Properties');
    }
    setRedirect(true);
    setPrevSreen('init-1');
    setCurrentSreen('viewProperties');
  };
  const handlePreQualified = () => {
    setRedirect(false);
    setLeadType('Check Mortgage Rates');
    setPrevSreen('init-1');
    setCurrentSreen('preQualified');
  };
  const handleHomeValues = () => {
    setLeadType('Home Values');
    setPrevSreen('init-1');
    setCurrentSreen('homeValues');
  };

  const handlePrevious = () => {
    setRedirect(false);
    setCurrentSreen(prevScreen);
    setPrevSreen(null);
  };

  const captureUserEvents = (data) => {
    axios({
      url: `/api/plugin/newsletter/add/${currentPlugin.token}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        noAttachement: true,
        ...data,
      },
    })
      .then((res) => {})
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  return (
    <ModalMain show={true} onClose={onClose}>
      <div className="lead_container">
        {/* <FadeIn
          from="bottom"
          positionOffset={200}
          triggerOffset={100}
          delayInMilliseconds={0}
        > */}
        <div
          className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper_lead w-full max-w-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mx-auto flex justify-center items-center space-x-1">
            <img
              src="/images/logo.png"
              className="w-7 h-7 object-cover"
              alt="Savage Sales Logo"
            />
            <span className="text-sm font-semibold"> Savage Sales </span>
          </div>
          {prevScreen && (
            <div
              className="mtge-calc_lead-prev slide-in-left"
              onClick={handlePrevious}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>
            </div>
          )}

          <div
            id="mtge-calc_newsletter-close"
            className="mtge-calc_modal-close"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
          {currentScreen === 'init-1' ? (
            <div className="mtge-calc_lead-container">
              {showEmailPaymentDetails && (
                <button className="mtge-calc_btn" onClick={handleEmailReport}>
                  Email Payment Details
                </button>
              )}
              {showViewProperties && (
                <button
                  className="mtge-calc_btn"
                  onClick={handleViewProperties}
                >
                  {leadName}
                </button>
              )}
              {showPreQualified && (
                <button className="mtge-calc_btn" onClick={handlePreQualified}>
                  Check Mortgage Rates
                </button>
              )}

              {showCheckHomeValues && (
                <button className="mtge-calc_btn" onClick={handleHomeValues}>
                  Check Home Values
                </button>
              )}
            </div>
          ) : currentScreen === 'leadForm' ? (
            <LeadForm
              onClose={onClose}
              leadName={leadName}
              leadType={leadType}
              rawData={rawData}
              calculatedData={calculatedData}
              redirect={redirect}
              uniqueId={uniqueId}
              handleReset={handleReset}
            />
          ) : currentScreen === 'preQualified' ? (
            <MortgageRates />
          ) : // <PreQualified
          //   handleEmailReport={(e) =>
          //     handleEmailReport(e, 'Check Mortgage Rates')
          //   }
          //   handleViewProperties={(e) =>
          //     handleViewProperties(e, 'Check Mortgage Rates')
          //   }
          //   uniqueId={uniqueId}
          //   handleReset={handleReset}
          // />
          currentScreen === 'viewProperties' ? (
            <ViewProperties
              onClose={onClose}
              leadType={leadType}
              rawData={rawData}
              calculatedData={calculatedData}
              homePrice={home_price}
              homeSearchPage={homeSearchPage}
              calenderBookingPage={calenderBookingPage}
              uniqueId={uniqueId}
              handleReset={handleReset}
              captureUserEvents={captureUserEvents}
            />
          ) : currentScreen === 'homeValues' ? (
            <CheckHomeValue />
          ) : (
            // <HomeValues
            //   onClose={onClose}
            //   leadType={leadType}
            //   calculatedData={calculatedData}
            //   homePrice={home_price}
            //   homeSearchPage={homeSearchPage}
            //   calenderBookingPage={calenderBookingPage}
            //   setShowZestimates={setShowZestimates}
            //   setZestimatesLocation={setZestimatesLocation}
            //   zestimatesLocation={zestimatesLocation}
            //   uniqueId={uniqueId}
            //   handleReset={handleReset}
            //   captureUserEvents={captureUserEvents}
            // />
            ''
          )}
        </div>
        {/* </FadeIn> */}
      </div>
    </ModalMain>
  );
}

function LeadForm({
  onClose,
  leadType,
  rawData,
  calculatedData,
  redirectLink,
  redirect,
  viewPropertiesDetails,
  setShowZestimates,
  uniqueId,
  handleReset,
}) {
  const [{ currentPlugin, calc_inputs }, dispatch] = useContext();
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      url: `/api/plugin/newsletter/add/${currentPlugin.token}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        fullName,
        email,
        phone,
        leadType,
        userInputs: calc_inputs,
        data: calculatedData,
        searchDetails: viewPropertiesDetails,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setLoading(false);
          dispatch({ type: ActionTypes.CLEAR_CALCULATOR_INPUTS });
          toast.success('Successfully done!', { className: 'z-toast' });
          if (redirect) {
            window.open(redirectLink);
          }
          if (leadType === 'Home Values') {
            setShowZestimates(true);
          }
          onClose();
          handleReset();
        } else {
          setLoading(false);
          toast.error(resData.status, {
            className: 'z-toast',
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, { className: 'z-toast' });
      });
  };

  return (
    <div className="slide-in-left">
      <h2 className="mtge-calc_newsletter-title">{leadType}</h2>

      <form onSubmit={handleSubmit}>
        <div className="mtge-calc_newsletter-field">
          <label> Full Name* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="text"
              name="full_name"
              placeholder="Full name"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Email* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Phone (verify you're human) </label>
          <div className="mtge-calc_newsletter-field_input">
            <PhoneInput
              international
              defaultCountry="US"
              value={phone}
              onChange={setPhone}
              required
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <div className="mtge-calc_newsletter-field_checkbox">
            <input
              defaultChecked
              type="checkbox"
              name="accept_privacy"
              required
            />
            <span>
              I agree to receive marketing emails to the email address provided
            </span>
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <button disabled={loading} type="submit">
            Submit
          </button>
        </div>
      </form>
      {loading && <ModalLoader />}
    </div>
  );
}

function PreQualified({ handleEmailReport, handleViewProperties }) {
  return (
    <div className=" mtge-calc_lead_step-preQualified slide-in-left">
      <h2 className="mtge-calc_newsletter-title">Check Mortgage Rates</h2>
      <div className="mtge-calc_notice">
        <h2> We will be in touch soon! </h2>
        <div></div>
      </div>

      <button
        id="mtge-calc_lead-email"
        className="mtge-calc_btn"
        onClick={handleEmailReport}
      >
        Email Payment Details
      </button>
      <button
        id="mtge-calc_lead-properties"
        className="mtge-calc_btn"
        onClick={handleViewProperties}
      >
        View Properties
      </button>
    </div>
  );
}

function ViewProperties({
  onClose,
  leadType,
  rawData,
  calculatedData,
  homePrice,
  homeSearchPage,
  calenderBookingPage,
  uniqueId,
  handleReset,
  captureUserEvents,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const [location, setLocation] = useState('');
  const [range, setRange] = useState({ min: 0, max: 0 });
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [condition, setCondition] = useState('');
  const [squareFootage, setSquareFootage] = useState(0);

  const [redirectLink, setRedirectLink] = useState('');

  useEffect(() => {
    setRange({ min: homePrice - 50000, max: homePrice + 50000 });
  }, [homePrice]);

  const handleNext = () => {
    captureUserEvents({
      leadType,
      searchDetails: {
        location,
        priceRange: range,
        bedrooms,
        bathrooms,
        condition,
        squareFootage,
      },
    });
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <div className="mtge-rates_modal_wrapper max-w-full mt-6 p-0 px-4 md:px-8 bg-white slide-in-left">
      <header className="flex items-center justify-between">
        <div className="w-14 lg:w-16">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar currentStep={currentStep} />
      <main className="mt-4 overflow-hidden">
        {currentStep === 0 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Enter your location
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="location"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="text"
                  name="location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label
                  htmlFor="location"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Location
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={() => {
                  if (location) {
                    handleNext();
                  } else {
                    toast.info('This field is required!');
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 1 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bedrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bedrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bedrooms"
                  required
                  value={bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                />
                <label
                  htmlFor="bedrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bedrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bathrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bathrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bathrooms"
                  required
                  value={bathrooms}
                  onChange={(e) => setBathrooms(e.target.value)}
                />
                <label
                  htmlFor="bathrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bathrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 3 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Approx. Square Footage
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="squareFootage"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="squareFootage"
                  required
                  value={squareFootage}
                  onChange={(e) => setSquareFootage(e.target.value)}
                />
                <label
                  htmlFor="squareFootage"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Square Footage
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 4 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Condition
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-2">
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('New');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'New'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  New
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Fully Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Fully Remodeled'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Fully Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Partially Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Partially Remodeled'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Partially Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Excellent');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Excellent'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Excellent
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Good');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Good'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Good
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Livable');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Livable'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Livable
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Needs TLC');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Needs TLC'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Needs TLC
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Poor');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Poor'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                >
                  Poor
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        ) : currentStep === 5 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Minimum Price Range
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="min-range"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="min-range"
                  required
                  value={range.min}
                  onChange={(e) =>
                    setRange((prev) => ({ ...prev, min: e.target.value }))
                  }
                />
                <label
                  htmlFor="min-range"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Price Range
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 6 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Maximum Price Range
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="max-range"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="max-range"
                  placeholder=""
                  required
                  value={range.max}
                  onChange={(e) =>
                    setRange((prev) => ({ ...prev, max: e.target.value }))
                  }
                />
                <label
                  htmlFor="max-range"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Price Range
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 7 ? (
          <div className="mtge-calc_lead-container px-2 md:px-4">
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(calenderBookingPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View in person
            </button>
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(homeSearchPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View Online
            </button>
          </div>
        ) : currentStep === 8 ? (
          <LeadForm
            onClose={onClose}
            leadType={leadType}
            rawData={rawData}
            calculatedData={calculatedData}
            redirectLink={redirectLink}
            redirect={true}
            viewPropertiesDetails={{
              location,
              priceRange: range,
              bedrooms,
              bathrooms,
              condition,
              squareFootage,
              // choosenBtn: redirectLink,
            }}
            uniqueId={uniqueId}
            handleReset={handleReset}
          />
        ) : (
          ''
        )}
        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep > 0) {
      let totalStep = 9;
      let calculatedPercentage = ((currentStep + 1) / totalStep) * 100;
      setWidth(calculatedPercentage.toFixed(0));
    } else {
      setWidth(0);
    }
  }, [currentStep]);

  return (
    <div className="relative w-full h-[1px] bg-gray-200">
      <div
        className="h-full bg-[#0c0c64] transition-all duration-750 ease-in-out"
        style={{
          width: width + '%',
        }}
      ></div>
      <div
        className="absolute top-2 px-1 py-1 -mx-4 text-white text-[8px] bg-[#0c0c64] rounded-sm after:content-[''] after:absolute after:-top-1 after:left-1/2 after:h-0 after:w-0 after:border-l-4 after:border-r-4 after:border-b-4 after:border-l-transparent after:border-r-transparent after:border-b-[#0c0c64] after:transform after:-translate-x-1/2 transition-all duration-750 ease-in-out"
        style={{
          visibility: width === 0 ? 'hidden' : 'visible',
          left: width + '%',
        }}
      >
        {width}%
      </div>
    </div>
  );
};

const PrevButton = ({ handlePrev }) => {
  return (
    <button
      className="absolute top-1/2 -left-1 p-1 border-2 border-gray-600 border-solid transform -translate-y-1/2"
      onClick={handlePrev}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3 h-3 text-[#0c0c64]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

//

function HomeValues({
  onClose,
  leadType,
  calculatedData,
  setShowZestimates,
  setZestimatesLocation,
  zestimatesLocation,
  uniqueId,
  handleReset,
  captureUserEvents,
}) {
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState('');

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyCzhuxXJQvtSqjsDBL6d_BHD412s2rQwNc',

    options: {
      types: ['address'],
      componentRestrictions: { country: ['us', 'ca'] },
      fields: [
        'adr_address',
        'geometry',
        'address_components',
        'formatted_address',
        'name',
        'place_id',
      ],
    },
    onPlaceSelected: (place) => {
      console.log(place);
      // var formatted_address = place.formatted_address.split(",");
      // var address = place.name;
      // var city = formatted_address[formatted_address.length - 3]?.trim();
      // var state = formatted_address[formatted_address.length - 2]
      //   .trim()
      //   .split(" ")[0]
      //   .trim();
      // var zip = formatted_address[formatted_address.length - 2]
      //   .trim()
      //   .split(" ")[1]
      //   .trim();
      // console.log({
      //   address,
      //   city,
      //   state,
      //   zip,
      // });

      var address = '';
      var city = '';
      var state = '';
      var zip = '';
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address = component.short_name + ' ' + address;
            break;
          case 'route':
            address = address + ' ' + component.short_name;
            break;
          case 'postal_code':
            zip = component.long_name;
            break;

          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1' || 'sublocality_level_1':
            state = component.short_name;
            break;
        }
      }
      setLocation(address + ', ' + city + ', ' + state + ' ' + zip);
      console.log(address + ', ' + city + ', ' + state + ' ' + zip);
      setZestimatesLocation({
        address,
        city,
        state,
        zip,
        geometry: place.geometry,
        formatted_address: address + ', ' + city + ', ' + state + ' ' + zip,
      });
    },
  });

  return (
    <div className="mtge-calc_view-properties slide-in-left">
      {step === 1 ? (
        <div className="mtge-location_suggest mtge-calc_newsletter-field">
          <label htmlFor="location"> Location </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              ref={ref}
              type="text"
              name="location"
              placeholder="Type your location"
              required
            />
          </div>
        </div>
      ) : (
        <LeadForm
          onClose={onClose}
          leadType={leadType}
          calculatedData={calculatedData}
          redirect={false}
          setShowZestimates={setShowZestimates}
          uniqueId={uniqueId}
          handleReset={handleReset}
        />
      )}

      <div className="mtge-calc_progress">
        <div className="mtge-calc_nf">
          {step > 1 && (
            <button
              className="mtge-calc-prev"
              onClick={() => setStep((prev) => prev - 1)}
            >
              {' '}
              &laquo; Previous{' '}
            </button>
          )}
          {step !== 2 && (
            <button
              className="mtge-calc-next"
              onClick={() => {
                captureUserEvents({
                  searchDetails: {
                    leadType,
                    location,
                  },
                });
                setStep((prev) => prev + 1);
              }}
              disabled={zestimatesLocation === null}
            >
              {' '}
              Next &raquo;{' '}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

// contact us
function ContactUs({
  onClose,
  calculatedData,
  homeSearchPage,
  calenderBookingPage,
  uniqueId,
  handleReset,
}) {
  const [redirectLink, setRedirectLink] = useState('');
  const [leadType, setLeadType] = useState('');
  const [showLeadForm, setShowLeadForm] = useState('');

  const handleCallBack = () => {
    setRedirectLink(homeSearchPage);
    setLeadType('Request a callback');
    setShowLeadForm(true);
  };

  const handleSchedule = () => {
    setRedirectLink(calenderBookingPage);
    setLeadType('Schedule Appointment');
    setShowLeadForm(true);
  };

  return (
    <ModalMain show={true} onClose={onClose}>
      <div className="lead_container">
        <div
          className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper_lead"
          onClick={(e) => e.stopPropagation()}
        >
          {showLeadForm && (
            <div
              className="mtge-calc_lead-prev slide-in-left"
              onClick={() => setShowLeadForm(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>
            </div>
          )}
          <div className="mtge-calc_modal-close" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>

          {showLeadForm ? (
            <LeadForm
              calculatedData={calculatedData}
              leadType={leadType}
              onClose={onClose}
              redirect={true}
              redirectLink={redirectLink}
              uniqueId={uniqueId}
              handleReset={handleReset}
            />
          ) : (
            <>
              <h1 className="mtge-calc_lead-wrapper-title"> Contact Us </h1>
              <div>
                <button
                  className="mtge-calc_share-btn"
                  onClick={handleCallBack}
                >
                  Request a call back
                </button>
                <button
                  className="mtge-calc_share-btn"
                  onClick={handleSchedule}
                >
                  Schedule Appointment
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalMain>
  );
}

function ShareModal({ calculatedData, setShowShareModal }) {
  const [{ currentPlugin }] = useContext();
  const [loading, setLoading] = useState(false);

  const handleShare = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);

    const email = formData.get('email');

    fetch(`/api/plugin/pdf/${currentPlugin.token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: calculatedData,
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((jsonRes) => {
        if (jsonRes.success) {
          setLoading(false);
          toast.success(
            "Mail has been sent, you can check your spam folder if you don't see on primary mailbox"
          );
          setShowShareModal(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <ModalMain show={true} onClose={() => setShowShareModal(false)}>
      <div
        className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper_lead"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          id="mtge-share-close"
          className="mtge-calc_modal-close"
          onClick={() => setShowShareModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>

        <h2 className="mtge-calc_newsletter-title"> Enter Your E-mail </h2>
        <form id="mtge-calc_share-form" onSubmit={handleShare}>
          <div className="mtge-calc_newsletter-field">
            <label> Email </label>
            <div className="mtge-calc_newsletter-field_input">
              <input type="email" name="email" />
            </div>
          </div>
          <div className="mtge-calc_newsletter-field">
            <button type="submit">Send</button>
          </div>
        </form>
        {loading && <ModalLoader />}
      </div>
    </ModalMain>
  );
}

function Zestimates({ location, onClose }) {
  const [data, setData] = useState({
    zillow: null,
    attom: null,
  });

  useEffect(() => {
    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/zillow',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          zillow:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          zillow: 'Not Available',
        }));
      });

    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/attom',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          attom:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          attom: 'Not Available',
        }));
      });
  }, []);

  return (
    <div className="mtge-calc_modal-wrapper mtge-calc_modal-wrapper-show">
      <div className="mtge-calc_modal-overlay"></div>
      <div
        className="mtge-calc_modal"
        id="mtge-calc_modal-result"
        onClick={onClose}
      >
        <div
          className="mtge-calc_modal_wrapper_lead"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            id="mtge-calc_result-close"
            className="mtge-calc_modal-close"
            onClick={onClose}
          >
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="mtge-zestimates_container">
            <div className="mtge-zestimates">
              <div className="mtge-zestimates_items">
                <div className="mtge-zestimates_item">
                  <img src="/images/attom-100.0bdd301.png" alt="attom" />
                  <h2> {data.attom ? data.attom : 'Loading...'} </h2>
                </div>
                <div className="mtge-zestimates_item-devider"></div>
                <div className="mtge-zestimates_item">
                  <img src="/images/zillow-100.3244ea1.png" alt="zillow" />
                  <h2> {data.zillow ? data.zillow : 'Loading...'} </h2>
                </div>
              </div>

              <p className="mtge-zestimates_info">
                Independent price estimates are based on statistical models that
                aggregate available data to form an estimate of market value.
                Estimates are not local experts, they don`t walk through your
                home or the homes in your market to verify their data or account
                for condition. I do - ask me how I`d price your home in today`s
                market.
              </p>
            </div>
            <div className="mtge-zestimates_c-map">
              <h2> Is it a buyer’s market or seller’s market? </h2>
              <p>
                The law of supply and demand dictates the equilibrium price of a
                property, and I can show you if buyers are searching your area
                now. We keep a pulse on buyer interest and how that interest
                compares to the areas around you.
              </p>
              <div
                className="mtge-zestimates_g-map"
                style={{ height: '400px', width: '100%' }}
              >
                {/* AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw */}
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw',
                  }}
                  defaultCenter={{
                    address: location.formatted_address,
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng(),
                  }}
                  defaultZoom={14}
                >
                  <div
                    className="mtge-zestimates_pin"
                    lat={location.geometry.location.lat()}
                    lng={location.geometry.location.lng()}
                  >
                    <p>A</p>
                  </div>
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ShareModalOptions({ pluginTitle, onClose }) {
  const handleBookmark = () => {
    const pageTitle = `${pluginTitle}`;
    const pageUrl = window.location.href;

    if (window.sidebar && window.sidebar.addPanel) {
      // Firefox
      window.sidebar.addPanel(pageTitle, pageUrl, '');
    } else if (window.external && window.external.AddFavorite) {
      // Internet Explorer
      window.external.AddFavorite(pageUrl, pageTitle);
    } else if (window.opera && window.print) {
      // Opera
      const bookmarkLink = document.createElement('a');
      bookmarkLink.setAttribute('href', pageUrl);
      bookmarkLink.setAttribute('title', pageTitle);
      bookmarkLink.setAttribute('rel', 'sidebar');
      bookmarkLink.click();
    } else {
      // Other browsers - manually prompt the user to add the page as a bookmark
      alert(
        'To add a bookmark, please press Ctrl+D (Windows) or Command+D (Mac).'
      );
    }
  };

  const handleSocialShare = () => {
    let text = `
${window.location.href}
    
Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.
    `;
    let navLink = 'https://www.facebook.com/sharer.php?u=' + text;

    window.open(navLink, '__blank');
  };
  return (
    <ModalMain show={true} onClose={onClose}>
      <div
        className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper_lead"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          id="mtge-share-close"
          className="mtge-calc_modal-close"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>

        <h2 className="mtge-calc_newsletter-title"> Choose an option </h2>
        <div className="mtge-calc_lead-container px-2 md:px-4">
          <button className="mtge-calc_btn" onClick={handleBookmark}>
            Bookmark this mortgage calculator
          </button>

          <TwitterShareButton
            url={`${window.location.href} 

Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.`}
          >
            <button className="mtge-calc_btn">
              Post this mortgage calculator on Twitter
            </button>
          </TwitterShareButton>
          <WhatsappShareButton
            url={`${window.location.href} 

Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.`}
          >
            <button className="mtge-calc_btn">
              Post this mortgage calculator on Whatsapp
            </button>
          </WhatsappShareButton>
        </div>
      </div>
    </ModalMain>
  );
}

export default Preview;
