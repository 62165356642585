import axios from './axios';

const services = {
  createFunnel: (profileId, token, funnelName) => {
    return axios({
      url: `/api/funnels/${profileId}/create`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        name: funnelName,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  getAllFunnels: (profileId, token) => {
    return axios({
      url: `/api/funnels/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  getFunnelById: (funnelId, token) => {
    return axios({
      url: `/api/funnels/${funnelId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  saveSteps: (funnelId, steps, token) => {
    return axios({
      url: `/api/funnels/${funnelId}/save`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        steps: steps,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  deleteOptions: (funnelId, stepIndex, questionIndex, optionIndex, token) => {
    return axios({
      url: `/api/funnels/${funnelId}/deleteOptions`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        stepIndex,
        questionIndex,
        optionIndex,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  deleteQuestion: (funnelId, stepIndex, questionIndex, token) => {
    return axios({
      url: `/api/funnels/${funnelId}/deleteQuestion`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        stepIndex,
        questionIndex,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  deleteStep: (funnelId, stepIndex, token) => {
    return axios({
      url: `/api/funnels/${funnelId}/deleteStep`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        stepIndex,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  deleteFunnel: (funnelId, token) => {
    return axios({
      url: `/api/funnels/${funnelId}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          return {
            status: resData.status,
            success: true,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      })
      .catch((error) => {
        console.error('Error deleting the funnel:', error);
        return {
          success: false,
          status: 'Error deleting the funnel',
        };
      });
  },

  getUserIPAddress: async () => {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const ipAddress = response.data.ip;
      return ipAddress;
    } catch (error) {
      console.error('Error fetching user IP address:', error);
      return null;
    }
  },

  getUserGeoLocation: async (ipAddress) => {
    try {
      const response = await axios.get('https://api.ipgeolocation.io/ipgeo', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          apiKey: 'deb9a79bf3024e94865209fd35da0521',
          fields: 'city,country_name',
          output: 'json',
          ip: ipAddress,
        },
      });

      const { city, country_name } = response.data;
      return { city, country_name };
    } catch (error) {
      console.error('Error fetching user IP geolocation:', error);
      return null;
    }
  },

  leadInputData: (pluginToken, uniqueId, ipAddress, geoData, formValues) => {
    const liveAddress = `${geoData.city}, ${geoData.country_name}`;
    console.log('axios formValue Update: ', formValues);
    return axios({
      url: `/api/funnels/leads/add/${pluginToken}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        ipAddress,
        liveAddress: liveAddress,
        formValues,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          return {
            status: resData.status,
            success: true,
            data: resData.data,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      })
      .catch((error) => {
        console.error('Error submitting lead data:', error);
        return {
          success: false,
          status: 'Error submitting lead data',
        };
      });
  },

  // updateOptionInfo: (pluginId, formData, token) => {
  //   console.log('axios formData', formData);
  //   axios({
  //     url: '/api/funnels/update/' + pluginId,
  //     method: 'put',
  //     data: formData,
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization: 'Bearer ' + token,
  //     },
  //   })
  //     .then((res) => {
  //       const resData = res.data;

  //       if (resData.success) {
  //         return {
  //           status: resData.status,
  //           success: true,
  //           data: resData.data,
  //         };
  //       } else {
  //         return {
  //           success: false,
  //           status: resData.status,
  //         };
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('Error submitting optionData:', err);
  //       return {
  //         success: false,
  //         status: 'Error submitting lead data',
  //       };
  //     });
  // },

  updateOptionInfo: (pluginId, formData, token) => {
    return axios({
      url: '/api/funnels/update/' + pluginId,
      method: 'put',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          return {
            status: resData.status,
            success: true,
            data: resData.data,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      })
      .catch((err) => {
        console.error('Error submitting optionData:', err);
        return {
          success: false,
          status: 'Error submitting lead data',
        };
      });
  },
};

export default services;
