import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsFacebook, BsGoogle } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useContext from "../../context/StateProvider";
import ActionTypes from "../../utils/actionTypes";
import Loader from "../Loader/Loader";

function Signin() {
  const [{}, dispatch] = useContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("bg-[#DFE3E8]");

    return () => {
      document.body.classList.remove("bg-[#DFE3E8]");
    };
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      toast.promise(
        axios({
          url: "/api/users/login",
          method: "post",
          data: {
            email: email.toLowerCase(),
            password,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            const resData = res.data;

            if (resData.success) {
              setLoading(false);

              dispatch({
                type: ActionTypes.SET_USER,
                payload: resData.data,
              });
              if (resData.data.accountType === "Admin") {
                navigate("/dashboard/admin");
              } else {
                navigate("/dashboard/profiles");
              }
            } else {
              toast.error(resData.status, {});
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            let error = err.response.data.error;
            let status = err.response.data.status;
            toast.error(error?.message || status, {});
          }),
        {
          pending: "Loging...",
          success: "Login successful",
          error: "Login failed. Please try again later.",
        },
      );
    } else {
      toast.error("Fill up all the fields", {});
    }
  };

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <div className="sm:container sm:mx-auto sm:max-w-lg pt-12 pb-16 sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          LogIn
        </div>
        {/*  */}
        {/* login with social */}
        <div className="loginWLink p-5">
          <h5 className="text-[#161C24] dark:text-white text-center">
            Log in with one of the following:
          </h5>
          <div className="allLinks flex justify-center mt-3">
            <a href="/api/users/auth/facebook">
              <BsFacebook className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
            <a href="/api/users/auth/google">
              <BsGoogle className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
            <button>
              <img src="/images/logo.png" className="h-9 w-9" />
            </button>
          </div>
        </div>

        {/* orr */}
        <div
          className="text-center  relative before:content-[''] before:block max-[415px]:before:w-[130px]  before:w-[200px] before:h-[2px] before:absolute before:left-0 before:top-1/2  before:bg-[#424250] before:ml-7
 after:content-[''] after:block  max-[415px]:after:w-[130px] after:w-[200px] after:h-[2px] after:absolute after:right-0 after:top-1/2  after:bg-[#424250] after:mr-7"
        >
          <span className="text-white bg-[#33333D]">OR</span>
        </div>

        <div className="form pt-4">
          <form
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
            onSubmit={handleLogin}
          >
            <div className="email">
              <input
                type="email"
                name="email"
                value={email}
                placeholder="E-Mail Address"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="pass">
              <input
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {/* forget */}
            <div className="flex items-center">
              <label
                for="remember"
                className="inline-flex items-center text-sm"
              >
                <input
                  type="checkbox"
                  name="session"
                  id="remember"
                  className="form-checkbox h-4 w-4 bg-[#fff] text-[#AD1418] border border-[#d2d6dc] "
                  onChange={() => setSession((prev) => !prev)}
                />{" "}
                <span className="ml-2 text-[#161C24] dark:text-white">
                  Remember Me
                </span>
              </label>{" "}
              <Link
                href=""
                className="text-sm text-[#AD1418] whitespace-no-wrap no-underline hover:underline ml-auto"
                to="/resetPass"
              >
                Forgot Your Password?
              </Link>
            </div>

            {/* buttton others */}
            <div class="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                // disabled={isLogin}
              >
                Login
              </button>{" "}
              <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#161C24] dark:text-[#CFCFD3] ">
                Don't have an account?
                <Link
                  to="/register"
                  className="text-[#AD1418] no-underline hover:underline ml-1"
                >
                  Register
                </Link>
              </p>
            </div>
          </form>
        </div>
        {/* {loading && <Loader />} */}
      </div>
    </div>
  );
}

function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPass = () => {
    if (email) {
      setLoading(true);
      axios({
        url: "/api/users/resetPassword",
        method: "post",
        data: {
          email,
        },
      })
        .then((res) => {
          const resData = res.data;
          setLoading(false);
          setEmail("");
          if (resData.success) {
            toast.success("E-mail has been sent, check your inbox.");
            navigate("/");
          } else {
            toast.error(resData.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    } else {
      toast.error("Please fill up the email field");
    }
  };

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <div className="min-h-screen flex items-center md:py-12">
      <div className="strt_container w-full md:max-w-md mx-auto py-8 px-6 md:px-12 bg-white">
        <div className="mb-8 text-center">
          <div className="pb-4 border-b-2">
            <img
              className="w-16 md:w-20 mx-auto"
              src="/images/logo.png"
              alt="Savage Sales - Logo"
            />
          </div>
          <h1 className="mt-20 text-2xl text-[#39393bde] font-bold">
            Reset Password
          </h1>
        </div>
        <div className="mx-auto">
          <div className="flex flex-col space-y-1">
            <label
              className="text-base text-[#39393bde] font-extrabold"
              htmlFor="email"
            >
              {" "}
              Email *{" "}
            </label>
            <input
              id="email"
              className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
              type="email"
              name="email"
              value={email}
              placeholder="Email *"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mt-12 text-center">
            <p className="text-start text-xs text-[#39393bde] font-semibold">
              An email will be sent with the reset password link.
            </p>
            <button
              className="strt_btn mt-4 px-4 py-3 font-bold rounded-full"
              disabled={loading}
              onClick={handleResetPass}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
          <div className="mt-4 text-start text-black font-medium">
            <Link to="/login" className="hover:underline">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChangePassword() {
  const [{ user }] = useContext();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [tokenVerified, setTokenVerified] = useState(false);

  const navigate = useNavigate();
  const { token, userId } = useParams();

  useEffect(() => {
    if (token && userId) {
      setLoader(true);
      axios({
        url: "/api/users/resetPassword/verify",
        method: "post",
        data: {
          userId,
        },
        headers: {
          "x-access-token": token,
        },
      })
        .then((res) => {
          const resData = res.data;
          setLoader(false);
          if (resData.success) {
            setTokenVerified(true);
          } else {
            setTokenVerified(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          setTokenVerified(false);
        });
    }
  }, [token]);

  const handlePassChange = () => {
    if (
      (oldPassword && newPassword && rePassword) ||
      ((token || user.setPassword) && newPassword && rePassword)
    ) {
      if (newPassword === rePassword) {
        setLoading(true);
        let URL = token
          ? "/api/users/changePassword/reset"
          : "/api/users/changePassword";
        console.log(URL);
        axios({
          url: URL,
          method: "put",
          headers: {
            Authorization: user ? "Bearer " + user.token : "null",
          },
          data: {
            userId: userId || user._id,
            oldPassword,
            newPassword,
          },
        })
          .then((res) => {
            const resData = res.data;
            if (resData.success) {
              setLoading(false);
              toast.success("Password reseted!", {});
              navigate("/login");
            } else {
              toast.error(resData.status, {});
            }
          })
          .catch((err) => {
            setLoading(false);
            let error = err.response.data.error;
            let status = err.response.data.status;
            toast.error(error.message || status, {});
          });
      } else {
        toast.error("Re-type Password didn't match");
      }
    } else {
      toast.error("Fill all the fields!");
    }
  };

  if (loader) {
    return <Loader />;
  }

  if (!user && !token) {
    return navigate("/");
  } else if (token && userId && !tokenVerified) {
    return (
      <div className="min-h-screen flex items-center md:py-12">
        <div className="strt_container w-full md:max-w-md mx-auto py-8 px-6 md:px-12 bg-white">
          <div className="mb-8 text-center">
            <div className="pb-4 border-b-2">
              <img
                className="w-16 md:w-20 mx-auto"
                src="/images/logo.png"
                alt="Savage Sales - Logo"
              />
            </div>
          </div>
          <div className="mx-auto">
            <div>Invalid or Broken link</div>
            <div className="mt-4 text-start text-black font-medium">
              <Link to="/login" className="hover:underline">
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center md:py-12">
      <div className="strt_container w-full md:max-w-md mx-auto py-8 px-6 md:px-12 bg-white">
        <div className="mb-8 text-center">
          <div className="pb-4 border-b-2">
            <img
              className="w-16 md:w-20 mx-auto"
              src="/images/logo.png"
              alt="Savage Sales - Logo"
            />
          </div>
          <h1 className="mt-20 text-2xl text-[#39393bde] font-bold">
            Change Password
          </h1>
        </div>
        <div className="mx-auto">
          {(!token || !user.setPassword) && (
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="oldPassword"
              >
                {" "}
                Old password *{" "}
              </label>
              <input
                id="oldPassword"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="password"
                name="oldPassword"
                value={oldPassword}
                placeholder="Type your old password *"
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
          )}

          <div className="mb-6 flex flex-col space-y-1">
            <label
              className="text-base text-[#39393bde] font-extrabold"
              htmlFor="newPassword"
            >
              {" "}
              New password *{" "}
            </label>
            <input
              id="newPassword"
              className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
              type="password"
              name="newPassword"
              value={newPassword}
              placeholder="Type your new password *"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label
              className="text-base text-[#39393bde] font-extrabold"
              htmlFor="rePassword"
            >
              {" "}
              Re-type password *{" "}
            </label>
            <input
              id="rePassword"
              className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
              type="password"
              name="rePassword"
              value={rePassword}
              placeholder="Re-type your new password *"
              onChange={(e) => setRePassword(e.target.value)}
            />
          </div>

          <div className="mt-8 text-center">
            <button
              className="strt_btn mt-4 px-4 py-3 font-bold rounded-full"
              disabled={loading}
              onClick={handlePassChange}
            >
              {loading ? "Changing..." : "Change"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ChangePassword, ResetPassword, Signin };
