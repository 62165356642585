import React, { useState, useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useContext from "../../context/StateProvider";
import PluginCard from "./PluginCard";
import SearchFilter from "./SearchFilter";
// import BackButton from '../BackButton';

const PluginsDashboard = () => {
  const { profileId } = useParams();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const [filteredFunnels, setFilteredFunnels] = useState([]);

  const [{ user, admin, allProfiles, isOwnPlugin, isOwnProfile }, dispatch] =
    useContext();
  const [allowedFunnels, setAllowedFunnels] = useState("");

  const plugins = [
    {
      id: 1,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
        />
      ),
      include: "Calculator",
      name: "Mortage Calculator",
      link: `/dashboard/${profileId}/all-funnels/calculators`,
      details:
        "Mortgage calculator Lead Gadget includes a mortgage calculator that allows your visitors to calculate their monthly mortgage payments. This can help you generate leads and qualify them for your services.",
    },
    {
      id: 2,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
        />
      ),
      include: "MortgageRates",
      name: "Mortgage Rates",
      link: `/dashboard/${profileId}/all-funnels/mortgage-rates`,
      details:
        "Show us what type of client you want, and we'll show you the type of funnels you should be using AND we'll GIVE YOU our highest converting funnel templates!",
    },
    {
      id: 3,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
        />
      ),
      include: "ViewProperties",
      name: "View Properties",
      link: `/dashboard/${profileId}/all-funnels/viewProperties`,
      details:
        "View properties Lead Gadget allows your visitors to view properties that are currently for sale. This can help you generate leads and qualify them for your services.",
    },
    {
      id: 4,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
        />
      ),
      include: "checkHomeValues",
      name: "Check Home Values",
      link: `/dashboard/${profileId}/all-funnels/checkHomeValues`,
      details:
        "Check home values Lead Gadget allows your visitors to check the value of their home. This can help you generate leads and qualify them for your services.",
    },
    {
      id: 5,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
        />
      ),
      include: "FlexiButtons",
      name: "FlexiButtons",
      link: `/dashboard/${profileId}/all-funnels/flexibuttons`,
      details:
        "Flexibutton can be used to generate leads is by providing visitors with a way to check the value of their home. This can be a great way to attract potential clients who are interested in selling their homes.",
    },
    {
      id: 6,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
        />
      ),
      include: "FlexiFrames",
      name: "FlexiFrames",
      link: `/dashboard/${profileId}/all-funnels/flexiframe`,
      details:
        "Show us what type of client you want, and we'll show you the type of funnels you should be using AND we'll GIVE YOU our highest converting funnel templates!",
    },

    {
      id: 7,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
        />
      ),
      include: "CustomFunnel",
      name: "CustomFunnel",
      link: `/dashboard/${profileId}/all-funnels/custom-funnels`,
      details:
        "Show us what type of client you want, and we'll show you the type of funnels you should be using AND we'll GIVE YOU our highest converting funnel templates!",
    },
    {
      id: 8,
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
        />
      ),
      include: "FacePopUp",
      name: "FacePopUp",
      link: `/dashboard/${profileId}/all-funnels/face-popup`,
      details:
        "Show us what type of client you want, and we'll show you the type of funnels you should be using AND we'll GIVE YOU our highest converting funnel templates!",
    },
  ];

  useEffect(() => {
    allProfiles.map((dt) => {
      if (dt._id === profileId) {
        let managers = dt.managers;
        managers.map((dst) => {
          if (dst.user._id === user._id) {
            setAllowedFunnels(dst.funnels);
          }
        });
      }
    });
  }, [profileId, isOwnProfile]);

  useEffect(() => {
    if (search) {
      const filter = plugins.filter((funnel) =>
        Object.values(funnel).find((value) => {
          if (typeof value === "string") {
            return value.replace(/\s/g, "").toLowerCase().includes(search);
          }
          return false;
        })
      );

      setFilteredFunnels(filter);
    } else {
      setFilteredFunnels(plugins);
    }
  }, [search]);

  return (
    <>
      <div className="px-4 py-6 sm:p-8">
        <div className="pb-6">
          {/* <BackButton /> */}
          <h1 className="pb-2 text-center text-3xl text-[#39393bde] font-bold">
            Search what you are looking for...
          </h1>
          <SearchFilter />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
          {/* Grid items */}
          {filteredFunnels.map(
            (dt) =>
              (isOwnProfile || allowedFunnels.includes(dt.include)) && (
                <Link to={dt.link} key={dt.id}>
                  <PluginCard icon={dt.icon}>
                    <span className="text-center text-lg font-semibold text-black-500">
                      {dt.name}
                    </span>
                    <p className="mt-2 text-sm text-gray-600">{dt.details}</p>
                  </PluginCard>
                </Link>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PluginsDashboard;
