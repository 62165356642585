import React, { useEffect, useState } from "react";
import axios from "axios";
import useContext from "../../context/StateProvider";
import { toast } from "react-toastify";

function Subscriptions() {
  const [{ user }] = useContext();

  return (
    <div className="w-full mx-auto py-8 px-6 md:px-12 bg-white">
      <h1 className="text-center text-3xl text-[#39393bde] font-bold">
        All Subscriptions Plan
      </h1>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card
          name="Free"
          description="Some description"
          price="0.00"
          subscribed={user.accountType === "Free"}
          features={["Feature1", "Feature2"]}
        />
        {/* <Card
          name="Trial"
          description="Some description"
          price="0.00"
          subscribed={user.accountType === "Trail"}
          features={["Feature1", "Feature2"]}
          email={user.email}
        />
        <Card
          name="Enterprise"
          description="Some description"
          price="29"
          features={["Feature1", "Feature2"]}
          subscribed={user.accountType === "Enterprise"}
          email={user.email}
        /> */}
        <Card
          name="Pro Started"
          description="Some description"
          price="19"
          subscribed={user.accountType === "Pro Starter"}
          features={[
            "1 of the following: Morgage Calculator, Home Values Funnel, Mortgage Rates Funnel, View Properties Funnel, Flexibuttons",
            "Up to 3 domains per lead gadget",
            "Lead Lists CRM with lead data & notes",
            "Unlimited Automatic Email notifications",
            "Unlimited Automatic Email drips to leads",
            "Unlimited Leads",
            "Unlimited Views / Page Loads",
          ]}
          nonFeatures={[
            "1 Standalone website",
            "Unlimited Automatic Email drips to leads",
            "Unlimited Admin users",
          ]}
          addOns={[
            "Ability to 'Add-on' any of the other lead gadgets for $9 per month each",
          ]}
          subscriptionUrl={`/api/p/customer/${user.customerId}/subscribe/pro_started`}
          email={user.email}
        />
        <Card
          name="Pro"
          description="Some description"
          price="49"
          subscribed={user.accountType === "Pro"}
          features={[
            "1 Mortgage Calculator",
            "1 Mortgage Rates Funnel",
            "1 Home Values Funnel",
            "1 Search Properties Funnel",
            "1 Flexibutton",
            "1 Standalone website",
            "Up to 3 domains per lead gadget",
            "Lead Lists CRM with lead data & notes",
            "Unlimited Automatic Email notifications",
            "Unlimited Automatic Email drips to leads",
            "Unlimited Leads",
            "Unlimited Views / Page Loads",
            "Unlimited Admin users",
          ]}
          subscriptionUrl={`/api/p/customer/${user.customerId}/subscribe/pro`}
          email={user.email}
        />
      </div>
    </div>
  );
}

function Card({
  name,
  description,
  price,
  features,
  nonFeatures = [],
  addOns = [],
  subscribed = false,
  subscriptionUrl = "#",
  email,
}) {
  return (
    <div className="w-full flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
      <h3 className="mb-4 text-2xl font-semibold">{name}</h3>
      {/* <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
        {description}
      </p> */}
      <div className="flex justify-center items-baseline my-8">
        <span className="mr-2 text-5xl font-extrabold">${price}</span>
        <span className="text-gray-500 dark:text-gray-400">/month</span>
      </div>

      <ul role="list" className="mb-8 space-y-4 text-left">
        {features.map((dt, key) => (
          <li key={key} className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>{dt}</span>
          </li>
        ))}
        {nonFeatures.map((dt, key) => (
          <li key={key} className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="flex-shrink-0 w-5 h-5 text-red-500 dark:text-red-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>

            <span>{dt}</span>
          </li>
        ))}
      </ul>
      {addOns.length > 0 && (
        <div className="mb-8">
          <h4 className="mb-4 text-lg text-center font-bold"> Add-Ons </h4>
          <ul role="list" className="space-y-4 text-left">
            {addOns.map((dt, key) => (
              <li key={key} className="flex items-center space-x-3">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>{dt}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {subscribed ? (
        <div className="flex flex-col space-y-4">
          <button
            disabled
            className="text-white bg-gray-500 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Current Plan
          </button>
          {name !== "Free" && (
            <a
              target="__blank"
              href={`https://billing.stripe.com/p/login/14k7sS4eaac74hi5kk?prefilled_email=${email}`}
              className="text-red-500 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Cancel
            </a>
          )}
        </div>
      ) : (
        <a
          href={subscriptionUrl}
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Get started
        </a>
      )}
    </div>
  );
}

export default Subscriptions;
