import React from 'react';

import { Slider, Stack } from '@mui/material';

function StepFive({ value, onChange, handleNext }) {
  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">Condition</h1>
      <div className="w-full mt-3 px-10 flex flex-col items-center space-y-2">
        <div className="mb-12 w-full flex justify-center items-center space-x-3">
          <button
            className={`p-4 font-bold !border-solid !border-2 !border-[#d2d1d1] rounded-lg transition-all duration-300 hover:bg-[var(--color-red)] hover:text-white ${
              value === 'Worst' ? 'bg-[var(--color-red)] text-white' : ''
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: 'condition',
                  value: 'Worst',
                },
              });
            }}
          >
            Worst
          </button>
          <button
            className={`p-4 font-bold !border-solid !border-2 !border-[#d2d1d1] rounded-lg transition-all duration-300 hover:bg-[var(--color-red)] hover:text-white ${
              value === 'Bad' ? 'bg-[var(--color-red)] text-white' : ''
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: 'condition',
                  value: 'Bad',
                },
              });
            }}
          >
            Bad
          </button>
          <button
            className={`p-4 font-bold !border-solid !border-2 !border-[#d2d1d1] rounded-lg transition-all duration-300 hover:bg-[var(--color-red)] hover:text-white ${
              value === 'Average' ? 'bg-[var(--color-red)] text-white' : ''
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: 'condition',
                  value: 'Average',
                },
              });
            }}
          >
            Average
          </button>
          <button
            className={`p-4 font-bold !border-solid !border-2 !border-[#d2d1d1] rounded-lg transition-all duration-300 hover:bg-[var(--color-red)] hover:text-white ${
              value === 'Good' ? 'bg-[var(--color-red)] text-white' : ''
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: 'condition',
                  value: 'Good',
                },
              });
            }}
          >
            Good
          </button>
          <button
            className={`p-4 font-bold !border-solid !border-2 !border-[#d2d1d1] rounded-lg transition-all duration-300 hover:bg-[var(--color-red)] hover:text-white ${
              value === 'Best' ? 'bg-[var(--color-red)] text-white' : ''
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: 'condition',
                  value: 'Best',
                },
              });
            }}
          >
            Best
          </button>
        </div>
        {/* <div className="w-full">
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1, px: 1 }}
            alignItems="center"
          >
            <Slider
              aria-label="Volume"
              value={value}
              min={0}
              step={1}
              max={10}
              name="condition"
              onChange={onChange}
              sx={{
                color: "var(--color-red)",
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  width: 24,
                  height: 24,
                  backgroundColor: "#fff",
                  "&:before": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
              }}
            />
          </Stack>

          <div className="flex items-center justify-between">
            <p className="text-xs font-semibold text-[var(--color-red)]">
              0
            </p>
            <p className="text-xs font-semibold text-[var(--color-red)]">
              10
            </p>
          </div>
        </div> */}

        <button
          className="mtge-rates_next-btn px-12 py-2 text-lg md:text-xl"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepFive;
