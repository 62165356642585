import React from "react";
import { toast } from "react-toastify";

function StepTwelve({ value, onChange, handleNext }) {
  const verifyAndProceed = () => {
    var regx = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    if (value.match(regx)) {
      handleNext();
    } else {
      toast.error("Invalid mobile number!");
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="my-16 flex flex-col items-center space-y-3">
        <div>
          <div className="relative max-w-[220px] md:max-w-[320px]">
            <input
              id="phone"
              className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
              type="text"
              name="phone"
              required
              value={value}
              maxLength={12}
              onChange={(e) => {
                onChange({
                  target: {
                    name: e.target.name,
                    // value: e.target.value,
                    value: formatPhoneNumber(e.target.value),
                  },
                });
              }}
            />
            <label
              htmlFor="phone"
              className="mtge-rates_input-label text-xs md:text-sm"
            >
              Phone
            </label>
          </div>
          <div className="text-center text-sm">
            <small>(Verify that you’re human)</small>
          </div>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-lg md:text-xl"
          onClick={verifyAndProceed}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepTwelve;
