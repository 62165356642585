import React from "react";
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";

const PlayerController = ({
  playerController,
  isplaying,
  onPlayHandler,
  onToggleMute,
  isMuted,
}) => {
  return (
    <>
      {playerController && (
        <div className="bottom-2 left-2 flex flex-col items-center z-9999">
          {/* Mute/Unmute Button */}
          <button className="text-white mb-4 mr-4" onClick={onToggleMute}>
            {isMuted ? (
              <FaVolumeMute style={{ fontSize: "20px", zIndex: 99 }} />
            ) : (
              <FaVolumeUp style={{ fontSize: "20px", zIndex: 99 }} />
            )}
          </button>

          {/* Play/Pause Button */}
          <button className="text-white mr-4" onClick={onPlayHandler}>
            {isplaying ? (
              <FaPause style={{ fontSize: "20px", zIndex: 99 }} />
            ) : (
              <FaPlay style={{ fontSize: "20px", zIndex: 99 }} />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default PlayerController;
