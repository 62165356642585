import React, { useState } from "react";

import Modal from "@mui/material/Modal";

import { FaExclamationTriangle } from "react-icons/fa";
import PopUpColorModal from "./PopUpColorModal";
const CollectToEmailPopUp = ({ open, handleActionpopUp, setPopup }) => {
  const [state, setState] = useState("integrations");
  const [buttonSetting, setButtonSetting] = useState("general");

  const [open12, setOpen12] = useState(false);
  const [bgColor2, setBgColor2] = useState("#FF0000");

  const [open13, setOpen13] = useState(false);
  const [bgColor3, setBgColor3] = useState("#FF0000");

  const handleClose = () => {
    setOpen12(false);
    setOpen13(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleActionpopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <div className="min-h-screen flex items-center">
          <div
            style={{ width: "420px" }}
            className="   h-fit border mx-auto  rounded-md  bg-white sm:px-7 px-2 py-5"
          >
            <h1 className="text-color11 font-bold text-2xl mb-4">
              Collect E-mail Addresses
            </h1>
            <div className="mt-2">
              <ul className="flex sm:gap-6 gap-4 text-sm  font-semibold text-color7">
                <li
                  onClick={() => setState("integrations")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "integrations"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Integrations
                </li>
                <li
                  onClick={() => setState("formfields")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "formfields"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Form Fields
                </li>
                <li
                  onClick={() => setState("btnsetting")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "btnsetting"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Button Setting
                </li>
              </ul>
            </div>

            {state === "btnsetting" && (
              <div className="mt-4">
                <ul className="flex sm:gap-8 gap-3  text-xs font-semibold  text-color7">
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "general"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("general")}
                  >
                    General
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "style"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("style")}
                  >
                    Style
                  </li>

                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "icon"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("icon")}
                  >
                    Icon
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "color"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("color")}
                  >
                    Colors
                  </li>
                </ul>
              </div>
            )}

            <div className=" mt-7">
              {state === "integrations" ? (
                <div className="flex flex-col gap-0">
                  <div className="flex flex-col gap-2">
                    <label className="text-color2 text-sm font-semibold">
                      Integrate with
                    </label>
                    <select className="border  border-color3 py-2 rounded-sm">
                      <option value="" key="">
                        No Integration avaiable
                      </option>
                      <option value="" key="">
                        No Integration avaiable
                      </option>
                    </select>
                  </div>
                  <h3 className="text-xs text-color10 cursor-pointer mt-1 font-semibold">
                    Create Integration Now
                  </h3>
                  <div className="flex mt-5 flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <label className="text-color2 text-sm  font-semibold">
                        Success message 💡
                      </label>
                      <input
                        type="text"
                        placeholder="✅ E-mail submitted!"
                        className="input-colors px-2 border border-color5 py-2 w-full rounded-sm text-black"
                      />
                    </div>
                  </div>
                </div>
              ) : state === "formfields" ? (
                <div className="flex flex-col gap-0 text-color10">
                  <div className="flex gap-1 ">
                    <FaExclamationTriangle style={{ fontSize: "20px" }} />
                    <span className="text-xs font-semibold">
                      Please ensure that these IDs match the IDs you have
                      assigned to the Text Input CTAs (Call-to-Action) within
                      your FacePop.
                    </span>
                  </div>

                  <div className="flex mt-5 flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <label className="text-color2 text-sm  font-semibold">
                        E-mail Address (Element ID)
                      </label>
                      <input
                        type="text"
                        placeholder="✅ E-mail submitted!"
                        className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                      />
                    </div>
                  </div>
                  <div className="flex mt-5 flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <label className="text-color2 text-sm  font-semibold">
                        First name (Element ID)
                      </label>
                      <input
                        type="text"
                        placeholder="This is Optional"
                        className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                      />
                    </div>
                  </div>
                  <div className="flex mt-5 flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <label className="text-color2 text-sm  font-semibold">
                        Last name (Element ID)
                      </label>
                      <input
                        type="text"
                        placeholder="This is Optional"
                        className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {buttonSetting === "general" ? (
                    <>
                      {" "}
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Text
                          </label>
                          <input
                            type="text"
                            placeholder="Button Text"
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>{" "}
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Button Height
                          </label>
                          <input
                            type="number"
                            placeholder="10px"
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>
                      </div>
                    </>
                  ) : buttonSetting === "style" ? (
                    <div className="flex flex-col gap-0">
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button style
                        </label>
                        <select className="border  border-color3 py-2 rounded-sm">
                          <option value="" key="">
                            Filled
                          </option>
                          <option value="" key="">
                            Outlined
                          </option>
                          <option value="" key="">
                            Text Only
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button Width
                        </label>
                        <select className="border  border-color3 py-2 rounded-sm">
                          <option value="" key="">
                            Stretch
                          </option>
                          <option value="" key="">
                            Auto/fit to text
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Corner Style
                        </label>
                        <select className="border  border-color3 py-2 rounded-sm">
                          <option value="" key="">
                            Round
                          </option>
                          <option value="" key="">
                            Dubbed
                          </option>
                          <option value="" key="">
                            Sharp
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : buttonSetting === "icon" ? (
                    <>icons</>
                  ) : (
                    <div className="flex flex-col ">
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Text color
                        </label>
                        <input
                          value={bgColor2}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: bgColor2 }}
                          onClick={() => setOpen12(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Background color
                        </label>
                        <input
                          value={bgColor3}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: bgColor3 }}
                          onClick={() => setOpen13(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <button className="text-white bg-color10 font-semibold  px-4 py-1 rounded-md">
                Save
              </button>{" "}
              <button
                onClick={() => setPopup(false)}
                className="text-color12 font-semibold  px-2 py-1 rounded-sm"
              >
                Cancel
              </button>
            </div>
            <PopUpColorModal
              open2={open12}
              open3={open13}
              handleClose={handleClose}
              setBgColor2={setBgColor2}
              setBgColor3={setBgColor3}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CollectToEmailPopUp;
