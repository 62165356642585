import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import ModalWrapper from "./Modal";

ChartJS.register(ArcElement, Tooltip, Legend);

function Result({
  monthly,
  total,
  schedule,
  show,
  onClose,
  setShowShareModal,
  setShowContactModal,
  setCalculatedData,
  setShowLeads,
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    let total_amount =
      total.principal +
      total.interest +
      total.down_payment +
      total.extra_payments;

    const data = {
      labels: [
        "Total interest paid",
        "Down payment",
        "Extra payments",
        "Total principal paid",
      ],
      datasets: [
        {
          data: [
            ((total.interest / total_amount) * 100).toFixed(0),
            ((total.down_payment / total_amount) * 100).toFixed(0),
            ((total.extra_payments / total_amount) * 100).toFixed(0),
            ((total.principal / total_amount) * 100).toFixed(0),
          ],
          backgroundColor: [
            "rgb(255 144 26 / 85%)",
            "rgb(244 49 91 / 85%)",
            "rgb(255 196 46 / 85%)",
            "rgb(35 182 185 / 86%)",
          ],
          hoverOffset: 1,
        },
      ],
    };

    setData(data);
  }, []);
  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          id="mtge-calc_result-close"
          className="mtge-calc_modal-close"
          onClick={onClose}
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <table className="mtge-calc_table">
          <tbody>
            <tr>
              <td>Monthly Principal &amp; Interest </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly?.principal_interest)}
              </td>
            </tr>
            <tr>
              <td>Monthly Extra Payment </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.extra_payments)}
              </td>
            </tr>
            <tr>
              <td>PMI </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.pmi)}
              </td>
            </tr>
            <tr>
              <td>Taxes </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.taxes)}
              </td>
            </tr>
            <tr>
              <td>Insurance </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.insurance)}
              </td>
            </tr>
            <tr>
              <td>HOA </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.hoa)}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>MONTHLY PAYMENT </td>
              <td className="mtge-calc_text-right">
                ${currencyWithCommas(monthly.total)}
              </td>
            </tr>
          </tfoot>
        </table>

        <div className="mtge-calc_chart_container">
          <div className="mtge-calc_dogout-chart">
            {data && (
              <Doughnut
                data={data}
                options={{
                  responsive: true,
                  cutout: "70%",
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: (Item) =>
                          Item.label + " " + Item.formattedValue + "%",
                      },
                    },
                  },
                  animation: {
                    onComplete: function (e) {
                      let base64 =
                        e.chart.toBase64Image && e.chart.toBase64Image();
                      if (base64) {
                        setCalculatedData((prev) => ({
                          ...prev,
                          chart: base64,
                        }));
                      }
                    },
                  },
                }}
              />
            )}
          </div>
          <table className="mtge-calc_table">
            <tbody>
              <tr>
                <td>Down payment </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.down_payment)}
                </td>
              </tr>
              <tr>
                <td>Extra payments </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.extra_payments)}
                </td>
              </tr>
              <tr>
                <td>Total principal paid </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.principal)}
                </td>
              </tr>
              <tr>
                <td>Total interest paid </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.interest)}
                </td>
              </tr>
              <tr>
                <td>PMI, Taxes, Insurance, HOA </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.ptihoa)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL OF ALL PAYMENTS </td>
                <td className="mtge-calc_text-right">
                  ${currencyWithCommas(total.value)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="mtge-calc_amortization">
          <h2 className="mtge-calc_amortization-title">
            Amortization Schedule
          </h2>
          <div className="mtge-calc_amortization-schedule">
            <table className="mtge-calc_table mtge-calc_table-schedule">
              <thead>
                <tr>
                  <th>Payment date </th>
                  <th>Payment</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Total Interest </th>
                  <th>PMI, Taxes, Ins, HOA </th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {schedule.map((dt, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{dt.date}</td>
                      <td className="mtge-calc_text-center">
                        ${currencyWithCommas(dt.payment)}
                      </td>
                      <td className="mtge-calc_text-center">
                        ${currencyWithCommas(dt.principal)}
                      </td>
                      <td className="mtge-calc_text-center">
                        ${currencyWithCommas(dt.interest)}
                      </td>
                      <td className="mtge-calc_text-center">
                        ${currencyWithCommas(dt.totalInterest)}
                      </td>
                      <td className="mtge-calc_text-center">
                        ${currencyWithCommas(dt.ptihoa)}
                      </td>
                      <td className="mtge-calc_text-right">
                        ${currencyWithCommas(dt.balance)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            onClick={() => setShowShareModal(true)}
            className="mtge-calc_share-btn"
          >
            {" "}
            Send with E-mail{" "}
          </button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => setShowContactModal(true)}
              className="mtge-calc_share-btn"
              style={{ marginRight: "8px" }}
            >
              Contact Us
            </button>
            <button
              onClick={() => setShowLeads(true)}
              className="mtge-calc_share-btn"
            >
              Leads
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function currencyWithCommas(x) {
  x = x.toFixed(2);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default Result;
