import React from 'react';

import Widget from './Widget/Widget';

function Preview({ funnelTitle, pluginToken, varbiage, calenderBookingPage }) {
  return (
    <div className="mtge-rates_app">
      <Widget
        funnelTitle={funnelTitle}
        sitekey={pluginToken}
        varbiage={varbiage}
        calenderBookingPage={calenderBookingPage}
      />
    </div>
  );
}

export default Preview;
