import React, { useEffect, useState, useRef } from 'react';
import adminService from '../../../services/adminBulder.service';
import { useParams } from 'react-router-dom';
import AddPreviews from './AddPreviews';
import { toast } from 'react-toastify';

const AdsManager = () => {
  const { id } = useParams();

  const [adlink, setAdLink] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [mediaLink, setMediaLink] = useState('');

  const [loadData, setLoadData] = useState([]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setAdLink(value); // Set the value directly
  };

  const handleMediaTypeChange = (e) => {
    const { value } = e.target;
    setMediaType(value);
  };
  const fileInputRef = useRef(null);
  const handleMediaUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const mediaFormData = new FormData();
      mediaFormData.append('media', file);

      console.log('imageFormData:', mediaFormData);

      // const response = await adminService.uploadImage(imageFormData);

      const response = await toast.promise(
        adminService.uploadMedia(mediaFormData),
        {
          pending: 'Please wait. The media is uploading...',
          success: 'Media uploaded successfully',
          error: 'An error occurred while uploading the media.',
        }
      );

      const path = response.data.path;

      console.log(path);

      // Update the formData with the image path
      setMediaLink(path);
    }
  };

  const handleSave = async () => {
    try {
      // Create a FormData object to send both the media file and link
      const data = {
        media: [
          {
            type: mediaType, // or 'video'
            link: adlink, // Link to the media
            filename: mediaLink, // Filename of the media
          },
        ],
      };

      console.log('Data to be sent:', data);

      const response = await toast.promise(adminService.adUpdate(id, data), {
        pending: 'Please wait. The ad is saving...',
        success: 'Ad saved successfully',
        error: 'An error occurred while saving the ad.',
      });

      console.log('Response from server:', response);

      // const response = await adminService.adUpdate(id, formData);
      // setLoadData(response.data);

      // Clear the form after saving
      fileInputRef.current.value = '';
      setAdLink('');
      setMediaLink('');

      loadAds();
    } catch (error) {
      console.error('Error saving ad:', error);
    }
  };

  useEffect(() => {
    loadAds();
  }, []);

  const loadAds = async () => {
    try {
      const response = await adminService.getAd(id);
      setLoadData(response.data);
      console.log('Response:', response.data); // Add this line to check the response
      setAdLink(response.data.link);
      // setMediaLink(response.data.image);
    } catch (error) {
      console.error('Error loading ads:', error);
    }
  };

  return (
    <div className="px-6 py-6">
      {/* Ads Manager Form */}
      {loadData.length < 5 ? (
        <div className="max-w-md mx-auto border border-gray-300 rounded-lg p-6">
          <h1 className="text-3xl font-semibold mb-6 text-center">
            Ads Manager
          </h1>
          <div className="space-y-4">
            <div className="relative">
              <label htmlFor="link" className="text-gray-700 block mb-2">
                URL
              </label>
              <input
                id="link"
                name="link"
                type="text"
                value={adlink}
                className="px-4 py-2 border border-gray-300 w-full rounded-lg outline-none focus:border-blue-500"
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="mt-4 relative">
              <label className="text-gray-700 block mb-2">Media Type</label>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="mediaType"
                    value="image"
                    checked={mediaType === 'image'}
                    onChange={handleMediaTypeChange}
                  />
                  <span className="ml-2">Image</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="mediaType"
                    value="video"
                    checked={mediaType === 'video'}
                    onChange={handleMediaTypeChange}
                  />
                  <span className="ml-2">Video</span>
                </label>
              </div>
            </div>

            <div className="relative">
              <label htmlFor="media" className="text-gray-700 block mb-2">
                {mediaType === 'image' ? 'Image' : 'Video'}
              </label>
              <input
                id="media"
                type="file"
                name="media"
                accept={`${mediaType === 'image' ? 'image/*' : 'video/*'}`}
                onChange={(e) => handleMediaUpload(e)}
                ref={fileInputRef}
              />
            </div>

            <div className="py-6">
              <button
                className="w-full max-w-[300px] mx-auto text-white px-4 py-2 bg-[#262C34] rounded-lg hover:bg-gray-800 focus:outline-none"
                onClick={handleSave}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h1 className="text-gray-500 text-lg font-semibold mb-2">
            You cannot upload more than 5 ads.
          </h1>
        </div>
      )}

      <hr className="my-6 border-t border-[#1e1e1e]" />

      {/* Preview Ads Grid */}
      <div className="mt-6 text-center">
        <h1 className="text-gray-500 text-lg font-semibold mb-2">
          Your ads will be shown here
        </h1>
      </div>
      <div className="mt-8 flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
          {loadData
            ? loadData?.map((previewAd, index) => (
                <div key={index}>
                  <AddPreviews data={previewAd} />
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default AdsManager;
