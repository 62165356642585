import React from 'react';
// import config from './../../../utils/config';

function Thankyou() {
  return (
    <div className="py-6 space-y-3">
      <header className="flex justify-center">
        <img
          className="w-12 md:w-16"
          src={`/images/logo.png`}
          alt="Savage Sales"
        />
      </header>
      <main className="text-center space-y-4">
        <h2 className="text-sm text-[#5b5b5b]">
          Thanks for Your Information Request!
        </h2>
        <p className="md:px-16 text-xs text-[#5b5b5b]">
          We have received your inquiry and are currently reviewing your
          information. One of our experts will follow up shortly to provide a
          FREE, personalized rate quote and one-on-one consultation.
        </p>
        <p className="md:px-16 text-xs text-[#5b5b5b]">
          To speak with a mortgage expert immediately, call{' '}
          <a href="tel:1231231234" className="text-[#1daefc] font-bold">
            (123) 123-1234
          </a>
        </p>
        <p className="md:px-16 text-xs text-[#5b5b5b] italic">
          Our Experts Are Standing By and Look Forward to Speaking with You!
        </p>
      </main>
    </div>
  );
}

export default Thankyou;
