import React, { useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';
import { toast } from 'react-toastify';
import useGlobalState from '../../../context/StateProvider';
import { FadeIn } from 'react-slide-fade-in';

import ModalWrapper from './Modal';
import ActionTypes from '../../../utils/actionTypes';
import config from '../../../utils/config';

function Leads({
  onClose,
  leadname,
  home_price,
  sitekey,
  calculatedData,
  homeSearchPage,
  calenderBookingPage,
  setShowZestimates,
  setZestimatesLocation,
  zestimatesLocation,
  uniqueId,
  handleReset,
  leadButtons,
}) {
  const [leadType, setLeadType] = useState('');
  const [currentScreen, setCurrentSreen] = useState('init-1');
  const [prevScreen, setPrevSreen] = useState(null);

  const [redirect, setRedirect] = useState(true);

  const handleEmailReport = (e, type) => {
    if (type) {
      setLeadType(type);
    } else {
      setLeadType('Email Payment Details');
    }
    setRedirect(false);
    setPrevSreen('init-1');
    setCurrentSreen('leadForm');
  };
  const handleViewProperties = (e, type) => {
    if (type) {
      setLeadType(type);
    } else {
      setLeadType(leadname);
    }
    setRedirect(true);
    setPrevSreen('init-1');
    setCurrentSreen('viewProperties');
  };
  const handlePreQualified = () => {
    setRedirect(false);
    setLeadType('Check Mortgage Rates');
    setPrevSreen('init-1');
    setCurrentSreen('preQualified');
  };
  const handleHomeValues = () => {
    setLeadType('Home Values');
    setPrevSreen('init-1');
    setCurrentSreen('homeValues');
  };

  const handlePrevious = () => {
    setRedirect(false);
    setCurrentSreen(prevScreen);
    setPrevSreen(null);
  };

  const captureUserEvents = (data) => {
    axios({
      url: `/api/plugin/newsletter/add/${sitekey}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        noAttachement: true,
        ...data,
      },
    })
      .then((res) => {})
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  return (
    <ModalWrapper show={true} onClose={onClose}>
      <FadeIn
        from="bottom"
        positionOffset={200}
        triggerOffset={100}
        delayInMilliseconds={0}
      >
        <div
          className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper max-w-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mx-auto flex justify-center items-center space-x-1">
            <img
              src="/images/logo.png"
              className="w-7 h-7 object-cover"
              alt="Savage Sales Logo"
            />
            <span className="text-sm font-semibold"> Savage Sales </span>
          </div>
          {prevScreen && (
            <div className="mtge-calc_lead-prev" onClick={handlePrevious}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>
            </div>
          )}

          <div
            id="mtge-calc_newsletter-close"
            className="mtge-calc_modal-close"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
          {currentScreen === 'init-1' ? (
            <div className="mtge-calc_lead-container">
              {leadButtons.emailPaymentDetails && (
                <button className="mtge-calc_btn" onClick={handleEmailReport}>
                  Email Payment Details
                </button>
              )}
              {leadButtons.viewProperties && (
                <button
                  className="mtge-calc_btn"
                  onClick={handleViewProperties}
                >
                  {leadname}
                </button>
              )}
              {leadButtons.preQualified && (
                <button className="mtge-calc_btn" onClick={handlePreQualified}>
                  Check Mortgage Rates
                </button>
              )}
              {leadButtons.checkHomeValues && (
                <button className="mtge-calc_btn" onClick={handleHomeValues}>
                  Check Home Values
                </button>
              )}
            </div>
          ) : currentScreen === 'leadForm' ? (
            <LeadForm
              calculatedData={calculatedData}
              leadName={leadname}
              sitekey={sitekey}
              leadType={leadType}
              onClose={onClose}
              redirect={redirect}
              uniqueId={uniqueId}
              handleReset={handleReset}
            />
          ) : currentScreen === 'preQualified' ? (
            <PreQualified
              handleEmailReport={(e) =>
                handleEmailReport(e, 'Check Mortgage Rates')
              }
              handleViewProperties={(e) =>
                handleViewProperties(e, 'Check Mortgage Rates')
              }
            />
          ) : currentScreen === 'viewProperties' ? (
            <ViewProperties
              onClose={onClose}
              leadType={leadType}
              calculatedData={calculatedData}
              homePrice={home_price}
              homeSearchPage={homeSearchPage}
              calenderBookingPage={calenderBookingPage}
              sitekey={sitekey}
              uniqueId={uniqueId}
              handleReset={handleReset}
              captureUserEvents={captureUserEvents}
            />
          ) : currentScreen === 'homeValues' ? (
            <HomeValues
              onClose={onClose}
              leadType={leadType}
              calculatedData={calculatedData}
              homePrice={home_price}
              homeSearchPage={homeSearchPage}
              calenderBookingPage={calenderBookingPage}
              sitekey={sitekey}
              setShowZestimates={setShowZestimates}
              setZestimatesLocation={setZestimatesLocation}
              zestimatesLocation={zestimatesLocation}
              uniqueId={uniqueId}
              handleReset={handleReset}
              captureUserEvents={captureUserEvents}
            />
          ) : (
            ''
          )}
        </div>
      </FadeIn>
    </ModalWrapper>
  );
}

function LeadForm({
  onClose,
  redirectLink,
  calculatedData,
  leadName,
  leadType,
  sitekey,
  redirect,
  viewPropertiesDetails,
  setShowZestimates,
  uniqueId,
  handleReset,
}) {
  const [{ calc_inputs }, dispatch] = useGlobalState();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      url: `/api/plugin/newsletter/add/${sitekey}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        fullName,
        email,
        phone,
        leadType,
        userInputs: calc_inputs,
        data: calculatedData,
        searchDetails: viewPropertiesDetails || '',
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(res);
        console.log(resData);
        if (resData.success) {
          dispatch({ type: ActionTypes.CLEAR_CALCULATOR_INPUTS });
          toast.success('Successfully done!', {});
          if (redirect) {
            window.open(redirectLink);
          }
          if (leadType === 'Home Values') {
            setShowZestimates(true);
          }
          onClose();
          handleReset();
        } else {
          toast.error(resData.status, {});
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  return (
    <div>
      <h2 className="mtge-calc_newsletter-title">{leadType}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mtge-calc_newsletter-field">
          <label> Full Name* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="text"
              name="full_name"
              placeholder="Full name"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Email* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Phone (verify you're human) </label>
          <div className="mtge-calc_newsletter-field_input">
            <PhoneInput
              international
              defaultCountry="US"
              value={phone}
              onChange={setPhone}
              required
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <div className="mtge-calc_newsletter-field_checkbox">
            <input
              defaultChecked
              type="checkbox"
              name="accept_privacy"
              required
            />
            <span>
              I agree to receive marketing emails to the email address provided
            </span>
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

function PreQualified({ handleEmailReport, handleViewProperties }) {
  return (
    <div className=" mtge-calc_lead_step-preQualified">
      <h2 className="mtge-calc_newsletter-title">Check Mortgage Rates</h2>
      <div className="mtge-calc_notice">
        <h2> We will be in touch soon! </h2>
        <div></div>
      </div>

      <button
        id="mtge-calc_lead-email"
        className="mtge-calc_btn"
        onClick={handleEmailReport}
      >
        Email Payment Details
      </button>
      <button
        id="mtge-calc_lead-properties"
        className="mtge-calc_btn"
        onClick={handleViewProperties}
      >
        View Properties
      </button>
    </div>
  );
}

function ViewProperties({
  onClose,
  leadType,
  calculatedData,
  homePrice,
  homeSearchPage,
  calenderBookingPage,
  sitekey,
  uniqueId,
  handleReset,
  captureUserEvents,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const [location, setLocation] = useState('');
  const [range, setRange] = useState({ min: 0, max: 0 });
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [condition, setCondition] = useState('');
  const [squareFootage, setSquareFootage] = useState(0);

  const [redirectLink, setRedirectLink] = useState('');

  useEffect(() => {
    setRange({ min: homePrice - 50000, max: homePrice + 50000 });
  }, [homePrice]);

  const handleNext = () => {
    captureUserEvents({
      leadType,
      searchDetails: {
        location,
        priceRange: range,
        bedrooms,
        bathrooms,
        condition,
        squareFootage,
      },
    });
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <div className="mtge-rates_modal_wrapper max-w-full mt-6 p-0 px-4 md:px-8 bg-white">
      <header className="flex items-center justify-between">
        <div className="w-14 md:w-16">
          <img
            className="w-full object-contain"
            src={`${config.baseUrl}/images/logo.png`}
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar currentStep={currentStep} />
      <main className="mt-4 overflow-hidden">
        {currentStep === 0 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Enter your location
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="location"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="text"
                  name="location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label
                  htmlFor="location"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Location
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={() => {
                  if (location) {
                    handleNext();
                  } else {
                    toast.info('This field is required!');
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 1 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bedrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bedrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bedrooms"
                  required
                  value={bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                />
                <label
                  htmlFor="bedrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bedrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bathrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bathrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bathrooms"
                  required
                  value={bathrooms}
                  onChange={(e) => setBathrooms(e.target.value)}
                />
                <label
                  htmlFor="bathrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bathrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 3 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Approx. Square Footage
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="squareFootage"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="squareFootage"
                  required
                  value={squareFootage}
                  onChange={(e) => setSquareFootage(e.target.value)}
                />
                <label
                  htmlFor="squareFootage"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Square Footage
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 4 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Condition
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-2">
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('New');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'New'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'New'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  New
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Fully Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Fully Remodeled'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Fully Remodeled'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Fully Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Partially Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Partially Remodeled'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Partially Remodeled'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Partially Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Excellent');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Excellent'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Excellent'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Excellent
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Good');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Good'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Good'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Good
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Livable');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Livable'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Livable'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Livable
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Needs TLC');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Needs TLC'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Needs TLC'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Needs TLC
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[#0c0c64]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Poor');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Poor'
                      ? 'text-[#0c0c64] bg-white'
                      : 'text-white bg-[#0c0c64]'
                  }`}
                  style={
                    condition === 'Poor'
                      ? { color: '#0c0c64' }
                      : { color: '#FFFFFF' }
                  }
                >
                  Poor
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c0c64]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        ) : currentStep === 5 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Minimum Price Range
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="min-range"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="min-range"
                  required
                  value={range.min}
                  onChange={(e) =>
                    setRange((prev) => ({ ...prev, min: e.target.value }))
                  }
                />
                <label
                  htmlFor="min-range"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Price Range
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 6 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Maximum Price Range
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="max-range"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="max-range"
                  placeholder=""
                  required
                  value={range.max}
                  onChange={(e) =>
                    setRange((prev) => ({ ...prev, max: e.target.value }))
                  }
                />
                <label
                  htmlFor="max-range"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Price Range
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 7 ? (
          <div className="mtge-calc_lead-container px-4 md:px-8">
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(calenderBookingPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View in person
            </button>
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(homeSearchPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View Online
            </button>
          </div>
        ) : currentStep === 8 ? (
          <LeadForm
            onClose={onClose}
            leadType={leadType}
            calculatedData={calculatedData}
            redirectLink={redirectLink}
            redirect={true}
            viewPropertiesDetails={{
              location,
              priceRange: range,
              bedrooms,
              bathrooms,
              // choosenBtn: redirectLink,
            }}
            sitekey={sitekey}
            uniqueId={uniqueId}
            handleReset={handleReset}
          />
        ) : (
          ''
        )}
        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep > 0) {
      let totalStep = 9;
      let calculatedPercentage = ((currentStep + 1) / totalStep) * 100;
      setWidth(calculatedPercentage.toFixed(0));
    } else {
      setWidth(0);
    }
  }, [currentStep]);

  return (
    <div className="relative w-full text-white h-[1px] bg-gray-200">
      <div
        className="h-full bg-[#0c0c64] transition-all duration-750 ease-in-out"
        style={{
          width: width + '%',
        }}
      ></div>
      <div
        className="absolute top-2 px-1 py-1 -mx-4 text-white text-[8px] bg-[#0c0c64] rounded-sm after:content-[''] after:absolute after:-top-1 after:left-1/2 after:h-0 after:w-0 after:border-l-4 after:border-r-4 after:border-b-4 after:border-l-transparent after:border-r-transparent after:border-b-[#0c0c64] after:transform after:-translate-x-1/2 transition-all duration-750 ease-in-out"
        style={{
          visibility: width === 0 ? 'hidden' : 'visible',
          left: width + '%',
          color: 'white',
        }}
      >
        {width}%
      </div>
    </div>
  );
};

const PrevButton = ({ handlePrev }) => {
  return (
    <button
      className="absolute top-1/2 left-0 p-1 border-2 border-gray-600 border-solid transform -translate-y-1/2"
      onClick={handlePrev}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3 h-3 text-[#0c0c64]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

//
function HomeValues({
  onClose,
  leadType,
  calculatedData,
  sitekey,
  setShowZestimates,
  setZestimatesLocation,
  zestimatesLocation,
  uniqueId,
  handleReset,
  captureUserEvents,
}) {
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState('');

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyCzhuxXJQvtSqjsDBL6d_BHD412s2rQwNc',

    options: {
      types: ['address'],
      componentRestrictions: { country: ['us', 'ca'] },
      fields: [
        'adr_address',
        'geometry',
        'address_components',
        'formatted_address',
        'name',
        'place_id',
      ],
    },
    onPlaceSelected: (place) => {
      console.log(place);
      var formatted_address = place.formatted_address.split(',');
      var address = place.name;
      var city = formatted_address[formatted_address.length - 3].trim();
      var state = formatted_address[formatted_address.length - 2]
        .trim()
        .split(' ')[0]
        .trim();
      var zip = formatted_address[formatted_address.length - 2]
        .trim()
        .split(' ')[1]
        .trim();
      console.log({
        address,
        city,
        state,
        zip,
      });
      setLocation(address + ', ' + city + ', ' + state + ' ' + zip);
      console.log(address + ', ' + city + ', ' + state + ' ' + zip);
      setZestimatesLocation({
        address,
        city,
        state,
        zip,
        geometry: place.geometry,
        formatted_address: place.formatted_address,
      });
    },
  });

  return (
    <div className="mtge-calc_view-properties">
      {step === 1 ? (
        <div className="mtge-location_suggest mtge-calc_newsletter-field">
          <label htmlFor="location"> Location </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              ref={ref}
              type="text"
              name="location"
              placeholder="Type your location"
              required
            />
          </div>
        </div>
      ) : (
        <LeadForm
          onClose={onClose}
          leadType={leadType}
          calculatedData={calculatedData}
          redirect={false}
          sitekey={sitekey}
          setShowZestimates={setShowZestimates}
          uniqueId={uniqueId}
          handleReset={handleReset}
        />
      )}

      <div className="mtge-calc_progress">
        <div className="mtge-calc_nf">
          {step > 1 && (
            <button
              className="mtge-calc-prev"
              onClick={() => {
                captureUserEvents({
                  searchDetails: {
                    leadType,
                    location,
                  },
                });
                setStep((prev) => prev - 1);
              }}
            >
              {' '}
              &laquo; Previous{' '}
            </button>
          )}
          {step !== 2 && (
            <button
              className="mtge-calc-next"
              onClick={() => setStep((prev) => prev + 1)}
              disabled={zestimatesLocation === null}
            >
              {' '}
              Next &raquo;{' '}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

// contact us
export function ContactUs({
  onClose,
  sitekey,
  calculatedData,
  calenderBookingPage,
  uniqueId,
  handleReset,
}) {
  const [redirectLink, setRedirectLink] = useState('');
  const [leadType, setLeadType] = useState('');
  const [showLeadForm, setShowLeadForm] = useState('');

  const handleCallBack = () => {
    setRedirectLink(calenderBookingPage);
    setLeadType('Request a callback');
    setShowLeadForm(true);
  };

  const handleSchedule = () => {
    setRedirectLink(calenderBookingPage);
    setLeadType('Schedule Appointment');
    setShowLeadForm(true);
  };

  return (
    <ModalWrapper show={true} onClose={onClose}>
      <div
        className="mtge-calc_lead-wrapper mtge-calc_modal_wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        {showLeadForm && (
          <div
            className="mtge-calc_lead-prev"
            onClick={() => setShowLeadForm(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
          </div>
        )}
        <div className="mtge-calc_modal-close" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>

        {showLeadForm ? (
          <LeadForm
            calculatedData={calculatedData}
            sitekey={sitekey}
            leadType={leadType}
            onClose={onClose}
            redirect={true}
            redirectLink={redirectLink}
            uniqueId={uniqueId}
            handleReset={handleReset}
          />
        ) : (
          <>
            <h1 className="mtge-calc_lead-wrapper-title"> Contact Us </h1>
            <div>
              <button className="mtge-calc_share-btn" onClick={handleCallBack}>
                Request a call back
              </button>
              <button className="mtge-calc_share-btn" onClick={handleSchedule}>
                Schedule Appointment
              </button>
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}

export default Leads;
