import React, { useEffect, useState, useReducer, useMemo, useRef } from 'react';
import Slider from '@mui/material/Slider';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import useContext from '../../context/StateProvider';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ActionTypes from '../../utils/actionTypes';
import Loader from '../Loader/Loader';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function MinimizeButton() {
  const [{ user, currentPlugin }, dispatch] = useContext();
  const { pluginId, profileId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // options
  const [selectedBtnOption, setSelectedBtnOption] = useState('default');
  const [selectedPosOption, setSelectedPosOption] = useState('bl-corner');

  const [imageType, setImageType] = useState('icon');
  const [image, setImage] = useState('');

  // customized - btn
  const [customBtn, rDispatch] = useReducer(
    (state, action) => {
      const { payload } = action;
      switch (action.type) {
        case 'SET_HEIGHT':
          return { ...state, height: payload };
        case 'SET_WIDTH':
          return { ...state, width: payload };
        case 'SET_BORDERRADIUS':
          return { ...state, borderRadius: payload };
        case 'SET_TEXT':
          return { ...state, text: payload };
        case 'SET_BACKGROUND':
          return { ...state, background: payload };
        case 'SET_FONTCOLOR':
          return { ...state, fontColor: payload };
        case 'SET_ALL':
          return payload;
      }
    },
    {
      height: '',
      width: '',
      borderRadius: '',
      text: '',
      background: '',
      fontColor: '',
    }
  );

  // position
  const [leftMeasurement, setLeftMeasurement] = useState('%');
  const [bottomMeasurement, setBottomMeasurement] = useState('%');
  const [fromLeft, setFromLeft] = useState(20);
  const [fromBottom, setFromBottom] = useState(20);

  useEffect(() => {
    if (!currentPlugin || currentPlugin === null) {
      navigate(`/dashboard/${profileId}/flexiframe/${pluginId}`, {
        replace: true,
      });
      return;
    }
    rDispatch({
      type: 'SET_ALL',
      payload: currentPlugin.button.styles,
    });
    setSelectedBtnOption(currentPlugin.button.styles.type);
    setSelectedPosOption(currentPlugin.button.position.type);
  }, [currentPlugin]);

  useEffect(() => {
    if (selectedBtnOption === 'icon') {
      setImage(currentPlugin.button.styles.icon);
    } else if (selectedBtnOption === 'logo') {
      setImage(currentPlugin.button.styles.logo);
    }
  }, [selectedBtnOption]);

  const buttonStyles = useMemo(() => {
    let customStyles = {};
    let posStyles = {};

    switch (selectedBtnOption) {
      case 'default':
        customStyles = {
          height: 45,
          width: 210,
          borderRadius: 99,
          background:
            'linear-gradient(37deg,rgba(172, 30, 35, 1) 5%,rgba(39, 45, 52, 1) 74%)',
          color: '#ffffff',
        };
        break;
      case 'customized':
        customStyles = {
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: customBtn.borderRadius,
          background: customBtn.background,
          color: customBtn.fontColor,
        };
        break;
      case 'icon':
        setImageType('icon');

        customStyles = {
          height: customBtn.height,
          width: customBtn.width,
        };
        break;
      case 'logo':
        setImageType('logo');

        customStyles = {
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: customBtn.borderRadius,
          background: customBtn.background,
          color: customBtn.fontColor,
        };
        break;
      default:
    }

    switch (selectedPosOption) {
      case 'bl-corner':
        posStyles = {
          left: '20px',
          right: 'unset',
          top: 'unset',
          bottom: '20px',
        };

        break;
      case 'br-corner':
        posStyles = {
          left: 'unset',
          right: '20px',
          top: 'unset',
          bottom: '20px',
        };
        break;
      case 'tl-corner':
        posStyles = {
          left: '20px',
          right: 'unset',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'tr-corner':
        posStyles = {
          left: 'unset',
          right: '20px',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'custom':
        posStyles = {
          left: fromLeft + leftMeasurement,
          right: 'unset',
          top: 'unset',
          bottom: fromBottom + bottomMeasurement,
        };
        break;
      default:
    }

    return {
      ...customStyles,
      ...posStyles,
    };
  }, [
    selectedBtnOption,
    selectedPosOption,
    fromLeft,
    fromBottom,
    leftMeasurement,
    bottomMeasurement,
    customBtn,
  ]);

  const handleSave = () => {
    setIsLoading(true);
    let buttonStyles = {
      styles: {},
      position: {},
    };

    switch (selectedBtnOption) {
      case 'default':
        buttonStyles.styles = {
          type: 'default',
          height: '45',
          width: '210',
          borderRadius: '99',
          background:
            'linear-gradient(37deg,rgba(172, 30, 35, 1) 5%,rgba(39, 45, 52, 1) 74%)',
          fontColor: '#ffffff',
        };
        break;
      case 'customized':
        buttonStyles.styles = {
          type: 'customized',
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: customBtn.borderRadius,
          background: customBtn.background,
          fontColor: customBtn.fontColor,
          text: customBtn.text,
        };

        break;
      default:
        break;
    }

    switch (selectedPosOption) {
      case 'bl-corner':
        buttonStyles.position = {
          type: 'bl-corner',
          left: '20px',
          right: 'unset',
          top: 'unset',
          bottom: '20px',
        };
        break;
      case 'br-corner':
        buttonStyles.position = {
          type: 'br-corner',
          left: 'unset',
          right: '20px',
          top: 'unset',
          bottom: '20px',
        };
        break;
      case 'tl-corner':
        buttonStyles.position = {
          type: 'tl-corner',
          left: '20px',
          right: 'unset',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'tr-corner':
        buttonStyles.position = {
          type: 'tr-corner',
          left: 'unset',
          right: '20px',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'custom':
        buttonStyles.position = {
          type: 'custom',
          left: fromLeft + leftMeasurement,
          right: 'unset',
          top: 'unset',
          bottom: fromBottom + bottomMeasurement,
        };
        break;
      default:
        break;
    }

    axios({
      url: '/api/flexiframe/update/' + pluginId,
      method: 'put',
      data: {
        button: buttonStyles,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          toast.success('Updated successfully');

          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleReset = () => {
    setSelectedBtnOption('default');
    setSelectedPosOption('bl-corner');
    setImageType('icon');
    setImage('');
    setLeftMeasurement('%');
    setBottomMeasurement('%');
    setFromLeft(20);
    setFromBottom(20);
  };

  const uploadIconAndLogo = () => {
    setIsLoading(true);
    let buttonStyles = {
      styles: {},
      position: {},
    };

    switch (selectedBtnOption) {
      case 'default':
        buttonStyles.styles = {
          type: 'default',
          height: '45',
          width: '210',
          borderRadius: '99',
          background:
            'linear-gradient(37deg,rgba(172, 30, 35, 1) 5%,rgba(39, 45, 52, 1) 74%)',
          fontColor: '#ffffff',
        };
        break;
      case 'customized':
        buttonStyles.styles = {
          type: 'customized',
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: customBtn.borderRadius,
          background: customBtn.background,
          fontColor: customBtn.fontColor,
        };
        break;
      case 'icon':
        buttonStyles.styles = {
          type: 'icon',
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: 0,
          background: 'none',
          fontColor: '#ffffff',
        };
        break;
      case 'logo':
        buttonStyles.styles = {
          type: 'logo',
          height: customBtn.height,
          width: customBtn.width,
          borderRadius: customBtn.borderRadius,
          background: customBtn.background,
          fontColor: customBtn.fontColor,
        };
        break;
      default:
        break;
    }

    switch (selectedPosOption) {
      case 'bl-corner':
        buttonStyles.position = {
          type: 'bl-corner',
          left: '20px',
          right: 'unset',
          top: 'unset',
          bottom: '20px',
        };
        break;
      case 'br-corner':
        buttonStyles.position = {
          type: 'br-corner',
          left: 'unset',
          right: '20px',
          top: 'unset',
          bottom: '20px',
        };
        break;
      case 'tl-corner':
        buttonStyles.position = {
          type: 'tl-corner',
          left: '20px',
          right: 'unset',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'tr-corner':
        buttonStyles.position = {
          type: 'tr-corner',
          left: 'unset',
          right: '20px',
          top: '20px',
          bottom: 'unset',
        };
        break;
      case 'custom':
        buttonStyles.position = {
          type: 'custom',
          left: fromLeft + leftMeasurement,
          right: 'unset',
          top: 'unset',
          bottom: fromBottom + bottomMeasurement,
        };
        break;
      default:
        break;
    }

    const formData = new FormData();
    formData.append('button-' + imageType, image);
    formData.append('name', imageType);
    formData.append('styles', JSON.stringify(buttonStyles.styles));
    formData.append('position', JSON.stringify(buttonStyles.position));
    axios({
      url: '/api/flexiframe/update/' + pluginId + '/button/image',
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          toast.success('Updated successfully');

          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const imageUrl = useMemo(() => {
    return typeof image === 'string'
      ? image
      : typeof image === 'object'
      ? URL.createObjectURL(image)
      : '';
  }, [image]);

  if (isLoading) {
    return <Loader customClass="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <div className="mb-4">
        <Link
          to={`/dashboard/${profileId}/flexiframe/${pluginId}`}
          className="flex items-center space-x-2 text-sm font-semibold"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
          </span>
          <span>Settings</span>
        </Link>
      </div>
      <h1 className="text-2xl text-[#39393bde] font-bold">
        Customize Minimize Button
      </h1>
      <div className="mt-8">
        <h2 className="text-base text-[#39393bde] font-bold"> Options </h2>
        <div className="mt-4">
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={selectedBtnOption}
            exclusive
            onChange={(e) => setSelectedBtnOption(e.target.value)}
            aria-label="Button Options"
          >
            <ToggleButton value="default">Default</ToggleButton>
            <ToggleButton value="customized">Customized</ToggleButton>
            <ToggleButton value="icon">Icon</ToggleButton>
            <ToggleButton value="logo">With Logo</ToggleButton>
          </ToggleButtonGroup>

          {(selectedBtnOption === 'icon' || selectedBtnOption === 'logo') && (
            <IconControls
              imageType={imageType}
              setImage={(l) => setImage(l)}
              state={customBtn}
              dispatch={rDispatch}
            />
          )}

          {selectedBtnOption === 'customized' && (
            <CustomizedButton state={customBtn} dispatch={rDispatch} />
          )}
        </div>

        <h2 className="mt-8 text-base text-[#39393bde] font-bold">
          {' '}
          Positions{' '}
        </h2>
        <div className="mt-4">
          <ToggleButtonGroup
            size="medium"
            color="primary"
            value={selectedPosOption}
            exclusive
            onChange={(e) => setSelectedPosOption(e.target.value)}
            aria-label="Button Positions"
          >
            <ToggleButton value="bl-corner">
              Bottom - <br /> Left Corner
            </ToggleButton>
            <ToggleButton value="br-corner">
              Bottom - <br /> Right Corner
            </ToggleButton>
            <ToggleButton value="tl-corner">
              Top - <br /> Left Corner
            </ToggleButton>
            <ToggleButton value="tr-corner">
              Top - <br /> Right Corner
            </ToggleButton>
            <ToggleButton value="custom">Custom</ToggleButton>
          </ToggleButtonGroup>
        </div>

        {selectedPosOption === 'custom' && (
          <div className="mt-6 space-y-6">
            <div className="">
              <h2 className="mb-4 text-sm text-[#39393bde] font-bold">
                {' '}
                Position from Left{' '}
              </h2>

              <div className="my-4">
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={leftMeasurement}
                  exclusive
                  onChange={(e) => setLeftMeasurement(e.target.value)}
                  aria-label="Platform"
                >
                  <ToggleButton value="px">Pixel</ToggleButton>
                  <ToggleButton value="%">Percentage (%)</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <Slider
                valueLabelDisplay="auto"
                slots={{
                  valueLabel: ValueLabelComponent,
                }}
                aria-label="custom thumb label"
                defaultValue={20}
                value={fromLeft}
                max={leftMeasurement === 'px' ? 1000 : 100}
                onChange={(e) => setFromLeft(e.target.value)}
              />
            </div>
            <div className="">
              <h2 className="mb-4 text-sm text-[#39393bde] font-bold">
                {' '}
                Position from Bottom{' '}
              </h2>

              <div className="my-4">
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={bottomMeasurement}
                  exclusive
                  onChange={(e) => setBottomMeasurement(e.target.value)}
                  aria-label="Platform"
                >
                  <ToggleButton value="px">Pixel</ToggleButton>
                  <ToggleButton value="%">Percentage (%)</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <Slider
                valueLabelDisplay="auto"
                slots={{
                  valueLabel: ValueLabelComponent,
                }}
                aria-label="custom thumb label"
                defaultValue={20}
                value={fromBottom}
                max={bottomMeasurement === 'px' ? 1000 : 100}
                onChange={(e) => setFromBottom(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="mt-6 flex items-center gap-4">
          <button
            className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
            onClick={
              selectedBtnOption === 'logo' || selectedBtnOption === 'icon'
                ? uploadIconAndLogo
                : handleSave
            }
          >
            Save
          </button>
          <button
            className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>

      {selectedBtnOption === 'icon' ? (
        <button
          className="mtge-calc_toggle"
          style={{
            width: buttonStyles.width,
            height: buttonStyles.height,
            background: 'none',
            borderRadius: 0,
            top: buttonStyles.top,
            bottom: buttonStyles.bottom,
            left: buttonStyles.left,
            right: buttonStyles.right,
          }}
        >
          <img src={imageUrl} alt="Icon" style={{ width: '100%' }} />
        </button>
      ) : selectedBtnOption === 'logo' ? (
        <button className="mtge-calc_toggle" style={buttonStyles}>
          <img
            src={imageUrl}
            alt="Icon"
            style={{ width: '20%', objectFit: 'contain' }}
          />
          <span>{customBtn.text}</span>
        </button>
      ) : (
        <button className="mtge-calc_toggle" style={buttonStyles}>
          {customBtn.text}{' '}
        </button>
      )}
    </div>
  );
}

const CustomizedButton = ({ state, dispatch }) => {
  // refs
  const backgroundColorPicker = useRef(null);
  const fontColorPicker = useRef(null);

  return (
    <div className="mt-6 flex flex-col space-y-6">
      <div className="flex flex-col">
        <label
          htmlFor="btnText"
          className="mb-2 text-sm text-[#39393bde] font-bold"
        >
          {' '}
          Text{' '}
        </label>
        <input
          id="btnText"
          type="text"
          value={state.text}
          onChange={(e) =>
            dispatch({ type: 'SET_TEXT', payload: e.target.value })
          }
          className="w-full py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
        />
      </div>
      <div>
        <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Height </h2>
        <Slider
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          aria-label="Height slider"
          defaultValue={20}
          value={state.height}
          max={500}
          onChange={(e) =>
            dispatch({ type: 'SET_HEIGHT', payload: e.target.value })
          }
        />
      </div>
      <div>
        <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Width </h2>
        <Slider
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          aria-label="Width slider"
          defaultValue={20}
          value={state.width}
          max={500}
          onChange={(e) =>
            dispatch({ type: 'SET_WIDTH', payload: e.target.value })
          }
        />
      </div>
      <div>
        <h2 className="mb-2 text-sm text-[#39393bde] font-bold">
          {' '}
          Border Radius (pixel){' '}
        </h2>
        <Slider
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          aria-label="Borrder Raius slider"
          defaultValue={20}
          value={state.borderRadius}
          max={99}
          onChange={(e) =>
            dispatch({ type: 'SET_BORDERRADIUS', payload: e.target.value })
          }
        />
      </div>

      <h3 className="mb-2 text-sm text-[#39393bde] font-bold"> Colors </h3>
      <div className="flex space-x-4">
        <div
          className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
          onClick={() => backgroundColorPicker.current.click()}
        >
          <div className="relative">
            <div
              className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
              style={{ background: state.background }}
            ></div>
            <input
              className="w-8 h-1 border-none bg-none rounded-sm"
              ref={backgroundColorPicker}
              type="color"
              onChange={(e) =>
                dispatch({
                  type: 'SET_BACKGROUND',
                  payload: e.target.value,
                })
              }
            />
          </div>
          <span className="text-sm select-none"> Background </span>
        </div>
        <div
          className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
          onClick={() => fontColorPicker.current.click()}
        >
          <div className="relative">
            <div
              className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
              style={{ background: state.fontColor }}
            ></div>
            <input
              className="w-8 h-1 border-none bg-none rounded-sm"
              ref={fontColorPicker}
              type="color"
              onChange={(e) =>
                dispatch({
                  type: 'SET_FONTCOLOR',
                  payload: e.target.value,
                })
              }
            />
          </div>
          <span className="text-sm select-none"> Color </span>
        </div>
      </div>
    </div>
  );
};
const IconControls = ({ imageType, setImage, state, dispatch }) => {
  // refs
  const backgroundColorPicker = useRef(null);
  const fontColorPicker = useRef(null);
  console.log(imageType);
  if (imageType === 'icon') {
    return (
      <div className="mt-6 flex flex-col space-y-6">
        <div className="flex flex-col">
          <label
            htmlFor="image"
            className="mb-2 text-sm text-[#39393bde] font-bold"
          >
            {' '}
            Upload your {imageType}{' '}
          </label>
          <input
            id="image"
            type="file"
            name="image"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Height </h2>
          <Slider
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            aria-label="Height slider"
            defaultValue={20}
            value={state.height}
            max={500}
            onChange={(e) =>
              dispatch({ type: 'SET_HEIGHT', payload: e.target.value })
            }
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Width </h2>
          <Slider
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            aria-label="Width slider"
            defaultValue={20}
            value={state.width}
            max={500}
            onChange={(e) =>
              dispatch({ type: 'SET_WIDTH', payload: e.target.value })
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-6 flex flex-col space-y-6">
        <div className="flex flex-col">
          <label
            htmlFor="image"
            className="mb-2 text-sm text-[#39393bde] font-bold"
          >
            {' '}
            Upload your {imageType}{' '}
          </label>
          <input
            id="image"
            type="file"
            name="image"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Height </h2>
          <Slider
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            aria-label="Height slider"
            defaultValue={20}
            value={state.height}
            max={500}
            onChange={(e) =>
              dispatch({ type: 'SET_HEIGHT', payload: e.target.value })
            }
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm text-[#39393bde] font-bold"> Width </h2>
          <Slider
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            aria-label="Width slider"
            defaultValue={20}
            value={state.width}
            max={500}
            onChange={(e) =>
              dispatch({ type: 'SET_WIDTH', payload: e.target.value })
            }
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm text-[#39393bde] font-bold">
            {' '}
            Border Radius (pixel){' '}
          </h2>
          <Slider
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            aria-label="Borrder Raius slider"
            defaultValue={20}
            value={state.borderRadius}
            max={99}
            onChange={(e) =>
              dispatch({ type: 'SET_BORDERRADIUS', payload: e.target.value })
            }
          />
        </div>

        <h3 className="mb-2 text-sm text-[#39393bde] font-bold"> Colors </h3>
        <div className="flex space-x-4">
          <div
            className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
            onClick={() => backgroundColorPicker.current.click()}
          >
            <div className="relative">
              <div
                className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                style={{ background: state.background }}
              ></div>
              <input
                className="w-8 h-1 border-none bg-none rounded-sm"
                ref={backgroundColorPicker}
                type="color"
                onChange={(e) =>
                  dispatch({
                    type: 'SET_BACKGROUND',
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <span className="text-sm select-none"> Background </span>
          </div>
          <div
            className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
            onClick={() => fontColorPicker.current.click()}
          >
            <div className="relative">
              <div
                className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                style={{ background: state.fontColor }}
              ></div>
              <input
                className="w-8 h-1 border-none bg-none rounded-sm"
                ref={fontColorPicker}
                type="color"
                onChange={(e) =>
                  dispatch({
                    type: 'SET_FONTCOLOR',
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <span className="text-sm select-none"> Color </span>
          </div>
        </div>
      </div>
    );
  }
};

export default MinimizeButton;
