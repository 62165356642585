import React from 'react';
import './Loader.css';

function Loader({ customClass }) {
  return (
    <div
      className={`fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center ${customClass} z-[999999]`}
    >
      <div className="loader w-12 h-12 md:w-16 md:h-16"></div>
    </div>
  );
}

export default Loader;
