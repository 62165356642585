import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// components
import StepOne from './Steps/1';
import StepTwo from './Steps/2';
import StepThree from './Steps/3';
import StepFour from './Steps/4';
import StepFive from './Steps/5';
import StepSix from './Steps/6';
import StepSeven from './Steps/7';
import { StepEight1, StepEight2, StepEight3 } from './Steps/8';

import { StepNine1, StepNine2, StepNine3 } from './Steps/9';
import StepTen from './Steps/10';
import StepEleven from './Steps/11';
import StepTwelve from './Steps/12';
import StepThirdteen from './Steps/13';
import StepFourteen from './Steps/14';

import Thankyou from './Steps/Thankyou';

import ProgressBar from './../common/ProgressBar';
import PrevButton from './../common/PrevButton';

// import MortgageCalculator from './mortgage/Widget';
// import ViewProperties from './viewproperties/Widget';
// import { ContactUs } from './mortgage/Leads';

function Widget() {
  const [swiper, setSwiper] = useState(null);
  // const [showMortgageCalculator, setShowMortgageCalculator] = useState(false);
  // const [showViewProperties, setShowViewProperties] = useState(false);
  // const [showContactUs, setShowContactUs] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    address: '',
    beds: 0,
    baths: 0,
    squareFootage: 0,
    condition: 0,
    valueRangEstimate: [100000, 8400000],
    preferenceButton: '',
    needValueEstimateFor: '',
    checkAllThatApply: '',
    compareToMine: '',
    whereAreYouInTheProcess: '',
    subjectPropertyAddress: '',
    name: '',
    email: '',
    phone: '',
  });

  const [zestimatesLocation, setZestimatesLocation] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    geometry: {
      location: {
        lat: () => '',
        lng: () => '',
      },
    },
    formatted_address: '',
  });

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [loading, setLoading] = useState(false)

  const handleChangeStep = (newStep) => {
    setCurrentStep(newStep);
  };

  useEffect(() => {
    if (currentStep === 1 && !uniqueId) {
      let uuid = new Date().getTime();
      setUniqueId(uuid);

      axios({
        url: `https://api.ipify.org/?format=json`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const resData = res.data;
          setIpAddress(resData.ip);
          return axios({
            url: `https://api.ipgeolocation.io/ipgeo`,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              apiKey: 'deb9a79bf3024e94865209fd35da0521',
              fields: 'city,country_name',
              output: 'json',
              ip: resData.ip,
            },
          });
        })
        .then((res) => {
          const resData = res.data;
          return axios({
            url: `/api/checkHomeValues/leads/add/eptsneYqIz5TfMtYxPZI4BmHEeAqaioaDjQvFMiSy6o`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              ref: uuid,
              ipAddress: resData.ip,
              liveAddress: resData.city + ', ' + resData.country_name,
            },
          });
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    }
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const captureUserEvents = (data) => {
    axios({
      url: `/api/checkHomeValues/leads/add/eptsneYqIz5TfMtYxPZI4BmHEeAqaioaDjQvFMiSy6o`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        formValues: { ...data },
      },
    })
      .then((res) => {})
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  const handleNext = () => {
    if (currentStep === 0 && !formValues.address) return;
    captureUserEvents(formValues);
    setCurrentStep((prev) => prev + 1);
    swiper.slideNext();
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
    swiper.slidePrev();
  };

  const handleFinish = () => {
    setLoading(true);
    axios({
      url: `/api/checkHomeValues/leads/add/eptsneYqIz5TfMtYxPZI4BmHEeAqaioaDjQvFMiSy6o`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ref: uniqueId, ipAddress: ipAddress, formValues },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
    setLoading(false);

          toast.success('Successfully done!', { className: 'z-toast' });
          handleNext();
          setFormValues({
            address: '',
            beds: 0,
            baths: 0,
            squareFootage: 0,
            condition: 0,
            valueRangEstimate: [100000, 8400000],
            preferenceButton: '',
            needValueEstimateFor: '',
            checkAllThatApply: '',
            compareToMine: '',
            whereAreYouInTheProcess: '',
            subjectPropertyAddress: '',
            name: '',
            email: '',
            phone: '',
          });
          setUniqueId('');
          setIpAddress('');
          console.log('succes ckh');
        } else {
    setLoading(false);

          toast.error(resData.status, {
            className: 'z-toast',
          });
        }
      })
      .catch((err) => {
    setLoading(false);

        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, { className: 'z-toast' });
      });
  };

  //   if (showMortgageCalculator) {
  //     return (
  //       <MortgageCalculator
  //         pluginData={{
  //           showBrandingBottomText: true,
  //           logo: {
  //             allowed: false,
  //           },
  //         }}
  //         sitekey="do6X8oYvCwc4cLP7lvZPhsxsTyD9FPfb6zp27DLi4pM"
  //         showWidget={showMortgageCalculator}
  //         theme="linear-gradient(37deg, rgb(172, 30, 35) 5%, rgb(39, 45, 52) 74%)"
  //         color="rgb(233, 226, 241)"
  //         titlecolor="#ffffff"
  //         pluginTitle="Mortgage Calculator"
  //         buttontextcolor="#000000"
  //         buttoncolor="#ffffff"
  //         behaviour="withMinimize"
  //         leadname="View Properties"
  //         leadlink="https://www.bmpnetwork.com/index.php?advanced=1&display=&custombox=&types[]=1&types[]=2&types[]=3&types[]=4&types[]=5&types[]=6&types[]=8&types[]=9&types[]=12&types[]=17&types[]=20&types[]=21&types[]=31&types[]=32&types[]=38&beds=0&baths=0&min={home_price}&max={home_price}&rtype=map#rslt"
  //         varbiage={[
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972e9' },
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972ea' },
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972eb' },
  //         ]}
  //         onClose={() => setShowMortgageCalculator(false)}
  //         homeSearchPage="https://www.bmpnetwork.com/index.php?advanced=1&display=&custombox=&types[]=1&types[]=2&types[]=3&types[]=4&types[]=5&types[]=6&types[]=8&types[]=9&types[]=12&types[]=17&types[]=20&types[]=21&types[]=31&types[]=32&types[]=38&beds=0&baths=0&min={home_price}&max={home_price}&rtype=map#rslt"
  //         calenderBookingPage="https://savagesales.com/meet/DavidQueen"
  //         leadButtons={{
  //           emailPaymentDetails: true,
  //           viewProperties: true,
  //           preQualified: true,
  //           checkHomeValues: true,
  //         }}
  //       />
  //     );
  //   }
  //   if (showViewProperties) {
  //     return (
  //       <ViewProperties
  //         sitekey="do6X8oYvCwc4cLP7lvZPhsxsTyD9FPfb6zp27DLi4pM"
  //         behaviour="withMinimize"
  //         varbiage={[
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972e9' },
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972ea' },
  //           { text: '', link: '', _id: '6399600ebc03f9a9231972eb' },
  //         ]}
  //         onClose={() => setShowViewProperties(false)}
  //         homeSearchPage="https://www.bmpnetwork.com/index.php?advanced=1&display=&custombox=&types[]=1&types[]=2&types[]=3&types[]=4&types[]=5&types[]=6&types[]=8&types[]=9&types[]=12&types[]=17&types[]=20&types[]=21&types[]=31&types[]=32&types[]=38&beds=0&baths=0&min={home_price}&max={home_price}&rtype=map#rslt"
  //         calenderBookingPage="https://savagesales.com/meet/DavidQueen"
  //       />
  //     );
  //   }
  //   if (showContactUs) {
  //     return (
  //       <ContactUs
  //         sitekey="do6X8oYvCwc4cLP7lvZPhsxsTyD9FPfb6zp27DLi4pM"
  //         calculatedData={{}}
  //         onClose={() => setShowContactUs(false)}
  //         uniqueId={Date.now()}
  //         calenderBookingPage="https://savagesales.com/meet/DavidQueen"
  //         handleReset={() => setShowContactUs(false)}
  //       />
  //     );
  //   }

  if (currentStep === 13) {
    return (
      <div className="rates_modal">
        <div className="rates_modal_wrapper px-4 py-5 bg-white rounded-xl">
          <Thankyou />
        </div>
      </div>
    );
  }

  return (
    <div className="mtge-rates_modal_wrapper max-w-full mt-6 p-0 px-4 md:px-8 bg-white slide-in-left">
      <header className="flex items-center justify-between">
        <div className="w-14 md:w-16">
          <img
            className="w-full object-contain"
            src={`/images/logo.png`}
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar
        formValues={formValues}
        totalStep={12}
        currentStep={currentStep}
        onChangeStep={handleChangeStep}
      />
      <main className="min-h-[50px] mt-4 overflow-hidden">
        <Swiper
          cssMode={true}
          allowTouchMove={false}
          navigation={{
            nextEl: '.swiper-button-custom-next',
            prevEl: '.swiper-button-custom-prev',
          }}
          initialSlide={currentStep}
          modules={[Navigation]}
          className="mySwiper"
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
          <SwiperSlide>
            {' '}
            <StepOne
              value={formValues.zipCode}
              onChange={handleChange}
              handleNext={handleNext}
              setZestimatesLocation={setZestimatesLocation}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwo
              value={formValues.beds}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThree
              value={formValues.baths}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFour
              value={formValues.squareFootage}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFive
              value={formValues.condition}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSix
              value={formValues.valueRangEstimate}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSeven
              value={formValues.preferenceButton}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>{' '}
          <SwiperSlide>
            {formValues.preferenceButton === 'I own this property' ? (
              <StepEight1
                value={formValues.needValueEstimateFor}
                onChange={handleChange}
                handleNext={handleNext}
              />
            ) : formValues.preferenceButton ===
              'I am interested in this property' ? (
              <StepEight2
                value={formValues.checkAllThatApply}
                onChange={handleChange}
                handleNext={handleNext}
              />
            ) : formValues.preferenceButton ===
              'I am using this property as a comparable to mine' ? (
              <StepEight3
                value={formValues.compareToMine}
                onChange={handleChange}
                handleNext={handleNext}
              />
            ) : (
              <SwiperSlide>
                {' '}
                <StepTen
                  value={formValues.name}
                  onChange={handleChange}
                  handleNext={handleNext}
                />
              </SwiperSlide>
            )}{' '}
          </SwiperSlide>
          <SwiperSlide>
            {formValues.preferenceButton === 'I own this property' &&
            formValues.needValueEstimateFor === 'Selling' ? (
              <StepNine1
                value={formValues.whereAreYouInTheProcess}
                onChange={handleChange}
                handleNext={handleNext}
              />
            ) : formValues.preferenceButton ===
                'I am using this property as a comparable to mine' &&
              formValues.compareToMine === 'Send me other comparables' ? (
              <StepNine3
                value={formValues.subjectPropertyAddress}
                onChange={handleChange}
                handleNext={handleNext}
              />
            ) : (
              <SwiperSlide>
                {' '}
                <StepTen
                  value={formValues.name}
                  onChange={handleChange}
                  handleNext={handleNext}
                />
              </SwiperSlide>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTen
              value={formValues.name}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepEleven
              value={formValues.email}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwelve
              value={formValues.phone}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThirdteen
              location={zestimatesLocation}
              state={currentStep === 12}
              handleFinish={handleFinish}
              loading={loading}
            />
          </SwiperSlide>{' '}
          {/* <SwiperSlide>
            {' '}
            <StepFourteen
              value={formValues.phone}
              onChange={handleChange}
              setShowMortgageCalculator={setShowMortgageCalculator}
              setShowViewProperties={setShowViewProperties}
              setShowContactUs={setShowContactUs}
              calenderBookingPage={calenderBookingPage}
            />
          </SwiperSlide> */}
        </Swiper>

        {(currentStep !== 0 || currentStep !== 13) && (
          <PrevButton handlePrev={handlePrev} />
        )}
      </main>

      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-center text-xs text-[#6e7c81] space-x-2">
          {/* {varbiage.map((dt, idx) => {
            if (dt.text) {
              return (
                <a
                  className="hover:underline"
                  key={idx}
                  target="_blank"
                  href={dt.link}
                >
                  {dt.text}
                </a>
              );
            }
          })} */}
        </div>

        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

// const PrevButton = ({ handlePrev }) => {
//   return (
//     <button
//       className="absolute top-1/2 left-2 p-1 border-[1px] transform -translate-y-1/2 z-50"
//       onClick={handlePrev}
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth={1.5}
//         stroke="currentColor"
//         className="w-3 h-3 text-[var(--color-red)]"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           d="M15.75 19.5L8.25 12l7.5-7.5"
//         />
//       </svg>
//     </button>
//   );
// };

export default Widget;
