import mortgageViewProperties from './viewComponent';

export default function mortgageViewPlugin({ editor }) {
  editor.Components.addType('mortgage-viewProperties', {
    model: {
      defaults: {
        script: mortgageViewProperties,
      },
    },
  });

  editor.Blocks.add('mortgage-widget-viewProperties', {
    label: 'View Properties',
    attributes: { class: 'fa fa-house-chimney' },
    content: { type: 'mortgage-viewProperties' },
  });
}
