import React, { useEffect, useState } from "react";
import axios from "axios";

import Loader from "../components/Loader/Loader";

function MarketPlace() {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);

  const [filteredServices, setFilteredServices] = useState([]);

  const [marketPlaceBg, setMarketPlaceBg] = useState({
    type: "color",
    value: "",
  });

  useEffect(() => {
    if (marketPlaceBg.type === "color") {
      document.body.style.background = marketPlaceBg.value;
    } else {
      document.body.style.background = `url('${marketPlaceBg.value}')`;
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundPosition = "center center";
    }

    return () => {
      document.body.style.background = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundRepeat = "";
      document.body.style.backgroundPosition = "";
    };
  }, [marketPlaceBg]);

  useEffect(() => {
    axios({
      url: "/api/system/marketplaces",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          setMarketPlaceBg(resData.data.background);
          setServices(resData.data.services);
          setFilteredServices(resData.data.services);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (value.length > 0) {
      const regx = new RegExp(`^.*?\\b(${value.toLowerCase()}).*?`, "gi");
      let fs = services.filter((dt) => {
        if (regx.test(dt.name.toLowerCase())) {
          return dt;
        }
      });
      console.log(fs);
      setFilteredServices(fs);
    } else {
      setFilteredServices(services);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <header className="py-6 px-4">
        <div className="mx-auto w-14 md:w-24">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
        </div>
      </header>
      <div className="px-6 py-8">
        <h1 className="text-2xl text-[#39393bde] font-bold">Marketplaces</h1>
        <div className="mt-8 flex items-center space-x-2">
          <input
            type="text"
            className="w-full max-w-lg p-2 border-2 rounded-md outline-none"
            placeholder="Search for marketplace"
            onChange={handleSearch}
          />
        </div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredServices.map((dt, idx) => (
            <Card
              key={idx}
              url={dt.url}
              img={dt.img}
              name={dt.name}
              info={dt.info}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const Card = ({ img, name, info, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="border-[1px] bg-white"
    >
      <div
        className="w-full h-44 bg-no-repeat bg-contain bg-center"
        style={{
          backgroundImage: `url('${img}')`,
        }}
      ></div>
      <div className="px-4 py-6">
        <h2 className="text-lg font-bold"> {name} </h2>
        <p>{info}</p>
        {!url && (
          <p>
            {" "}
            <small>Coming soon!</small>{" "}
          </p>
        )}
      </div>
    </a>
  );
};

export default MarketPlace;
