import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Widget from '../Mortgage_Rates/Widget/Widget';
import Loader from '../Loader/Loader';

function StandAloneMortgageRates() {
  const params = useParams();
  const [pluginToken, setPluginToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [varbiage, setVarbiage] = useState([]);
  const [mortgageRateTitle, setMortgageRateTitle] = useState('');

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    // axios({
    //   url:
    //     "/api/plugin/standalone/" +
    //     params.username +
    //     "/" +
    //     params.calculatorname,
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => {
    //     const resData = res.data;
    //     setIsLoading(false);
    //     if (resData.success) {
    //       setPluginToken(resData.data.token);
    //     } else {
    //       setError(true);
    //     }
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err);
    //     setError(true);
    //   });

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);

  useEffect(() => {
    if (params.pluginToken) {
      axios({
        url: '/api/mortgageRates/token/' + params.pluginToken,
        method: 'get',
      })
        .then((res) => {
          const resData = res.data;
          console.log(resData);

          if (resData.success) {
            setMortgageRateTitle(resData.data.title);
            setVarbiage(resData.data.varbiage);

            setPluginToken(resData.data.token);
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          // toast.error(error.message || status, {});
        });
    }
  }, [params]);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!params.pluginToken) return <></>;
  return (
    <div className="min-h-screen flex items-center justify-center">
      {' '}
      <Widget
        mortageRateTitle={mortgageRateTitle}
        pluginToken={params.pluginToken}
        varbiage={varbiage}
      />
    </div>
  );
}

export default StandAloneMortgageRates;
