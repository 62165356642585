import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Widget from '../Custom_funnel/CustomFunnel';
import Loader from '../Loader/Loader';

function StandAloneMortgageRates() {
  const params = useParams();
  const [pluginToken, setPluginToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [varbiage, setVarbiage] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [pluginTitle, setPluginTitle] = useState('');

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);

  useEffect(() => {
    if (params.pluginToken) {
      axios({
        url: '/api/funnels/token/' + params.pluginToken,
        method: 'get',
      })
        .then((res) => {
          const resData = res.data;
          console.log(resData);

          if (resData.success) {
            setPluginTitle(resData.data.title);
            setVarbiage(resData.data.varbiage);
            setStepsData(resData.data.steps);
            setPluginToken(resData.data.token);
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          // toast.error(error.message || status, {});
        });
    }
  }, [params]);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!params.pluginToken) return <></>;
  return (
    <div className="min-h-screen flex items-center justify-center">
      {' '}
      <Widget
        stepsData={stepsData}
        pluginTitle={pluginTitle}
        pluginToken={params.pluginToken}
        varbiage={varbiage}
      />
      {/* stepsData, pluginTitle, pluginToken, varbiage */}
    </div>
  );
}

export default StandAloneMortgageRates;
