import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Header from '../Dashboard/Header';
import Sidebar from './Sidebar';
import Main from './Main';
import Loader from '../Loader/Loader';
import System from './System';
import TemplateList from './AdminBuilder/TemplateList';
import AdsManager from './AdsManagement/AdsManager';
import AdsCards from './AdsManagement/AdsCards';

function Admin() {
  const navigate = useNavigate();

  const [{ user }] = useContext();

  const [currentTab, setCurrentTab] = useState(0);

  const [showSidebar, setShowSidebar] = useState(false);
  const [pages, setPages] = useState({ current: 1, total: 1 });
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!user) return navigate('/');
    axios({
      url: '/api/users/all',
      params: {
        page: 1,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        setIsLoading(false);
        if (resData.success) {
          setUsers(resData.data);
          setPages({
            current: resData.current_page,
            total: resData.total_pages,
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  }, []);

  const handleSelectUser = (id) => {
    let tmpt = users.find((user) => user._id === id);
    setSelectedUser(tmpt);
    setShowSidebar(false);
  };

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Header />
      <main className="main_admin pt-[88px] md:pt-[104px] pb-4 md:pb-8 px-4 md:px-16">
        <Box
          sx={{
            width: '100%',
            height: 'auto !important',
            bgcolor: 'background.paper',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            // centered
            style={{ justifyContent: 'center' }}
            sx={{
              [`& .${tabsClasses.scroller}`]: {
                '&.MuiTabs-scrollableX': { flexGrow: '0' },
              },
            }}
          >
            <Tab label="User management" />
            <Tab label="system Management" />
            <Tab label="Template Editor" />
            <Tab label="Ads Management" />
          </Tabs>
        </Box>
        {currentTab === 0 ? (
          <div className="relative flex overflow-hidden">
            <Sidebar
              users={users}
              pages={pages}
              setPages={setPages}
              setUsers={setUsers}
              selectedUser={selectedUser}
              onSelect={handleSelectUser}
              show={showSidebar}
              handleToggler={() => setShowSidebar((prev) => !prev)}
            />
            <Main
              allUsers={users}
              setUsers={setUsers}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              handleToggler={() => setShowSidebar((prev) => !prev)}
            />
          </div>
        ) : currentTab === 1 ? (
          <System />
        ) : currentTab === 2 ? (
          <TemplateList />
        ) : (
          <AdsCards />
        )}
      </main>
    </>
  );
}

export default Admin;
