import React from 'react';
import { toast } from 'react-toastify';
import USCITIES from '../USCities.json';

function StepOne({ value, onChange, handleNext }) {
  const verifyZipCodeAndProceed = () => {
    let ft = USCITIES.find((dt) => {
      if (dt.zip_code === Number(value)) {
        return dt;
      }
    });

    if (ft) {
      handleNext();
    } else {
      toast.error('Invalid zip code!');
    }
  };
  return (
    <div className="my-4 flex flex-col items-center">
      <h1 className="text-xl md:text-3xl text-[#ac1e23] capitalize font-medium">
        Today's Mortgage Rates
      </h1>
      <p className="my-4 md:w-96 text-center text-xs md:text-sm text-[#6e7c81]">
        Mortgage rates change daily and vary depending on your unique situation.
        Get your FREE customized quote here!
      </p>
      <div className="flex flex-col items-center space-y-3">
        <div className="relative max-w-[100px] md:max-w-[125px]">
          <input
            id="zipCode"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="zipCode"
            value={value}
            onChange={onChange}
            onInput={(e) => {
              e.target.value = e.target.value
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1');
            }}
          />
          <label
            htmlFor="zipCode"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Zip Code
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-lg md:text-xl"
          onClick={verifyZipCodeAndProceed}
        >
          GO!
        </button>
      </div>
    </div>
  );
}

export default StepOne;
