import axios from 'axios';
import React, { useState, useEffect } from 'react';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';

import ModalWrapper from '../Modal';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';

function ManageAccess() {
  const [{ user, allProfiles, isOwnPlugin }, dispatch] = useContext();
  const { profileId } = useParams();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    allProfiles.map((dt) => {
      if (dt._id === profileId) {
        setManagers(dt.managers);
      }
    });
  }, [profileId, allProfiles]);

  const handleRemoveUser = (userId) => {
    axios({
      url: '/api/profiles/delUser',
      method: 'PUT',
      data: {
        userId,
        profileId,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          let updated = allProfiles.map((dt) => {
            if (dt._id === profileId) {
              return { ...dt, managers: resData.data.managers };
            } else {
              return dt;
            }
          });
          dispatch({
            type: ActionTypes.SET_ALL_PROFILES,
            payload: updated,
          });
          setManagers(resData.data.managers);
          toast.success('User removed!', {});
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  // if (!isOwnPlugin) {
  //   return <></>;
  // }

  return (
    <div className="px-6 py-8">
      <h1 className="text-2xl text-[#39393bde] font-bold">Manage access</h1>

      <div className="mt-12 overflow-auto">
        <table className="w-full min-w-[500px]">
          <thead>
            <tr className="text-center border-b-2 border-collapse">
              <th className="px-2 py-4"> Email </th>

              <th className="px-2 py-4"> Action </th>
              <th className="px-2 py-4"> Funnels </th>
            </tr>
          </thead>
          <tbody>
            {managers.map((dt) => {
              return (
                <tr className="text-center border-b-2 border-collapse">
                  <td className="px-2 py-4"> {dt.user.email} </td>
                  <td className="px-2 py-4">
                    <button
                      className="px-4 py-1 text-sm text-white font-bold rounded-lg bg-red-600"
                      onClick={(e) => handleRemoveUser(dt.user._id)}
                    >
                      Remove
                    </button>
                  </td>
                  <td className="px-2 py-4"> {dt.funnels.join(',')} </td>
                </tr>
              );
            })}

            <tr className="text-center border-collapse">
              <td colSpan={3} className="pt-8">
                <button
                  className="px-4 py-2 text-sm text-white font-bold rounded-lg bg-gray-600"
                  onClick={() => setShowAddUserModal(true)}
                >
                  Add new user
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {showAddUserModal && (
        <AddNewUser
          show={true}
          onClose={() => setShowAddUserModal(false)}
          setManagers={setManagers}
          profileId={profileId}
        />
      )}
    </div>
  );
}

const AddNewUser = ({ show, onClose, setManagers, profileId }) => {
  const [{ user, allProfiles }, dispatch] = useContext();
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState('');

  const [checkedValues, setCheckedValues] = useState([]);

  const handleAddUser = () => {
    setLoader(true);
    if (user.email === email) {
      setLoader(false);
      toast.error("You can't add yourself.");
      return;
    }

    axios({
      url: '/api/profiles/addUser',
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      data: {
        email: email,
        profileId,
        funnels: checkedValues,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setLoader(false);

          let updated = allProfiles.map((dt) => {
            if (dt._id === profileId) {
              return { ...dt, managers: resData.data.managers };
            } else {
              return dt;
            }
          });
          dispatch({
            type: ActionTypes.SET_ALL_PROFILES,
            payload: updated,
          });
          setManagers(resData.data.managers);
          toast.success('User added!', {});
          onClose();
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setLoader(false);

        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const handleCheckbox = (e) => {
    const { name, value } = e.target;
    let index = checkedValues.indexOf(value);
    console.log(value, index);
    if (index > -1) {
      let update = checkedValues.filter((dt) => dt !== value);
      setCheckedValues(update);
    } else {
      setCheckedValues((prev) => [...prev, value]);
    }
  };

  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper py-8 w-full max-w-[560px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mtge-calc_modal-close" onClick={onClose}>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="w-full">
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="email"
                className="text-sm text-black font-semibold"
              >
                User's Email
              </label>
            </div>
            <div>
              <input
                id="email"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-2 py-3 rounded-lg text-black text-base outline-none border-2"
                required
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="funnels"
                className="text-sm text-black font-semibold"
              >
                Funnels
              </label>
            </div>
            <div className="flex items-center flex-wrap gap-3">
              <div className="flex items-center space-x-2">
                <input
                  id="calculator"
                  type="checkbox"
                  value="Calculator"
                  checked={checkedValues.indexOf('Calculator') !== -1}
                  onChange={handleCheckbox}
                />
                <label htmlFor="calculator">Calculator</label>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  id="mortgageRate"
                  type="checkbox"
                  value="MortgageRates"
                  checked={checkedValues.indexOf('MortgageRates') !== -1}
                  onChange={handleCheckbox}
                />
                <label htmlFor="mortgageRate">MortgageRates</label>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  id="viewProperties"
                  type="checkbox"
                  value="ViewProperties"
                  checked={checkedValues.indexOf('ViewProperties') !== -1}
                  onChange={handleCheckbox}
                />
                <label htmlFor="viewProperties">ViewProperties</label>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  id="flexiButtons"
                  type="checkbox"
                  value="FlexiButtons"
                  checked={checkedValues.indexOf('FlexiButtons') !== -1}
                  onChange={handleCheckbox}
                />
                <label htmlFor="flexiButtons">FlexiButtons</label>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="block w-full md:w-60 mx-auto px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleAddUser}
            >
              Add
            </button>
          </div>
          {loader && <Loader />}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ManageAccess;
