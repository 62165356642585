import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import config from './../../../../../utils/config';
import ModalLoader from '../../../../Loader/ModalLoader';

function StepThirdteen({ location, state, handleFinish, loading }) {
  const [data, setData] = useState({
    zillow: null,
    attom: null,
  });

  useEffect(() => {
    console.log('Zetimates');
    if (!state) return;
    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/zillow',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          zillow:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          zillow: 'Not Available',
        }));
      });

    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/attom',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          attom:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          attom: 'Not Available',
        }));
      });
  }, [state]);
  if (!state) return <></>;
  return (
    <div className="mtge-zestimates_container">
      <div className="mtge-zestimates">
        <div className="mtge-zestimates_items">
          <div className="mtge-zestimates_item">
            <img
              src={`${config.baseUrl}/images/attom-100.0bdd301.png`}
              alt="attom"
            />
            <h2> {data.attom ? data.attom : 'Loading...'} </h2>
          </div>
          <div className="mtge-zestimates_item-devider"></div>
          <div className="mtge-zestimates_item">
            <img
              src={`${config.baseUrl}/images/zillow-100.3244ea1.png`}
              alt="zillow"
            />
            <h2> {data.zillow ? data.zillow : 'Loading...'} </h2>
          </div>
        </div>

        <p className="mtge-zestimates_info">
          Independent price estimates are based on statistical models that
          aggregate available data to form an estimate of market value.
          Estimates are not local experts, they don`t walk through your home or
          the homes in your market to verify their data or account for
          condition. I do - ask me how I`d price your home in today`s market.
        </p>
      </div>
      <div className="mt-6">
        <h6 className="text-base text-center font-medium">
          {' '}
          Do you agree with these valuations?{' '}
        </h6>
        <div className="m-1 flex items-center justify-center space-x-3">
          <button
            className="px-2 py-1 border-2 rounded-sm hover:bg-slate-50 transition-all duration-300"
            onClick={() => {
              // handleNext();
              handleFinish();
            }}
          >
            Yes
          </button>
          <button
            className="px-2 py-1 border-2 rounded-sm hover:bg-slate-50 transition-all duration-300"
            onClick={() => {
              // handleNext();
              handleFinish();
            }}
          >
            No
          </button>
        </div>
      </div>
      <div className="mtge-zestimates_c-map">
        <h2> Is it a buyer’s market or seller’s market? </h2>
        <p>
          The law of supply and demand dictates the equilibrium price of a
          property, and I can show you if buyers are searching your area now. We
          keep a pulse on buyer interest and how that interest compares to the
          areas around you.
        </p>
        <div
          className="mtge-zestimates_g-map"
          style={{ height: '400px', width: '100%' }}
        >
          {/* AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw */}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw',
            }}
            defaultCenter={{
              address: location.formatted_address,
              lat: location.geometry.location.lat(),
              lng: location.geometry.location.lng(),
            }}
            defaultZoom={14}
          >
            <div
              className="mtge-zestimates_pin"
              lat={location?.geometry.location.lat()}
              lng={location?.geometry.location.lng()}
            >
              <p>A</p>
            </div>
          </GoogleMapReact>
        </div>
        {loading && <ModalLoader />}
      </div>
    </div>
  );
}

function currencyWithCommas(x) {
  x = x.toFixed(2);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default StepThirdteen;
