import React from 'react';

function StepFourteen({ value, onChange, handleNext }) {
  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        Can you show proof of income?
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Yes',
                name: 'hasProofOfIncome',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Yes'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Yes
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'No',
                name: 'hasProofOfIncome',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'No'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            No
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}

export default StepFourteen;
