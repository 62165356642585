import React, { useState, useRef, useEffect, useReducer } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import axios from 'axios';
import ActionTypes from '../../utils/actionTypes';
import useContext from '../../context/StateProvider';

import Loader from '../Loader/Loader';
import Preview from './Preview';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import config from '../../utils/config';
import ApiModal from '../common/APIModal';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function Plugin() {
  const [{ user, currentPlugin, allPlugins, isOwnPlugin }, dispatch] =
    useContext();
  const { pluginId } = useParams();
  const navigate = useNavigate();

  // const [pluginId, setPluginId] = useState("");
  const [pluginLogo, setPluginLogo] = useState('/images/logo.png');

  const [pluginName, setPluginName] = useState('');
  const [pluginTitle, setPluginTitle] = useState('');
  const [pluginToken, setPluginToken] = useState('');
  const [emailTo, setEmailTo] = useState('');

  const [varbiage, setVarbiage] = useState({});
  const [varbiage1, setVarbiage1] = useState({
    text: 'Varbiage1',
    link: 'Virvas2',
  });
  const [varbiage2, setVarbiage2] = useState({
    text: 'Varbiage1',
    link: 'Virvas2',
  });

  const [behaviour, setBehaviour] = useState('withMinimize');
  const [state, setState] = useState(true);

  const [domainNo, setDomainNo] = useState(1);
  const [focusNextInput, setFocusNextInput] = useState(false);

  const [domains, domainsDispatch] = useReducer((state, action) => {
    const { payload, type } = action;

    if (type === 'add') {
      state[payload.pos] = payload.value;
    } else if (type === 'del') {
      let tmpt = state.filter((t, idx) => idx !== payload.pos);
      state = tmpt;
    } else if ('initial') {
      setDomainNo(payload.total + 1);
      state = payload.value;
    }
    return state;
  }, []);

  const [loading, setLoading] = useState(true);
  const [openApiModal, setOpenApiModal] = useState(false);

  const getApi = `${config.baseUrl}/api/funnels/v1/mortgage-rates/get/${pluginToken}`;
  const postApi = `${config.baseUrl}/api/funnels/v1/mortgage-rates/post/${pluginToken}`;

  const handleOpenApiModal = () => {
    setOpenApiModal(true);
  };

  const handleCloseApiModal = () => {
    setOpenApiModal(false);
  };

  useEffect(() => {
    if (pluginId) {
      axios({
        url: '/api/mortgageRates/' + pluginId,
        method: 'get',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            dispatch({
              type: ActionTypes.SET_CURRENT_PLUGIN,
              payload: resData.data,
            });

            setVarbiage(resData.data.varbiage[0] || { text: '', link: '' });
            setVarbiage1(resData.data.varbiage[1] || { text: '', link: '' });
            setVarbiage2(resData.data.varbiage[2] || { text: '', link: '' });

            setPluginName(resData.data.name);
            setPluginTitle(resData.data.title);
            setPluginToken(resData.data.token);
            localStorage.setItem('mortgageRateToken', resData.data.token);
            setEmailTo(resData.data.emailTo);
            setBehaviour(resData.data.behaviour);
            setState(resData.data.state);

            domainsDispatch({
              type: 'initial',
              payload: {
                total: resData.data.domains.length,
                value: resData.data.domains,
              },
            });
          } else {
            toast.error(resData.status);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });

      setLoading(false);
    }
  }, [pluginId]);

  const handleSave = () => {
    if (user.accountType !== 'Pro') {
      toast.error('Please upgrade your plan');
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        name: pluginName,
        title: pluginTitle,
        emailTo: emailTo.toLowerCase(),
        behaviour,

        domains,
        varbiage: [varbiage, varbiage1, varbiage2],
        state,
      })
    );
    axios({
      url: '/api/mortgageRates/update/' + pluginId,
      method: 'put',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        setLoading(false);
        if (resData.success) {
          toast.success('Updated successfully');
          setVarbiage(resData.data.varbiage[0] || { text: '', link: '' });
          setVarbiage1(resData.data.varbiage[1] || { text: '', link: '' });
          setVarbiage2(resData.data.varbiage[2] || { text: '', link: '' });

          setPluginName(resData.data.name);
          setPluginTitle(resData.data.title);
          setEmailTo(resData.data.emailTo);
          setBehaviour(resData.data.behaviour);
          setState(resData.data.state);

          domainsDispatch({
            type: 'initial',
            payload: {
              total: resData.data.domains.length,
              value: resData.data.domains,
            },
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const newDomain = (idx) => {
    return (
      <input
        className="px-2 text-sm h-[2rem] rounded-md bg-[#FFFFFF80] shadow-sm w-full outline-none border-2"
        placeholder="Add domain"
        key={idx}
        disabled={idx + 1 < domainNo}
        autoFocus={focusNextInput && idx + 1 === domainNo}
        onChange={(e) =>
          domainsDispatch({
            type: 'add',
            payload: { pos: idx, value: e.target.value },
          })
        }
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13 || e.code === 'Enter') {
            if (domainNo <= 3 && domains[idx].length > 0) {
              setFocusNextInput(true);
            }
            setDomainNo((prev) => prev + 1);
          }
        }}
      />
    );
  };

  if (loading) {
    return <Loader customClass="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <div>
        <h1 className="text-2xl text-[#39393bde] font-bold">
          Embed Mortgage Rates in your website
        </h1>
        <div className="dashboard_plugin_embed flex justify-between mt-4">
          <div
            className={`relative flex-1 py-2 px-3 ${
              user.accountType === 'Pro'
                ? 'overflow-auto'
                : 'overflow-hidden select-none'
            }`}
          >
            {user.accountType !== 'Pro' && (
              <div
                className="absolute top-0 left-0 right-0 bottom-0 z-10"
                style={{
                  background: 'rgba( 255, 255, 255, 0.25 )',
                  boxShadow: ' 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                  backdropFilter: 'blur( 5.5px )',
                  WebkitBackdropFilter: 'blur( 5.5px )',

                  border: '1px solid rgba( 255, 255, 255, 0.18 )',
                }}
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <span className="text-base text-[#39393bde] font-bold">
                    Upgrade your plan to use this funnel
                  </span>{' '}
                </div>
              </div>
            )}
            <pre>
              <ol className="text-[#97AAC3]">
                <li>
                  <span className="text-gray-400">
                    {' '}
                    &lt;!-- Mortgage Rates Widget --&gt;{' '}
                  </span>
                </li>
                <li>
                  <span> &lt;script&gt;</span>
                </li>

                <li>
                  {' '}
                  <span>
                    {' '}
                    var d = document.createElement("div");var qs =
                    document.createElement("script");d.classList.add("mtge-rates_embeded");d.setAttribute("id",
                    "mtge-rates_embeded");
                  </span>
                </li>
                <li>
                  {'  '}
                  <span>
                    d.setAttribute("data-sitekey","
                    {user.accountType === 'Pro'
                      ? pluginToken
                      : '*****************************'}
                    ");qs.src = "{config.baseUrl}
                    /static/js/morgage-rates.js";qs.defer = true;
                  </span>
                </li>
                <li>
                  <span>
                    {'  '}
                    var l1 =
                    document.createElement("link");l1.setAttribute("rel",
                    "stylesheet");l1.setAttribute("type",
                    "text/css");l1.setAttribute("href","{config.baseUrl}
                    /static/css/morgage-rates.css");
                  </span>
                </li>
                <li>
                  {' '}
                  <span>
                    {' '}
                    document.head.appendChild(l1);
                    document.body.appendChild(d);document.body.appendChild(qs);{' '}
                  </span>
                </li>

                <li>
                  <span> &lt;/script&gt;</span>
                </li>

                <li>
                  <span className="text-gray-400">
                    {' '}
                    &lt;!-- End Mortgage Rates Widget --&gt;{' '}
                  </span>
                </li>
              </ol>
            </pre>
          </div>
          <div className="dashboard_plugin_embed-clipboard px-6 py-2 md:px-10 md:py-6">
            <CopyToClipboard
              text={
                user.accountType === 'Pro' &&
                `<!-- Mortgage Rates Widget --> 
                            <script>
                              var d = document.createElement("div");var qs = document.createElement("script");var currentScriptTag = document.currentScript;d.classList.add("mtge-rates_embeded");d.setAttribute("id", "mtge-rates_embeded");                       
                              d.setAttribute("data-sitekey","${pluginToken}");qs.src = "${config.baseUrl}/static/js/morgage-rates.js";qs.defer = true;
                              var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/morgage-rates.css");
                              document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
                            </script>
                          <!-- End Mortgage Rates Widget -->`
              }
              onCopy={() => {
                if (user.accountType === 'Pro') {
                  toast.success('Copied to clipboard');
                  toast.success('Please add the code into body tag');
                } else {
                  toast.error('Please upgrade your plan');
                }
              }}
            >
              <button className="w-full h-full text-center text-[#7B94B5] text-sm md:text-base">
                <span> Copy to </span> <br />
                <span> Clipboard </span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-12 space-y-4 md:space-y-0 md:space-x-6">
        <div className="md:mr-6">
          <h1 className="text-2xl text-[#39393bde] font-bold">
            Customize the plugin
          </h1>

          <div className="mt-6">
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="pluginName"
              >
                Funnel Name
              </label>
              <input
                id="pluginName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="pluginName"
                value={pluginName}
                placeholder="Default"
                onChange={(e) => setPluginName(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="pluginName"
              >
                Funnel Title
              </label>
              <input
                id="pluginTitle"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="pluginTitle"
                value={pluginTitle}
                placeholder="Mortage Rate"
                onChange={(e) => setPluginTitle(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="emailTo"
              >
                Email To
              </label>
              <input
                id="emailTo"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="emailTo"
                value={emailTo}
                placeholder="example@gmail.com"
                onChange={(e) => setEmailTo(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="behaviour"
              >
                {' '}
                Behavior{' '}
              </label>
              <div className="ml-2 md:flex items-center md:space-x-5">
                <label
                  className="flex items-center space-x-2"
                  htmlFor="withMinimize"
                >
                  <input
                    id="withMinimize"
                    name="behaviour"
                    type="radio"
                    value="withMinimize"
                    checked={behaviour === 'withMinimize'}
                    onChange={(e) => setBehaviour(e.target.value)}
                  />
                  <span>With minimize option</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="withoutMinimize"
                >
                  <input
                    id="withoutMinimize"
                    name="behaviour"
                    type="radio"
                    value="withoutMinimize"
                    checked={behaviour === 'withoutMinimize'}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Without minimize option</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="saveAsNativeElement"
                >
                  <input
                    id="saveAsNativeElement"
                    name="behaviour"
                    type="radio"
                    value="saveAsNativeElement"
                    checked={behaviour === 'saveAsNativeElement'}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Save as an element on your website</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="displayAsAFlexiFrame"
                >
                  <input
                    id="displayAsAFlexiFrame"
                    name="behaviour"
                    type="radio"
                    value="displayAsAFlexiFrame"
                    checked={behaviour === 'displayAsAFlexiFrame'}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Display as a FlexiFrame</span>
                </label>
              </div>
            </div>

            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Initial State
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={state}
                label={state ? 'Open' : 'Minimized'}
                onChange={(e) => setState((prev) => !prev)}
              />
            </div>

            {/*  */}

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-1
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-1 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>

            {/* varbiage2 */}
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-2
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage1.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage1((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-2 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage1.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage1((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>

            {/* varbiage3 */}
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-3
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage2.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage2((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-3 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage2.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage2((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>
            {/*  */}

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className=" text-base text-[#39393bde] font-extrabold"
                htmlFor="domains"
              >
                Domains{' '}
                <span className="text-sm text-gray-400">
                  {' '}
                  (eg. example.com){' '}
                </span>
              </label>
              <div className="flex flex-col space-y-3">
                {Array(domainNo)
                  .fill(newDomain)
                  .map((item, index) => {
                    if (index <= 2 && index + 1 === domainNo) {
                      return (
                        <span className="w-full flex space-x-2" key={index}>
                          <button
                            onClick={() => setDomainNo((prev) => prev + 1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                          {item(index)}
                        </span>
                      );
                    } else if (index <= 2) {
                      return (
                        <span className="w-full flex space-x-2" key={index}>
                          <button
                            onClick={(e) => {
                              console.log(e);
                              domainsDispatch({
                                type: 'del',
                                payload: { pos: index },
                              });
                              setDomainNo((prev) => prev - 1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          <span className="text-sm h-[2rem] flex items-center rounded-md bg-[#FFFFFF80] shadow-sm w-full mr-1">
                            {domains[index]}
                          </span>
                        </span>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          <Link
            to={`minimize-button`}
            className="mb-6 flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
          >
            <span>Customize Minimize Button</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
              />
            </svg>
          </Link>
          <CopyToClipboard
            text={`${window.location.origin}/view/mortgageRates/${pluginToken}`}
            onCopy={() => toast.success('Copied to clipboard')}
          >
            <button className="flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
              <span>Share/Preview Mortgage Rates</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
          </CopyToClipboard>

          <div className="mt-6 flex items-center flex-wrap gap-3">
            <button
              className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleSave}
              // disabled={!edited || loading}
            >
              Save
            </button>
            <button
              className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleOpenApiModal}
              // disabled={!edited || loading}
            >
              Get API
            </button>

            <ApiModal
              open={openApiModal}
              getApi={getApi}
              postApi={postApi}
              handleClose={handleCloseApiModal}
            />
          </div>
        </div>
        <Preview
          mortageRateTitle={pluginTitle}
          pluginToken={pluginToken}
          varbiage={[varbiage, varbiage1, varbiage2]}
        />
      </div>
    </div>
  );
}

export default Plugin;
