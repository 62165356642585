import React from 'react';

export function StepNine1({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        Where are you in the process?
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Just starting',
                name: 'whereAreYouInTheProcess',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Just starting'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Just starting
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Interviewing Agents',
                name: 'whereAreYouInTheProcess',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Interviewing Agents'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Interviewing Agents
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Gathering information',
                name: 'whereAreYouInTheProcess',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Gathering information'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Gathering information
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Already on the market',
                name: 'whereAreYouInTheProcess',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Already on the market'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Already on the market
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: "I don't know",
                name: 'whereAreYouInTheProcess',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === "I don't know"
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I don't know
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}

export function StepNine2({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        Check all that apply:
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Send me more info on this home',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Send me more info on this home'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Send me more info on this home
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I would like additional photos',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I would like additional photos'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I would like additional photos
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I would like to schedule a showing',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I would like to schedule a showing'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I would like to schedule a showing
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}
export function StepNine3({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        What is the address of your subject property?
      </h1>
      <h6 className="text-xs font-medium">
        (the home you want comparables for)
      </h6>
      <div className="mt-3 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="subjectPropertyAddress"
            className="rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="subjectPropertyAddress"
            required
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="subjectPropertyAddress"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Address
          </label>
        </div>

        <button
          className="rates_next-btn px-16 py-2 text-base md:text-lg"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
