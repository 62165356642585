import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import services from './../../services/pageBulder.service';
// import DOMPurify from 'dompurify';
// import useContext from '../../context/StateProvider';

function RenderedPage() {
  const { pageId } = useParams();
  // const [{ user }] = useContext();
  // const [html, setHtml] = useState();
  // const [css, setCss] = useState();

  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await services.getPage(pageId);
        setPageContent(response);
        // setHtml(response.html);
        // setCss(response.css);
        // console.log(response.html);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageContent();
  }, []);

  useEffect(() => {
    if (!pageContent) {
      return;
    }

    const css = pageContent.css;
    // const { css } = pageContent;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = css;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [pageContent]);

  if (!pageContent) {
    return <div>Loading...</div>;
  }

  const { html } = pageContent;
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default RenderedPage;
