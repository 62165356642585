import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import useContext from './../context/StateProvider';

function Popup() {
  const [{ user }] = useContext();

  const [show, setshow] = useState(false);

  useEffect(() => {
    const popup = JSON.parse(localStorage.getItem('popup-state'));
    const currentTime = Date.now();
    const currentDate = new Date(currentTime).getDate();
    const currentMonth = new Date(currentTime).getMonth();
    if (popup) {
      const showedOnMonth = new Date(popup.timeStamp).getMonth();

      if (popup.status && currentMonth === showedOnMonth) {
        return;
      }
    }

    axios({
      url: '/api/system',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          if (resData.data.popupDate == currentDate && user) {
            setshow(true);
            localStorage.setItem(
              'popup-state',
              JSON.stringify({
                status: true,
                timeStamp: Date.now(),
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = () => {
    setshow(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={show}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className="h-[90vh]">
        <iframe
          src="https://go.savagesales.com/savage-sales"
          frameborder="0"
          className="w-full h-full"
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
