import React from "react";

import Modal from "@mui/material/Modal";
import ColorPicker from "react-best-gradient-color-picker";

const PopUpColorModal = ({
  open2,
  open3,
  handleClose,
  color,
  handleChange,
}) => {
  return (
    <Modal
      open={open2 || open3}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <ColorPicker value={color} onChange={handleChange} />
      </div>
    </Modal>
  );
};

export default PopUpColorModal;
