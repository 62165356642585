import React, { useCallback, useEffect, useState } from 'react';

import axios from 'axios';

import { Link, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import useContext from './../../context/StateProvider';
import services from './../../services/pageBulder.service';
import adminServices from './../../services/adminBulder.service';
import pluginService from './../../services/plugins.service';
import PageList from './PageList';
import ShowTemplates from './ShowTemplates';
import TokenSelectionForm from './TokenSelectionForm';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PageModal() {
  const params = useParams();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [userPages, setUserPages] = useState([]);

  const [isLoadingUserPages, setLoadingUserPages] = useState(false);
  const [isLoadingTemplates, setLoadingTemplates] = useState(false);
  const [hasExistingPage, setHasExistingPage] = useState(false);

  const [calculators, setCalculators] = useState([]);
  const [calculatorToken, setCalculatorToken] = useState('');

  const [mortgageRates, setMortgageRates] = useState([]);

  const [viewProperties, setViewProperties] = useState([]);
  const [checkHomeValues, setCheckHomeValues] = useState([]);
  const [flexiFrame, setFlexiFrame] = useState([]);

  const [mortgageRatesToken, setMortgageRatesToken] = useState('');
  const [viewPropertiesToken, setViewPropertiesToken] = useState('');
  const [checkHomeValuesToken, setCheckHomeValuesToken] = useState('');

  const [flexiFrameToken, setFlexiFrameToken] = useState('');

  const [values, setValues] = useState({
    name: '',
    error: false,
    success: false,
    removed: false,
    reload: false,
  });

  const { name, error, success, removed, reload } = values;

  const [{ user }, dispatch] = useContext();

  // const selectedTokens = {
  //   calcToken: calculatorToken,
  //   rateToken: mortgageRatesToken,
  //   viewPropertyToken: viewPropertiesToken,
  //   checkHomeToken: checkHomeValuesToken,
  //   flexiFrameToken: flexiFrameToken,
  // };

  const loadPageList = useCallback(async () => {
    setLoadingUserPages(true);
    try {
      const loadItem = await services.pageList(user._id);
      setUserPages(loadItem);
      console.log('pageModal usrid', user, user._id);

      localStorage.setItem('calculatorToken', loadItem[0]?.calculatorToken);
      localStorage.setItem(
        'mortgageRatesToken',
        loadItem[0]?.mortgageRatesToken
      );
      localStorage.setItem(
        'viewPropertiesToken',
        loadItem[0]?.viewPropertiesToken
      );
      localStorage.setItem(
        'checkHomeValuesToken',
        loadItem[0]?.checkHomeValuesToken
      );
      localStorage.setItem('flexiFrameToken', loadItem[0]?.flexiFrameToken);

      setHasExistingPage(loadItem.length > 0);
      console.log(loadItem);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUserPages(false);
    }
  }, [user._id]);

  const loadTemplates = useCallback(async () => {
    setLoadingTemplates(true);
    try {
      const response = await adminServices.templateList();
      setTemplates(response);
    } catch (error) {
      console.error('Error loading templates:', error);
    } finally {
      setLoadingTemplates(false);
    }
  }, []);

  const loadCalc = useCallback(async () => {
    try {
      const response = await pluginService.fetchCalculators(
        params.profileId,
        user.token
      );
      if (response.success) {
        const resData = response.data;
        if (resData) {
          setCalculatorToken(resData[0]?.token);
          localStorage.setItem('calculatorToken', resData[0]?.token);
        }
        console.log('calc plugin dd: ', resData);
        setCalculators(resData);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  }, [params.profileId, user.token]);

  const fetchMortgageRates = useCallback(async () => {
    try {
      const response = await pluginService.fetchMortgageRates(
        params.profileId,
        user.token
      );
      if (response.success) {
        const resData = response.data;
        if (resData) {
          setMortgageRatesToken(resData[0]?.token);
          localStorage.setItem('mortgageRatesToken', resData[0]?.token);
        }
        // console.log('calc plugin dd: ', resData);
        setMortgageRates(resData);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  }, [params.profileId, user.token]);

  const fetchViewProperties = useCallback(async () => {
    try {
      const response = await pluginService.fetchViewProperties(
        params.profileId,
        user.token
      );
      if (response.success) {
        const resData = response.data;
        if (resData) {
          setViewPropertiesToken(resData[0]?.token);
          localStorage.setItem('viewPropertiesToken', resData[0]?.token);
        }
        // console.log('calc plugin dd: ', resData);
        setViewProperties(resData);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  }, [params.profileId, user.token]);

  const fetchCheckHomeValues = useCallback(async () => {
    try {
      const response = await pluginService.fetchCheckHomeValues(
        params.profileId,
        user.token
      );
      if (response.success) {
        const resData = response.data;
        if (resData[0].token) {
          setCheckHomeValuesToken(resData[0]?.token);
          localStorage.setItem('checkHomeValuesToken', resData[0]?.token);
        }
        // console.log('calc plugin dd: ', resData);
        setCheckHomeValues(resData);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  }, [params.profileId, user.token]);

  const fetchFlexiFrame = useCallback(async () => {
    try {
      const response = await pluginService.fetchFlexiFrame(
        params.profileId,
        user.token
      );
      if (response.success) {
        const resData = response.data;
        if (resData) {
          setFlexiFrameToken(resData[0]?.token);
          localStorage.setItem('flexiFrameToken', resData[0]?.token);
        }
        console.log('flexiframe plugin dd: ', resData);
        setFlexiFrame(resData);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  }, [params.profileId, user.token]);

  const handleAddButtonClick = (templateId) => {
    if (hasExistingPage) {
      toast.error(
        'You already have an existing page. If you want to create a new one, please delete the existing page first.',
        {
          position: 'top-right',
          autoClose: 3000, // 3 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      setSelectedTemplate(templateId);
      handleOpen();
    }
  };

  useEffect(() => {
    loadPageList();
    loadTemplates();

    loadCalc();
    fetchFlexiFrame();
    fetchMortgageRates();
    fetchViewProperties();
    fetchCheckHomeValues();
  }, [reload]);

  const handleChange = (e) => {
    setValues({
      ...values,
      name: e.target.value,
      error: false,
      success: false,
      removed: '',
    });
  };

  const clickSubmit = (e) => {
    e.preventDefault();
    services.createPage(user._id, name, selectedTemplate).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, success: false });
        handleClose();
      } else {
        setValues({
          ...values,
          error: false,
          success: true,
          name: '',
          removed: '',
          reload: !reload,
        });
        handleClose();
        loadPageList();
      }
    });
  };

  const showPage = () => {
    return userPages.map((p) => {
      return (
        <PageList
          personalPage={p.name}
          userId={user._id}
          pageNum={p._id}
          reloadPageList={loadPageList}
        />
      );
    });
  };

  const showTemplateList = () => {
    return templates.map((template) => {
      return (
        <ShowTemplates
          templateView={template.name}
          templateId={template._id}
          handleOpenModal={() => handleAddButtonClick(template._id)}
        />
      );
    });
  };

  const isPage = () => {
    return userPages?.length > -1;
  };
  console.log(userPages);

  if (isLoadingTemplates) {
    return <Loader customClass="md:ml-[300px]" />;
  }

  return (
    <div className="mx-auto max-w-4xl mt-10">
      <div>
        <div className="p-4">
          <h2 className="text-2xl font-bold pb-3">Starter Templates</h2>
          <div className="grid grid-cols-3 gap-4 pt-4">
            {showTemplateList()}
          </div>
        </div>

        {/* <Button onClick={handleOpen}>Create your page</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={clickSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  value={name}
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Save Page
                </button>
              </div>
            </form>
          </Box>
        </Modal>

        <hr className="my-4 border-t border-gray-300" />

        <div className="p-4">
          <h2 className="text-2xl font-bold p-3">Your Page:</h2>
          <TokenSelectionForm
            calculators={calculators}
            mortgageRates={mortgageRates}
            viewProperties={viewProperties}
            checkHomeValues={checkHomeValues}
            flexiFrame={flexiFrame}
            user={user}
            data={userPages[0]}
          />

          <div className="grid grid-cols-3 gap-4 pt-4">
            {isPage() ? showPage() : <p>No page publish yet</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
