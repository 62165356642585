const mortgageFlexiFrame = function () {
  const flexiFrameToken = localStorage.getItem('flexiFrameToken');

  const initFlexiFrame = function (el) {
    var d = document.createElement('div');
    d.classList.add('flexiframe');
    d.setAttribute('id', 'flexiframe');
    d.setAttribute(
      'data-sitekey',
      `${flexiFrameToken}`
      // 'VKy5bJtoaLnVMcHym35e3hYyYnRT09BbtdfV44qfORs'
    );
    el.appendChild(d);
    var qs = document.createElement('script');
    qs.src = `https://mortgage.shadman-software-development.website/static/js/flexiframe.js`;
    qs.defer = true;

    document.head.appendChild(qs);

    var l1 = document.createElement('link');
    l1.setAttribute('rel', 'stylesheet');
    l1.setAttribute('type', 'text/css');
    l1.setAttribute(
      'href',
      `https://mortgage.shadman-software-development.website/static/css/flexiframe.css`
    );
    document.head.appendChild(l1);
  };

  const el = this;
  if (typeof document.currentScript == 'undefined') {
    const scriptTag = document.createElement('script');
    scriptTag.onload = function () {
      initFlexiFrame(el);
    };
    scriptTag.src = `https://mortgage.shadman-software-development.website/static/js/flexiframe.js`;
    document.body.appendChild(scriptTag);
  } else {
    initFlexiFrame(el);
  }
};

export default mortgageFlexiFrame;
