import mortgageCalculator from './calcComponent';

export default function calculatorPlugin({ editor }) {
  const calculatorToken = localStorage.getItem('calculatorToken');

  if (calculatorToken) {
    editor.Components.addType('mortgage-widget', {
      model: {
        defaults: {
          script: mortgageCalculator,
        },
      },
    });

    //   editor.on('component:add', (model) => {
    //     if (model.attributes.type === 'mortgage-widget') {
    //       model.set('attributes', { token: token });
    //     }
    //   });

    editor.Blocks.add('mortgage-widget-block', {
      label: 'Mortgage Calculator',
      attributes: { class: 'fa fa-calculator' },
      content: { type: 'mortgage-widget' },
    });
  } else {
    editor.Components.addType('mortgage-widget', {
      model: {
        defaults: {},
        draggable: false,
      },
    });
    editor.Blocks.add('mortgage-widget-block', {
      label: 'Mortgage Calculator',
      attributes: { class: 'fa fa-lock' },
      content: { type: 'mortgage-widget' },
    });
  }
}

// import mortgageCalculator from './calcComponent';

// export default function calculatorPlugin({ editor }) {
//   const calculatorToken = localStorage.getItem('calculatorToken');

//   // Check if the calculatorToken is present in localStorage
//   const isCalculatorTokenAvailable = !!calculatorToken;

//   // Define the block type
//   const blockType = isCalculatorTokenAvailable
//     ? 'mortgage-widget'
//     : 'locked-mortgage-widget';

//   // Add the block type to Components
//   editor.Components.addType(blockType, {
//     model: {
//       defaults: {
//         script: mortgageCalculator,
//       },
//     },
//   });

//   // Add the block to Blocks
//   editor.Blocks.add('mortgage-widget-block', {
//     label: isCalculatorTokenAvailable
//       ? 'Mortgage Calculator'
//       : 'Locked Mortgage Calculator',
//     attributes: {
//       class: isCalculatorTokenAvailable ? 'fa fa-calculator' : 'fa fa-lock',
//     },
//     content: { type: blockType },
//     // Make it drag and dropable only if calculatorToken is available
//     draggable: isCalculatorTokenAvailable,
//   });
// }
