import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import services from '../../services/pageBulder.service';

function PageList({ personalPage, usereId, pageNum, reloadPageList }) {
  const handleDeletePage = async (pageId) => {
    await services.deletePage(pageId);
    toast.success('Page deleted successfully!', {
      position: 'top-right',
      autoClose: 3000, // 3 seconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    reloadPageList();
  };

  const handleCopySuccess = () => {
    toast.success('Your site URL copied!', {
      position: 'top-right',
      autoClose: 3000, // 3 seconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <li className="flex justify-between items-center border-b border-gray-200 py-2">
        <p className="text-lg font-semibold">{personalPage}</p>
        <div className="space-x-2">
          <button className="btn btn-sm btn-outline-primary">
            <Link to={`/page-builder/${pageNum}`}>
              <EditIcon className="w-5 h-5" />
            </Link>
          </button>
          <button className="btn btn-sm btn-outline-primary">
            <Link to={`/my-page/${pageNum}`}>
              <VisibilityIcon className="w-5 h-5" />
            </Link>
          </button>
          <CopyToClipboard
            text={`${window.location.origin}/my-page/${pageNum}`}
            onCopy={handleCopySuccess}
          >
            <button className="btn btn-sm btn-outline-primary">
              <FileCopyIcon className="w-5 h-5" />
            </button>
          </CopyToClipboard>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDeletePage(pageNum)}
          >
            <DeleteIcon className="w-5 h-5" />
          </button>
        </div>
      </li>
    </div>
  );
}

export default PageList;
