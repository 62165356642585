import React from 'react';

const AddPreviews = ({ data }) => {
  return (
    <div>
      {data ? (
        <div className="tems-center">
          {data.type === 'image' ? (
            <a
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block mb-4"
            >
              <img
                src={data.filename}
                alt="Ad"
                className="w-100 h-50 object-cover border border-gray-300 rounded-lg shadow-md cursor-pointer"
                onClick={() => window.open(data.link, '_blank')}
              />
            </a>
          ) : data.type === 'video' ? (
            <div className="relative">
              <video
                controls
                className="w-100 h-50 object-cover border border-gray-300 rounded-lg shadow-md cursor-pointer"
              >
                <source src={data.filename} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : null}
        </div>
      ) : (
        <h1>Loading ad...</h1>
      )}
    </div>
  );
};

export default AddPreviews;
