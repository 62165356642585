import axios from "./axios";

const services = {
  createFunnel: (profileId, token, pluginName) => {
    return axios({
      url: `/api/facepop/new`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        name: pluginName,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  getAllFunnels: (profileId, token) => {
    return axios({
      url: `/api/facepop/${profileId}/all`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  getFunnelById: (pluginId, token) => {
    return axios({
      url: `/api/facepop/id/${pluginId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  standAloneData: (pluginToken) => {
    return axios({
      url: `/api/facepop/standalone/${pluginToken}`,
      method: "get",
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  updateFacepop: (
    pluginId,
    videoURL,
    thumbnail,
    thumbOption,
    thumbPlay,
    videoTitle,
    videDesc,
    authorName,
    occupation,
    authorImg,
    authorPosition,
    whenShowCTA,
    ctaShow,
    facePopStyle,
    toggleStyle,
    playerStyle,
    generalSettings,
    playerColor,
    toggleColor,
    loadingScreen,
    otherColor,
    behavior,
    defaultSound,
    playerPosition,
    autoplay,
    state,
    token,
  ) => {
    return axios({
      url: `/api/facepop/options/${pluginId}/update`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        videoURL,
        thumbnail,
        thumbOption,
        thumbPlay,
        videoTitle,
        videDesc,
        authorName,
        occupation,
        authorImg,
        authorPosition,
        whenShowCTA,
        ctaShow,
        facePopStyle,
        toggleStyle,
        playerStyle,
        generalSettings,
        playerColor,
        toggleColor,
        loadingScreen,
        otherColor,
        behavior,
        defaultSound,
        playerPosition,
        autoplay,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        console.log(resData);

        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  uploadMedia: async (pluginId, formData) => {
    try {
      const response = await axios.post(
        `/api/facepop/${pluginId}/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      console.log("video path: ", response.data);

      return response.data;
    } catch (err) {
      console.error("Error uploading image:", err.message, err);
      throw err;
    }
  },

  uploadThumbnail: async (pluginId, formData) => {
    try {
      const response = await axios.post(
        `/api/facepop/${pluginId}/thumbnail/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      console.log("thumbnail path: ", response.data);

      return response.data;
    } catch (err) {
      console.error("Error uploading image:", err.message, err);
      throw err;
    }
  },

  uploadImage: async (pluginId, formData) => {
    try {
      const response = await axios.post(
        `/api/facepop/${pluginId}/image/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      console.log("image path: ", response.data);

      return response.data;
    } catch (err) {
      console.error("Error uploading image:", err.message, err);
      throw err;
    }
  },

  deleteFunnel: (funnelId, token) => {
    return axios({
      url: `/api/funnels/${funnelId}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          return {
            status: resData.status,
            success: true,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      })
      .catch((error) => {
        console.error("Error deleting the funnel:", error);
        return {
          success: false,
          status: "Error deleting the funnel",
        };
      });
  },

  getUserIPAddress: async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ipAddress = response.data.ip;
      return ipAddress;
    } catch (error) {
      console.error("Error fetching user IP address:", error);
      return null;
    }
  },

  getUserGeoLocation: async (ipAddress) => {
    try {
      const response = await axios.get("https://api.ipgeolocation.io/ipgeo", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          apiKey: "deb9a79bf3024e94865209fd35da0521",
          fields: "city,country_name",
          output: "json",
          ip: ipAddress,
        },
      });

      const { city, country_name } = response.data;
      return { city, country_name };
    } catch (error) {
      console.error("Error fetching user IP geolocation:", error);
      return null;
    }
  },

  // CTA
  allConnectCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/createConnect`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getConnectCta: async (ctaId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${ctaId}/createConnect`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getRedirectCta: async (ctaId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${ctaId}/redirectCta`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getRedirectFullCta: async (ctaId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${ctaId}/redirectFull`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getVideoRecCta: async (ctaId, token) => {
    try {
      const response = await axios.get(`/api/facepop/cta/${ctaId}/videoRec`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getCalenderBookCta: async (ctaId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${ctaId}/calenderBook`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getChatAiCta: async (ctaId, token) => {
    try {
      const response = await axios.get(`/api/facepop/cta/${ctaId}/chatAi`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getInputTextCta: async (ctaId, token) => {
    try {
      const response = await axios.get(`/api/facepop/cta/${ctaId}/inputText`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getRunCodeCta: async (ctaId, token) => {
    try {
      const response = await axios.get(`/api/facepop/cta/${ctaId}/runCode`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  getCloseCtaCta: async (ctaId, token) => {
    try {
      const response = await axios.get(`/api/facepop/cta/${ctaId}/closeCta`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching cta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allRedirectCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/redirectCta`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allRedirectFullCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/redirectFull`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allVideoRecCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/videoRec`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allCalenderBookCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/calenderBook`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all calenderBook:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allchatAiCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/chatAi`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all chatAi:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allInputTextCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/inputText`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all inputText:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allRunCodeCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/runCode`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all runCode:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  allCloseCTA: async (pluginId, token) => {
    try {
      const response = await axios.get(
        `/api/facepop/cta/${pluginId}/all/closeCta`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error fetching all closeCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  connectCtaBtn: async (
    pluginId,
    token,
    yourFacepop,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/createConnect`,
        { yourFacepop, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateConnectCta: async (
    id,
    token,
    yourFacepop,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/createConnect/update`,
        { yourFacepop, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateRedirectCta: async (
    id,
    token,
    url,
    openUrlIn,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/redirectCta/update`,
        { url, openUrlIn, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating RedirectCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateRedirectFullCta: async (
    id,
    token,
    url,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/redirectFullCta/update`,
        { url, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating RedirectCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateVideoRecordCTA: async (id, token, general, style, icon, colors) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/videoRec/update`,
        { general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateCalenderBookCTA: async (
    id,
    token,
    url,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/calenderBook/update`,
        { url, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating calenderBook:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateChatAiCTA: async (
    id,
    token,
    chatBotId,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/chatAi/update`,
        { chatBotId, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating chatAi:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateInputTextCTA: async (id, token, elementId, settings, style, colors) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/inputText/update`,
        { elementId, settings, style, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating chatAi:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateRunCodeCta: async (id, token, jsCode, general, style, icon, colors) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/runCode/update`,
        { jsCode, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating runCode:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  updateCloseCTA: async (id, token, general, style, icon, colors) => {
    try {
      const response = await axios.put(
        `/api/facepop/cta/${id}/closeCta/update`,
        { general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error updating closeCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  calenderBookCTA: async (
    pluginId,
    token,
    url,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/calenderBook`,
        { url, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating calenderBookCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  chatAiCTA: async (
    pluginId,
    token,
    chatBotId,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/chatAi`,
        { chatBotId, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating chatAi:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  inputTextCTA: async (pluginId, token, elementId, settings, style, colors) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/inputText`,
        { elementId, settings, style, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating inputText:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  runCodeCTA: async (pluginId, token, jsCode, general, style, icon, colors) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/runCode`,
        { jsCode, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating inputText:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  closeCTA: async (pluginId, token, general, style, icon, colors) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/closeCta`,
        { general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating closeCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delConnectCTA: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/createConnect`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting ConnectCTA:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delRedirectCTA: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/redirectCta`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting redirectCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delRedirectFullCTA: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/redirectFull`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting redirectFullCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delVideoRecCTA: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/videoRec`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delCalenderBook: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/calenderBook`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delChatAiCTA: async (id, token) => {
    try {
      const response = await axios.delete(`/api/facepop/cta/${id}/del/chatAi`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delInputTextCta: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/inputText`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delRunCode: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/runCode`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  delCloseCTA: async (id, token) => {
    try {
      const response = await axios.delete(
        `/api/facepop/cta/${id}/del/closeCta`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
      };
    } catch (error) {
      console.error(
        "Error deleting closeCta:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  redirectToURL: async (
    pluginId,
    token,
    url,
    openUrlIn,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/redirectCta`,
        { url, openUrlIn, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating redirect:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  redirectToFullURL: async (
    pluginId,
    token,
    url,
    general,
    style,
    icon,
    colors,
  ) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/redirectFull`,
        { url, general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating redirect:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },

  videoRecCta: async (pluginId, token, general, style, icon, colors) => {
    try {
      const response = await axios.post(
        `/api/facepop/cta/${pluginId}/videoRec`,
        { general, style, icon, colors },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return {
        status: response.data.status,
        success: response.data.success,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error creating videoRec:",
        error.response ? error.response.data : error.message,
      );
      return {
        success: false,
        error: error.response ? error.response.data : "Internal Server Error",
      };
    }
  },
};

export default services;
