import React, { useState } from 'react';
import services from '../../services/pageBulder.service';
import CustomSelect from './CustomSelect';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';

function TokenSelectionForm({
  calculators,
  mortgageRates,
  viewProperties,
  checkHomeValues,
  flexiFrame,
  user,
  data,
}) {
  const [selectedTokens, setSelectedTokens] = useState({
    calculatorToken: '',
    mortgageRatesToken: '',
    viewPropertiesToken: '',
    checkHomeValuesToken: '',
    flexiFrameToken: '',
  });

  const [selectedTokenLabels, setSelectedTokenLabels] = useState({
    calculatorToken: '',
    mortgageRatesToken: '',
    viewPropertiesToken: '',
    checkHomeValuesToken: '',
    flexiFrameToken: '',
  });

  const [loadToken, setLoadToken] = useState({});
  const [loading, setLoading] = useState('');

  const handleTokenChange = (tokenName, selectedOption) => {
    setSelectedTokens({
      ...selectedTokens,
      [tokenName]: selectedOption ? selectedOption.value : '',
    });

    setSelectedTokenLabels({
      ...selectedTokenLabels,
      [tokenName]: selectedOption, // Store the selected option label
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('selected token update: ', selectedTokens);
    setLoading(true);

    const response = await services.saveTokensToServer(
      data._id,
      selectedTokens
    );

    console.log(response);

    if (response.success) {
      toast.success(response.status);
      setLoadToken(response.data);
      setLoading(false);

      localStorage.setItem('calculatorToken', response.data?.calculatorToken);
      localStorage.setItem(
        'mortgageRatesToken',
        response.data?.mortgageRatesToken
      );
      localStorage.setItem(
        'viewPropertiesToken',
        response.data?.viewPropertiesToken
      );
      localStorage.setItem(
        'checkHomeValuesToken',
        response.data?.checkHomeValuesToken
      );
      localStorage.setItem('flexiFrameToken', response.data?.flexiFrameToken);
    } else {
      // Handle error, e.g., display an error message
      toast.status(
        'There is a problem to select token, please try again later.'
      );
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2 p-3">Choose your funnels</h3>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        {calculators[0]?.token && (
          <CustomSelect
            options={calculators.map((dt) => ({
              value: dt.token,
              label: `${dt.name}`,
            }))}
            value={selectedTokenLabels.calculatorToken}
            onChange={(selectedOption) =>
              handleTokenChange('calculatorToken', selectedOption)
            }
          />
        )}

        {user.accountType === 'Pro' && mortgageRates[0]?.token && (
          <CustomSelect
            options={mortgageRates.map((dt) => ({
              value: dt.token,
              label: `${dt.name}`,
            }))}
            value={selectedTokenLabels.mortgageRatesToken}
            onChange={(selectedOption) =>
              handleTokenChange('mortgageRatesToken', selectedOption)
            }
          />
        )}

        {user.accountType === 'Pro' && viewProperties[0]?.token && (
          <CustomSelect
            options={viewProperties.map((dt) => ({
              value: dt.token,
              label: `${dt.name}`,
            }))}
            value={selectedTokenLabels.viewPropertiesToken}
            onChange={(selectedOption) =>
              handleTokenChange('viewPropertiesToken', selectedOption)
            }
          />
        )}

        {user.accountType === 'Pro' && checkHomeValues[0]?.token && (
          <CustomSelect
            options={checkHomeValues.map((dt) => ({
              value: dt.token,
              label: `${dt.name}`,
            }))}
            value={selectedTokenLabels.checkHomeValuesToken}
            onChange={(selectedOption) =>
              handleTokenChange('checkHomeValuesToken', selectedOption)
            }
          />
        )}

        {user.accountType === 'Pro' && flexiFrame[0]?.token && (
          <CustomSelect
            options={flexiFrame.map((dt) => ({
              value: dt.token,
              label: `${dt.name}`,
            }))}
            value={selectedTokenLabels.flexiFrameToken}
            onChange={(selectedOption) =>
              handleTokenChange('flexiFrameToken', selectedOption)
            }
          />
        )}

        <button
          type="submit"
          className="p-2 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
        >
          Save Tokens
        </button>
      </form>
      {loading && <Loader customClass="md:ml-[300px]" />}
    </div>
  );
}

export default TokenSelectionForm;
