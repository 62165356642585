import mortgageFlexiFrame from './flexiFrameComponent';

export default function mortgageFlexiFramePlugin({ editor }) {
  editor.Components.addType('mortgage-flexiframe', {
    model: {
      defaults: {
        script: mortgageFlexiFrame,
      },
    },
  });

  editor.Blocks.add('mortgage-widget-flexiframe', {
    label: 'FlexiFrame',
    attributes: { class: 'fa fa-film' },
    content: { type: 'mortgage-flexiframe' },
  });
}
