import React from 'react';
import { BsSquareFill } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { RiLinkedinFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
function Footer() {
  return (
    <div className="footer bg-[#AC1E23] text-white md:grid md:grid-cols-3 py-24 px-5 md:px-14 md:space-x-4">
      <div className="sales">
        <h3 className="text-xl font-bold my-7">About Savage Sales</h3>
        <p className="text-sm font-normal my-7 w-4/5 ">
          We're passionate about offering the best marketing automation app to
          our customers
        </p>
      </div>

      <div className="links">
        <h3 className="text-xl font-bold my-7">Important Links</h3>

        <ul className="space-y-5">
          <li>
            <div className="flex">
              {' '}
              <BsSquareFill className="mt-[7px] mr-1  text-white font-bold text-xs" />
              <p className="font-normal text-base">User Control Management</p>{' '}
            </div>
          </li>

          <li>
            <div className="flex ">
              {' '}
              <BsSquareFill className="mt-[7px] mr-1 text-white font-bold text-xs" />
              <p className="font-normal text-base">User Control Management</p>{' '}
            </div>
          </li>
        </ul>
      </div>

      <div className="social ">
        <h3 className="text-xl font-bold my-7">Important Links</h3>
        <div className="flex space-x-4">
          <div className="icon flex">
            <a href="#">
              <FaFacebookF className="bg-white text-[#AC1E23] w-10 h-10 md:w-12 md:h-12 lg:w-[60px] lg:h-[60px] p-3  text-base rounded-full hover:bg-[#AC1E23] hover:text-white transition duration-150 ease-in-out cursor-pointer" />
            </a>
          </div>

          <div className="icon flex">
            <a href="#">
              <AiOutlineTwitter className="bg-white text-[#AC1E23] w-10 h-10 md:w-12 md:h-12 lg:w-[60px] lg:h-[60px] p-3  text-base rounded-full hover:bg-[#AC1E23] hover:text-white transition duration-150 ease-in-out cursor-pointer" />
            </a>
          </div>

          <div className="icon flex">
            <a href="#">
              <AiOutlineInstagram className="bg-white text-[#AC1E23] w-10 h-10 md:w-12 md:h-12 lg:w-[60px] lg:h-[60px] p-3  text-base rounded-full hover:bg-[#AC1E23] hover:text-white transition duration-150 ease-in-out cursor-pointer" />
            </a>
          </div>

          <div className="icon flex">
            <a href="#">
              <RiLinkedinFill className="bg-white text-[#AC1E23] w-10 h-10 md:w-12 md:h-12 lg:w-[60px] lg:h-[60px] p-3  text-base rounded-full hover:bg-[#AC1E23] hover:text-white transition duration-150 ease-in-out cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
