import React from "react";

function StepSeven({ value, onChange, handleNext }) {
  return (
    <div className="py-6 flex flex-col items-center">
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
          }}
          onClick={() => {
            onChange({
              target: {
                value: "I own this property",
                name: "preferenceButton",
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === "I own this property"
                ? "text-[var(--color-red)] bg-white"
                : "text-white bg-[var(--color-red)]"
            }`}
          >
            I own this property
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
          }}
          onClick={() => {
            onChange({
              target: {
                value: "I am interested in this property",
                name: "preferenceButton",
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === "I am interested in this property"
                ? "text-[var(--color-red)] bg-white"
                : "text-white bg-[var(--color-red)]"
            }`}
          >
            I am interested in this property
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
          }}
          onClick={() => {
            onChange({
              target: {
                value: "I am using this property as a comparable to mine",
                name: "preferenceButton",
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === "I am using this property as a comparable to mine"
                ? "text-[var(--color-red)] bg-white"
                : "text-white bg-[var(--color-red)]"
            }`}
          >
            I am using this property as a comparable to mine
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <div className="">
          <div className="mt-8 mb-4 relative mx-auto max-w-[200px] md:max-w-[205px]">
            <input
              id="preferenceButton"
              className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
              type="number"
              name="preferenceButton"
              required
              //   value={value}
              onChange={onChange}
            />
            <label
              htmlFor="preferenceButton"
              className="mtge-rates_input-label text-xs md:text-sm"
            >
              Other
            </label>
          </div>

          <button
            className="mtge-rates_next-btn px-12 py-2 text-lg md:text-xl"
            onClick={handleNext}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepSeven;
