import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import adminServices from './../../../services/adminBulder.service';
// import useContext from '../../context/StateProvider';

function RenderedTemplate() {
  const { pageId } = useParams();

  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await adminServices.getPage(pageId);
        setPageContent(response);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageContent();
  }, [pageId]);

  useEffect(() => {
    if (!pageContent) {
      return;
    }

    const { css } = pageContent;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = css;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [pageContent]);

  if (!pageContent) {
    return <div>Loading...</div>;
  }

  const { html } = pageContent;

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default RenderedTemplate;
