// import config from '../../../../utils/config';

const mortgageViewProperties = function () {
  const viewPropertyToken = localStorage.getItem('viewPropertiesToken');

  const initViewProperty = function (el) {
    var d = document.createElement('div');
    d.classList.add('viewproperties');
    d.setAttribute('id', 'viewproperties');
    d.setAttribute(
      'data-sitekey',
      `${viewPropertyToken}`
      // 'fUOHBRIDmc4F_ErDrfnwGdja6549Z9Bry1VhJgTjptk'
    );
    el.appendChild(d);

    var qs = document.createElement('script');
    qs.src = `https://mortgage.shadman-software-development.website/static/js/viewproperties.js`;
    qs.defer = true;

    document.head.appendChild(qs);

    var l1 = document.createElement('link');
    l1.setAttribute('rel', 'stylesheet');
    l1.setAttribute('type', 'text/css');
    l1.setAttribute(
      'href',
      `https://mortgage.shadman-software-development.website/static/css/viewproperties.css`
    );
    document.head.appendChild(l1);
  };

  const el = this;
  if (typeof document.currentScript == 'undefined') {
    const scriptTag = document.createElement('script');
    scriptTag.onload = function () {
      initViewProperty(el);
    };
    scriptTag.src = `https://mortgage.shadman-software-development.website/static/js/viewproperties.js`;
    document.body.appendChild(scriptTag);
  } else {
    initViewProperty(el);
  }
};

export default mortgageViewProperties;
