import React from 'react';

import { Slider, Stack } from '@mui/material';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function StepSix({ value, onChange, handleNext }) {
  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">Value Range Estimate</h1>
      <div className="w-full mt-3 px-10 flex flex-col items-center space-y-2">
        <div className="w-full">
          <div className="my-4">
            <div className="flex items-center justify-center space-x-4">
              <div className="relative max-w-[100px] md:max-w-[125px]">
                <input
                  id="valueRangEstimate"
                  className="mtge-rates_input-field border-none text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="text"
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1'))
                  }
                  name="valueRangEstimate"
                  required
                  value={value[0]}
                  onChange={(e) => {
                    const { name } = e.target;

                    onChange({
                      target: {
                        name,
                        value: [e.target.value, value[1]],
                      },
                    });
                  }}
                />
                <label
                  htmlFor="valueRangEstimate"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Min
                </label>
              </div>
              <div className="relative max-w-[100px] md:max-w-[125px]">
                <input
                  id="valueRangEstimate"
                  className="mtge-rates_input-field border-none text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="text"
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1'))
                  }
                  name="valueRangEstimate"
                  required
                  value={value[1]}
                  onChange={(e) => {
                    const { name } = e.target;

                    onChange({
                      target: {
                        name,
                        value: [value[0], e.target.value],
                      },
                    });
                  }}
                />
                <label
                  htmlFor="valueRangEstimate"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Max
                </label>
              </div>
            </div>
          </div>
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1, px: 1 }}
            alignItems="center"
          >
            <Slider
              aria-label="Volume"
              value={value}
              min={80000}
              step={10000}
              max={20000000}
              name="valueRangEstimate"
              onChange={onChange}
              valueLabelDisplay="auto"
              getAriaValueText={numberWithCommas}
              sx={{
                color: 'var(--color-red)',
                '& .MuiSlider-track': {
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  width: 24,
                  height: 24,
                  backgroundColor: '#fff',
                  '&:before': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                  },
                  '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </Stack>

          <div className="flex items-center justify-between">
            <p className="text-xs font-semibold text-[var(--color-red)]">80k</p>
            <p className="text-xs font-semibold text-[var(--color-red)]">20M</p>
          </div>
        </div>

        <button
          className="mtge-rates_next-btn px-12 py-2 text-lg md:text-xl"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepSix;
