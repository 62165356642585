import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MyConstextApi } from "../../../../context/ContextApi";
import useContext from "../../../../context/StateProvider";

import facepopService from "../../../../services/facepop.service";
import { IconArrowRight } from "../icons/RightArrow";
import PopUpColorModal from "./PopUpColorModal";

const RunCodePopUp = ({ open, handleActionpopUp, setPopup }) => {
  const [{ user }] = useContext();
  const params = useParams();

  const { fetchRunCodeCta } = MyConstextApi();

  const [state, setState] = useState("url");
  const [buttonSetting, setButtonSetting] = useState("general");

  const [open2, setOpen2] = useState(false);
  const [bgColor2, setBgColor2] = useState("#FF0000");

  const [open3, setOpen3] = useState(false);
  const [bgColor3, setBgColor3] = useState("#FF0000");
  const [iconIndex, setIconIndex] = useState();

  const [code, setCode] = useState("");
  const [general, setGeneral] = useState({
    btnText: "",
    btnHeight: "10px",
  });
  const [style, setStyle] = useState({
    btnStyle: "Filled",
    btnWidth: "Stretch",
    cornerStyle: "Round",
  });

  const [icon, setIcon] = useState("");
  const [txtColor, setTxtClr] = useState("#000000");
  const [bgColor, setBgClr] = useState("#FFFFFF");

  const handleClose = () => {
    setOpen2(false);
    setOpen3(false);
  };

  const handleGeneralChange = (field, value) => {
    setGeneral((prevGeneral) => ({
      ...prevGeneral,
      [field]: value,
    }));
  };

  const handleStyleChange = (field, value) => {
    setStyle((prevStyle) => ({
      ...prevStyle,
      [field]: value,
    }));
  };

  const handleTxtClrChange = (newColor) => {
    setTxtClr(newColor);
  };

  const handleBgClrChange = (newColor) => {
    setBgClr(newColor);
  };

  const handleSave = async () => {
    let toastId = toast.loading("Processing...");

    const pluginId = params.pluginId;
    console.log("pluginId: ", pluginId);

    const colors = {
      textColor: txtColor,
      bgColor: bgColor,
    };

    try {
      const response = await facepopService.runCodeCTA(
        pluginId,
        user.token,
        code,
        general,
        style,
        icon,
        colors,
      );

      if (response.success) {
        toast.update(toastId, {
          render: "Button Save Successfully",
          type: "success",
          autoClose: 3000,
          isLoading: false,
        });
        fetchRunCodeCta(params.pluginId, user.token);
        console.log(
          "calender booking cta created successfully:",
          response.data,
        );

        setGeneral((prevGeneral) => ({
          ...prevGeneral,
          btnText: "",
        }));
        setTxtClr("#000000");
        setBgClr("#FFFFFF");
        setPopup(false);
      } else {
        toast.update(toastId, {
          render: "An error occurred. Please try again",
          type: "error",
          autoClose: 3000,
          isLoading: false,
        });
      }
    } catch (err) {
      console.error("Error:", err);
      toast.update(toastId, {
        render: "An error occurred. Please try again",
        type: "error",
        autoClose: 3000,
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleActionpopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <div className="min-h-screen flex items-center">
          <div
            style={{ width: "420px" }}
            className="   h-fit border mx-auto  rounded-md  bg-white sm:px-7 px-2 py-5"
          >
            <h1 className="text-color11 font-bold text-2xl mb-4">Run Code</h1>
            <div className="mt-2">
              <ul className="flex sm:gap-6 gap-4 text-sm  font-semibold text-color7">
                <li
                  onClick={() => setState("url")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "url"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Code
                </li>
                <li
                  onClick={() => setState("btnsetting")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "btnsetting"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Button Setting
                </li>
              </ul>
            </div>

            {state === "btnsetting" && (
              <div className="mt-4">
                <ul className="flex sm:gap-8 gap-3  text-xs font-semibold  text-color7">
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "general"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("general")}
                  >
                    General
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "style"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("style")}
                  >
                    Style
                  </li>

                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "icon"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("icon")}
                  >
                    Icon
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "color"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("color")}
                  >
                    Colors
                  </li>
                </ul>
              </div>
            )}

            <div className=" mt-7">
              {state === "url" ? (
                <div className="flex flex-col gap-0">
                  <div className="flex flex-col gap-2">
                    <label className="text-color2 text-sm  font-semibold">
                      Javascript Code
                    </label>
                    <textarea
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                      rows={4}
                      type="text"
                      placeholder="console.log('hello')"
                      className="input-colors rounded-md px-2 border border-color5 py-2 w-full  text-black"
                    />
                  </div>
                </div>
              ) : (
                <>
                  {buttonSetting === "general" ? (
                    <>
                      {" "}
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Text
                          </label>
                          <input
                            type="text"
                            value={general.btnText}
                            onChange={(e) =>
                              handleGeneralChange("btnText", e.target.value)
                            }
                            placeholder="Button Text"
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>{" "}
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Button Height
                          </label>
                          <input
                            type="number"
                            value={general.btnHeight}
                            onChange={(e) =>
                              handleGeneralChange("btnHeight", e.target.value)
                            }
                            placeholder="10px"
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>
                      </div>
                    </>
                  ) : buttonSetting === "style" ? (
                    <div className="flex flex-col gap-0">
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button style
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.btnStyle}
                          onChange={(e) =>
                            handleStyleChange("btnStyle", e.target.value)
                          }
                        >
                          <option value="Filled" key="Filled">
                            Filled
                          </option>
                          <option value="Outlined" key="Outlined">
                            Outlined
                          </option>
                          <option value="Text Only" key="TextOnly">
                            Text Only
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button Width
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.btnWidth}
                          onChange={(e) =>
                            handleStyleChange("btnWidth", e.target.value)
                          }
                        >
                          <option value="Stretch" key="Stretch">
                            Stretch
                          </option>
                          <option value="Auto/fit to text" key="AutoFitToText">
                            Auto/fit to text
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Corner Style
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.cornerStyle}
                          onChange={(e) =>
                            handleStyleChange("cornerStyle", e.target.value)
                          }
                        >
                          <option value="Round" key="Round">
                            Round
                          </option>
                          <option value="Dubbed" key="Dubbed">
                            Dubbed
                          </option>
                          <option value="Sharp" key="Sharp">
                            Sharp
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : buttonSetting === "icon" ? (
                    <div className="flex flex-wrap gap-4">
                      {IconArrowRight.map((item, i) => (
                        <span
                          className={`cursor-pointer ${
                            iconIndex === i
                              ? "bg-color10 px-2 rounded-sm text-white "
                              : ""
                          }`}
                          onClick={() => {
                            return (
                              <>
                                <>{setIconIndex(i)}</>{" "}
                              </>
                            );
                          }}
                          key={i}
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col ">
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Text color
                        </label>
                        <input
                          value={txtColor}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: txtColor }}
                          onClick={() => setOpen2(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Background color
                        </label>
                        <input
                          value={bgColor}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: bgColor }}
                          onClick={() => setOpen3(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <button
                className="text-white bg-color10 font-semibold  px-4 py-1 rounded-md"
                onClick={handleSave}
              >
                Save
              </button>{" "}
              <button
                onClick={() => setPopup(false)}
                className="text-color12 font-semibold  px-2 py-1 rounded-sm"
              >
                Cancel
              </button>
            </div>
            <PopUpColorModal
              open2={open2}
              open3={open3}
              color={open2 ? txtColor : bgColor}
              handleChange={open2 ? handleTxtClrChange : handleBgClrChange}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RunCodePopUp;
