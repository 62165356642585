import React from 'react';
import config from '../../utils/config';

function failed() {
  return (
    <div className="bg-gray-100 h-screen">
      <header className="bg-white py-4 px-12 md:px-20 lg:px-28">
        <img
          className="w-16 object-contain"
          src={`${config.baseUrl}/images/logo.png`}
          alt="Savage Sales"
        />
      </header>
      <div className="mt-32 mb-20 max-w-lg bg-white p-6 md:mx-auto rounded-2xl">
        <div className="w-full px-2 py-4 border-2 border-red-200 bg-[#fff6f6] flex items-center space-x-3 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-14 h-14 text-[#9f3a38]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>
          <div className="flex flex-col space-y-1">
            <span className="text-[#9f3a38] font-bold text-lg">
              Oops! Something went wrong.
            </span>
            <span className="text-[#9f3a38] text-sm">
              While trying to reserve money from your account
            </span>
          </div>
        </div>

        <div className="mt-6 w-full">
          <a
            className="block px-2 py-3 bg-[#ffc952] text-[#444444] font-medium text-center rounded-lg"
            href="/"
          >
            Try again
          </a>
        </div>
      </div>
    </div>
  );
}

export default failed;
