import React, { useState, useEffect } from 'react';
import Leads, { ContactUs } from './Leads';
import Result from './Result';
import SharePDF from './SharePDF';
import Zestimates from './Zestimates';
import { toast } from 'react-toastify';
import useGlobalState from '../../../context/StateProvider';
import ActionTypes from '../../../utils/actionTypes';
import axios from 'axios';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import config from '../../../utils/config';

const calculateMortgage = ({
  home_price,
  down_payment,
  interest_rate,
  term,
  start_time,
  pmi,
  taxes,
  insurance,
  hoa,
  extra_payments,
}) => {
  const calculatedValues = {
    home_price,
    monthly: {
      principal_interest: 0,
      extra_payments: 0,
      pmi: 0,
      taxes: 0,
      insurance: 0,
      hoa: 0,
      total: 0,
    },
    total: {
      down_payment: 0,
      principal: 0,
      ptihoa: 0,
      interest: 0,
      extra_payments: 0,
      value: 0,
    },
    schedule: [],
  };

  // monthly
  let p = home_price - down_payment;
  let r = interest_rate / 100 / 12;
  let n = term * 12;
  let m = p * ((r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1));

  calculatedValues.monthly.principal_interest = m;
  calculatedValues.monthly.extra_payments = extra_payments.monthly;
  calculatedValues.monthly.pmi = (pmi * 2.5) / 30;
  calculatedValues.monthly.taxes = (taxes * 2.5) / 30;
  calculatedValues.monthly.insurance = (insurance * 2.5) / 30;
  calculatedValues.monthly.hoa = hoa;
  calculatedValues.monthly.total =
    m +
    extra_payments.monthly +
    calculatedValues.monthly.pmi +
    calculatedValues.monthly.taxes +
    calculatedValues.monthly.insurance +
    hoa;

  // amortization schedule
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let total_interest = 0;
  let end = false;

  for (let i = 0; i < n && !end; i++) {
    let start_month = start_time.month.split('-');
    let start_year = Number(start_time.year);

    let payment_date, interest, total_monthly, ptihoa, balance, principal;

    if (i === 0) {
      payment_date = start_month[0] + ' ' + start_year;
      interest = p * r;
      total_monthly = calculatedValues.monthly.total;
      ptihoa =
        calculatedValues.monthly.pmi +
        calculatedValues.monthly.taxes +
        calculatedValues.monthly.insurance +
        hoa;
      balance = p - (total_monthly - interest - ptihoa);
      principal = total_monthly - interest - ptihoa;
    } else {
      payment_date =
        months[(Number(start_month[1]) + i - 1) % 12] +
        ' ' +
        (start_year + Math.floor((Number(start_month[1]) + i - 1) / 12));
      interest = calculatedValues.schedule[i - 1].balance * r;
      total_monthly = calculatedValues.monthly.total;
      ptihoa =
        calculatedValues.monthly.pmi +
        calculatedValues.monthly.taxes +
        calculatedValues.monthly.insurance +
        hoa;

      if ((calculatedValues.schedule[i - 1].balance * 100) / home_price < 80) {
        total_monthly -= calculatedValues.monthly.pmi;
        ptihoa =
          calculatedValues.monthly.taxes +
          calculatedValues.monthly.insurance +
          hoa;
      }

      balance =
        calculatedValues.schedule[i - 1].balance -
        (total_monthly - interest - ptihoa);
      principal = total_monthly - interest - ptihoa;

      if (calculatedValues.schedule[i - 1].balance <= principal) {
        total_monthly = calculatedValues.schedule[i - 1].balance + interest;
        balance = 0;
        principal = calculatedValues.schedule[i - 1].balance;
        end = true;
      }
    }

    if (
      extra_payments.yearly.start_month === payment_date.split(' ')[0] &&
      extra_payments.yearly.value > 0
    ) {
      if (
        calculatedValues.schedule[i - 1].balance < extra_payments.yearly.value
      ) {
        total_monthly = calculatedValues.schedule[i - 1].balance + interest;
        principal = calculatedValues.schedule[i - 1].balance;
        balance = 0;
        calculatedValues.total.extra_payments += extra_payments.yearly.value;
        end = true;
      } else {
        total_monthly += extra_payments.yearly.value;
        principal += extra_payments.yearly.value;
        balance -= extra_payments.yearly.value;
        calculatedValues.total.extra_payments += extra_payments.yearly.value;
      }
    }

    calculatedValues.total.extra_payments += extra_payments.monthly;
    calculatedValues.total.ptihoa += ptihoa;
    calculatedValues.total.interest += interest;

    total_interest += interest;
    calculatedValues.schedule.push({
      date: payment_date,
      balance: balance,
      payment: total_monthly,
      principal: principal,
      interest: interest,
      totalInterest: total_interest,
      ptihoa: ptihoa,
    });
  }

  // total
  calculatedValues.total.down_payment = down_payment;
  calculatedValues.total.principal =
    home_price - down_payment - calculatedValues.total.extra_payments;
  calculatedValues.total.value =
    down_payment +
    calculatedValues.total.principal +
    calculatedValues.total.interest +
    calculatedValues.total.extra_payments +
    calculatedValues.total.ptihoa;

  return calculatedValues;
};

function Widget({
  pluginData,
  sitekey,
  showWidget,
  theme,
  color,
  titlecolor,
  pluginTitle,
  buttontextcolor,
  buttoncolor,
  behaviour,
  leadname,
  leadlink,
  varbiage,
  onClose,
  homeSearchPage,
  calenderBookingPage,
  leadButtons,
}) {
  const [{}, dispatch] = useGlobalState();

  const [hasExtraPayments, setHasExtraPayments] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showLeads, setShowLeads] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showZestimates, setShowZestimates] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});

  const [zestimatesLocation, setZestimatesLocation] = useState('');

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    if (showResult || showLeads || showShareModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showResult, showLeads, showShareModal]);

  const handleCalculate = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    const data = {
      home_price: Number(formData.get('home_price')) || 0,
      down_payment: Number(formData.get('down_payment')) || 0,
      interest_rate: Number(formData.get('interest_rate')) || 0,
      term: Number(formData.get('term')) || 0,
      start_time: {
        month: formData.get('start_month'),
        year: formData.get('start_year'),
      },
      pmi: Number(formData.get('pmi')) || 0,
      taxes: Number(formData.get('taxes')) || 0,
      insurance: Number(formData.get('insurance')) || 0,
      hoa: Number(formData.get('hoa')) || 0,
      extra_payments: {
        monthly: hasExtraPayments
          ? Number(formData.get('extra_monthly')) || 0
          : 0,
        yearly: {
          value: hasExtraPayments
            ? Number(formData.get('extra_yearly')) || 0
            : 0,
          start_month: formData.get('extra_yearly-month'),
        },
      },
    };

    dispatch({ type: ActionTypes.SET_CALCULATOR_INPUTS, payload: data });

    if (data.home_price < data.down_payment) {
      alert("Down Payment can't be greater than Home Price");
      return;
    }

    setCalculatedData(calculateMortgage(data));
    setShowResult(true);

    let uuid = new Date().getTime();
    setUniqueId(uuid);

    axios({
      url: `https://api.ipify.org/?format=json`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIpAddress(resData.ip);
        return axios({
          url: `https://api.ipgeolocation.io/ipgeo`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            apiKey: 'deb9a79bf3024e94865209fd35da0521',
            fields: 'city,country_name',
            output: 'json',
            ip: resData.ip,
          },
        });
      })
      .then((res) => {
        const resData = res.data;
        axios({
          url: `/api/plugin/newsletter/add/${sitekey}`,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            ref: uuid,
            ipAddress: resData.ip,
            liveAddress: resData.city + ', ' + resData.country_name,
            noAttachement: true,
            userInputs: data,
          },
        });
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });

    setTimeout(() => {
      if (
        leadButtons.emailPaymentDetails ||
        leadButtons.viewProperties ||
        leadButtons.preQualified ||
        leadButtons.checkHomeValues
      ) {
        setShowLeads(true);
      }
    }, 1000);
  };

  const handleReset = () => {
    let uuid = new Date().getTime();
    setUniqueId(uuid);
    axios({
      url: `https://api.ipify.org/?format=json`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIpAddress(resData.ip);
        return axios({
          url: `https://api.ipgeolocation.io/ipgeo`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            apiKey: 'deb9a79bf3024e94865209fd35da0521',
            fields: 'city,country_name',
            output: 'json',
            ip: resData.ip,
          },
        });
      })
      .then((res) => {
        const resData = res.data;
        axios({
          url: `/api/plugin/newsletter/add/${sitekey}`,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            ref: uuid,
            ipAddress: resData.ip,
            liveAddress: resData.city + ', ' + resData.country_name,
            noAttachement: true,
          },
        });
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  return (
    <>
      <div
        className={`mtge-calc_container ${showWidget ? 'mtge-calc_show' : ''}`}
        style={
          behaviour === 'withoutMinimize'
            ? {
                color: color,
                position: 'relative',
                top: 'unset',
                left: 'unset',
                right: 'unset',
                bottom: 'unset',
                transform: 'unset',
                margin: '15 auto',
              }
            : behaviour === 'saveAsNativeElement'
            ? {
                color: color,
                margin: '15px auto',
              }
            : { color: color }
        }
      >
        <div
          className="mtge-calc_container-widget mtge-calc_container-shadow"
          style={
            behaviour === 'withoutMinimize'
              ? { background: theme, maxHeight: 'unset' }
              : { background: theme }
          }
        >
          <div className="mtge-calc_widget-header">
            {behaviour === 'withoutMinimize' ? (
              <div></div>
            ) : (
              <button onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15"
                  />
                </svg>
              </button>
            )}

            <button onClick={() => setShowShareOptions(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ width: '20px', height: '20px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                />
              </svg>
            </button>
          </div>

          <div className="mtge-calc_title">
            {pluginData.logo.allowed ? (
              <img
                src={pluginData.logo.value}
                alt="Logo"
                onError={({ currentTarget }) => {
                  console.log(currentTarget);
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = `${config.baseUrl}/images/logo.png`;
                }}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={`${config.baseUrl}/images/logo.png`}
                alt="Logo"
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  objectFit: 'cover',
                }}
              />
            )}

            <h1 style={{ color: titlecolor }}>{pluginTitle}</h1>
          </div>

          <form
            id="mtge-calc_form"
            className="mtge-calc_fields_container"
            onSubmit={handleCalculate}
          >
            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Home Price</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  required
                  id="mtge-home_price"
                  type="number"
                  name="home_price"
                  step=".01"
                  defaultValue="350000"
                />
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Down Payment</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-down_payment"
                  type="number"
                  name="down_payment"
                  step=".01"
                  defaultValue="52000"
                />
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Interest Rate</div>
              <div className="mtge-calc_field-input">
                <input
                  required
                  id="mtge-interest_rate"
                  type="number"
                  name="interest_rate"
                  step=".01"
                  min="0"
                  max="100"
                  defaultValue="4.05"
                />
                <span className="mtge-calc_symbol"> % </span>
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Mortgage Term</div>
              <div className="mtge-calc_field-input">
                <input
                  required
                  id="mtge-term"
                  type="number"
                  name="term"
                  defaultValue="30"
                />
                <span className="mtge-calc_symbol"> years </span>
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Start Date</div>
              <div className="mtge-calc_field-input mtge-calc_field-select">
                <div className="mtge-calc_selector">
                  <select name="start_month" required>
                    <option value="Jan-1">Jan</option>
                    <option value="Feb-2">Feb</option>
                    <option value="Mar-3">Mar</option>
                    <option value="Apr-4">April</option>
                    <option value="May-5">May</option>
                    <option value="Jun-6">Jun</option>
                    <option value="Jul-7">Jul</option>
                    <option value="Aug-8">Aug</option>
                    <option value="Sep-9">Sep</option>
                    <option value="Oct-10">Oct</option>
                    <option value="Nov-11">Nov</option>
                    <option value="Dec-12">Dec</option>
                  </select>
                </div>
                <div className="mtge-calc_selector">
                  <select name="start_year" required>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                    <option value="2031">2031</option>
                    <option value="2032">2032</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">PMI</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-pmi"
                  type="number"
                  name="pmi"
                  step=".01"
                  defaultValue="1881.24"
                />
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Taxes</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-taxes"
                  type="number"
                  name="taxes"
                  step=".01"
                  defaultValue="4375"
                />
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">Insurance</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-insurance"
                  type="number"
                  name="insurance"
                  step=".01"
                  defaultValue="1225"
                />
              </div>
            </div>

            <div className="mtge-calc_field">
              <div className="mtge-calc_field-label">HOA</div>
              <div className="mtge-calc_field-input">
                <span className="mtge-calc_symbol"> $ </span>
                <input
                  id="mtge-hoa"
                  type="number"
                  name="hoa"
                  step=".01"
                  defaultValue="0"
                />
              </div>
            </div>

            <button
              className="mtge-calc_extra_payment-btn"
              type="button"
              onClick={() => setHasExtraPayments((prev) => !prev)}
            >
              {hasExtraPayments
                ? 'Remove extra payments'
                : 'Add extra payments'}
            </button>

            <div className="mtge-calc_extra_container">
              <div
                className={`mtge-calc_field mtge-calc_extra ${
                  hasExtraPayments ? '' : 'mtge-calc_extra-closed'
                } `}
              >
                <div className="mtge-calc_field-label">To monthly</div>
                <div className="mtge-calc_field-input">
                  <span className="mtge-calc_symbol"> $ </span>
                  <input
                    id="mtge-extra_monthly"
                    type="number"
                    name="extra_monthly"
                    step=".01"
                    defaultValue="0"
                  />
                </div>
              </div>

              <div
                className={`mtge-calc_field mtge-calc_extra ${
                  hasExtraPayments ? '' : 'mtge-calc_extra-closed'
                } `}
              >
                <div className="mtge-calc_field-label">Extra yearly</div>
                <div className="mtge-calc_field-input">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="mtge-calc_symbol"> $ </span>
                    <input
                      id="mtge-extra_yearly"
                      type="number"
                      name="extra_yearly"
                      step=".01"
                      defaultValue="0"
                    />
                  </div>
                  <div
                    className="mtge-calc_field-select"
                    style={{ width: '30%' }}
                  >
                    <div className="mtge-calc_selector">
                      <select name="extra_yearly-month">
                        <option value="Jan">Jan</option>
                        <option value="Feb">Feb</option>
                        <option value="Mar">Mar</option>
                        <option value="Apr">April</option>
                        <option value="May">May</option>
                        <option value="Jun">Jun</option>
                        <option value="Jul">Jul</option>
                        <option value="Aug">Aug</option>
                        <option value="Sep">Sep</option>
                        <option value="Oct">Oct</option>
                        <option value="Nov">Nov</option>
                        <option value="Dec">Dec</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="mtge-calc_calculate-btn"
              type="submit"
              style={{
                color: buttontextcolor,
                background: buttoncolor,
              }}
            >
              Calculate
            </button>
          </form>
        </div>
        <div className="mtge-calc_discloser-container">
          <div className="mtge-calc_discloser">
            {varbiage.map((dt, idx) => {
              if (dt.text) {
                return (
                  <a key={idx} target="_blank" href={dt.link}>
                    {dt.text}
                  </a>
                );
              }
            })}
          </div>
          <div className="mtge-calc_discloser">
            <a target="_blank" href="#terms">
              Terms & Conditions
            </a>
          </div>
          {pluginData.showBrandingBottomText && (
            <div className="mtge-calc_discloser">
              <a target="_blank" href="http://www.SavageSales.com">
                Provided by Savage Sales
              </a>
            </div>
          )}
        </div>
      </div>

      {showResult && (
        <Result
          monthly={calculatedData.monthly}
          total={calculatedData.total}
          schedule={calculatedData.schedule}
          show={showResult}
          onClose={() => setShowResult(false)}
          setShowShareModal={setShowShareModal}
          setShowContactModal={setShowContactModal}
          setCalculatedData={setCalculatedData}
          setShowLeads={setShowLeads}
        />
      )}
      {showShareModal && (
        <SharePDF
          show={showShareModal}
          calculatedData={calculatedData}
          onClose={() => setShowShareModal(false)}
          sitekey={sitekey}
        />
      )}

      {showLeads && (
        <Leads
          sitekey={sitekey}
          home_price={calculatedData.home_price}
          leadname={leadname}
          leadlink={leadlink}
          onClose={() => setShowLeads(false)}
          calculatedData={calculatedData}
          homeSearchPage={homeSearchPage}
          calenderBookingPage={calenderBookingPage}
          setShowZestimates={setShowZestimates}
          setZestimatesLocation={setZestimatesLocation}
          zestimatesLocation={zestimatesLocation}
          uniqueId={uniqueId}
          handleReset={handleReset}
          leadButtons={leadButtons}
        />
      )}

      {showContactModal && (
        <ContactUs
          sitekey={sitekey}
          onClose={() => setShowContactModal(false)}
          calculatedData={calculatedData}
          homeSearchPage={homeSearchPage}
          calenderBookingPage={calenderBookingPage}
          uniqueId={uniqueId}
          handleReset={handleReset}
        />
      )}

      {showZestimates && (
        <Zestimates
          show={showZestimates}
          onClose={() => setShowZestimates(false)}
          location={zestimatesLocation}
        />
      )}

      {showShareOptions && (
        <ShareModalOptions
          pluginTitle={pluginTitle}
          show={showShareOptions}
          onClose={() => setShowShareOptions(false)}
        />
      )}
    </>
  );
}

function ShareModalOptions({ pluginTitle, show, onClose }) {
  const handleBookmark = () => {
    const pageTitle = `${pluginTitle}`;
    const pageUrl = window.location.href;

    if (window.sidebar && window.sidebar.addPanel) {
      // Firefox
      window.sidebar.addPanel(pageTitle, pageUrl, '');
    } else if (window.external && window.external.AddFavorite) {
      // Internet Explorer
      window.external.AddFavorite(pageUrl, pageTitle);
    } else if (window.opera && window.print) {
      // Opera
      const bookmarkLink = document.createElement('a');
      bookmarkLink.setAttribute('href', pageUrl);
      bookmarkLink.setAttribute('title', pageTitle);
      bookmarkLink.setAttribute('rel', 'sidebar');
      bookmarkLink.click();
    } else {
      // Other browsers - manually prompt the user to add the page as a bookmark
      alert(
        'To add a bookmark, please press Ctrl+D (Windows) or Command+D (Mac).'
      );
    }
  };

  const handleSocialShare = () => {
    let text = `
${window.location.href}
    
Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.
    `;
    let navLink = 'https://www.facebook.com/sharer.php?u=' + text;

    window.open(navLink, '__blank');
  };
  return (
    <div
      className={`mtge-calc_modal-wrapper ${
        show ? 'mtge-calc_modal-wrapper-show' : ''
      } mtge-calc_modal-wrapper-share`}
    >
      <div className="mtge-calc_modal-overlay"></div>
      <div
        className="mtge-calc_modal"
        id="mtge-calc_share-wrapper"
        onClick={onClose}
      >
        <div
          className="mtge-calc_modal_wrapper"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            id="mtge-share-close"
            className="mtge-calc_modal-close"
            onClick={onClose}
          >
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <h2 className="mtge-calc_newsletter-title"> Choose a option </h2>
          <div className="mtge-calc_lead-container svsl-px-2 md:svsl-px-4">
            <button className="mtge-calc_btn" onClick={handleBookmark}>
              Bookmark this mortgage calculator
            </button>

            <TwitterShareButton
              url={`${window.location.href} 

Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.`}
            >
              <button className="mtge-calc_btn">
                Post this mortgage calculator on Twitter
              </button>
            </TwitterShareButton>
            <WhatsappShareButton
              url={`${window.location.href} 

Awesome Mortgage Calculator!
Use this free mortgage calculator to quickly and easily estimate your monthly mortgage payment.`}
            >
              <button className="mtge-calc_btn">
                Post this mortgage calculator on Whatsapp
              </button>
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widget;
