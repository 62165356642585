import React, { useState, useEffect } from 'react';

const ProgressBar = ({ currentStep, totalStep, formValues, onChangeStep }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep > 0) {
      // let totalStep = 13;
      let calculatedPercentage = (currentStep / (totalStep - 1)) * 100;
      const clampedPercentage = Math.min(
        100,
        Math.max(0, calculatedPercentage)
      );
      setWidth(clampedPercentage);
    } else {
      setWidth(0);
    }
  }, [currentStep]);

  return (
    <div className="relative w-full h-[1px] bg-gray-200">
      <div
        className="h-full bg-[var(--color-red)] transition-all duration-750 ease-in-out"
        style={{
          width: width + '%',
        }}
      ></div>
      <div
        className="absolute top-2 px-1 py-1 -mx-4 !text-white text-[8px] bg-[var(--color-red)] rounded-sm after:content-[''] after:absolute after:-top-1 after:left-1/2 after:h-0 after:w-0 after:border-l-4 after:border-r-4 after:border-b-4 after:border-l-transparent after:border-r-transparent after:border-b-[var(--color-red)] after:transform after:-translate-x-1/2 transition-all duration-750 ease-in-out"
        style={{
          visibility: width === 0 ? 'hidden' : 'visible',
          left: width + '%',
        }}
      >
        {width.toFixed(0)}%
      </div>
    </div>
  );
};

export default ProgressBar;
