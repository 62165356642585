import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Widget from '../Check_Home_Values/Widget/Widget';
import Loader from '../Loader/Loader';

function StandAloneCheckHomeValues() {
  const params = useParams();
  const [pluginToken, setPluginToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [varbiage, setVarbiage] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);

  useEffect(() => {
    if (params.pluginToken) {
      axios({
        url: '/api/checkHomeValues/token/' + params.pluginToken,
        method: 'get',
      })
        .then((res) => {
          const resData = res.data;
          console.log(resData);
          if (resData.success) {
            setData(resData.data);
            setVarbiage(resData.data.varbiage);

            setPluginToken(resData.data.token);
            setIsLoading(false);
          } else {
            setError(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(true);
          let error = err.response.data.error;
          let status = err.response.data.status;
          // toast.error(error.message || status, {});
        });
    }
  }, [params]);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!params.pluginToken) return <></>;
  return (
    <div className="min-h-screen flex items-center justify-center">
      {' '}
      <Widget
        funnelTitle={data.title}
        handleShow={() => {}}
        behaviour="withoutMinimize"
        calenderBookingPage={data.calenderBookingPage}
        sitekey={params.pluginToken}
        varbiage={varbiage}
      />
    </div>
  );
}

export default StandAloneCheckHomeValues;
