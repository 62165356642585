import React from 'react';
import SkipNextIcon from '@mui/icons-material/SkipNext';

function CustomStep({ data, value, onChange, handleNext }) {
  if (!data || !data.questions || !Array.isArray(data.questions)) {
    return null; // or handle the case where the necessary data is missing
  }

  return (
    <div className="py-6 flex flex-col items-center">
      {data.questions.map((question, questionIndex) => (
        <div key={questionIndex} className="text-center">
          <h1 className="text-base md:text-xl font-medium">{question.text}</h1>
          <div className="mt-3 flex flex-col items-center space-y-2">
            {question.options.map((option, index) => (
              <button
                key={index}
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  onChange({
                    target: {
                      value: option.option,
                      name: 'selectedOptions',
                    },
                  });
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    value === option.option
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
                  }`}
                >
                  {option.option}
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
            ))}
          </div>
        </div>
      ))}

      {/* Skip Button */}
      {data.questions.every((question) => question.requiredField === false) && (
        <button
          className="mt-8 bg-blue-400 text-white px-4 py-2 rounded flex items-center transition duration-300 ease-in-out hover:bg-blue-500 hover:shadow-lg"
          onClick={handleNext}
        >
          <span>Skip</span>
          <SkipNextIcon className="ml-2 h-5 w-5" />
        </button>
      )}
    </div>
  );
}

export default CustomStep;
