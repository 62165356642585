import React, { useEffect } from "react";

function ModalWrapper({ show, onClose, children }) {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  // useEffect(() => {
  //   if (show) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "";
  //   }
  // }, [show]);

  useEffect(() => {
    document.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <div
      className={`mtge-calc_modal-wrapper ${
        show ? "mtge-calc_modal-wrapper-show" : ""
      } `}
    >
      <div className="mtge-calc_modal-overlay"></div>
      <div className="mtge-calc_modal" onClick={onClose}>
        {children}
      </div>
    </div>
  );
}

export default ModalWrapper;
