import React from 'react';

const FunnelHeader = () => {
  return (
    <>
      <header className="flex items-center justify-between">
        <div className="w-14 lg:w-16">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
    </>
  );
};

export default FunnelHeader;
