import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import ActionTypes from "../../../utils/actionTypes";
import useContext from "../../../context/StateProvider";
import customFunnelService from "../../../services/customFunnel.service";

import ModalWrapper from "../../Modal";
import ModalComponent from "./ModalComponent";
// import Loader from '../../Loader/Loader';

function CreateFunnels() {
  const [{ user }] = useContext();
  const { profileId } = useParams();
  const [funnelName, setFunnelName] = useState("");
  const [funnels, setFunnels] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //   const [isCreateNewPlugin, setIsCreateNewPlugin] = useState(false);
  const fetchFunnels = async () => {
    try {
      const funnelsData = await customFunnelService.getAllFunnels(
        profileId,
        user.token
      ); // Fetch existing funnels
      setFunnels(funnelsData.data); // Update the state with fetched data
    } catch (error) {
      console.error("Error fetching funnels:", error);
    }
  };

  useEffect(() => {
    fetchFunnels();
  }, []);

  const handleCreateFunnel = async () => {
    try {
      const newFunnel = await customFunnelService.createFunnel(
        profileId,
        user.token,
        funnelName
      );

      if (newFunnel.success) {
        setFunnels([...funnels, newFunnel.data]);
        toast.success("Funnel created successfully");
        handleCloseModal();
      } else {
        toast.error("Failed to create funnel. Please try again.");
      }
    } catch (error) {
      toast.error("There was an error. Please try again.");
      console.error("Error creating funnel:", error);
    }
  };

  return (
    <div className="w-full md:max-w-2xl mx-auto py-8 px-6 md:px-12 bg-white">
      <h1 className="text-center text-3xl text-[#39393bde] font-bold">
        {" "}
        Your funnels{" "}
      </h1>
      <div className="mt-8">
        <div className="mt-4 grid grid-cols-1 itmes-center space-y-6">
          <button
            className="flex justify-center items-center p-6 rounded-lg border-2 shadow-lg"
            onClick={handleOpenModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 md:w-10 md:h-10 text-gray"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
          <ModalComponent
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          >
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Your Funnel Name</h3>
              <TextField
                label="Enter Question"
                variant="outlined"
                fullWidth
                value={funnelName}
                onChange={(e) => setFunnelName(e.target.value)}
                className="mb-4"
              />
            </div>

            <Button
              variant="contained"
              onClick={handleCreateFunnel}
              className="w-full"
            >
              Save Funnel
            </Button>
          </ModalComponent>

          {funnels.length > 0 ? (
            <div>
              {funnels.map((funnel) => (
                <Card data={funnel} />
              ))}
            </div>
          ) : (
            <Typography variant="body1">No funnels created yet.</Typography>
          )}
        </div>
      </div>
    </div>
  );
}

const Card = ({ data }) => {
  const params = useParams();
  return (
    <>
      <Link to={`/dashboard/${params.profileId}/custom-funnel/${data._id}`}>
        <div className="text-center p-6 rounded-lg border-2 shadow-lg">
          <h3> {data.name} </h3>
        </div>
      </Link>
    </>
  );
};

export default CreateFunnels;
