import React, { useState } from "react";
import { toast } from "react-toastify";

// components

function StepFourteen({
  setShowMortgageCalculator,
  setShowViewProperties,
  setShowContactUs,
  calenderBookingPage,
}) {
  return (
    <>
      <div className="py-6 flex flex-col items-center">
        <div className="mt-3 flex flex-col items-center space-y-2">
          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              toast.info("Under development");
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              Get a Professional Estimate
            </div>
          </button>
          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              if (calenderBookingPage) {
                window.open(calenderBookingPage);
              } else {
                toast.info("We will get in touch with you ASAP! Thank you!");
              }
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              Schedule Appointment
            </div>
          </button>
          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              setShowViewProperties(true);
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              View Properties
            </div>
          </button>
          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              toast.info("Under development");
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              Get Pre-Qualified
            </div>
          </button>
          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              setShowMortgageCalculator(true);
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              Mortgage Calculator
            </div>
          </button>

          <button
            className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
            style={{
              boxShadow: "0 6px 10px 0 rgb(0 0 0 / 15%)",
            }}
            onClick={() => {
              setShowContactUs(true);
            }}
          >
            <div
              className={`w-full px-2 py-3 text-center text-sm font-[500] text-white bg-[var(--color-red)]`}
            >
              Contact Us
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default StepFourteen;
