import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ThemeProvider } from "@mui/material/styles";
import { Select, FormControl, MenuItem } from "@mui/material";
// themes
import { filterSelect } from "../../utils/themes.mui";
import axios from "axios";
import { toast } from "react-toastify";

function Marketplace() {
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const [services, setServices] = useState([]);

  const [filteredServices, setFilteredServices] = useState([]);

  const [marketPlaceBg, setMarketPlaceBg] = useState({
    type: "color",
    value: "",
  });

  useEffect(() => {
    axios({
      url: "/api/system/marketplaces",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setMarketPlaceBg(resData.data.background);
          setServices(resData.data.services);
          setFilteredServices(resData.data.services);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (value.length > 0) {
      const regx = new RegExp(`^.*?\\b(${value.toLowerCase()}).*?`, "gi");
      let fs = services.filter((dt) => {
        if (regx.test(dt.name.toLowerCase())) {
          return dt;
        }
      });
      console.log(fs);
      setFilteredServices(fs);
    } else {
      setFilteredServices(services);
    }
  };

  return (
    <div
      className="px-6 py-8"
      style={
        marketPlaceBg.type === "color"
          ? { background: marketPlaceBg.value }
          : {
              background: `url("${marketPlaceBg.value}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }
      }
    >
      <h1 className="text-2xl text-[#39393bde] font-bold">Marketplaces</h1>
      <div className="mt-8 flex items-center space-x-2">
        <input
          type="text"
          className="w-full max-w-lg p-2 border-2 rounded-md outline-none"
          placeholder="Search for marketplace"
          onChange={handleSearch}
        />
        <div className="relative">
          <button
            className="flex font-medium items-center text-sm"
            onClick={handleOpenFilter}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
              />
            </svg>
            <span>Filter</span>
          </button>
          <ThemeProvider theme={filterSelect}>
            <FormControl sx={{ m: 0 }}>
              <Select
                open={openFilter}
                onClose={() => setOpenFilter(false)}
                onOpen={() => setOpenFilter(true)}
                value={selectedFilter}
                label="filter"
                onChange={(e) => {
                  setSelectedFilter(e.target.value);
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="my_systems&services">
                  My Systems & Services
                </MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>

      <div className="mt-8">
        <CopyToClipboard
          text={`${window.location.origin}/share/marketplace`}
          onCopy={() => toast.success("Copied to clipboard")}
        >
          <button className="flex items-center space-x-2 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700">
            <span> Copy share Link</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
          </button>
        </CopyToClipboard>
      </div>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredServices.map((dt, idx) => (
          <Card
            key={idx}
            url={dt.url}
            img={dt.img}
            name={dt.name}
            info={dt.info}
          />
        ))}
      </div>
    </div>
  );
}

const Card = ({ img, name, info, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="border-[1px]"
    >
      <div
        className="w-full h-44 bg-no-repeat bg-contain bg-center"
        style={{
          backgroundImage: `url('${img}')`,
        }}
      ></div>
      <div className="px-4 py-6">
        <h2 className="text-lg font-bold"> {name} </h2>
        <p>{info}</p>
        {!url && (
          <p>
            {" "}
            <small>Coming soon!</small>{" "}
          </p>
        )}
      </div>
    </a>
  );
};

export default Marketplace;
