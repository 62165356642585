import ActionTypes from './actionTypes';

export const initialState = {
  user: null,
  page: [],
  admin: null,
  currentPlugin: null,
  allProfiles: [],
  allPlugins: [],
  calc_inputs: [],
  isOwnPlugin: true,
  isOwnProfile: true,
};

export const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.SET_IS_OWN_PROFILE:
      return { ...state, isOwnProfile: payload };
    case ActionTypes.SET_USER:
      return { ...state, user: payload };
    case ActionTypes.SET_PAGE:
      return { ...state, page: payload };
    case ActionTypes.SET_ADMIN:
      return { ...state, admin: payload };
    case ActionTypes.SET_CURRENT_PLUGIN:
      return {
        ...state,
        currentPlugin: payload,
        isOwnPlugin:
          action.isOwnPlugin === undefined ? true : action.isOwnPlugin,
      };
    case ActionTypes.SET_ALL_PLUGIN:
      return {
        ...state,
        allPlugins: payload,
      };
    case ActionTypes.SET_ALL_PROFILES:
      return {
        ...state,
        allProfiles: payload,
      };
    case ActionTypes.UPDATE_PLUGIN_IN_USER:
      return { ...state, user: { ...state.user, plugins: payload } };
    case ActionTypes.SET_EMAIL_TEMPLATE:
      return {
        ...state,
        currentPlugin: { ...state.currentPlugin, emailTemplate: payload },
      };
    case ActionTypes.SET_CALCULATOR_INPUTS:
      return { ...state, calc_inputs: [...state.calc_inputs, payload] };
    case ActionTypes.CLEAR_CALCULATOR_INPUTS:
      return { ...state, calc_inputs: [] };
    default:
      return state;
  }
};
