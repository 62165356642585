import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { io } from "socket.io-client";
import { SocketProvider } from "./context/Socket";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "./context/StateProvider";
import { initialState, reducer } from "./utils/reducer";
import { ContextWrapper } from "./context/ContextApi";
// import DataProvider from './redux/store';

const socket = io();

localStorage.debug = "*";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div>
    <Router>
      <StateProvider initialState={initialState} reducer={reducer}>
        <SocketProvider socket={socket}>
          <ContextWrapper>
            <App />
          </ContextWrapper>
        </SocketProvider>
      </StateProvider>
    </Router>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
