import React from 'react';
import { toast } from 'react-toastify';

function StepTen({ value, onChange, handleNext }) {
  const verifyAndProceed = () => {
    if (value) {
      handleNext();
    } else {
      toast.error('The field is required!');
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="my-16 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="name"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="name"
            required
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="name"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Name
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-lg md:text-xl"
          onClick={verifyAndProceed}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepTen;
