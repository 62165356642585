import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { toast } from 'react-toastify';

import profileIcon from '../../assets/images/user-icon.png';
import companyIcon from '../../assets/images/company-icon.png';

import useContext from '../../context/StateProvider';
import ActionTypes from '../../utils/actionTypes';
import config from '../../utils/config';
import Loader from '../Loader/Loader';

// import BackButton from '../BackButton';

function AccountSettings() {
  const [{ user, currentPlugin }, dispatch] = useContext();

  const [profilePic, setProfilePic] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');

  const [username, setUsername] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyMail, setCompanyMail] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [license, setLicense] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  // const [pluginToken, setPluginToken] = useState("");

  useEffect(() => {
    setProfilePic(user.profilePic);
    setCompanyLogo(user.companyLogo);
  }, []);

  useEffect(() => {
    setUsername(user.username);
    setCompany(user.company);
    setTitle(user.title);
    setCompanyAddress(user.companyAddress);
    setCompanyMail(user.companyMail);
    setCompanyWebsite(user.companyWebsite);
    setLicense(user.license);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setEmail(user.email);
    // setPluginToken(currentPlugin.token);
  }, [user]);

  const handleSave = () => {
    setLoading(true);
    let formData = new FormData();

    formData.append('profilePic', profilePic);
    formData.append('companyLogo', companyLogo);

    formData.append('username', username);
    formData.append('company', company);
    formData.append('title', title);
    formData.append('companyMail', companyMail);
    formData.append('companyAddress', companyAddress);
    formData.append('companyWebsite', companyWebsite);
    formData.append('license', license);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('phone', phone);

    axios({
      url: '/api/users/update/' + user._id,
      method: 'put',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          toast.success('Updated successfully');
          setLoading(false);
          dispatch({
            type: ActionTypes.SET_USER,
            payload: { ...resData.data, token: user.token },
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const previewProfilePic = useMemo(() => {
    return typeof profilePic === 'string'
      ? profilePic
      : typeof profilePic === 'object'
      ? URL.createObjectURL(profilePic)
      : '';
  }, [profilePic]);
  const previewCompanyLogo = useMemo(() => {
    return typeof companyLogo === 'string'
      ? companyLogo
      : typeof companyLogo === 'object'
      ? URL.createObjectURL(companyLogo)
      : '';
  }, [companyLogo]);

  return (
    <div className="px-6 py-8">
      <h1 className="text-2xl text-[#39393bde] font-bold">
        {' '}
        Edit your account details{' '}
      </h1>
      <div className="grid md:grid-cols-2 mt-12">
        <div className="mb-6 ">
          <p className="mb-2 text-base text-[#39393bde] font-extrabold">
            Account Profile Pic
          </p>
          <div>
            <label
              htmlFor="profilePic"
              className="relative image_edit-container block w-28 h-28 md:w-40 md:h-40 cursor-pointer"
              style={{
                backgroundImage: `url(${previewProfilePic}), url(${profileIcon})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <span className="image_edit-btn flex items-center space-x-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-sm z-10">
                <span>Change </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </span>
            </label>
          </div>
          <input
            hidden
            id="profilePic"
            type="file"
            name="profilePic"
            onChange={(e) => setProfilePic(e.target.files[0])}
          />
        </div>
        <div className="mb-6 ">
          <p className="mb-2 text-base text-[#39393bde] font-extrabold">
            {' '}
            Your Brand Logo{' '}
          </p>
          <div>
            <label
              htmlFor="companyLogo"
              className="relative image_edit-container block w-28 h-28 md:w-40 md:h-40 cursor-pointer"
              style={{
                backgroundImage: `url(${previewCompanyLogo}), url(${companyIcon})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <span className="image_edit-btn flex items-center space-x-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-sm z-10">
                <span>Change </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </span>
            </label>
          </div>
          <input
            hidden
            id="companyLogo"
            type="file"
            name="companyLogo"
            onChange={(e) => setCompanyLogo(e.target.files[0])}
          />
        </div>
        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="username"
          >
            Username
          </label>
          <input
            id="username"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="username"
            value={username}
            placeholder="Your username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="company"
          >
            {' '}
            Company{' '}
          </label>
          <input
            id="company"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="company"
            value={company}
            placeholder="Your Company Name"
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="title"
          >
            Title
          </label>
          <input
            id="title"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="title"
            value={title}
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="companyMail"
          >
            Company Mail
          </label>
          <input
            id="companyMail"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="companyMail"
            value={companyMail}
            placeholder="companyMail"
            onChange={(e) => setCompanyMail(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="companyAddress"
          >
            Company Address
          </label>
          <input
            id="companyAddress"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="companyAddress"
            value={companyAddress}
            placeholder="companyAddress"
            onChange={(e) => setCompanyAddress(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="companyWebsite"
          >
            Company Website
          </label>
          <input
            id="companyWebsite"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="companyWebsite"
            value={companyWebsite}
            placeholder="companyWebsite"
            onChange={(e) => setCompanyWebsite(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="license"
          >
            License
          </label>
          <input
            id="license"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="license"
            value={license}
            placeholder="Your license"
            onChange={(e) => setLicense(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="email"
          >
            {' '}
            Email{' '}
          </label>
          <span className="py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm overflow-hidden">
            {email}
          </span>
        </div>
        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="firstName"
          >
            {' '}
            First name{' '}
          </label>
          <input
            id="firstName"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="firstName"
            value={firstName}
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="lastName"
          >
            {' '}
            Last name{' '}
          </label>
          <input
            id="lastName"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="text"
            name="lastName"
            value={lastName}
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="phone"
          >
            {' '}
            Phone{' '}
          </label>
          <input
            id="phone"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="tel"
            name="phone"
            value={phone}
            placeholder="Phone"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="password"
          >
            {' '}
            Password{' '}
          </label>
          <input
            id="password"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="password"
            name="password"
            value="XXXXXXXXXXXXXXXXXXX"
            placeholder="XXXXXXXXXXXXXXXXXXX"
            disabled
          />
        </div>

        <div className="mb-6 flex items-end">
          <Link
            to={'/changePassword'}
            className="px-6 py-2 text-white font-bold rounded-lg bg-neutral-700"
          >
            {user.setPassword ? 'Set Password' : 'Change Password'}
          </Link>
        </div>

        <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <a
            target="__blank"
            href={`${config.baseUrl}/api/p/customer/${user.customerId}/portal`}
            className="block w-full md:w-60 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
          >
            Update Billing Details
          </a>
        </div>

        {/* <div className="mb-6 md:mr-4 flex flex-col space-y-1">
          <label className="text-base text-[#39393bde] font-extrabold">
            Site Key
          </label>
          <span className="py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm overflow-hidden">
            {pluginToken}
          </span>
        </div>
        <div className="mb-6 flex items-end">
          <CopyToClipboard
            text={pluginToken}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="flex items-center px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                />
              </svg>
              <span>Copy</span>
            </button>
          </CopyToClipboard>
        </div> */}
        <div className="mt-12 md:col-span-2">
          <button
            className="block w-full md:w-60 mx-auto px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
        {loading && <Loader customClass="md:ml-[300px]" />}
      </div>
    </div>
  );
}

export default AccountSettings;
