import React from 'react';

import Widget from './Widget/Widget';

function Preview({ mortageRateTitle, pluginToken, varbiage }) {
  return (
    <div className="mtge-rates_app">
      <Widget
        mortageRateTitle={mortageRateTitle}
        pluginToken={pluginToken}
        varbiage={varbiage}
      />
    </div>
  );
}

export default Preview;
