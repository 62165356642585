import React, { useState } from "react";
import profileIcon from "../../assets/images/user-icon.png";
import useContext from "../../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";

function Sidebar({
  users,
  pages,
  setPages,
  setUsers,
  selectedUser,
  onSelect,
  show,
  handleToggler,
}) {
  const [{ user }] = useContext();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  const [activeStatusFilter, setActiveStatusFilter] = useState(true);
  const [verfiedStatusFilter, setVerfiedStatusFilter] = useState(false);

  const handleNextPage = () => {
    setIsLoading(true);
    axios({
      url: "/api/users/all",
      params: {
        page: pages.current + 1,
      },
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);

        if (resData.success) {
          setUsers((prev) => [...prev, ...resData.data]);
          filteredUsers((prev) => [...prev, ...resData.data]);
          setPages({
            current: resData.current_page,
            total: resData.total_pages,
          });
        } else {
          toast.error(resData.status);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleSearch = () => {
    if (searchText === "") {
      setFilteredUsers(users);
      return;
    }

    axios({
      url: "/api/users/search/" + searchText,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);

        if (resData.success) {
          setFilteredUsers(resData.data);
        } else {
          toast.error(resData.status);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };
  const handleFilterByAccountType = (e) => {
    const { value } = e.target;
    if (value === "All") {
      setFilteredUsers(users);
      return;
    }

    axios({
      url: "/api/users/search/accountType/" + value,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);

        if (resData.success) {
          setFilteredUsers(resData.data);
        } else {
          toast.error(resData.status);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleFilterByActiveStatus = (e) => {
    let value = !activeStatusFilter ? "yes" : "no";

    axios({
      url: "/api/users/search/active/" + value,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);

        if (resData.success) {
          setActiveStatusFilter((prev) => !prev);
          setFilteredUsers(resData.data);
        } else {
          toast.error(resData.status);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleFilterByVerfiedStatus = (e) => {
    let value = !verfiedStatusFilter ? "yes" : "no";

    axios({
      url: "/api/users/search/verified/" + value,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);

        if (resData.success) {
          setVerfiedStatusFilter((prev) => !prev);
          setFilteredUsers(resData.data);
        } else {
          toast.error(resData.status);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  return (
    <div
      className={`pt-4 lg:pt-0 absolute top-0 bottom-0 ${
        show ? "left-0 right-0" : "-left-[150%] right-[150%] lg:left-0"
      } lg:relative lg:flex-[0.3] flex flex-col transition-all ease-linear duration-300 overflow-y-auto bg-white z-10`}
    >
      <div className="lg:hidden absolute top-2 right-6 bg-white">
        <button onClick={handleToggler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="m-4 mt-8 p-2 flex items-center border-2 rounded-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>

        <input
          className="w-full px-1 py-1  outline-none"
          type="text"
          value={searchText}
          onChange={(e) => {
            if (!e.target.value) {
              setFilteredUsers(users);
            }
            setSearchText(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.code === "Enter" || e.keyCode === 13) {
              handleSearch();
            }
          }}
        />
      </div>
      <div className="px-4 mb-3 flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <label htmlFor="accountType">AccountType</label>
          <select
            className="border border-dotted outline-none"
            name="accountType"
            id="accountType"
            onChange={handleFilterByAccountType}
          >
            <option value="All">All</option>
            <option value="Free">Free </option>
            <option value="Trial">Trial</option>
            <option value="Pro">Pro</option>
            <option value="Enterprise">Enterprise</option>
            <option value="All">All</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="accountType">Status</label>
          <select
            className="border border-dotted outline-none"
            name="accountType"
            id="accountType"
          >
            <option value="Active Subscriptions">Active Subscriptions</option>
            <option value="Inactive Subscriptions">
              Inactive Subscriptions{" "}
            </option>
            <option value="Past Due Users">Past Due Users</option>
            <option value="Suspended Users">Suspended Users</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <label className="flex items-center space-x-1" htmlFor="active">
            <span>Active</span>{" "}
            <input
              type="checkbox"
              name="active"
              id="active"
              value="yes"
              checked={activeStatusFilter}
              onChange={handleFilterByActiveStatus}
            />{" "}
          </label>
          <label className="flex items-center space-x-1" htmlFor="verified">
            <span>Verified</span>{" "}
            <input
              type="checkbox"
              name="verified"
              id="verified"
              value="yes"
              checked={verfiedStatusFilter}
              onChange={handleFilterByVerfiedStatus}
            />{" "}
          </label>
        </div>
      </div>
      <div className="p-8 pt-6 space-y-6 overflow-y-auto">
        {filteredUsers.map((user, idx) => (
          <UserCard
            key={idx}
            user={user}
            selectedUser={selectedUser}
            onSelect={onSelect}
          />
        ))}

        {pages.current < pages.total && (
          <div className="text-center">
            {!isLoading ? (
              <button
                className="px-4 py-1 bg-green-600"
                onClick={handleNextPage}
              >
                Load more
              </button>
            ) : (
              <div className="text-center font-medium">Loading...</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const UserCard = ({ user, selectedUser, onSelect }) => {
  return (
    <button
      className={`w-full p-2 flex items-center space-x-2 hover:bg-[#d5d5d5] rounded-lg ${
        selectedUser?._id === user._id ? "bg-[#d5d5d5]" : ""
      }`}
      onClick={(e) => onSelect(user._id)}
    >
      <div
        className="w-10 h-10 md:w-16 md:h-16"
        style={{
          backgroundImage: `url(${user.profilePic}), url(${profileIcon})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="text-left text-sm">
        <h3 className="m-0 font-bold">
          {" "}
          {user.firstName} {user.lastName}{" "}
        </h3>
        <p className="m-0">
          {" "}
          <small> {user.email} </small>{" "}
        </p>
        <p className="m-0"> Company: {user.company || "null"} </p>
        <p className="m-0">
          <small> Account Type: {user.accountType} </small>
        </p>
      </div>
    </button>
  );
};

export default Sidebar;
