import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import { TiTick } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MyConstextApi } from "../../../../context/ContextApi";
import useContext from "../../../../context/StateProvider";
import facepopService from "../../../../services/facepop.service";
import PopUpColorModal from "./PopUpColorModal";

const InputFieldPupUp = ({ open, handleActionpopUp, setPopup }) => {
  const [{ user }] = useContext();
  const params = useParams();

  const {
    textInputElementId,
    setTextInputElementId,
    textInputType,
    setTextInputType,
    textInputPlaceHolder,
    setTextInputPlaceHolder,
    textInputbgColor,
    setTextInputbgColor,
    textInputTextColor,
    setTextInputTextColor,
    textInputUpdate,
    setTextInputUpdate,
    fetchInputTextCta,
  } = MyConstextApi();

  const [state, setState] = useState("url");
  const [buttonSetting, setButtonSetting] = useState("general");

  const [open2, setOpen2] = useState(false);
  const [inputTextClr, setInputTextClr] = useState("#000000");

  const [open3, setOpen3] = useState(false);
  const [inPutBgClr, setInPutBgClr] = useState("#ffffff");

  const [elementId, setElementId] = useState("");
  const [settings, setSettings] = useState({
    inputType: textInputType,
    placeholder: textInputPlaceHolder,
  });
  const [style, setStyle] = useState({
    btnStyle: "Filled",
    btnWidth: "Stretch",
    cornerStyle: "Round",
  });

  const [icon, setIcon] = useState("");

  const handleClose = () => {
    setOpen2(false);
    setOpen3(false);
  };

  const onRedicrectSaveHandler = () => {
    setPopup(false);
    setTextInputUpdate(true);
  };
  const handleStyleChange = (field, value) => {
    setStyle((prevStyle) => ({
      ...prevStyle,
      [field]: value,
    }));
  };

  const handleTxtClrChange = (newColor) => {
    setInputTextClr(newColor);
  };

  const handleBgClrChange = (newColor) => {
    setInPutBgClr(newColor);
  };

  const handleSave = async () => {
    let toastId = toast.loading("Processing...");

    const pluginId = params.pluginId;
    console.log("pluginId: ", pluginId);

    const colors = {
      textColor: inputTextClr,
      bgColor: inPutBgClr,
    };

    const settings = {
      inputType: textInputType,
      placeholder: textInputPlaceHolder,
    };

    try {
      const saveInputCta = await facepopService.inputTextCTA(
        pluginId,
        user.token,
        textInputElementId,
        settings,
        style,
        colors,
      );

      if (saveInputCta.success) {
        toast.update(toastId, {
          render: "Button Save Successfully",
          type: "success",
          autoClose: 3000,
          isLoading: false,
        });
        toast.success("Button Save Successfully");
        fetchInputTextCta(params.pluginId, user.token);
        setPopup(false);
        setTextInputUpdate(true);
        console.log("saveInputCta created successfully:", saveInputCta.data);
      } else {
        toast.update(toastId, {
          render: "An error occurred. Please try again",
          type: "error",
          autoClose: 3000,
          isLoading: false,
        });
      }
    } catch (err) {
      console.error("Error:", err);
      toast.update(toastId, {
        render: "An error occurred. Please try again",
        type: "error",
        autoClose: 3000,
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleActionpopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <div className="min-h-screen flex items-center">
          <div
            style={{ width: "420px" }}
            className="   h-fit border mx-auto  rounded-md  bg-white sm:px-7 px-2 py-5"
          >
            <h1 className="text-color11 font-bold text-2xl mb-4">Text Input</h1>

            <div className="mt-4">
              <ul className="flex sm:gap-8 gap-3  text-xs font-semibold  text-color7">
                <li
                  className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                    buttonSetting === "general"
                      ? "border-2 border-gray-600 text-gray-500"
                      : ""
                  }`}
                  onClick={() => setButtonSetting("general")}
                >
                  General
                </li>
                <li
                  className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                    buttonSetting === "setting"
                      ? "border-2 border-gray-600 text-gray-500"
                      : ""
                  }`}
                  onClick={() => setButtonSetting("setting")}
                >
                  Setting
                </li>
                <li
                  className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                    buttonSetting === "style"
                      ? "border-2 border-gray-600 text-gray-500"
                      : ""
                  }`}
                  onClick={() => setButtonSetting("style")}
                >
                  Style
                </li>

                <li
                  className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                    buttonSetting === "color"
                      ? "border-2 border-gray-600 text-gray-500"
                      : ""
                  }`}
                  onClick={() => setButtonSetting("color")}
                >
                  Colors
                </li>
              </ul>
            </div>

            <div className=" mt-5">
              <>
                {buttonSetting === "general" ? (
                  <>
                    {" "}
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <label className="text-color2 text-sm  font-semibold">
                          Element ID
                        </label>
                        <input
                          onChange={(e) =>
                            setTextInputElementId(e.target.value)
                          }
                          type="text"
                          placeholder="email_address"
                          className="input-colors border px-2 border-color5 py-2 w-full rounded-sm text-black"
                        />
                      </div>{" "}
                      <div className="flex items-center text-color10">
                        <TiTick />
                        <span className="text-sm">This field is required</span>
                      </div>
                    </div>
                  </>
                ) : buttonSetting === "style" ? (
                  <div className="flex flex-col gap-0">
                    <div className="flex mt-5 flex-col gap-2">
                      <label className="text-color2 text-sm font-semibold">
                        Button style
                      </label>
                      <select
                        className="border  border-color3 py-2 rounded-sm"
                        value={style.btnStyle}
                        onChange={(e) =>
                          handleStyleChange("btnStyle", e.target.value)
                        }
                      >
                        <option value="Filled" key="Filled">
                          Filled
                        </option>
                        <option value="Outlined" key="Outlined">
                          Outlined
                        </option>
                        <option value="Text Only" key="TextOnly">
                          Text Only
                        </option>
                      </select>
                    </div>
                    <div className="flex mt-5 flex-col gap-2">
                      <label className="text-color2 text-sm font-semibold">
                        Button Width
                      </label>
                      <select
                        className="border  border-color3 py-2 rounded-sm"
                        value={style.btnWidth}
                        onChange={(e) =>
                          handleStyleChange("btnWidth", e.target.value)
                        }
                      >
                        <option value="Stretch" key="Stretch">
                          Stretch
                        </option>
                        <option value="Auto/fit to text" key="AutoFitToText">
                          Auto/fit to text
                        </option>
                      </select>
                    </div>
                    <div className="flex mt-5 flex-col gap-2">
                      <label className="text-color2 text-sm font-semibold">
                        Corner Style
                      </label>
                      <select
                        className="border  border-color3 py-2 rounded-sm"
                        value={style.cornerStyle}
                        onChange={(e) =>
                          handleStyleChange("cornerStyle", e.target.value)
                        }
                      >
                        <option value="Round" key="Round">
                          Round
                        </option>
                        <option value="Dubbed" key="Dubbed">
                          Dubbed
                        </option>
                        <option value="Sharp" key="Sharp">
                          Sharp
                        </option>
                      </select>
                    </div>
                  </div>
                ) : buttonSetting === "setting" ? (
                  <div className="flex flex-col gap-2">
                    <div className="flex mt-5 flex-col gap-2">
                      <label className="text-color2 text-sm font-semibold">
                        Input type
                      </label>
                      <select
                        onChange={(e) => setTextInputType(e.target.value)}
                        className="border  border-color3 py-2 rounded-sm"
                      >
                        <option value="text" key="">
                          Text
                        </option>
                        <option value="password" key="">
                          Password
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-color2 text-sm  font-semibold">
                        Placeholder Text
                      </label>
                      <input
                        onChange={(e) =>
                          setTextInputPlaceHolder(e.target.value)
                        }
                        type="text"
                        placeholder="email address"
                        className="input-colors border px-2 border-color5 py-2 w-full rounded-sm text-black"
                      />
                    </div>{" "}
                  </div>
                ) : (
                  <div className="flex flex-col ">
                    <div className="flex flex-col gap-1 relative ">
                      <label className="text-color7 text-xs ">Text color</label>
                      <input
                        value={inputTextClr}
                        type="text"
                        className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                      />
                      <div
                        style={{ backgroundColor: inputTextClr }}
                        onClick={() => setOpen2(true)}
                        className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                      ></div>
                    </div>
                    <div className="flex flex-col gap-1 relative ">
                      <label className="text-color7 text-xs ">
                        Background color
                      </label>
                      <input
                        value={inPutBgClr}
                        type="text"
                        className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                      />
                      <div
                        style={{ backgroundColor: inPutBgClr }}
                        onClick={() => setOpen3(true)}
                        className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                      ></div>
                    </div>
                  </div>
                )}
              </>
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <button
                onClick={handleSave}
                className="text-white bg-color10 font-semibold  px-4 py-1 rounded-md"
              >
                Save
              </button>{" "}
              <button
                onClick={() => setPopup(false)}
                className="text-color12 font-semibold  px-2 py-1 rounded-sm"
              >
                Cancel
              </button>
            </div>
            <PopUpColorModal
              open2={open2}
              open3={open3}
              color={open2 ? inputTextClr : inPutBgClr}
              handleChange={open2 ? handleTxtClrChange : handleBgClrChange}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InputFieldPupUp;
