import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <button
      className="fixed bottom-4 right-4 bg-[#1e1e1e] hover:bg-[#ff2828] text-white font-semibold py-2 px-4 rounded shadow flex items-center z-[9999]"
      onClick={handleGoBack}
    >
      <ArrowBackIcon className="text-white mr-2" />
      Back
    </button>
  );
};

export default BackButton;
