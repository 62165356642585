import mortgageRates from './ratesComponent';

export default function mortgageRatesPlugin({ editor }) {
  editor.Components.addType('mortgage-rates', {
    model: {
      defaults: {
        script: mortgageRates,
      },
    },
  });

  editor.Blocks.add('mortgage-widget-rates', {
    label: 'Mortgage Rates',
    attributes: { class: 'fa fa-building' },
    content: { type: 'mortgage-rates' },
  });
}
