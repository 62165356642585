import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Modal from "@mui/material/Modal";
import { MyConstextApi } from "../../../../context/ContextApi";
import useContext from "../../../../context/StateProvider";

import { toast } from "react-toastify";
import facepopService from "../../../../services/facepop.service";
import { IconArrowRight } from "../icons/RightArrow";
import PopUpColorModal from "./PopUpColorModal";

const ConnectFacePopUpModal = ({ open, handleActionpopUp, setPopup }) => {
  const [{ user }] = useContext();

  const params = useParams();

  const { updateFunction, fetchConnectCta } = MyConstextApi();

  const [state, setState] = useState("facepopup");
  const [buttonSetting, setButtonSetting] = useState("general");
  const [iconIndex, setIconIndex] = useState();
  const [open2, setOpen2] = useState(false);
  // const [bgColor2, setBgColor2] = useState("#FF0000");

  const [open3, setOpen3] = useState(false);
  // const [bgColor3, setBgColor3] = useState("#FF0000");

  const [facepopList, setFacepopList] = useState([]);
  const [yourFacepop, setYourFacepop] = useState("");

  const [general, setGeneral] = useState({
    btnText: "",
    btnHeight: "10px",
  });
  const [style, setStyle] = useState({
    btnStyle: "Filled",
    btnWidth: "Stretch",
    cornerStyle: "Round",
  });

  const [icon, setIcon] = useState("");
  const [connectTextClr, setConnectTextClr] = useState("#000000");
  const [connectBgClr, setConnectBgClr] = useState("#FFFFFF");
  // const [colors, setColors] = useState({
  //   textColor: bgColor2,
  //   bgColor: bgColor3,
  // });

  const handleClose = () => {
    setOpen2(false);
    setOpen3(false);
  };

  const facepopHandleSelect = (e) => {
    const selectedValue = e.target.value;
    console.log("Selected facepopId:", selectedValue);
    setYourFacepop(selectedValue);
  };

  const handleGeneralChange = (field, value) => {
    setGeneral((prevGeneral) => ({
      ...prevGeneral,
      [field]: value,
    }));
  };

  const handleStyleChange = (field, value) => {
    setStyle((prevStyle) => ({
      ...prevStyle,
      [field]: value,
    }));
  };

  const handleTxtClrChange = (newColor) => {
    setConnectTextClr(newColor);
  };

  const handleBgClrChange = (newColor) => {
    setConnectBgClr(newColor);
  };

  useEffect(() => {
    const fetchData = async () => {
      const profileId = params.profileId;
      try {
        const result = await facepopService.getAllFunnels(
          profileId,
          user.token,
        );

        if (result.success) {
          setFacepopList(result.data);
          console.log("Facepop list:", result.data);
          setConnectTextClr("#000000");
          setConnectBgClr("#FFFFFF");
        } else {
          console.error(`Error fetching Facepop list: ${result.status}`);
        }
      } catch (error) {
        console.error("Error fetching Facepop list:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    const pluginId = params.pluginId;
    console.log("pluginId: ", pluginId);

    const colors = {
      textColor: connectTextClr,
      bgColor: connectBgClr,
    };

    try {
      const saveConnectCTA = await facepopService.connectCtaBtn(
        pluginId,
        user.token,
        yourFacepop,
        general,
        style,
        icon,
        colors,
      );

      if (saveConnectCTA.success) {
        toast.success("Button Save Successfully");
        fetchConnectCta(params.pluginId, user.token);
        console.log("ConnectCTA created successfully:", saveConnectCTA.data);
      } else {
        toast.error(saveConnectCTA.error || "Error creating ConnectCTA");
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error("Internal Server Error");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleActionpopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <div className="min-h-screen flex items-center">
          <div
            style={{ width: "420px" }}
            className="   h-fit border mx-auto  rounded-md  bg-white sm:px-7 px-2 py-5"
          >
            <h1 className="text-color11 font-bold text-2xl mb-4">
              Connect to a FacePop
            </h1>
            <div className="mt-2">
              <ul className="flex sm:gap-6 gap-4 text-sm  font-semibold text-color7">
                <li
                  onClick={() => setState("facepopup")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "facepopup"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Select FacePop
                </li>
                <li
                  onClick={() => setState("btnsetting")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "btnsetting"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Button Setting
                </li>
              </ul>
            </div>

            {state === "btnsetting" && (
              <div className="mt-4">
                <ul className="flex sm:gap-8 gap-3  text-xs font-semibold  text-color7">
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "general"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("general")}
                  >
                    General
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "style"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("style")}
                  >
                    Style
                  </li>

                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "icon"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("icon")}
                  >
                    Icon
                  </li>
                  <li
                    className={`border-t-0 cursor-pointer border-r-0 border-l-0 ${
                      buttonSetting === "color"
                        ? "border-2 border-gray-600 text-gray-500"
                        : ""
                    }`}
                    onClick={() => setButtonSetting("color")}
                  >
                    Colors
                  </li>
                </ul>
              </div>
            )}

            <div className=" mt-7">
              {state === "facepopup" ? (
                <div className="flex flex-col gap-5">
                  <div className="flex mt-5 flex-col gap-2">
                    <label className="text-color2 text-sm font-semibold">
                      Your FacePops
                    </label>
                    <select
                      className="border  border-color3 py-2 rounded-sm"
                      value={yourFacepop}
                      onChange={facepopHandleSelect}
                    >
                      {facepopList.map((facepop) => (
                        <option value={facepop._id} key={facepop._id}>
                          {facepop.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <>
                  {buttonSetting === "general" ? (
                    <>
                      {" "}
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Text
                          </label>
                          <input
                            type="text"
                            placeholder="Button Text"
                            value={general.btnText}
                            onChange={(e) =>
                              handleGeneralChange("btnText", e.target.value)
                            }
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>{" "}
                        <div className="flex flex-col gap-2">
                          <label className="text-color2  font-semibold">
                            Button Height
                          </label>
                          <input
                            type="number"
                            placeholder="10px"
                            value={general.btnHeight}
                            onChange={(e) =>
                              handleGeneralChange("btnHeight", e.target.value)
                            }
                            className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                          />
                        </div>
                      </div>
                    </>
                  ) : buttonSetting === "style" ? (
                    <div className="flex flex-col gap-0">
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button style
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.btnStyle}
                          onChange={(e) =>
                            handleStyleChange("btnStyle", e.target.value)
                          }
                        >
                          <option value="Filled" key="Filled">
                            Filled
                          </option>
                          <option value="Outlined" key="Outlined">
                            Outlined
                          </option>
                          <option value="Text Only" key="TextOnly">
                            Text Only
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Button Width
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.btnWidth}
                          onChange={(e) =>
                            handleStyleChange("btnWidth", e.target.value)
                          }
                        >
                          <option value="Stretch" key="Stretch">
                            Stretch
                          </option>
                          <option value="Auto/fit to text" key="AutoFitToText">
                            Auto/fit to text
                          </option>
                        </select>
                      </div>
                      <div className="flex mt-5 flex-col gap-2">
                        <label className="text-color2 text-sm font-semibold">
                          Corner Style
                        </label>
                        <select
                          className="border  border-color3 py-2 rounded-sm"
                          value={style.cornerStyle}
                          onChange={(e) =>
                            handleStyleChange("cornerStyle", e.target.value)
                          }
                        >
                          <option value="Round" key="Round">
                            Round
                          </option>
                          <option value="Dubbed" key="Dubbed">
                            Dubbed
                          </option>
                          <option value="Sharp" key="Sharp">
                            Sharp
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : buttonSetting === "icon" ? (
                    <div className="flex flex-wrap gap-4">
                      {IconArrowRight.map((item, i) => (
                        <span
                          className={`cursor-pointer ${
                            iconIndex === i
                              ? "bg-color10 px-2 rounded-sm text-white "
                              : ""
                          }`}
                          onClick={() => {
                            console.log(item);
                            return (
                              <>
                                <>{setIconIndex(i)}</>
                              </>
                            );
                          }}
                          key={i}
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col ">
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Text color
                        </label>
                        <input
                          value={connectTextClr}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: connectTextClr }}
                          onClick={() => setOpen2(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                      <div className="flex flex-col gap-1 relative ">
                        <label className="text-color7 text-xs ">
                          Background color
                        </label>
                        <input
                          value={connectBgClr}
                          type="text"
                          className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                        />
                        <div
                          style={{ backgroundColor: connectBgClr }}
                          onClick={() => setOpen3(true)}
                          className="w-10 h-10  absolute cursor-pointer right-0  bottom-0"
                        ></div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <button
                className="text-white bg-color10 font-semibold  px-4 py-1 rounded-md"
                onClick={handleSave}
              >
                Save
              </button>{" "}
              <button
                onClick={() => setPopup(false)}
                className="text-color12 font-semibold  px-2 py-1 rounded-sm"
              >
                Cancel
              </button>
            </div>
            <PopUpColorModal
              open2={open2}
              open3={open3}
              color={open2 ? connectTextClr : connectBgClr}
              handleChange={open2 ? handleTxtClrChange : handleBgClrChange}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConnectFacePopUpModal;
