import React, { useState } from "react";
import { GiBreakingChain } from "react-icons/gi";
import { toast } from "react-toastify";
import { MyConstextApi } from "../../../../context/ContextApi";
import facepopService from "../../../../services/facepop.service";
import CalenderUpdate from "../CallToAction/updates/CalenderUpdate";
import CloseCtaUpdate from "../CallToAction/updates/CloseCtaUpdate";
import FullRedirectUpdate from "../CallToAction/updates/FullRedirectUpdate";
import InputCtaUpdate from "../CallToAction/updates/InputCtaUpdate";
import RedirectUrlUpdate from "../CallToAction/updates/RedirectUrlUpdate";
import RunCodeUpdate from "../CallToAction/updates/RunCodeUpdate";
import CTAButtons from "./CTAButtons";

const CtaBuilder = ({
  user,
  pluginId,
  setPopup,
  value,
  value2,
  onCtaShow,
  afterClick,
  onAfterClick,
}) => {
  const {
    // connectCta,
    // fetchConnectCta,
    redirectCta,
    fetchRedirectCta,
    redirectFullCta,
    fetchRedirectFullCta,
    // videoRec,
    // fetchVideoRecCta,
    calbook,
    fetchCalBookCta,
    // chatAi,
    // fetchChatAiCta,
    runCode,
    fetchRunCodeCta,
    inputText,
    fetchInputTextCta,
    closeCta,
    fetchCloseCta,
  } = MyConstextApi();

  // const [openConnect, setOpenConnect] = useState(false);
  // const [openVideoRec, setOpenVideoRec] = useState(false);
  // const [openChatAi, setOpenChatAi] = useState(false);
  const [openRedirect, setOpenRedirect] = useState(false);
  const [openRedirectFull, setOpenRedirectFull] = useState(false);
  const [openCalBook, setOpenCalBook] = useState(false);
  const [openRunCode, setOpenRunCode] = useState(false);
  const [openInputCta, setOpenInputCta] = useState(false);
  const [openCloseCta, setOpenCloseCta] = useState(false);
  // const handleOpen = () => {
  //   setOpenConnect(true);
  // };
  // const handleOpenVideoRec = () => {
  //   setOpenVideoRec(true);
  // };
  // const handleOpenChatAi = () => {
  //   setOpenChatAi(true);
  // };

  // const handleConnectAc = () => {
  //   setOpenConnect(false);
  // };
  // const handleVideoRecAc = () => {
  //   setOpenVideoRec(false);
  // };
  // const handleChatAiAc = () => {
  //   setOpenChatAi(false);
  // };

  const handleOpenRedirect = () => {
    setOpenRedirect(true);
  };

  const handleOpenRedirectFull = () => {
    setOpenRedirectFull(true);
  };

  const handleopenCalBook = () => {
    setOpenCalBook(true);
  };

  const handleOpenRunCode = () => {
    setOpenRunCode(true);
  };
  const handleOpenInputCta = () => {
    setOpenInputCta(true);
  };
  const handleOpenCloseCta = () => {
    setOpenCloseCta(true);
  };

  const handleRedirectAc = () => {
    setOpenRedirect(false);
  };

  const handleRedirectFullAc = () => {
    setOpenRedirectFull(false);
  };

  const handleCalBookAc = () => {
    setOpenCalBook(false);
  };

  const handleRunCodeAc = () => {
    setOpenRunCode(false);
  };

  const handleInputCtaAc = () => {
    setOpenInputCta(false);
  };

  const handleCloseCtaAc = () => {
    setOpenCloseCta(false);
  };

  // const connectCtaDelete = async (id) => {
  //   try {
  //     const res = await facepopService.delConnectCTA(id, user.token);
  //     if (res.success) {
  //       toast.success("Button Deleted Successfully!");
  //       fetchConnectCta(pluginId, user.token);
  //     } else {
  //       toast.error("Something went wrong!");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.message);
  //   }
  // };

  const redirectDel = async (id) => {
    try {
      const res = await facepopService.delRedirectCTA(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchRedirectCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const redirectFullDel = async (id) => {
    try {
      const res = await facepopService.delRedirectFullCTA(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchRedirectFullCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // const videoRecDel = async (id) => {
  //   try {
  //     const res = await facepopService.delVideoRecCTA(id, user.token);
  //     if (res.success) {
  //       toast.success("Button Deleted Successfully!");
  //       fetchVideoRecCta(pluginId, user.token);
  //     } else {
  //       toast.error("Something went wrong!");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.message);
  //   }
  // };

  const calBookDel = async (id) => {
    try {
      const res = await facepopService.delCalenderBook(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchCalBookCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // const chatAiDel = async (id) => {
  //   try {
  //     const res = await facepopService.delChatAiCTA(id, user.token);
  //     if (res.success) {
  //       toast.success("Button Deleted Successfully!");
  //       fetchChatAiCta(pluginId, user.token);
  //     } else {
  //       toast.error("Something went wrong!");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.message);
  //   }
  // };

  const runCodeDel = async (id) => {
    try {
      const res = await facepopService.delRunCode(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchRunCodeCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const inputFieldDel = async (id) => {
    try {
      const res = await facepopService.delInputTextCta(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchInputTextCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const closeCtaDel = async (id) => {
    try {
      const res = await facepopService.delCloseCTA(id, user.token);
      if (res.success) {
        toast.success("Button Deleted Successfully!");
        fetchCloseCta(pluginId, user.token);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  console.log("closeCta: ", closeCta);

  return (
    <>
      <div>
        <h1 className="text-xl text-color2 font-semibold ">Call to Action</h1>
        <span className="text-xs text-color5 mt-1">CTA Builder</span>
      </div>
      {/* <div className="flex items-center gap-1 mt-3">
        <div className="w-10 h-10 rounded-xl bg-color6 flex items-center justify-center">
          <GiBreakingChain className="text-white" />
        </div>
        <div className="">
          <h3 className="text-color2 font-semibold">Redirect to URL</h3>
          <span className="text-color2 text-xs">
            Direct users to specified URL on click
          </span>
        </div>
      </div> */}
      {/* {connectCta &&
        connectCta.map((cta) => (
          <div>
            <CTAButtons
              id={cta._id}
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Connect to a Facepop"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpen}
              handleDel={() => connectCtaDelete(cta._id)}
            />
            <ConnectFpUpdate
              id={cta._id}
              open={openConnect}
              handleActionpopUp={handleConnectAc}
              setPopup={setOpenConnect}
            />
          </div>
        ))} */}
      {redirectCta &&
        redirectCta.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Redirect to URL"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenRedirect}
              handleDel={() => redirectDel(cta._id)}
            />
            <RedirectUrlUpdate
              id={cta._id}
              open={openRedirect}
              handleActionpopUp={handleRedirectAc}
              setPopup={setOpenRedirect}
            />
          </div>
        ))}
      {redirectFullCta &&
        redirectFullCta.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Redirect to URL (Full Screen)"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenRedirectFull}
              handleDel={() => redirectFullDel(cta._id)}
            />
            <FullRedirectUpdate
              id={cta._id}
              open={openRedirectFull}
              handleActionpopUp={handleRedirectFullAc}
              setPopup={setOpenRedirectFull}
            />
          </div>
        ))}
      {/* {videoRec &&
        videoRec.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Video Response"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenVideoRec}
              handleDel={() => videoRecDel(cta._id)}
            />
            <VideoRecUpdate
              id={cta._id}
              open={openVideoRec}
              handleActionpopUp={handleVideoRecAc}
              setPopup={setOpenVideoRec}
            />
          </div>
        ))} */}
      {calbook &&
        calbook.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Calendar Booking"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleopenCalBook}
              handleDel={() => calBookDel(cta._id)}
            />
            <CalenderUpdate
              id={cta._id}
              open={openCalBook}
              handleActionpopUp={handleCalBookAc}
              setPopup={setOpenCalBook}
            />
          </div>
        ))}
      {/* {chatAi &&
        chatAi.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Chat Ai"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenChatAi}
              handleDel={() => chatAiDel(cta._id)}
            />
            <AiChatUpdate
              id={cta._id}
              open={openChatAi}
              handleActionpopUp={handleChatAiAc}
              setPopup={setOpenChatAi}
            />
          </div>
        ))} */}
      {runCode &&
        runCode.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Run Code"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenRunCode}
              handleDel={() => runCodeDel(cta._id)}
            />
            <RunCodeUpdate
              id={cta._id}
              open={openRunCode}
              handleActionpopUp={handleRunCodeAc}
              setPopup={setOpenRunCode}
            />
          </div>
        ))}
      {inputText &&
        inputText.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Input Text"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenInputCta}
              handleDel={() => inputFieldDel(cta._id)}
            />
            <InputCtaUpdate
              id={cta._id}
              open={openInputCta}
              handleActionpopUp={handleInputCtaAc}
              setPopup={setOpenInputCta}
            />
          </div>
        ))}
      {closeCta &&
        closeCta.map((cta) => (
          <div>
            <CTAButtons
              reactIcon={<GiBreakingChain className="text-white" />}
              title={"Close FacePop"}
              desc={" Direct users to specified URL on click"}
              handleOpen={handleOpenCloseCta}
              handleDel={() => closeCtaDel(cta._id)}
            />
            <CloseCtaUpdate
              id={cta._id}
              open={openCloseCta}
              handleActionpopUp={handleCloseCtaAc}
              setPopup={setOpenCloseCta}
            />
          </div>
        ))}
      {/* <CTAButtons
        reactIcon={<GiBreakingChain className="text-white" />}
        title={"Redirect to URL"}
        desc={" Direct users to specified URL on click"}
      /> */}
      <div className="mt-5">
        <button
          onClick={() => setPopup(true)}
          className="text-center py-2 rounded-sm text-color7 font-semibold bg-gray-300 w-full"
        >
          Add CTA
        </button>
      </div>
      <div className="flex mt-5 flex-col gap-2">
        <label className="text-color2 text-sm font-semibold">
          When to show the CTAs?
        </label>
        <select
          value={value}
          className="border font-semibold border-color3 py-2 rounded-sm"
          onChange={(e) => onCtaShow(e.target.value)}
        >
          <option value="Always show" key="">
            Always show
          </option>
          <option value="After Video Begin" key="">
            After Video Begin
          </option>
          <option value="After Video End" key="">
            After Video End
          </option>
          <option value="Before Video End" key="">
            Before Video End
          </option>
          <option value="Never Show" key="">
            Never Show
          </option>
        </select>
      </div>{" "}
      <div className="flex mt-5 flex-col gap-2">
        <label className="text-color2 text-sm font-semibold">
          After clicking the CTA
        </label>
        <select
          value={afterClick ? "don't hide" : "hide"}
          className="border font-semibold  border-color3 py-2 rounded-sm"
          onChange={(e) => onAfterClick(e.target.value)}
        >
          <option value="don't hide" key="">
            Don't hide Widget
          </option>
          <option value="hide" key="">
            hide Widget
          </option>
        </select>
      </div>
    </>
  );
};

export default CtaBuilder;
