import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { toast } from 'react-toastify';
import { GrClose } from 'react-icons/gr';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// components
import CustomStep from './Steps/CustomStep';
import StepOne from './Steps/One';
import StepSixteen from './Steps/Sixteen';
import StepSeventeen from './Steps/Seventeen';
import StepEighteen from './Steps/Eighteen';
import Thankyou from './Steps/Thankyou';
import ProgressBar from './../common/ProgressBar';
import PrevButton from './../common/PrevButton';

import Footer from './FunnelFooter';

import customFunnelServices from './../../services/customFunnel.service';
import FunnelHeader from './FunnelHeader';

function Widget({ stepsData, pluginTitle, pluginToken, varbiage }) {
  const [swiper, setSwiper] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    zipCode: '',
    fullName: '',
    email: '',
    phone: '',
    selectedOptions: [],
  });

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [geoData, setGeoData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const ipResponse = await customFunnelServices.getUserIPAddress();
      if (ipResponse) {
        setIpAddress(ipResponse);
        const geoLocation = await customFunnelServices.getUserGeoLocation(
          ipResponse
        );
        if (geoLocation) {
          setGeoData(geoLocation);
        }
      } else {
        setIpAddress('no IP');
      }
    };

    if (currentStep === 1 && !uniqueId) {
      const uuid = new Date().getTime();
      setUniqueId(uuid);
      fetchData();
    }

    console.log('plugin token', pluginToken, 'And: ', { pluginToken });
  }, [currentStep, uniqueId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name !== 'selectedOptions') {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    } else {
      const stepIndex = Number(e.target.value);

      setFormValues((prev) => {
        const updatedOptions = [...prev.selectedOptions];
        if (updatedOptions[stepIndex]) {
          updatedOptions[stepIndex] = value;
        } else {
          updatedOptions.push(value);
        }

        return { ...prev, selectedOptions: updatedOptions };
      });
    }

    console.log('formValues:', formValues);
  };

  const handleNext = () => {
    if (currentStep === 0 && !formValues.zipCode) return;

    setCurrentStep((prev) => prev + 1);
    console.log('formValues 2 :', formValues);

    swiper.slideNext();
  };

  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
    swiper.slidePrev();
  };

  const handleFinish = async () => {
    setLoading(true);

    try {
      console.log('formValues handleFinish :', formValues);
      const response = await customFunnelServices.leadInputData(
        pluginToken,
        uniqueId,
        ipAddress,
        geoData,
        formValues
      );

      if (response.success) {
        setLoading(false);
        toast.success('Successfully done!', { className: 'z-toast' });
        console.log('finish submit');
        handleNext();
        setFormValues({
          zipCode: '',
          fullName: '',
          email: '',
          phone: '',
          selectedOptions: [],
        });
        setUniqueId('');
        setIpAddress('');
      } else {
        setLoading(false);
        toast.error(response.status, {
          className: 'z-toast',
        });
      }
    } catch (err) {
      setLoading(false);

      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, { className: 'z-toast' });
    }
  };

  if (currentStep === stepsData.length + 4) {
    return (
      <div className="mtge-rates_modal-native-el">
        <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl">
          <Thankyou />
        </div>
      </div>
    );
  }

  return (
    <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl shadow-2xl">
      <FunnelHeader />
      <ProgressBar currentStep={currentStep} totalStep={stepsData.length + 4} />
      <main className="min-h-[350px] mt-4 overflow-hidden">
        <Swiper
          cssMode={true}
          allowTouchMove={false}
          navigation={{
            nextEl: '.swiper-button-custom-next',
            prevEl: '.swiper-button-custom-prev',
          }}
          modules={[Navigation]}
          className="mySwiper"
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
          <SwiperSlide>
            {' '}
            <StepOne
              pluginTitle={pluginTitle}
              value={formValues.zipCode}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>

          {/* dynamic steps from user */}
          {stepsData &&
            stepsData.map((step, index) => (
              <SwiperSlide key={index}>
                <CustomStep
                  data={step}
                  value={formValues.selectedOptions[index]}
                  onChange={handleChange}
                  handleNext={handleNext}
                />
              </SwiperSlide>
            ))}

          <SwiperSlide>
            <StepSixteen
              value={formValues.fullName}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepSeventeen
              value={formValues.email}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepEighteen
              value={formValues.phone}
              onChange={handleChange}
              handleFinish={handleFinish}
              loading={loading}
            />
          </SwiperSlide>
        </Swiper>

        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      <Footer varbiage={varbiage} />
    </div>
  );
}

export default Widget;
