import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';
import useContext from '../../context/StateProvider';

import ModalWrapper from '../Modal';
import Loader from '../Loader/Loader';

function AllLeads() {
  const [{ user }] = useContext();

  const [funnels, setFunnels] = useState([
    {
      name: 'Calculator',
      uri: 'calculators',
    },
  ]);

  useEffect(() => {
    if (user.accountType === 'Free' || user.accountType === 'Pro') {
      setFunnels([
        {
          name: 'Calculator',
          uri: 'calculators',
        },
        {
          name: 'Mortgage Rates',
          uri: 'mortgage-rates',
        },
        {
          name: 'View Properties',
          uri: 'viewProperties',
        },
        {
          name: 'Check Home Values',
          uri: 'checkHomeValues',
        },
      ]);
    }
  }, [user]);

  return (
    <div className="w-full md:max-w-2xl mx-auto py-8 px-6 md:px-12 bg-white">
      <h1 className="text-center text-3xl text-[#39393bde] font-bold">
        {' '}
        Choose a funnel{' '}
      </h1>
      <div className="mt-8">
        <div className="mt-4 grid grid-cols-1 itmes-center space-y-6">
          {funnels.map((dt) => (
            <Card key={dt._id} data={dt} />
          ))}
        </div>
      </div>
    </div>
  );
}

const Card = ({ data }) => {
  const params = useParams();
  return (
    <>
      <Link to={`/dashboard/${params.profileId}/leads/${data.uri}`}>
        <div className="text-center p-6 rounded-lg border-2 shadow-lg">
          <h3> {data.name} </h3>
        </div>
      </Link>
    </>
  );
};

export default AllLeads;
