import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import gjsCustomCode from 'grapesjs-custom-code';
import gjsTap from 'grapesjs-tabs';
import gjsBasicBlocks from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import gjsPluginExport from 'grapesjs-plugin-export';
import gjsTouch from 'grapesjs-touch';
import gjsParserCss from 'grapesjs-parser-postcss';
import gjsTooltip from 'grapesjs-tooltip';
import gjsImageEditor from 'grapesjs-tui-image-editor';
import gjsTyped from 'grapesjs-typed';
import gjsStyleBg from 'grapesjs-style-bg';
// import grapesScript from 'grapesjs-script-editor';
import config from '../../utils/config';

import ActionTypes from '../../utils/actionTypes';

import useContext from '../../context/StateProvider';
import axios from 'axios';

import calculatorBlock from './plugins/calculatorLeads';
import mortgageRatesBlock from './plugins/mortgageRates';
import flexiFrameBlock from './plugins/flexiFramePlugin';
import viewPropertyBlock from './plugins/viewPropertyPlugin';
import { toast } from 'react-toastify';

function EditorGs({ pageId, userId = null, storageUrl, handleSubmit }) {
  const [editor, setEditor] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const editor = grapesjs.init({
      container: '#editor',
      parser: {
        optionsHtml: { allowScripts: true },
      },
      storageManager: {
        type: 'remote',
        id: 'mycustom-',
        options: {
          remote: {
            id: 'mycustom-',
            urlLoad: storageUrl,
            urlStore: storageUrl,
            fetchOptions: (opts) => {
              if (opts.body) {
                const contentWithAssets = JSON.parse(opts.body);
                opts.body = JSON.stringify(contentWithAssets);
              }
              return opts.method === 'POST' ? { method: 'PUT' } : {};
            },
            onStore: (data, editor) => {
              const pagesHtml = editor.Pages.getAll().map((page) => {
                const component = page.getMainComponent();
                return {
                  html: editor.getHtml({ component }),
                  css: editor.getCss({ component }),
                };
              });
              return { id: pageId, data, pagesHtml };
            },
            onLoad: (result) => result.data,
          },
        },
      },

      blockManager: {
        blocks: [],
      },

      // Avoid any default panel
      panels: { defaults: [] },
      plugins: [
        gjsPresetWebpage,
        gjsCustomCode,
        gjsTap,
        gjsBasicBlocks,
        gjsForms,
        gjsPluginExport,
        gjsTouch,
        gjsParserCss,
        gjsTooltip,
        gjsImageEditor,
        gjsTyped,
        gjsStyleBg,
        // grapesScript,
        calculatorBlock,
        mortgageRatesBlock,
        flexiFrameBlock,
        viewPropertyBlock,
      ],
      pluginsOpts: {
        gjsPresetWebpage: {},
      },
    });
    setEditor(editor);

    // Add a custom button to the panel toolbar
    const panelManager = editor.Panels;
    panelManager.addButton('options', {
      id: 'save-db',
      className: 'fa fa-save',
      command: 'save-db',
      attributes: { title: 'Save Page' },
    });

    // Register the save command
    editor.Commands.add('save-db', {
      run: (editor, sender) => {
        sender && sender.set('active');
        editor.store();
      },
    });

    panelManager.addButton('options', {
      id: 'go-back',
      className: 'fa fa-arrow-left',
      command: 'go-back',
      attributes: { title: 'Go Back' },
    });

    // Register the go-back command
    editor.Commands.add('go-back', {
      run: () => {
        navigate(-1); // Navigate back to the dashboard
      },
    });

    // Add a custom button to the panel toolbar
    panelManager.addButton('options', {
      id: 'save-publish',
      className: 'save-publish-button',
      command: 'save-publish',
      label: 'Save & Publish',
    });

    editor.Commands.add('save-publish', {
      run: (editor, sender) => {
        sender && sender.set('active');
        const html = editor.getHtml();
        const css = editor.getCss();
        handleSubmit(html, css);
      },
    });

    return () => {
      // Clean up the editor instance if necessary
      editor.destroy();
    };
  }, [userId, storageUrl, handleSubmit, pageId]);

  return (
    <div>
      <div id="editor"></div>
    </div>
  );
}

export default EditorGs;
