import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import { BsRobot } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { GiBreakingChain } from "react-icons/gi";
import { LuFocus } from "react-icons/lu";

import { AiOutlineClose } from "react-icons/ai";
import AiChatPop from "./AiChatPop";
import CalanderPupUp from "./CalanderPupUp";
import CloseFacePopUp from "./CloseFacePopUp";
import CollectToEmailPopUp from "./CollectToEmailPopUp";
import ConnectFacePopUpModal from "./ConnectFacePopUpModal";
import FullScreenRedirectPopUp from "./FullScreenRedirectPopUp";
import InputFieldPupUp from "./InputFieldPupUp";
import RedirectUrlPopUp from "./RedirectUrlPopUp";
import RunCodePopUp from "./RunCodePopUp";
import VideoPopUp from "./VideoPopUp";
const CallToActionPopUp = ({ open, handleActionpopUp, setPopup }) => {
  const [state, setState] = useState("funnel");

  const [openFacePopup, setOpenFacePopup] = useState(false);
  const [openRedirectUrl, setRedirectUrl] = useState(false);
  const [openFullScreenRedirectUrl, setFullScreenRedirectUrl] = useState(false);
  const [videoPopUp, setVideopopUp] = useState(false);
  const [calenderpopup, setCalenderPopup] = useState(false);
  const [collectToEmail, setCollectToEmail] = useState(false);
  const [inputField, setInputField] = useState(false);
  const [aichatPopup, setAiChatpopUp] = useState(false);
  const [runCodePupup, setRunCodepopUp] = useState(false);
  const [closeFacepopup, setCloseFacepopup] = useState(false);

  const handleFacepopUpAction = () => {
    setOpenFacePopup(false);
  };
  const handleRedirectUrl = () => {
    setRedirectUrl(false);
  };
  const handleFullScreenRedirectUrl = () => {
    setFullScreenRedirectUrl(false);
  };
  const handleVideoPopUp = () => {
    setVideopopUp(false);
  };
  const handleCalnderPopUp = () => {
    setCalenderPopup(false);
  };

  const handleCollectToEmailPopUp = () => {
    setCollectToEmail(false);
  };
  const handleInputPopUp = () => {
    setInputField(false);
  };
  const handleChatpopup = () => {
    setAiChatpopUp(false);
  };
  const handleRunCodepopup = () => {
    setRunCodepopUp(false);
  };
  const handleCloseFacepopup = () => {
    setCloseFacepopup(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleActionpopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <div className="min-h-screen flex items-center">
          <div
            style={{ width: "490px" }}
            className="   h-fit border mx-auto  rounded-md  bg-white sm:px-7 px-2 py-5"
          >
            <h1 className="text-color11 font-bold text-2xl mb-4 ">Add a CTA</h1>
            <div className="mt-2">
              <ul className="flex sm:gap-6 gap-4 text-sm  font-semibold text-color7">
                <li
                  onClick={() => setState("funnel")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "funnel"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Funneling
                </li>
                {/* <li
                  onClick={() => setState("visitor")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "visitor"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Visitor Feedback
                </li> */}
                <li
                  onClick={() => setState("conversation")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "conversation"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Conversation
                </li>
                <li
                  onClick={() => setState("others")}
                  className={`cursor-pointer   border-t-0 border-r-0 border-l-0 ${
                    state === "others"
                      ? "border-2 border-color10 text-color10"
                      : ""
                  }`}
                >
                  Others
                </li>
              </ul>
            </div>

            <div className=" mt-7">
              {state === "funnel" ? (
                <div className="flex flex-col gap-5">
                  {/* <div
                    onClick={() => setOpenFacePopup(true)}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="w-10 h-10 rounded-md  bg-color12 flex justify-center items-center">
                      <GiBreakingChain className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Connect to a FacePop
                      </h3>
                      <span className="text-color7 text-xs ">
                        Load a video from another FacePop from your account
                      </span>
                    </div>
                  </div> */}
                  <div
                    onClick={() => setRedirectUrl(true)}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="w-10 h-10 cursor-pointer rounded-md bg-color6 flex justify-center items-center">
                      {" "}
                      <GiBreakingChain className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Redirect to URLs
                      </h3>
                      <span className="text-color7 text-xs ">
                        Redirect users to a specified URL on click
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => setFullScreenRedirectUrl(true)}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="w-10 h-10 cursor-pointer rounded-md bg-color13 flex justify-center items-center">
                      {" "}
                      <LuFocus className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Redirect to URL (in Full Screen)
                      </h3>
                      <span className="text-color7 text-xs ">
                        Display an embedded webpage within FacePop
                      </span>
                    </div>
                  </div>
                </div>
              ) : // state === "visitor" ? (
              //   <div
              //     onClick={() => setVideopopUp(true)}
              //     className="flex cursor-pointer items-center gap-2"
              //   >
              //     <div className="w-10 h-10 cursor-pointer rounded-md bg-color6 flex justify-center items-center">
              //       {" "}
              //       <TbPlayerPlayFilled className="text-white" />
              //     </div>

              //     <div>
              //       <h3 className="text-color7 font-semibold -mb-1">
              //         Video Recording
              //       </h3>
              //       <span className="text-color7 text-xs ">
              //         Let your visitors record and send you a video from your
              //         FacePop
              //       </span>
              //     </div>
              //   </div>
              // )

              state === "conversation" ? (
                <div className="flex flex-col gap-5">
                  <div
                    onClick={() => setCalenderPopup(true)}
                    className="flex cursor-pointer items-center gap-2"
                  >
                    <div className="w-10 h-10 rounded-md cursor-pointer bg-color12 flex justify-center items-center">
                      <FaCalendarAlt className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Calendar Booking
                      </h3>
                      <span className="text-color7 text-xs ">
                        Integrate a booking calendar directly into the widget
                      </span>
                    </div>
                  </div>
                  {/* <div
                    onClick={() => setCollectToEmail(true)}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="w-10 h-10 cursor-pointer rounded-md bg-color6 flex justify-center items-center">
                      {" "}
                      <MdEmail className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Collect E-mail
                      </h3>
                      <span className="text-color7 text-xs ">
                        Gather and store user mail addresses from the widgets
                      </span>
                    </div>
                  </div> */}
                  <div
                    onClick={() => setInputField(true)}
                    className="flex items-center cursor-pointer gap-2"
                  >
                    <div className="w-10 h-10  rounded-md bg-color13 flex justify-center items-center">
                      {" "}
                      <FiMonitor className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Input Text
                      </h3>
                      <span className="text-color7 text-xs ">
                        Collect text data through an input field
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-5">
                  {/* <div
                    onClick={() => setAiChatpopUp(true)}
                    className="flex items-center cursor-pointer gap-2"
                  >
                    <div className="w-10 h-10 rounded-md  bg-color12 flex justify-center items-center">
                      <BsRobot className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Chat with AI
                      </h3>
                      <span className="text-color7 text-xs ">
                        Integrate with Savage Sales chatbot that opens your
                        FacePop widget
                      </span>
                    </div>
                  </div> */}
                  <div
                    onClick={() => setRunCodepopUp(true)}
                    className="flex items-center cursor-pointer gap-2"
                  >
                    <div className="w-10 h-10  rounded-md bg-color6 flex justify-center items-center">
                      {" "}
                      <BsRobot className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Run Code
                      </h3>
                      <span className="text-color7 text-xs ">
                        Execute custom code or scripts within the widget
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => setCloseFacepopup(true)}
                    className="flex cursor-pointer  items-center  gap-2"
                  >
                    <div className="w-10 h-10 rounded-md bg-color13 flex justify-center items-center">
                      {" "}
                      <AiOutlineClose className="text-white" />
                    </div>

                    <div>
                      <h3 className="text-color7 font-semibold -mb-1">
                        Close FacePop
                      </h3>
                      <span className="text-color7 text-xs ">
                        Allow users to close or hide the widget interface
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-end mt-6">
              <button
                onClick={() => setPopup(false)}
                className="text-color12 font-semibold  px-2 py-1 rounded-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ConnectFacePopUpModal
        open={openFacePopup}
        handleActionpopUp={handleFacepopUpAction}
        setPopup={setOpenFacePopup}
      />
      <RedirectUrlPopUp
        open={openRedirectUrl}
        handleActionpopUp={handleRedirectUrl}
        setPopup={setRedirectUrl}
      />
      <FullScreenRedirectPopUp
        open={openFullScreenRedirectUrl}
        handleActionpopUp={handleFullScreenRedirectUrl}
        setPopup={setFullScreenRedirectUrl}
      />
      <VideoPopUp
        open={videoPopUp}
        handleActionpopUp={handleVideoPopUp}
        setPopup={setVideopopUp}
      />
      <CalanderPupUp
        open={calenderpopup}
        handleActionpopUp={handleCalnderPopUp}
        setPopup={setCalenderPopup}
      />
      <CollectToEmailPopUp
        open={collectToEmail}
        handleActionpopUp={handleCollectToEmailPopUp}
        setPopup={setCollectToEmail}
      />
      <InputFieldPupUp
        open={inputField}
        handleActionpopUp={handleInputPopUp}
        setPopup={setInputField}
      />{" "}
      <AiChatPop
        open={aichatPopup}
        handleActionpopUp={handleChatpopup}
        setPopup={setAiChatpopUp}
      />{" "}
      <RunCodePopUp
        open={runCodePupup}
        handleActionpopUp={handleRunCodepopup}
        setPopup={setRunCodepopUp}
      />
      <CloseFacePopUp
        open={closeFacepopup}
        handleActionpopUp={handleCloseFacepopup}
        setPopup={setCloseFacepopup}
      />
    </>
  );
};

export default CallToActionPopUp;
