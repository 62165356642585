import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import config from "../../../../utils/config";

const CodeEmbed = ({ user, pluginToken }) => {
  return (
    <>
      <div className="dashboard_plugin_embed flex justify-between mt-4">
        <div
          className={`relative flex-1 py-2 px-3 ${
            user.accountType === "Pro"
              ? "overflow-auto"
              : "overflow-hidden select-none"
          }`}
        >
          {user.accountType !== "Pro" && (
            <div
              className="absolute top-0 left-0 right-0 bottom-0 z-10"
              style={{
                background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                backdropFilter: "blur( 5.5px )",
                WebkitBackdropFilter: "blur( 5.5px )",

                border: "1px solid rgba( 255, 255, 255, 0.18 )",
              }}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span className="text-base text-[#39393bde] font-bold">
                  Upgrade your plan to use this funnel
                </span>{" "}
              </div>
            </div>
          )}
          <pre>
            <ol className="text-[#97AAC3]">
              <li>
                <span className="text-gray-400">
                  {" "}
                  &lt;!-- Custom Funnel Widget --&gt;{" "}
                </span>
              </li>
              <li>
                <span> &lt;script&gt;</span>
              </li>

              <li>
                {" "}
                <span>
                  {" "}
                  var d = document.createElement("div");var qs =
                  document.createElement("script");d.classList.add("facepop_embeded");d.setAttribute("id",
                  "facepop_embeded");
                </span>
              </li>
              <li>
                {"  "}
                <span>
                  d.setAttribute("data-sitekey","
                  {user.accountType === "Pro"
                    ? pluginToken
                    : "*****************************"}
                  ");qs.src = "{config.baseUrl}
                  /static/js/facepop.js";qs.defer = true;
                </span>
              </li>
              <li>
                <span>
                  {"  "}
                  var l1 = document.createElement("link");l1.setAttribute("rel",
                  "stylesheet");l1.setAttribute("type",
                  "text/css");l1.setAttribute("href","{config.baseUrl}
                  /static/css/facepop.css");
                </span>
              </li>
              <li>
                {" "}
                <span>
                  {" "}
                  document.head.appendChild(l1);
                  document.body.appendChild(d);document.body.appendChild(qs);{" "}
                </span>
              </li>

              <li>
                <span> &lt;/script&gt;</span>
              </li>

              <li>
                <span className="text-gray-400">
                  {" "}
                  &lt;!-- End Custom Funnel Widget --&gt;{" "}
                </span>
              </li>
            </ol>
          </pre>
        </div>
        <div className="dashboard_plugin_embed-clipboard px-6 py-2 md:px-10 md:py-6">
          <CopyToClipboard
            text={
              user.accountType === "Pro" &&
              `<!-- Custom Funnel Widget -->
                            <script>
                              var d = document.createElement("div");var qs = document.createElement("script");var currentScriptTag = document.currentScript;d.classList.add("facepop_embeded");d.setAttribute("id", "facepop_embeded");
                              d.setAttribute("data-sitekey","${pluginToken}");qs.src = "${config.baseUrl}/static/js/facepop.js";qs.defer = true;
                              var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/facepop.css");
                              document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
                            </script>
                          <!-- End Custom Funnel Widget -->`
            }
            onCopy={() => {
              if (user.accountType === "Pro") {
                toast.success("Copied to clipboard");
                toast.success("Please add the code into body tag");
              } else {
                toast.error("Please upgrade your plan");
              }
            }}
          >
            <button className="w-full h-full text-center text-[#7B94B5] text-sm md:text-base">
              <span> Copy to </span> <br />
              <span> Clipboard </span>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

export default CodeEmbed;
