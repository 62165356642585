import React from "react";
import { useState } from "react";
import { Link as DLink } from "react-router-dom";
import {
  AiOutlineArrowRight,
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";
import { Link } from "react-scroll";
import useContext from "../context/StateProvider";

function Navbar() {
  const [{ user }] = useContext();

  const [toggle, setToggle] = useState(false);

  const handleNav = () => {
    setToggle(!toggle);
  };
  return (
    <div className="main">
      <div className="dekstop flex justify-between items-center md:h-[126px] pl-4 lg:pl-14 ">
        <div className="icon flex-1 md:flex-none">
          <img
            src="/images/logo.png"
            className="w-[40px] md:w-[133px] object-contain"
          />
        </div>

        <div className="menu hidden md:block">
          <ul className="flex space-x-5">
            <li>
              <Link
                to="service"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="cursor-pointer"
                // onClick={closeMenu}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="funnel"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="cursor-pointer"
                // onClick={closeMenu}
              >
                Funnel
              </Link>
            </li>

            <li>
              <Link
                to="pipeline"
                spy={true}
                smooth={true}
                offset={30}
                duration={600}
                className="cursor-pointer"
                // onClick={closeMenu}
              >
                Pipeline
              </Link>
            </li>

            <li>
              <Link
                to="price"
                spy={true}
                smooth={true}
                offset={50}
                duration={900}
                className="cursor-pointer"
                // onClick={closeMenu}
              >
                Price
              </Link>
            </li>

            <li>
              <Link
                to="faq"
                spy={true}
                smooth={true}
                offset={50}
                duration={900}
                className="cursor-pointer"
                // onClick={closeMenu}
              >
                FAQs
              </Link>
            </li>
          </ul>
        </div>

        {!user ? (
          <DLink to="/login">
            <div className="btn px-8 md:px-14 py-3 md:py-4 flex justify-center items-center my-3 mt-3 mx-2 border border-transparent rounded-full leading-6 font-medium  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
              <span className="text-xs md:text-base">ACTIVE FREE TRIAL</span>
              <AiOutlineArrowRight className="ml-1 text-lg font-black" />
            </div>
          </DLink>
        ) : (
          <DLink
            to={
              user.accountType === "Admin"
                ? "/dashboard/admin"
                : "/dashboard/profiles"
            }
          >
            <div className="btn px-8 md:px-14 py-3 md:py-4 flex justify-center items-center my-3 mt-3 mx-2 border border-transparent rounded-full leading-6 font-medium  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
              <span className="text-xs md:text-base">Dashboard</span>
              <AiOutlineArrowRight className="ml-1 text-lg font-black" />
            </div>
          </DLink>
        )}

        <div className="icons md:hidden">
          <AiOutlineMenu
            className="hover:cursor-pointer text-[#0DACFF] h-6 w-6 block mr-3"
            onClick={handleNav}
          />
        </div>

        {/* mobilee */}
        <div
          className={`mobile-main w-2/4 h-screen bg-white fixed ${
            toggle ? "top-0" : "-top-[200%]"
          } right-0 py-16 transition-all duration-500 ease-linear`}
        >
          <div
            className={`h-full fixed ${
              toggle ? "top-0" : "-top-[200%]"
            } left-0 bg-[#0000004d] w-2/4 transition-all duration-500 ease-linear`}
            onClick={() => setToggle(false)}
          ></div>{" "}
          <div className="flex justify-end mx-7 mb-7">
            <AiOutlineClose
              className="hover:cursor-pointer text-[#0DACFF] h-6 w-6 top-0 right-0"
              onClick={() => setToggle(false)}
            />
          </div>
          <ul className="text-center space-y-4">
            <li>
              <Link
                to="service"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="cursor-pointer"
                onClick={() => setToggle(false)}
                // onClick={closeMenu}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="funnel"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="cursor-pointer"
                onClick={() => setToggle(false)}
                // onClick={closeMenu}
              >
                Funnel
              </Link>
            </li>

            <li>
              <Link
                to="pipeline"
                spy={true}
                smooth={true}
                offset={30}
                duration={600}
                className="cursor-pointer"
                onClick={() => setToggle(false)}
                // onClick={closeMenu}
              >
                Pipeline
              </Link>
            </li>

            <li>
              <Link
                to="price"
                spy={true}
                smooth={true}
                offset={50}
                duration={900}
                className="cursor-pointer"
                onClick={() => setToggle(false)}
                // onClick={closeMenu}
              >
                Price
              </Link>
            </li>
            <li>
              <Link
                to="faq"
                spy={true}
                smooth={true}
                offset={50}
                duration={900}
                className="cursor-pointer"
                onClick={() => setToggle(false)}
                // onClick={closeMenu}
              >
                FAQs
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
