import React from "react";
import { toast } from "react-toastify";

function StepSixteen({ value, onChange, handleNext }) {
  const verifyAndProceed = () => {
    if (value) {
      handleNext();
    } else {
      toast.error("The field is required!");
    }
  };

  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        What is your full name?
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="fullName"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="fullName"
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="fullName"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Enter first and last name
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
          onClick={verifyAndProceed}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepSixteen;
