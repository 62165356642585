import React from "react";
import { toast } from "react-toastify";

function StepEleven({ value, onChange, handleNext }) {
  const verifyAndProceed = () => {
    var regx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(regx)) {
      handleNext();
    } else {
      toast.error("Invalid email address!");
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="my-16 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="email"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="email"
            name="email"
            required
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="email"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            E-mail Address
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-lg md:text-xl"
          onClick={verifyAndProceed}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepEleven;
