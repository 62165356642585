import React from "react";

const OptionInputField = ({
  labelTitle,
  styleProps,
  title,
  value,
  stateProp,
  labelCss,
}) => {
  return (
    <>
      <div className="flex flex-col gap-2">
        <label className={labelCss}>{title}</label>
        <input
          onChange={(e) => stateProp(e.target.value)}
          value={value}
          type="text"
          placeholder="This is facepop"
          className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
        />
      </div>
    </>
  );
};

export default OptionInputField;
