import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

function StepOne({
  funnelTitle,
  value,
  onChange,
  handleNext,
  setZestimatesLocation,
}) {
  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyCzhuxXJQvtSqjsDBL6d_BHD412s2rQwNc',

    options: {
      types: ['address'],
      componentRestrictions: { country: ['us', 'ca'] },
      fields: [
        'adr_address',
        'geometry',
        'address_components',
        'formatted_address',
        'name',
        'place_id',
      ],
    },
    onPlaceSelected: (place) => {
      console.log(place);
      // var formatted_address = place.formatted_address.split(",");
      // var address = place.name;
      // var city = formatted_address[formatted_address.length - 3].trim();
      // var state = formatted_address[formatted_address.length - 2]
      //   .trim()
      //   .split(" ")[0]
      //   .trim();
      // var zip = formatted_address[formatted_address.length - 2]
      //   .trim()
      //   .split(" ")[1]
      //   .trim();
      // console.log({
      //   address,
      //   city,
      //   state,
      //   zip,
      // });
      // onChange({
      //   target: {
      //     name: "address",
      //     value: address + ", " + city + ", " + state + " " + zip,
      //   },
      // });

      // console.log(address + ", " + city + ", " + state + " " + zip);
      // setZestimatesLocation({
      //   address,
      //   city,
      //   state,
      //   zip,
      //   geometry: place.geometry,
      //   formatted_address: place.formatted_address,
      // });

      var address = '';
      var city = '';
      var state = '';
      var zip = '';
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address = component.short_name + ' ' + address;
            break;
          case 'route':
            address = address + ' ' + component.short_name;
            break;
          case 'postal_code':
            zip = component.long_name;
            break;

          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1' || 'sublocality_level_1':
            state = component.short_name;
            break;
        }
      }

      console.log(address + ', ' + city + ', ' + state + ' ' + zip);
      onChange({
        target: {
          name: 'address',
          value: address + ', ' + city + ', ' + state + ' ' + zip,
        },
      });
      setZestimatesLocation({
        address,
        city,
        state,
        zip,
        geometry: place.geometry,
        formatted_address: address + ', ' + city + ', ' + state + ' ' + zip,
      });
    },
  });

  return (
    <div className="flex flex-col items-center">
      <h1 className="my-6 text-xl md:text-3xl text-[#ac1e23] capitalize font-medium">
        {funnelTitle}
      </h1>

      <div className="flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            ref={ref}
            id="address"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="address"
            required
            value={value}
            onChange={onChange}
            placeholder=""
          />
          <label
            htmlFor="address"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Address
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-lg md:text-xl"
          onClick={handleNext}
        >
          GO!
        </button>
      </div>
    </div>
  );
}

export default StepOne;
