import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Draggable from 'react-draggable';

// icons
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaFacebookF, FaFacebookMessenger, FaShareAlt } from 'react-icons/fa';
import { IoMdCall } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { BsCalculator, BsTelegram, BsWhatsapp } from 'react-icons/bs';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { AiFillHome } from 'react-icons/ai';

import Loader from '../Loader/Loader';

function StandAloneFlexiButtons() {
  const params = useParams();
  const [pluginToken, setPluginToken] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [behaviour, setBehaviour] = useState('fixed');
  const [layout, setLayout] = useState('vertical');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [menuIcon, setMenuIcon] = useState('');

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    // axios({
    //   url:
    //     "/api/plugin/standalone/" +
    //     params.username +
    //     "/" +
    //     params.calculatorname,
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => {
    //     const resData = res.data;

    //     if (resData.success) {
    //       setPluginToken(resData.data.token);
    //     } else {
    //       setIsLoading(false);
    //       setError(true);
    //     }
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err);
    //     setError(true);
    //   });

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);

  useEffect(() => {
    if (params.pluginToken) {
      axios({
        url: '/api/buttonizer/token/' + params.pluginToken,
        method: 'get',
      })
        .then((res) => {
          const resData = res.data;
          setIsLoading(false);
          if (resData.success) {
            setBehaviour(resData.data.behaviour);
            setLayout(resData.data.layout);
            setBackgroundColor(resData.data.backgroundColor);
            setHoverColor(resData.data.hoverColor);
            setMenuIcon(resData.data.menuIcon);
            setData(resData.data.buttons);
          } else {
            setError(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          setError(true);
        });
    }
  }, [params]);

  const handleOpen = () => {
    setIsOpened((prev) => !prev);
  };

  const detectLayoutStyles = useMemo(() => {
    if (layout === 'vertical') {
      return 'layout-vertical';
    } else if (layout === 'horizontal') {
      return 'layout-horizontal';
    } else if (layout === 'cloud') {
      return 'layout-cloud';
    }
  }, [layout]);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!params.pluginToken) return <></>;

  return (
    <div className="buttonizer_parent absolute top-0 left-0 right-0 bottom-0 bg-transparent">
      <header className="py-6 px-4">
        <div className="mx-auto w-14 md:w-24">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
        </div>
      </header>
      <Draggable
        disabled={behaviour === 'fixed'}
        onDrag={(e) => {
          console.log(e);
        }}
        bounds=".buttonizer_parent"
      >
        <div
          className="buttonizer_container"
          style={{
            '--bg-color': backgroundColor,
            '--hover-color': hoverColor,
          }}
        >
          <div className={`buttonizer_container-inner ${detectLayoutStyles}`}>
            <button
              className={`buttonizer_opener ${isOpened ? 'opened' : ''}`}
              onClick={handleOpen}
            >
              {/* <GiHamburgerMenu className="mx-auto text-white text-3xl" /> */}
              {menuIcon ? (
                <img
                  src={menuIcon}
                  alt="Custom Hamburger Icon"
                  className="mx-auto"
                />
              ) : (
                <GiHamburgerMenu className="mx-auto text-white text-3xl" />
              )}
            </button>
            {layout === 'vertical' ? (
              <>
                {data.map((dt, idx) => (
                  <LayoutVertical
                    data={dt}
                    sr={idx}
                    key={idx}
                    isOpened={isOpened}
                  />
                ))}
              </>
            ) : layout === 'horizontal' ? (
              <>
                {' '}
                {data.map((dt, idx) => (
                  <LayoutHorizontal
                    data={dt}
                    sr={idx}
                    key={idx}
                    isOpened={isOpened}
                  />
                ))}
              </>
            ) : layout === 'cloud' ? (
              <>
                {' '}
                {data
                  .filter((dst) => dst.visible)
                  .map((dt, idx, err) => {
                    return (
                      <LayoutCloud
                        data={dt}
                        sr={idx + 1}
                        total={err.length}
                        key={idx}
                        isOpened={isOpened}
                      />
                    );
                  })}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </Draggable>
    </div>
  );
}

const LayoutVertical = ({ data, sr, isOpened }) => {
  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto' + data.email}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ transitionDelay: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <img style={{ width: '60%' }} src={data.icon} alt="Icon" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  }
};

export const LayoutHorizontal = ({ data, sr, isOpened }) => {
  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto:' + data.email}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ transitionDelay: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <img src={data.icon} alt="Icon" />
      </a>
    );
  }
};

export const LayoutCloud = ({ data, sr, isOpened, total }) => {
  const for7Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-156px, 20px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-140px, -48px)',
        opacity: '1',
      };
    } else if (sr === 6) {
      return {
        transform: 'translate(-90px, -102px)',
        opacity: '1',
      };
    } else if (sr === 7) {
      return {
        transform: 'translate(-24px, -140px)',
        opacity: '1',
      };
    }
  };

  const for6Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-136px, -16px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-120px, -80px)',
        opacity: '1',
      };
    } else if (sr === 6) {
      return {
        transform: 'translate(-60px, -110px)',
        opacity: '1',
      };
    }
  };

  const for5Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px, -10px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-20px, -50px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(-130px, -12px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-100px, -76px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-40px, -110px)',
        opacity: '1',
      };
    }
  };
  const for4Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px,28px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-56px, -30px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(-8px, -60px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(44px, -54px)',
        opacity: '1',
      };
    }
  };

  const for3Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    }
  };

  const for2Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px, -10px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-20px, -50px)',
        opacity: '1',
      };
    }
  };

  const for1Items = () => {
    return {
      transform: 'translate(-40px, -36px)',
      opacity: '1',
    };
  };

  const measurePosition = useMemo(() => {
    console.log(sr);
    switch (total) {
      case 1:
        return for1Items();
      case 2:
        return for2Items();
      case 3:
        return for3Items();
      case 4:
        return for4Items();
      case 5:
        return for5Items();
      case 6:
        return for6Items();
      case 7:
        return for7Items();
      default:
        return {};
    }
  }, [sr, total]);

  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto:' + data.email}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          transitionDelay: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <img src={data.icon} alt="Icon" />
      </a>
    );
  }
};

export default StandAloneFlexiButtons;
