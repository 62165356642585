import React, { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";

const VideoComponent = ({
  playVideoRef,
  video,
  thumbnail,
  isToggled,
  shadow,
  isplaying,
  playerHeight,
  height,
  position,
  radious,
  playAgain,
  onPlayHandler,
  bgColor9,
  muted,
  handleVideoEnd,
  onMobile,
  inTabSize,
  thumbOption,
  thumbPlay,
}) => {
  const playThumbRef = useRef();

  const [showThumbnail, setShowThumbnail] = useState(true);

  const handleVideoPlay = () => {
    if (!isToggled) {
      setShowThumbnail(false);
    }
  };

  const handleVideoPause = () => {
    if (isToggled) {
      setShowThumbnail(true);
    }
  };

  useEffect(() => {
    const videoElement = playVideoRef.current;

    if (videoElement) {
      videoElement.addEventListener("play", handleVideoPlay);
      videoElement.addEventListener("pause", handleVideoPause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handleVideoPlay);
        videoElement.removeEventListener("pause", handleVideoPause);
      }
    };
  }, [playVideoRef, thumbnail, isToggled]);

  useEffect(() => {
    if (playVideoRef.current) {
      playVideoRef.current.muted = muted;
    }
  }, [muted]);

  useEffect(() => {
    const video = playThumbRef.current;

    if (thumbPlay === "auto") {
      video.play();
    } else if (thumbPlay === "onHover") {
      video.pause();
    }
  }, [thumbPlay]);

  const desktopStyles = {
    height: `${isToggled ? height : playerHeight}px`,
    borderRadius: `${radious}px`,
    transition: "height 0.3s ease 0s",
    display: showThumbnail ? "none" : "block",
  };

  const mobileStyles = {
    height: "100vh",
    margin: 0,
    borderRadius: `${radious}px`,
    transition: "height 0.3s ease 0s",
    display: showThumbnail ? "none" : "block",
  };

  const styles =
    inTabSize && onMobile && !isToggled ? mobileStyles : desktopStyles;

  return (
    <>
      <div onClick={onPlayHandler}>
        <video
          ref={playVideoRef}
          src={video}
          className={`w-full object-cover  z-0  ${
            shadow == "dim"
              ? "shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px]"
              : shadow == "medium"
              ? "shadow-[0_20px_70px_rgba(0,_0,_0,_1)]"
              : shadow == "light"
              ? "shadow-[rgba(0,_0,_0,_1)_0px_30px_90px]"
              : ""
          }`}
          style={styles}
          onEnded={handleVideoEnd}
        />

        {/* Thumbnail */}
        {thumbOption === "video" ? (
          <video
            ref={playThumbRef}
            src={thumbnail}
            className={`w-full object-cover z-0 ${
              shadow === "dim"
                ? "shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px]"
                : shadow === "medium"
                ? "shadow-[0_20px_70px_rgba(0,_0,_0,_1)]"
                : shadow === "light"
                ? "shadow-[rgba(0,_0,_0,_1)_0px_30px_90px]"
                : ""
            }	`}
            style={{
              height: `${isToggled ? height : "0"}px`,
              borderRadius: `${radious}px`,
              transition: "all 0.3s ease 0s",
              display: showThumbnail ? "block" : "none",
            }}
            loop
            muted
            autoPlay={thumbPlay === "auto"}
            onMouseOver={(event) =>
              thumbPlay === "onHover" && event.target.play()
            }
            onMouseOut={(event) =>
              thumbPlay === "onHover" && event.target.pause()
            }
          />
        ) : (
          <img
            src={thumbnail}
            alt="thumbnail"
            className={`w-full object-cover z-0 ${
              shadow === "dim"
                ? "shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px]"
                : shadow === "medium"
                ? "shadow-[0_20px_70px_rgba(0,_0,_0,_1)]"
                : shadow === "light"
                ? "shadow-[rgba(0,_0,_0,_1)_0px_30px_90px]"
                : ""
            }	`}
            style={{
              height: `${isToggled ? height : "0"}px`,
              borderRadius: `${radious}px`,
              transition: "all 0.3s ease 0s",
              display: showThumbnail ? "block" : "none",
            }}
          />
        )}

        {!isplaying && (
          <FaPlay
            className="absolute right-0 top-0 left-0 bottom-0 m-auto"
            style={{ fontSize: "50px", color: bgColor9 }}
          />
        )}
      </div>

      {/* video's gradient overlay */}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "50%",
          borderRadius: "5px",
          background: "linear-gradient(to bottom,transparent,#cfccff)",
          pointerEvents: "none",
        }}
      ></div>
    </>
  );
};

export default VideoComponent;
