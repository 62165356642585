import React from 'react';

export function StepEight1({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        I need a value estimate because:
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Just Curious',
                name: 'needValueEstimateFor',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Just Curious'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Just Curious
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Selling',
                name: 'needValueEstimateFor',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Selling'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Selling
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Refinancing',
                name: 'needValueEstimateFor',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Refinancing'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Refinancing
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}

export function StepEight2({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        Check all that apply:
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Send me more info on this home',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Send me more info on this home'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Send me more info on this home
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I would like additional photos',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I would like additional photos'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I would like additional photos
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I would like to schedule a showing',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I would like to schedule a showing'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I would like to schedule a showing
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}
export function StepEight3({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <div className="mt-3 flex flex-col items-center space-y-2">
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'Send me other comparables',
                name: 'compareToMine',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'Send me other comparables'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            Send me other comparables
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I only need this one',
                name: 'compareToMine',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I only need this one'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I only need this one
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
        <button
          className="relative max-w-[270px] min-w-[270px] border-[1px] border-[var(--color-red)]"
          style={{
            boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
          }}
          onClick={() => {
            onChange({
              target: {
                value: 'I would like to schedule a showing',
                name: 'checkAllThatApply',
              },
            });
            handleNext();
          }}
        >
          <div
            className={`w-full px-2 py-3 text-center text-sm font-[500] ${
              value === 'I would like to schedule a showing'
                ? 'text-[var(--color-red)] bg-white'
                : 'text-white bg-[var(--color-red)] hbtn hb-fill-right'
            }`}
          >
            I would like to schedule a showing
          </div>
          <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-[var(--color-red)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}
