import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import useContext from './../../../context/StateProvider';
import adminServices from './../../../services/adminBulder.service';

function TemplateList() {
  const [{ user }] = useContext();

  const [name, setName] = useState('');
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    // Fetch templates from the server when the component mounts
    loadTemplates();
  }, []);

  const loadTemplates = useCallback(async () => {
    try {
      const response = await adminServices.templateList();
      setTemplates(response);
    } catch (error) {
      console.error('Error loading templates:', error);
    }
  }, []);

  const handleSaveTemplate = async () => {
    try {
      const response = await adminServices.createTemplate(name, user.token);
      console.log('Response:', response); // Add this line to check the response
      loadTemplates();
      setName('');
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleDeleteTemplate = async (pageId) => {
    await adminServices.deleteTemplate(pageId);
    loadTemplates();
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Super User Page</h1>
      <div className="mb-4 flex space-x-2">
        <input
          type="text"
          className="border border-gray-300 rounded-lg px-4 py-2 flex-grow"
          placeholder="Enter template name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded-lg"
          onClick={handleSaveTemplate}
        >
          Create Template
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {templates && templates.length === 0 ? (
          <h2 className="text-xl font-semibold">No templates</h2>
        ) : (
          templates.map((template) => (
            <div
              key={template._id}
              className="border border-gray-300 rounded-lg p-4 mb-4"
            >
              <h3 className="text-xl font-semibold mb-2">{template.name}</h3>
              <p>Created By: {template.createdBy}</p>
              {/* Add other template details here */}
              <div className="flex mt-2 space-x-2">
                <button className="bg-yellow-500 text-white px-2 py-1 rounded">
                  <Link to={`template/editor/${template._id}`}>
                    <EditIcon className="w-5 h-5" />
                  </Link>
                </button>
                <button className="bg-blue-500 text-white px-2 py-1 rounded">
                  <Link to={`/template/${template._id}`} className="text-white">
                    <VisibilityIcon className="w-5 h-5" />
                  </Link>
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => handleDeleteTemplate(template._id)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default TemplateList;
