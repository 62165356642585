import React from 'react';
// import { FaHand } from 'react-icons/fa6';

import PanToolIcon from '@mui/icons-material/PanTool';

const AdblockerDetect = ({ warningClose }) => {
  return (
    <div className="flex flex-col absolute items-center justify-center object-contain">
      <div className="w-full p-8 rounded-lg space-y-4 text-center">
        <div className="flex items-center justify-center">
          <PanToolIcon
            sx={{ fontSize: 40 }}
            className="text-6xl text-red-600"
          />
        </div>
        <h2 className="text-4xl text-white-600 font-semibold">
          Ad Blocker Detected
        </h2>
        <p className="text-lg text-white-600">
          Please disable or remove your ad blocker to access this content. And
          reload the page after disable your ad blocker.
        </p>
        <button
          onClick={warningClose}
          className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AdblockerDetect;
