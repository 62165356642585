import React from 'react';

function FlexiFrameButton({ handleShow, show, minimizeButton }) {
  let buttonizer = false;
  if (!buttonizer) {
    if (minimizeButton.styles.type === 'icon') {
      return (
        <button
          className="flexiframe-minimize-btn fixed text-uppercase text-lg"
          onClick={handleShow}
          style={{
            ...minimizeButton.styles,
            ...minimizeButton.position,
            color: minimizeButton.styles.fontColor,
            display: show ? 'none' : 'block',
          }}
        >
          <img
            src={minimizeButton.styles.icon}
            alt="Icon"
            style={{ width: '100%' }}
          />
        </button>
      );
    } else if (minimizeButton.styles.type === 'logo') {
      return (
        <button
          className="flexiframe-minimize-btn fixed text-uppercase text-lg"
          onClick={handleShow}
          style={{
            ...minimizeButton.styles,
            ...minimizeButton.position,
            color: minimizeButton.styles.fontColor,
            display: show ? 'none' : 'block',
          }}
        >
          <img
            src={minimizeButton.styles.logo}
            alt="Logo"
            style={{
              width: '20%',
              objectFit: 'contain',
              paddingRight: '5px',
              display: 'inline',
            }}
          />
          <span>{minimizeButton.styles.text}</span>
        </button>
      );
    } else {
      return (
        <button
          className="flexiframe-minimize-btn fixed text-uppercase text-lg"
          onClick={handleShow}
          style={{
            ...minimizeButton.styles,
            ...minimizeButton.position,
            color: minimizeButton.styles.fontColor,
            display: show ? 'none' : 'block',
          }}
        >
          {minimizeButton.styles.text}
        </button>
      );
    }
  }
  return <></>;
}

export default FlexiFrameButton;
