import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import axios from "axios";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useSocket from "./context/Socket";
import useContext from "./context/StateProvider";
import ActionTypes from "./utils/actionTypes";

// components
import Failed from "./pages/failed/failed";
import SuccessPro from "./pages/success/Pro";

import AccountSettings from "./components/Dashboard/AccountSettings";
import Dashboard from "./components/Dashboard/Dashboard";
import Mails from "./components/Dashboard/Mails";
import ManageAccess from "./components/Dashboard/ManageAccess";
import Plugin from "./components/Dashboard/Plugin";
import { ConfirmEmail, Register } from "./components/GetStarted/Register";
import {
  ChangePassword,
  ResetPassword,
  Signin,
} from "./components/GetStarted/Signin";
import Loader from "./components/Loader/Loader";

import Admin from "./components/Admin";

import CheckHomeValuesPlugin from "./components/Check_Home_Values/Plugin";
import FlexiFramePlugin from "./components/FlexiFrame/FlexiFramePlugin";
import MortgageRatesPlugin from "./components/Mortgage_Rates/Plugin";
import ViewPropertiesPlugin from "./components/ViewProperties/Plugin";

import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";

import StandAlone from "./components/StandAlone";

import AllProfiles from "./components/Dashboard/AllProfiles";
import FlexiButtons from "./components/Dashboard/FlexiButtons";
import Subscriptions from "./components/Dashboard/Subscriptions";
import CalculatorLeads from "./components/Leads/Calculator";
import CheckHomeValuesLeads from "./components/Leads/CheckHomeValues";
import MortgageRatesLeads from "./components/Leads/MortgageRates";
import ViewPropertiesLeads from "./components/Leads/ViewProperties";
import AllCalculators from "./components/Lists/Calculators";
import AllCheckHomeValues from "./components/Lists/CheckHomeValues";
import AllFlexiButtons from "./components/Lists/FlexiButtons";
import AllFlexiFrame from "./components/Lists/FlexiFrame";
import AllLeads from "./components/Lists/Leads";
import AllMortgageRates from "./components/Lists/MortgageRates";
import AllViewProperties from "./components/Lists/ViewProperties";
import ScrollToTop from "./components/ScrollToTop";
import StandAloneCustomFunnel from "./components/StandAlone/CustomFunnelView";
import StandAloneFlexiButtons from "./components/StandAlone/FlexiButtons";
import StandAloneFlexiFrame from "./components/StandAlone/FlexiFrame";
import StandAloneMortgageRates from "./components/StandAlone/MortgageRates";
import StandAlonePersonalSite from "./components/StandAlone/PersonalSite";
import StandAloneViewProperties from "./components/StandAlone/ViewProperties";

import CalculatorMinimizeButton from "./components/MinimizeButtons/CalculatorButton";
import CheckHomeValuesMinimizeButton from "./components/MinimizeButtons/CheckHomeValuesButton";
import CustomFunnelMinimizeButton from "./components/MinimizeButtons/CustomFunnelMiniButton";
import FlexiFrameMinimizeButton from "./components/MinimizeButtons/FlexiFrameButton";
import MortgageRatesMinimizeButton from "./components/MinimizeButtons/MortgageRatesButton";
import ViewPropertiesMinimizeButton from "./components/MinimizeButtons/ViewPropertiesButton";

import PersonalSiteBuilder from "./components/StandAlone/PersonalSiteBuilder";

import Marketplace from "./components/Dashboard/Marketplace";
import MainSite from "./components/StandAlone/MainSite";

import PublicMarketPlace from "./pages/MarketPlace";

import AdminEditor from "./components/Admin/AdminBuilder/AdminEditor";
import RenderTemplate from "./components/Admin/AdminBuilder/RenderTemplate";
import PluginDashboard from "./components/Dashboard/PluginsDashboard";
import BasicOrAdvance from "./components/StandAlone/BasicOrAdvance";
import PageModal from "./components/StandAlone/PageModal";
import RenderPersonalPage from "./components/StandAlone/RenderPersonalPage";

import AdsManager from "./components/Admin/AdsManagement/AdsManager";
import CustomFunnelOption from "./components/Custom_funnel/CustomFunnelOption";
import FacePopUpOption from "./components/Dashboard/FacePopUp/FacePopUpOption";
import CreateFunnels from "./components/Dashboard/customFunnel/CreateFunnel";
import CustomFunnel from "./components/Dashboard/customFunnel/CustomFunnels";
import AllCustomFunnel from "./components/Lists/CustomFunnel";
import FacePopUp from "./components/Lists/FacePopUp";
import Popup from "./components/Popup";
import StandAloneCheckHomeValues from "./components/StandAlone/CheckHomeValues";
import StandAloneFacePop from "./components/StandAlone/FacePop";

function App() {
  const socket = useSocket();

  const [{ user }, dispatch] = useContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios({
      url: "/api/users/getUser",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log("user main data: ", resData.data);

        if (resData.success) {
          socket.emit("identity", resData.data._id);

          dispatch({
            type: ActionTypes.SET_USER,
            payload: resData.data,
          });
          setLoading(false);
          if (
            location.pathname.split("/")[1] !== "view" &&
            location.pathname !== "/" &&
            resData.data.accountType === "Admin"
          ) {
            navigate("/dashboard/admin");
          } else {
            if (
              location.pathname.split("/")[1] !== "view" &&
              location.pathname.split("/")[1] !== "my-site" &&
              location.pathname !== "/" &&
              location.pathname.split("/")[1] !== "dashboard" &&
              location.pathname.split("/")[1] !== "payment" &&
              location.pathname.split("/")[1] !== "share"
            ) {
              navigate("/dashboard/profiles");
            }
          }
        } else {
          toast.error(resData.status, {});
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    socket.emit("identity", user._id);
    axios({
      url: `/api/profiles/user/${user._id}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        setLoading(false);
        const resData = res.data;
        console.log("user data by id: ", resData.data);
        if (resData.success) {
          dispatch({
            type: ActionTypes.SET_ALL_PROFILES,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Popup />
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />

        <Route
          path="/share/marketplace"
          element={
            <>
              <PublicMarketPlace />
            </>
          }
        />
        <Route
          path="/payment/success"
          element={
            <>
              <SuccessPro />
            </>
          }
        />

        <Route
          path="/payment/failed"
          element={
            <>
              <Failed />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <Protected state={true}>
              <Signin />
            </Protected>
          }
        />
        <Route
          path="/resetPassword"
          element={
            <Protected state={true}>
              <ResetPassword />
            </Protected>
          }
        />
        <Route path="/changePassword">
          <Route path="reset/:userId/:token" element={<ChangePassword />} />
          <Route
            path=""
            element={
              <Protected>
                <ChangePassword />
              </Protected>
            }
          />
        </Route>
        <Route
          path="/register"
          element={
            <Protected state={true}>
              <Register />
            </Protected>
          }
        />
        <Route
          path="/confirmEmail/:token"
          element={
            <Protected state={true}>
              <ConfirmEmail />
            </Protected>
          }
        />

        <Route
          path="/page-builder/:pageId"
          element={
            <>
              <PersonalSiteBuilder />
            </>
          }
        />

        <Route path="dashboard">
          <Route path="" element={<Dashboard />} />
          <Route path="admin" element={<Admin />} />
          <Route
            path="admin/ads-manager/edit/:id"
            element={
              <AdminProtected role="Admin">
                <AdsManager />
              </AdminProtected>
            }
          />
          <Route
            path="admin/template/editor/:pageId"
            element={<AdminEditor />}
          />
          <Route
            path=":profileId/plans"
            element={
              <Dashboard>
                <Subscriptions />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/"
            element={
              <Dashboard>
                <PluginDashboard />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/calculators"
            element={
              <Dashboard>
                <AllCalculators />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/mortgage-rates"
            element={
              <Dashboard>
                <AllMortgageRates />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/viewProperties"
            element={
              <Dashboard>
                <AllViewProperties />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/checkHomeValues"
            element={
              <Dashboard>
                <AllCheckHomeValues />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/flexiframe"
            element={
              <Dashboard>
                <AllFlexiFrame />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/custom-funnels"
            element={
              <Dashboard>
                <AllCustomFunnel />
              </Dashboard>
            }
          />{" "}
          <Route
            path=":profileId/all-funnels/face-popup"
            element={
              <Dashboard>
                <FacePopUp />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/all-funnels/flexibuttons"
            element={
              <Dashboard>
                <AllFlexiButtons />
              </Dashboard>
            }
          />
          <Route
            path="profiles"
            element={
              <Protected state={true}>
                <AllProfiles />
              </Protected>
            }
          />
          <Route
            path=":profileId/accounts"
            element={
              <Dashboard>
                {" "}
                <AccountSettings />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/leads"
            element={
              <Dashboard>
                {" "}
                <AllLeads />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/custom-funnel"
            element={
              <Dashboard>
                {" "}
                <CreateFunnels />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/custom-funnel/:funnelId"
            element={
              <Dashboard>
                {" "}
                <CustomFunnel />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/leads/calculators"
            element={
              <Dashboard>
                {" "}
                <CalculatorLeads />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/leads/mortgage-rates"
            element={
              <Dashboard>
                {" "}
                <MortgageRatesLeads />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/leads/checkHomeValues"
            element={
              <Dashboard>
                {" "}
                <CheckHomeValuesLeads />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/leads/viewProperties"
            element={
              <Dashboard>
                {" "}
                <ViewPropertiesLeads />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/calculators/:pluginId"
            element={
              <Dashboard>
                {" "}
                <Plugin />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/mortgage-rates/:pluginId"
            element={
              <Dashboard>
                {" "}
                <MortgageRatesPlugin />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/checkHomeValues/:pluginId"
            element={
              <Dashboard>
                {" "}
                <CheckHomeValuesPlugin />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/viewProperties/:pluginId"
            element={
              <Dashboard>
                {" "}
                <ViewPropertiesPlugin />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/flexiframe/:pluginId"
            element={
              <Dashboard>
                {" "}
                <FlexiFramePlugin />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/custom-funnel/options/:pluginId"
            element={
              <Dashboard>
                {" "}
                <CustomFunnelOption />{" "}
              </Dashboard>
            }
          />{" "}
          <Route
            path=":profileId/face-popup/options/:pluginId"
            element={
              <Dashboard>
                {" "}
                <FacePopUpOption />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/emails"
            element={
              <Dashboard>
                {" "}
                <Mails />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/manage-access"
            element={
              <Dashboard>
                {" "}
                <ManageAccess />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/marketplace"
            element={
              <Dashboard>
                {" "}
                <Marketplace />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/flexibuttons/:pluginId"
            element={
              <Dashboard>
                {" "}
                <FlexiButtons />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/calculators/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <CalculatorMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/checkHomeValues/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <CheckHomeValuesMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/mortgage-rates/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <MortgageRatesMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/custom-funnel/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <CustomFunnelMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/viewProperties/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <ViewPropertiesMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/flexiframe/:pluginId/minimize-button"
            element={
              <Dashboard>
                {" "}
                <FlexiFrameMinimizeButton />{" "}
              </Dashboard>
            }
          />
          <Route
            path=":profileId/edit/profile/"
            element={
              <Dashboard>
                <BasicOrAdvance />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/edit/profile/advance"
            element={
              <Dashboard>
                <PageModal />
              </Dashboard>
            }
          />
          <Route
            path=":profileId/edit/profile/basic"
            element={
              <Dashboard>
                <StandAlonePersonalSite />
              </Dashboard>
            }
          />
        </Route>
        <Route path="/view/calculators/:pluginToken" element={<StandAlone />} />
        <Route
          path="/view/mortgageRates/:pluginToken"
          element={<StandAloneMortgageRates />}
        />
        <Route
          path="/view/flexiButtons/:pluginToken"
          element={<StandAloneFlexiButtons />}
        />
        <Route
          path="/view/viewProperties/:pluginToken"
          element={<StandAloneViewProperties />}
        />
        <Route
          path="/view/checkHomeValues/:pluginToken"
          element={<StandAloneCheckHomeValues />}
        />
        <Route
          path="/view/flexiframe/:pluginToken"
          element={<StandAloneFlexiFrame />}
        />
        <Route
          path="/view/facepop/:pluginToken"
          element={<StandAloneFacePop />}
        />
        <Route
          path="/view/customFunnel/:pluginToken"
          element={<StandAloneCustomFunnel />}
        />
        <Route path="/view/profile/:userId" element={<MainSite />} />
        <Route path="/my-site/:pageId" element={<RenderPersonalPage />} />
        <Route path="/my-site/template/:pageId" element={<RenderTemplate />} />
      </Routes>
    </>
  );
}

function Protected({ children, state }) {
  const [{ user }] = useContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (user && state) navigate("/dashboard/profiles");
    else if (!user && !state) {
      navigate("/");
    }
  }, [user]);

  return <>{children}</>;
}

function AdminProtected({ children, role }) {
  const [{ user }] = useContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/"); // Redirect unauthenticated users to the home page
    } else {
      if (role === "Admin" && user.accountType !== "Admin") {
        navigate("/dashboard/profiles"); // Redirect non-admins to profiles
      }
    }
  }, [user, role]);

  return <>{children}</>;
}

export default App;
