import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ColorPicker from "react-best-gradient-color-picker";

function System() {
  const [pdfResult, setPdfResult] = useState({
    topDiscloser: "",
    bottomDiscloser: "",
  });

  const [backgroundColorPicker, setBackgroundColorPicker] = useState(false);
  const [marketPlaces, setMarketplaces] = useState([]);
  const [marketPlaceBg, setMarketPlaceBg] = useState({
    type: "color",
    value: "",
  });

  useEffect(() => {
    axios({
      url: "/api/system",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setPdfResult(resData.data.pdfResult);
          setMarketplaces(resData.data.marketPlaces);
          setMarketPlaceBg(resData.data.marketPlaceBg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleMarketplaceChange = (e, idx) => {
    const { value, name } = e.target;

    let cp = marketPlaces.map((dt, index) => {
      if (index === idx) {
        return { ...dt, [name]: value };
      } else {
        return dt;
      }
    });

    setMarketplaces(cp);
  };

  const handleAddNewMarketPlace = () => {
    setMarketplaces((prev) => [
      ...prev,
      {
        name: "Untitled",
        img: "",
        info: "",
        url: "",
      },
    ]);
  };

  const handleRemoveMarketPlace = (idx) => {
    let cp = marketPlaces.filter((dt, index) => index !== idx);

    setMarketplaces(cp);
  };

  const handleSave = () => {
    axios({
      url: "/api/system/update",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        pdfResult,
        marketPlaces,
        marketPlaceBg,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          toast.success("Updated successfully");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleImageUploadMarketplace = (e, idx) => {
    const { name, files } = e.target;
    const formData = new FormData();

    formData.append(name, files[0]);

    axios({
      url: "/api/system/marketplaces/image",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        if (resData.success) {
          toast.success("Uploaded successfully");
          let cp = marketPlaces.map((dt, index) => {
            if (index === idx) {
              return { ...dt, img: resData.data.path };
            } else {
              return dt;
            }
          });

          setMarketplaces(cp);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  const handleImageUploadMarketplaceBg = (e) => {
    const { name, files } = e.target;
    const formData = new FormData();

    formData.append(name, files[0]);

    axios({
      url: "/api/system/marketplaces/background/image",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        if (resData.success) {
          toast.success("Uploaded successfully");
          setMarketPlaceBg((prev) => ({ ...prev, value: resData.data.path }));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handlePopUpDate = (value) => {
    axios({
      url: "/api/system/update",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        popupDate: value,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        if (resData.success) {
          toast.success("Updated");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <div className="p-4 overflow-auto">
      <div>
        <button
          className="w-full max-w-[300px] mx-auto text-white px-4 py-2 bg-[#262C34] rounded-lg"
          onClick={handleSave}
        >
          Save changes
        </button>
      </div>
      <div className="mt-8">
        <h2 className="text-lg"> Popup notification Date </h2>
        <input
          className="border-2 border-gray-800 outline-none"
          type="date"
          onChange={(e) => {
            const value = e.target.value.split("-")[2];
            handlePopUpDate(value);
          }}
        />
      </div>
      <div className="mt-8">
        <h2 className="text-lg"> PDF (results) </h2>

        <div className="mt-6 flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <label htmlFor="topDiscloser">
              {" "}
              Top Discloser (keep it short){" "}
            </label>
            <textarea
              className="p-2 border-2 w-full rounde-lg outline-none"
              name="topDiscloser"
              id="topDiscloser"
              rows="2"
              value={pdfResult.topDiscloser}
              onChange={(e) => {
                setPdfResult((prev) => ({
                  ...prev,
                  topDiscloser: e.target.value,
                }));
              }}
            ></textarea>
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="bottomDiscloser"> Bottom Discloser </label>
            <textarea
              className="p-2 border-2 w-full rounde-lg outline-none"
              name="bottomDiscloser"
              id="bottomDiscloser"
              rows="10"
              value={pdfResult.bottomDiscloser}
              onChange={(e) => {
                setPdfResult((prev) => ({
                  ...prev,
                  bottomDiscloser: e.target.value,
                }));
              }}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-lg"> Marketplaces </h2>

        <div className="mt-6 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="mbgtype"
          >
            Choose a background type
          </label>
          <div className="ml-2 md:flex items-center md:space-x-5">
            <label className="flex items-center space-x-2" htmlFor="color">
              <input
                id="color"
                name="behaviour"
                type="radio"
                value="color"
                checked={marketPlaceBg.type === "color"}
                onChange={(e) =>
                  setMarketPlaceBg((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
              />
              <span>Color</span>
            </label>
            <label className="flex items-center space-x-2" htmlFor="image">
              <input
                id="image"
                name="behaviour"
                type="radio"
                value="image"
                checked={marketPlaceBg.type === "image"}
                onChange={(e) =>
                  setMarketPlaceBg((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
              />
              <span>Image</span>
            </label>
          </div>
        </div>
        <div className="mt-8">
          {marketPlaceBg.type === "color" ? (
            <div
              className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => setBackgroundColorPicker(true)}
            >
              {backgroundColorPicker && (
                <div
                  className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center z-50"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="fixed bottom-0 left-0 right-0 top-0 bg-[#00000082]"
                    onClick={() => setBackgroundColorPicker(false)}
                  ></div>
                  <div className="relative bg-white z-[100]">
                    <ColorPicker
                      value={marketPlaceBg.value}
                      onChange={(value) =>
                        setMarketPlaceBg((prev) => ({ ...prev, value }))
                      }
                    />
                  </div>
                </div>
              )}

              <div className="relative">
                <div
                  className="h-5 w-8 rounded-sm border-2"
                  style={{ background: marketPlaceBg.value }}
                ></div>
              </div>
              <span className="text-sm select-none"> Choose a background </span>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
              <div className="">
                <img
                  src={marketPlaceBg.value}
                  alt="Marketplace Bg"
                  className="w-32 object-contain"
                />
              </div>
              <div>
                <input
                  type="file"
                  name="marketplaceImageBg"
                  accept="image/png, image/jpg, image/webp, image/jpeg"
                  onChange={handleImageUploadMarketplaceBg}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-5">
          {marketPlaces.map((dt, idx) => (
            <div className="mt-6" key={idx}>
              <h1 className="font-bold text-xl"> #{idx + 1} </h1>
              <div className="mt-6 flex flex-col space-y-2">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={marketPlaces[idx].name}
                  className="px-2 py-1 border-2 w-full rounded-lg outline-none"
                  onChange={(e) => handleMarketplaceChange(e, idx)}
                />
              </div>
              <div className="mt-6 flex flex-col space-y-2">
                <label>Image</label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={marketPlaces[idx].img}
                    name="img"
                    className="px-2 py-1 border-2 w-full rounded-lg outline-none"
                    onChange={(e) => handleMarketplaceChange(e, idx)}
                  />
                  <input
                    type="file"
                    name="marketplaceImage"
                    accept="image/png, image/jpg, image/webp, image/jpeg"
                    onChange={(e) => handleImageUploadMarketplace(e, idx)}
                  />
                </div>
              </div>
              <div className="mt-6 flex flex-col space-y-2">
                <label>Info</label>
                <textarea
                  name="info"
                  className="px-2 py-1 border-2 w-full rounded-lg outline-none"
                  rows="6"
                  value={marketPlaces[idx].info}
                  onChange={(e) => handleMarketplaceChange(e, idx)}
                ></textarea>
              </div>
              <div className="mt-6 flex flex-col space-y-2">
                <label>URL</label>
                <input
                  name="url"
                  type="text"
                  value={marketPlaces[idx].url}
                  className="px-2 py-1 border-2 w-full rounded-lg outline-none"
                  onChange={(e) => handleMarketplaceChange(e, idx)}
                />
              </div>
              <button
                className="mt-4 w-full max-w-[200px] mx-auto text-white px-4 py-2 bg-[#e41e1e] rounded-lg"
                onClick={() => handleRemoveMarketPlace(idx)}
              >
                Remove
              </button>
            </div>
          ))}

          <div className="flex justify-center">
            <button
              className="w-full max-w-[400px] mx-auto text-white px-4 py-2 bg-[#262C34] rounded-lg"
              onClick={handleAddNewMarketPlace}
            >
              {" "}
              Add new one{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default System;
