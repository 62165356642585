import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

const EditFunnel = ({
  steps,
  deleteStep,
  deleteQuestion,
  deleteOption,
  addQuestion,
  addStep,
  handleSaveSteps,
  handleDeleteFunnel,
}) => {
  return (
    <div>
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Customize Your Funnel</h2>

        {steps.map((step, index) => (
          <div key={index} className="mb-6 border rounded-lg p-6 relative">
            <button
              onClick={() => deleteStep(index)}
              className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-red-500"
            >
              <DeleteIcon />
            </button>
            <h2 className="text-xl font-semibold mb-4">{step.name}</h2>
            {step.questions.map((question, qIndex) => (
              <div key={qIndex} className="mb-6 border-b pb-6">
                <button
                  onClick={() => deleteQuestion(index, qIndex)}
                  className="float-right text-gray-500 hover:text-red-500"
                >
                  <RemoveIcon />
                </button>
                <h3 className="text-lg font-semibold mb-2">Question</h3>
                <h4 className="mb-4">{question.text}</h4>

                <h3 className="text-lg font-semibold mb-2">Options</h3>
                <ul>
                  {question.options.map((option, oIndex) => (
                    <div key={oIndex} className="mb-2 border-b pb-2">
                      <button
                        onClick={() => deleteOption(index, qIndex, oIndex)}
                        className="float-right text-gray-500 hover:text-red-500"
                      >
                        <RemoveIcon />
                      </button>
                      <li key={oIndex}>{option.option}</li>
                    </div>
                  ))}
                </ul>

                <h3 className="text-lg font-semibold mb-2">Field Required:</h3>
                <h4 className="mb-4">
                  {question.requiredField === true ? "true" : "false"}
                </h4>
              </div>
            ))}

            <Button
              startIcon={<AddIcon />}
              onClick={() => addQuestion(index)}
              className="mt-4 w-full bg-neutral-700 hover:bg-neutral-800 text-white"
            >
              Add Question
            </Button>
          </div>
        ))}

        <button
          onClick={addStep}
          className="w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 hover:bg-neutral-800"
        >
          Add Step
        </button>
        <div className="flex flex-row space-x-4 my-4">
          <Button
            variant="contained"
            onClick={handleSaveSteps}
            className="w-1/2 bg-neutral-700 hover:bg-neutral-800"
          >
            Save Funnel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteFunnel}
            className="w-1/2 bg-neutral-700 hover:bg-neutral-800"
          >
            Delete Funnel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditFunnel;
