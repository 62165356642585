import React from 'react';

import Widget from './Widget/Widget';

function Preview({
  funnelTitle,
  homeSearchPage,
  calenderBookingPage,
  pluginToken,
  varbiage,
}) {
  return (
    <div className="mtge-rates_app">
      <Widget
        funnelTitle={funnelTitle}
        homeSearchPage={homeSearchPage}
        calenderBookingPage={calenderBookingPage}
        sitekey={pluginToken}
        varbiage={varbiage}
      />
    </div>
  );
}

export default Preview;
