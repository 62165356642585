import React, { useState } from "react";
import { toast } from "react-toastify";
import useContext from "../../../context/StateProvider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";

function ResetPassword({ selectedUser, setSelectedUser, show, onClose }) {
  const [{ user }] = useContext();

  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const handleSave = () => {
    if (newPassword && rePassword && newPassword === rePassword) {
      axios({
        url: `/api/users/${selectedUser._id}/upgrade/password`,
        method: "PUT",
        data: {
          newPassword,
        },
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            toast.success(resData.status);
            setSelectedUser(resData.data);
            onClose();
          } else {
            toast.error(resData.status);
          }
        })
        .catch((err) => {
          console.log(err);

          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    } else if (newPassword !== rePassword) {
      toast.error("Both password didn't match!");
    }
  };

  return (
    <Dialog open={show} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent>
        <div className="mb-6 flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="password"
          >
            {" "}
            New Password{" "}
          </label>
          <input
            id="password"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="password"
            name="password"
            value={newPassword}
            placeholder="XXXXXXXXXXXXXXXXXXX"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label
            className="text-base text-[#39393bde] font-extrabold"
            htmlFor="password"
          >
            {" "}
            Re-Type Password{" "}
          </label>
          <input
            id="password"
            className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
            type="password"
            name="password"
            value={rePassword}
            placeholder="XXXXXXXXXXXXXXXXXXX"
            onChange={(e) => setRePassword(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={onClose}
          className="px-6 py-2 text-white font-bold rounded-lg bg-neutral-700"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="px-6 py-2 text-white font-bold rounded-lg bg-neutral-700"
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPassword;
