import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from "axios";
import useContext from "../../context/StateProvider";
import { toast } from "react-toastify";
import ActionTypes from "../../utils/actionTypes";
import { useNavigate } from "react-router-dom";

import profileIcon from "../../assets/images/user-icon.png";
import companyIcon from "../../assets/images/company-icon.png";

import Loader from "../Loader/Loader";
import LeadsModal from "./Modals/Leads";
import ResetPassword from "./Modals/ResetPassword";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function Main({
  selectedUser,
  setSelectedUser,
  setUsers,
  allUsers,
  handleToggler,
}) {
  const navigate = useNavigate();

  const [{ user }, dispatch] = useContext();

  const [openResetPass, setOpenResetPass] = useState(false);

  const [accAdminStatus, setAccAdminStatus] = useState(false);
  const [accActiveStatus, setAccActiveStatus] = useState(false);
  const [accVerifiedStatus, setAccVerifiedStatus] = useState(false);
  const [accPassword, setAccPassword] = useState("");
  const [upgradeLogo, setUpgradeLogo] = useState(false);
  const [showBranding, setShowBranding] = useState(true);
  const [plugins, setPlugins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPlugin, setSelectedPlugin] = useState({
    show: false,
    token: null,
  });

  useEffect(() => {
    if (!selectedUser) return;
    setAccAdminStatus(selectedUser.accountType === "Admin");
    setAccActiveStatus(selectedUser.active);
    setAccVerifiedStatus(selectedUser.verified);
    setAccPassword(selectedUser.ps);
    setIsLoading(true);
    axios({
      url: `/api/plugin/${selectedUser._id}/all`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          setUpgradeLogo(resData.data[0]?.logo.allowed);
          setShowBranding(resData.data[0].showBrandingBottomText);
          setPlugins(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);

        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [selectedUser]);

  const handleUpdate = () => {
    axios({
      url: `/api/plugin/${selectedUser._id}/upgrade`,
      method: "PUT",
      data: {
        showBrandingBottomText: showBranding,
        logo: upgradeLogo,
      },
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          toast.success("Updated!");
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);

        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const handleDeleteUser = () => {
    axios({
      url: `/api/users/${selectedUser._id}/delete`,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          let tmpt = allUsers.filter((dt) => dt._id !== selectedUser._id);
          console.log(tmpt);
          setUsers(tmpt);
          setSelectedUser(null);
          toast.success("Updated!");
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);

        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const handleUpdateUser = (data) => {
    axios({
      url: "/api/users/" + selectedUser._id + "/upgrade",
      method: "put",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          toast.success("Updated successfully");
          setSelectedUser(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const handleLoginAsUser = () => {
    dispatch({
      type: ActionTypes.SET_ADMIN,
      payload: user,
    });
    dispatch({
      type: ActionTypes.SET_USER,
      payload: { ...selectedUser, token: user.token },
    });

    navigate("/dashboard/profiles");
  };

  if (isLoading) return <Loader customClass="relative flex-1 lg:flex-[0.7]" />;

  return (
    <>
      <div className="relative p-4 md:p-8 flex-1 lg:flex-[0.7] overflow-y-auto">
        <div className="text-right lg:hidden">
          <button onClick={handleToggler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </button>
        </div>
        {selectedUser ? (
          <section>
            <h1 className="text-xl md:text-2xl font-bold"> User Details </h1>
            <div className="mt-8 grid md:grid-cols-2 space-x-1">
              <div
                className="w-20 h-20 md:w-24 md:h-24"
                style={{
                  backgroundImage: `url(${selectedUser.profilePic}), url(${profileIcon})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                className="w-20 h-20 md:w-24 md:h-24"
                style={{
                  backgroundImage: `url(${selectedUser.companyLogo}), url(${companyIcon})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="mt-4 col-span-2 sm:grid sm:grid-cols-2 space-y-4 sm:space-y-0">
                <h3 className="font-normal text-sm md:text-base">
                  <strong>Name:</strong> {selectedUser.firstName}{" "}
                  {selectedUser.lastName}
                </h3>
                <p className="font-normal text-sm md:text-base">
                  <strong>E-mail:</strong> <small>{selectedUser.email}</small>
                </p>
                <p className="font-normal text-sm md:text-base">
                  <strong>Phone:</strong> <small>{selectedUser.phone}</small>
                </p>
                <p className="font-normal text-sm md:text-base">
                  <strong>Company:</strong> {selectedUser.company || "null"}
                </p>
                <p className="font-normal text-sm md:text-base">
                  <strong>License:</strong> {selectedUser.license || "null"}
                </p>
                <p className="font-normal text-sm md:text-base">
                  <strong>Account Type:</strong>{" "}
                  <small>{selectedUser.accountType}</small>
                </p>
                <p className="font-normal text-sm md:text-base space-x-1">
                  <strong>Active:</strong>{" "}
                  <FormControlLabel
                    control={<Android12Switch />}
                    checked={accActiveStatus}
                    onChange={(e) => {
                      setAccActiveStatus((prev) => {
                        handleUpdateUser({ active: !prev });
                        return !prev;
                      });
                    }}
                  />
                </p>
                <p className="font-normal text-sm md:text-base space-x-1">
                  <strong>Verified:</strong>{" "}
                  <FormControlLabel
                    control={<Android12Switch />}
                    checked={accVerifiedStatus}
                    onChange={(e) => {
                      setAccVerifiedStatus((prev) => {
                        handleUpdateUser({ verified: !prev });
                        return !prev;
                      });
                    }}
                  />
                </p>
                <p className="font-normal text-sm md:text-base space-x-1">
                  <strong>Upgrade to admin:</strong>{" "}
                  <FormControlLabel
                    control={<Android12Switch />}
                    checked={accAdminStatus}
                    onChange={(e) => {
                      setAccAdminStatus((prev) => {
                        handleUpdateUser({
                          accountType: !prev ? "Manager" : "Basic",
                        });
                        return !prev;
                      });
                    }}
                  />
                </p>
                <div className="col-span-2 flex flex-col sm:flex-row sm:items-end sm:space-x-4 space-y-2 sm:space-y-0">
                  <div className="md:mr-4 flex flex-col space-y-1">
                    <label
                      className="text-base text-[#39393bde] font-extrabold"
                      htmlFor="password"
                    >
                      {" "}
                      Password{" "}
                    </label>
                    <input
                      id="password"
                      className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                      type="text"
                      name="password"
                      value={accPassword}
                      placeholder="XXXXXXXXXXXXXXXXXXX"
                      disabled
                    />
                  </div>
                  <div className="mb-6 flex items-end">
                    <button
                      className="px-6 py-2 text-white font-bold rounded-lg bg-neutral-700"
                      onClick={() => setOpenResetPass(true)}
                    >
                      Change Password
                    </button>
                  </div>
                </div>

                <FormGroup
                  className="col-span-2 grid grid-cols-1 sm:grid-cols-2"
                  style={{ display: "grid", mt: 1 }}
                >
                  <div className="flex flex-col space-y-2">
                    <span className="font-semibold text-sm md:text-base">
                      {" "}
                      Logo upgrade option{" "}
                    </span>
                    <FormControlLabel
                      control={<Android12Switch />}
                      checked={upgradeLogo}
                      label={upgradeLogo ? "on" : "off"}
                      onChange={(e) => setUpgradeLogo((prev) => !prev)}
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="font-semibold text-sm md:text-base">
                      {" "}
                      Branding{" "}
                    </span>
                    <FormControlLabel
                      control={<Android12Switch />}
                      checked={showBranding}
                      label={showBranding ? "Show" : "Hide"}
                      onChange={(e) => setShowBranding((prev) => !prev)}
                    />
                  </div>
                </FormGroup>
                <div className="mt-6 col-span-2 flex flex-wrap gap-2">
                  <button
                    className="block w-full sm:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
                    onClick={handleUpdate}
                  >
                    Save
                  </button>
                  <button
                    className="block w-full sm:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
                    onClick={handleLoginAsUser}
                  >
                    Login as user
                  </button>
                  <button
                    className="block w-full sm:w-40 px-4 py-2 text-white font-bold rounded-lg bg-red-600"
                    onClick={handleDeleteUser}
                  >
                    Delete This User
                  </button>
                </div>
              </div>
            </div>
            <h1 className="mt-12 text-xl md:text-3xl font-bold">
              All calculators <span>&#40;</span>
              {plugins.length}
              <span>&#41;</span>
            </h1>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              {plugins.map((pl, idx) => (
                <PluginCard
                  key={idx}
                  data={pl}
                  isOwnPlugin={pl.owner === selectedUser.email}
                  openLeadsModal={(token) =>
                    setSelectedPlugin({ show: true, token })
                  }
                />
              ))}
            </div>
          </section>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <div className="flex items-center text-lg font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
                />
              </svg>
              <span>No user selected!</span>
            </div>
          </div>
        )}
      </div>
      <LeadsModal
        show={selectedPlugin.show}
        pluginToken={selectedPlugin.token}
        allPlugins={plugins}
        onClose={() => setSelectedPlugin({ show: false, token: null })}
      />
      <ResetPassword
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        show={openResetPass}
        onClose={() => setOpenResetPass(false)}
      />
    </>
  );
}

const PluginCard = ({ data, isOwnPlugin, openLeadsModal }) => {
  return (
    <button
      className="text-center p-6 rounded-lg border-2 shadow-lg"
      onClick={() => openLeadsModal(data.token)}
    >
      <h3> {data.name} </h3>
      <p className="break-words">
        {" "}
        Owner - {isOwnPlugin ? "Self" : data.owner}{" "}
      </p>
    </button>
  );
};

export default Main;
