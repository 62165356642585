const types = {
  SET_USER: 'SET_USER',
  SET_ADMIN: 'SET_ADMIN',
  SET_CALCULATOR_INPUTS: 'SET_CALCULATOR_INPUTS',
  CLEAR_CALCULATOR_INPUTS: 'CLEAR_CALCULATOR_INPUTS',
  SET_CURRENT_PLUGIN: 'SET_CURRENT_PLUGIN',
  SET_ALL_PLUGIN: 'SET_ALL_PLUGIN',
  SET_EMAIL_TEMPLATE: 'SET_EMAIL_TEMPLATE',
  UPDATE_PLUGIN_IN_USER: 'UPDATE_PLUGIN_IN_USER',
  SET_ALL_PROFILES: 'SET_ALL_PROFILES',
  SET_IS_OWN_PROFILE: 'SET_IS_OWN_PROFILE',
  SET_PAGE: 'SET_PAGE',
};

export default types;
