import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { FaPlay } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdCancel, MdOutlineFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { MyConstextApi } from "../../../context/ContextApi";
import useContext from "../../../context/StateProvider";
import facepopService from "../../../services/facepop.service";
import CallToActionPopUp from "./CallToAction/CallToActionPopUp";
import GradianModal from "./GradientModal";
import AuthorInfo from "./component/AuthorInfo";
import CodeEmbed from "./component/CodeEmbed";
import CtaBuilder from "./component/CtaBuilder";
import OptionInputField from "./component/OptionInputField";
import PlayerController from "./component/PlayerController";
import VideoComponent from "./component/VideoComponent";
import VideoInfo from "./component/VideoInfo";
import VideoProgressBar from "./component/VideoProgressbar";
import "./facepopuoption.css";
import { IconArrowRight } from "./icons/RightArrow";

const FacePopUpOption = () => {
  const [{ user }, dispatch] = useContext();
  const params = useParams();
  const pluginId = params.pluginId;

  const {
    redirectUrl,
    newWindow,
    redirectButtonText,
    redirectButtonHeight,
    redirectButtonUpdate,
    redirecticonIndex,
    redirectUrl3,
    redirectButtonHeight3,
    redirectButtonText3,
    redirectButtonUpdate3,
    bgColor4: exBgColor4,
    bgColor5: exBgColor5,
    redirecticonIndex3,

    textInputType,

    textInputPlaceHolder,

    textInputbgColor,

    textInputTextColor,

    textInputUpdate,
    fetchConnectCta,
    fetchRedirectCta,
    fetchRedirectFullCta,
    fetchVideoRecCta,
    fetchCalBookCta,
    fetchInputTextCta,
    fetchChatAiCta,
    fetchRunCodeCta,
    fetchCloseCta,
  } = MyConstextApi();

  // data
  const [videoPositon, setVideoPosition] = useState("left");
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(480);
  const [radious, setRadious] = useState(0);
  const [vertcalMargin, setVerticleMargin] = useState(0);
  const [horizontalMargin, setHorizontalMargin] = useState(0);
  const [shadow, setShadow] = useState("");
  const [playerWidth, setPlayerWidth] = useState(400);
  const [playerHeight, setPlayerHeight] = useState(580);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDesc, setVideoDesc] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorOccupation, setAuthorOccupation] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [playerController, setPlayerController] = useState(true);
  const [showCTA, setShowCTA] = useState("");
  const [afterClick, setAfterClick] = useState(true);
  const [onMobile, setOnMobile] = useState(true);
  const [playerRadius, setPlayerRadious] = useState(0);
  const [controllerSize, setControllerSize] = useState(0);
  // data

  // redirect data

  const [pluginToken, setPluginToken] = useState("");
  let videoRef = useRef();
  let thumbnailRef = useRef();
  const imageRef = useRef();

  const [selectButton, setSelectButton] = useState(1);
  const [shadowButton, setShadowButton] = useState(1);

  const [playAgain, setPlayAgain] = useState(false);
  const [muted, setMuted] = useState(false);
  const [playerPosition, setPlayerPosition] = useState("toggle");

  const [popup, setPopup] = useState(false);

  const [open, setOpen] = useState(false);
  const [bgColor, setBgColor] = useState("#FF0000");

  const [open2, setOpen2] = useState(false);
  const [bgColor2, setBgColor2] = useState("#FF0000");

  const [open3, setOpen3] = useState(false);
  const [bgColor3, setBgColor3] = useState("#FF0000");

  const [open4, setOpen4] = useState(false);
  const [bgColor4, setBgColor4] = useState("#FF0000");

  const [open5, setOpen5] = useState(false);
  const [bgColor5, setBgColor5] = useState("#FF0000");

  const [open6, setOpen6] = useState(false);
  const [bgColor6, setBgColor6] = useState("#FF0000");

  const [open7, setOpen7] = useState(false);
  const [bgColor7, setBgColor7] = useState("#FF0000");

  const [open8, setOpen8] = useState(false);
  const [bgColor8, setBgColor8] = useState("#FF0000");

  const [open9, setOpen9] = useState(false);
  const [bgColor9, setBgColor9] = useState("gray");

  const [open10, setOpen10] = useState(false);
  const [bgColor10, setBgColor10] = useState("#FF0000");

  const [open11, setOpen11] = useState(false);
  const [bgColor11, setBgColor11] = useState("#FF0000");

  const [open12, setOpen12] = useState(false);
  const [bgColor12, setBgColor12] = useState("#FF0000");

  const [open13, setOpen13] = useState(false);
  const [bgColor13, setBgColor13] = useState("#FF0000");

  const [open14, setOpen14] = useState(false);
  const [bgColor14, setBgColor14] = useState("#FF0000");

  const [open15, setOpen15] = useState(false);
  const [bgColor15, setBgColor15] = useState("#FF0000");

  const [open16, setOpen16] = useState(false);
  const [bgColor16, setBgColor16] = useState("#FF0000");

  const [isMuted, setMutedd] = useState(false);
  const [objectPosition, setObjectPosition] = useState({ x: 0, y: 0 });

  const [videoProgress, setVideoProgress] = useState(0);
  const [seekerHeight, setSeekerHeight] = useState(0);

  const [showWidget, setShowWidget] = useState(true);

  const [thumbnail, setThumbnail] = useState("");

  const [minimizeButton, setMinimizeButton] = useState({
    styles: {},
    position: {},
  });
  const [authorPosition, setAuthorPosition] = useState("");

  const [isToggled, setIsToggle] = useState(true);
  const [video, setVideo] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [inTabSize, setInTabSize] = useState(false);
  const [thumbOption, setThumbOption] = useState("video");
  const [thumbPlay, setThumbPlay] = useState("auto");

  useEffect(() => {
    const checkWindowSize = () => {
      setInTabSize(window.innerWidth <= 765);
    };
    checkWindowSize();

    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  // const handleDragStart = (e) => {
  //   // Store the initial position when dragging starts
  //   const initialX = e.clientX - position.x;
  //   const initialY = e.clientY - position.y;

  //   const handleDragMove = (e) => {
  //     // Update the position while dragging
  //     const newX = e.clientX - initialX;
  //     const newY = e.clientY - initialY;

  //     setObjectPosition({ x: newX, y: newY });
  //   };

  //   const handleDragEnd = () => {
  //     // Remove the event listeners when dragging ends
  //     document.removeEventListener("mousemove", handleDragMove);
  //     document.removeEventListener("mouseup", handleDragEnd);
  //   };

  //   // Add event listeners for dragging
  //   document.addEventListener("mousemove", handleDragMove);
  //   document.addEventListener("mouseup", handleDragEnd);
  // };

  useEffect(() => {
    // fetchConnectCta(pluginId, user.token);
    // fetchVideoRecCta(pluginId, user.token);
    // fetchChatAiCta(pluginId, user.token);
    fetchRedirectCta(pluginId, user.token);
    fetchRedirectFullCta(pluginId, user.token);
    fetchCalBookCta(pluginId, user.token);
    fetchInputTextCta(pluginId, user.token);
    fetchRunCodeCta(pluginId, user.token);
    fetchCloseCta(pluginId, user.token);
  }, []);

  const onVideoHandler = () => {
    videoRef.current.click();
  };

  const onThumbnailHandler = () => {
    thumbnailRef.current.click();
  };
  const onImageHandler = () => {
    imageRef.current.click();
  };
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen14(false);
    setOpen15(false);
    setOpen16(false);
  };

  const callback = () => {
    setIsToggle(!isToggled);
  };
  // useEffect(() => {
  //   setPluginId(pluginId);
  //   setUserToken(user.token);
  // }, []);

  useEffect(() => {}, []);

  const handleVideoUpload = async (event) => {
    console.log("I am on");
    const selectedFile = event;
    const pluginId = params.pluginId;

    if (selectedFile) {
      const mediaFormData = new FormData();
      mediaFormData.append("media", selectedFile);
      console.log("imageFormData:", mediaFormData);
      const response = await toast.promise(
        facepopService.uploadMedia(pluginId, mediaFormData),
        {
          pending: "Please wait. The media is uploading...",
          success: "Media uploaded successfully",
          error: "An error occurred while uploading the media.",
        },
      );

      const videoURL = response.data.path;
      console.log("video path: ", response.data.path);
      setVideo(videoURL);
    }
  };

  const handleThumbnailUpload = async (event) => {
    const selectThumbnail = event;
    const pluginId = params.pluginId;

    if (selectThumbnail) {
      const thumbFormat = new FormData();
      thumbFormat.append("thumbnail", selectThumbnail);

      const response = await toast.promise(
        facepopService.uploadThumbnail(pluginId, thumbFormat),
        {
          pending: "Please wait. The thumbnail is uploading...",
          success: "thumbnail uploaded successfully",
          error: "An error occurred while uploading the thumbnail.",
        },
      );

      const thumbnailPath = response.data.path;
      console.log("thumbnail path: ", response.data.path);
      setThumbnail(thumbnailPath);
    }
  };

  const handleAuthorImage = async (e) => {
    const selectedImage = e.target.files[0];
    const pluginId = params.pluginId;

    if (selectedImage) {
      const mediaFormData = new FormData();
      mediaFormData.append("authorImage", selectedImage);
      console.log("authorImage:", mediaFormData);
      const response = await toast.promise(
        facepopService.uploadImage(pluginId, mediaFormData),
        {
          pending: "Please wait. The image is uploading...",
          success: "image uploaded successfully",
          error: "An error occurred while uploading the image.",
        },
      );

      const authorImgPath = response.data.path;
      console.log("video path: ", response.data.path);
      setProfileImage(authorImgPath);
    }
  };

  const playVideoRef = useRef();
  const [isplaying, setIsPlaying] = useState(false);

  const onPlayHandler = () => {
    const video = playVideoRef.current;
    if (isplaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsToggle(false);
      setIsPlaying(true);
    }
  };

  const handleActionpopUp = () => {
    setPopup(false);
  };

  // const [imageUrl2, setImageUrl2] = useState("");

  // useEffect(() => {
  //   if (!profileImage) {
  //     return;
  //   }
  //   const fileReader = new FileReader();
  //   fileReader.onload = () => {
  //     setImageUrl2(fileReader.result);
  //   };
  //   fileReader.readAsDataURL(profileImage);
  // }, [profileImage]);

  const onCloseHandler = () => {
    const video = playVideoRef.current;

    if (isplaying) {
      video.pause();
      setIsPlaying(false);
    }
    setIsToggle(true);
  };

  useEffect(() => {
    console.log("istoggle use: ", isToggled);
  }, [isToggled]);

  const onCloseSelectButtonHandler = (select) => {
    console.log("facepop onCloseSelectButtonHandler: ", select);
    if (select === "open") {
      setShowCloseIcon(true);
    } else {
      setShowCloseIcon(false);
    }
  };

  const onCtaShow = (select) => {
    setShowCTA(select);
  };

  const onAfterClick = (select) => {
    if (select === "don't hide") {
      setAfterClick(true);
    } else {
      setAfterClick(false);
    }
  };
  const onPlayerControl = (select) => {
    if (select === "Yes") {
      setPlayerController(true);
    } else if (select === "No") {
      setPlayerController(false);
    }
  };

  const onToggleMute = (e) => {
    e.stopPropagation();
    const video = playVideoRef.current;
    console.log("mute toggle: ", video);
    setMuted(!muted);
    if (video) {
      video.muted = muted === true ? false : true;
    }
  };

  const muteHandler = () => {
    setMuted(true);
    if (playVideoRef.current) {
      playVideoRef.current.muted = true;
    }
  };

  const unMuteHandler = () => {
    setMuted(false);
    if (playVideoRef.current) {
      playVideoRef.current.muted = false;
    }
  };

  useEffect(() => {
    const videoElement = playVideoRef.current;

    if (!videoElement) {
      console.error("Video element not found");
      return;
    }

    const updateProgress = () => {
      const progress = (videoElement.currentTime / videoElement.duration) * 100;
      console.log("Current progress:", progress);
      setVideoProgress(progress);
    };

    videoElement.addEventListener("timeupdate", updateProgress);

    return () => {
      videoElement.removeEventListener("timeupdate", updateProgress);
    };
  }, [playVideoRef, videoProgress, video]);

  const handleProgressBarClick = (e) => {
    e.stopPropagation();

    const progressBar = e.currentTarget;
    const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
    const percentageClicked = (clickPosition / progressBar.clientWidth) * 100;
    const newTime = (percentageClicked / 100) * playVideoRef.current.duration;

    playVideoRef.current.currentTime = newTime;
    setVideoProgress(percentageClicked);
  };

  const handleVideoEnd = () => {
    if (playAgain) {
      playVideoRef.current.currentTime = 0;
      playVideoRef.current.play();
    } else {
      setIsPlaying(false);
    }
  };

  const selectIcon = IconArrowRight[redirecticonIndex];
  const selectIcon2 = IconArrowRight[redirecticonIndex3];
  console.log(redirecticonIndex);
  console.log(selectIcon);

  const handleOptionUpdate = async () => {
    try {
      const pluginId = params.pluginId;
      // const { id, ...data } = formData;
      let facePopStyle = {
        position: videoPositon,
        shadow: shadow,
        verticalMargin: vertcalMargin,
        horizontalMargin: horizontalMargin,
      };
      let toggleStyle = {
        width: width,
        height: height,
        cornerRadius: radious,
        showCloseIcon: showCloseIcon,
      };

      let playerStyle = {
        width: playerWidth,
        height: playerHeight,
        cornerRadius: playerRadius,
        seekerHeight: seekerHeight,
        showControls: playerController,
        onMobile: onMobile,
      };

      let generalSettings = {
        titleColor: bgColor,
        descColor: bgColor2,
        overlay: bgColor3,
        shadow: bgColor4,
      };

      let playerColor = {
        control: bgColor5,
        seekerColor: bgColor6,
        authorName: bgColor7,
        authorOccup: bgColor8,
      };

      let toggleColor = {
        playIcon: bgColor9,
        closeBG: bgColor10,
        clsIconColor: bgColor11,
        clsIconBorder: bgColor12,
      };

      let loadingScreen = {
        background: bgColor13,
        spinner: bgColor14,
      };

      let otherColor = {
        bgOverlay: bgColor15,
        backBtnInsideFrame: bgColor16,
      };

      console.log("othercolor: ", otherColor, facePopStyle, toggleStyle);

      const updatedFacepop = await facepopService.updateFacepop(
        pluginId,
        video,
        thumbnail,
        thumbOption,
        thumbPlay,
        videoTitle,
        videoDesc,
        authorName,
        authorOccupation,
        profileImage,
        authorPosition,
        showCTA,
        afterClick,
        facePopStyle,
        toggleStyle,
        playerStyle,
        generalSettings,
        playerColor,
        toggleColor,
        loadingScreen,
        otherColor,
        playAgain,
        muted,
        playerPosition,
        isToggled,
      );
      console.log("Updated FacepopPlugin:", updatedFacepop);
      console.log("authorPosition", authorPosition);

      if (updatedFacepop.success) {
        toast.success("FacepopPlugin updated successfully");
        setVideo(updatedFacepop.data.videoURL);
        setThumbnail(updatedFacepop.data.thumbnail);
        setThumbOption(updatedFacepop.data.thumbOption);
        setThumbPlay(updatedFacepop.data.thumbPlay);
        setVideoTitle(updatedFacepop.data.videoTitle);
        setVideoDesc(updatedFacepop.data.videDesc);
        setAuthorName(updatedFacepop.data.authorName);
        setAuthorOccupation(updatedFacepop.data.occupation);
        setProfileImage(updatedFacepop.data.authorImg);
        setAuthorPosition(updatedFacepop.data.authorPosition);
        setShowCTA(updatedFacepop.data.whenShowCTA);
        setAfterClick(updatedFacepop.data.ctaShow);
        // facepop style
        setVideoPosition(updatedFacepop.data.facePopStyle.position);
        setShadow(updatedFacepop.data.facePopStyle.shadow);
        setVerticleMargin(updatedFacepop.data.facePopStyle.verticalMargin);
        setHorizontalMargin(updatedFacepop.data.facePopStyle.horizontalMargin);
        // toggle Style
        setWidth(updatedFacepop.data.toggleStyle.width);
        setHeight(updatedFacepop.data.toggleStyle.height);
        setRadious(updatedFacepop.data.toggleStyle.cornerRadius);
        setShowCloseIcon(updatedFacepop.data.toggleStyle.showCloseIcon);
        // player style
        setPlayerWidth(updatedFacepop.data.playerStyle.width);
        setPlayerHeight(updatedFacepop.data.playerStyle.height);
        setOnMobile(updatedFacepop.data.playerStyle.onMobile);
        setPlayerRadious(updatedFacepop.data.playerStyle.cornerRadius);
        setSeekerHeight(updatedFacepop.data.playerStyle.seekerHeight);
        setControllerSize(updatedFacepop.data.playerStyle.controllerSize);
        setPlayerController(updatedFacepop.data.playerStyle.showControls);

        // general settings
        setBgColor(updatedFacepop.data.generalSettings.titleColor);
        setBgColor2(updatedFacepop.data.generalSettings.descColor);
        setBgColor3(updatedFacepop.data.generalSettings.overlay);
        setBgColor4(updatedFacepop.data.generalSettings.shadow);
        // player colors
        setBgColor5(updatedFacepop.data.playerColor.control);
        setBgColor6(updatedFacepop.data.playerColor.seekerColor);
        setBgColor7(updatedFacepop.data.playerColor.authorName);
        setBgColor8(updatedFacepop.data.playerColor.authorOccup);
        // toggle colors
        setBgColor9(updatedFacepop.data.toggleColor.playIcon);
        setBgColor10(updatedFacepop.data.toggleColor.closeBG);
        setBgColor11(updatedFacepop.data.toggleColor.clsIconColor);
        setBgColor12(updatedFacepop.data.toggleColor.clsIconBorder);
        // loading screen
        setBgColor13(updatedFacepop.data.loadingScreen.background);
        setBgColor14(updatedFacepop.data.loadingScreen.spinner);
        // other color
        setBgColor15(updatedFacepop.data.otherColor.bgOverlay);
        setBgColor16(updatedFacepop.data.otherColor.backBtnInsideFrame);

        // others
        setPlayAgain(updatedFacepop.data.behavior);
        setMuted(updatedFacepop.data.defaultSound);

        setPlayerPosition(updatedFacepop.data.playerPosition);
        // setIsToggle(updatedFacepop.data.isToggled);
      }
      // Handle success, update state, or perform other actions
    } catch (error) {
      console.error("Error updating FacepopPlugin:", error);
      toast.error(error.message);

      // Handle error, show error message, or perform other actions
    }
  };

  useEffect(() => {
    const getFunnelData = async () => {
      try {
        const pluginId = params.pluginId;
        const response = await facepopService.getFunnelById(
          pluginId,
          user.token,
        );
        if (response.success) {
          console.log("get facepop data: ", response);

          // toast.success(response.status);

          setPluginToken(response.data.token);
          setVideo(response.data.videoURL);
          setThumbnail(response.data.thumbnail);
          setThumbOption(response.data.thumbOption);
          setThumbPlay(response.data.thumbPlay);
          setVideoTitle(response.data.videoTitle);
          setVideoDesc(response.data.videDesc);
          setAuthorName(response.data.authorName);
          setAuthorOccupation(response.data.occupation);
          setProfileImage(response.data.authorImg);
          setAuthorPosition(response.data.authorPosition);
          setShowCTA(response.data.whenShowCTA);
          setAfterClick(response.data.ctaShow);
          // facepop style
          setVideoPosition(response.data.facePopStyle.position);
          setShadow(response.data.facePopStyle.shadow);
          setVerticleMargin(response.data.facePopStyle.verticalMargin);
          setHorizontalMargin(response.data.facePopStyle.horizontalMargin);
          // toggle Style
          setWidth(response.data.toggleStyle.width);
          setHeight(response.data.toggleStyle.height);
          setRadious(response.data.toggleStyle.cornerRadius);
          setShowCloseIcon(response.data.toggleStyle.showCloseIcon);
          // player style
          setPlayerWidth(response.data.playerStyle.width);
          setPlayerHeight(response.data.playerStyle.height);
          setOnMobile(response.data.playerStyle.onMobile);
          setPlayerRadious(response.data.playerStyle.cornerRadius);
          setSeekerHeight(response.data.playerStyle.seekerHeight);
          setControllerSize(response.data.playerStyle.controllerSize);
          setPlayerController(response.data.playerStyle.showControls);
          // general settings
          setBgColor(response.data.generalSettings.titleColor);
          setBgColor2(response.data.generalSettings.descColor);
          setBgColor3(response.data.generalSettings.overlay);
          setBgColor4(response.data.generalSettings.shadow);
          // player colors
          setBgColor5(response.data.playerColor.control);
          setBgColor6(response.data.playerColor.seekerColor);
          setBgColor7(response.data.playerColor.authorName);
          setBgColor8(response.data.playerColor.authorOccup);
          // toggle colors
          setBgColor9(response.data.toggleColor.playIcon);
          setBgColor10(response.data.toggleColor.closeBG);
          setBgColor11(response.data.toggleColor.clsIconColor);
          setBgColor12(response.data.toggleColor.clsIconBorder);
          // loading screen
          setBgColor13(response.data.loadingScreen.background);
          setBgColor14(response.data.loadingScreen.spinner);
          // other color
          setBgColor15(response.data.otherColor.bgOverlay);
          setBgColor16(response.data.otherColor.backBtnInsideFrame);

          //button
          setMinimizeButton(response.data.button);

          // others
          setPlayAgain(response.data.behavior);
          setMuted(response.data.defaultSound);

          // setPosition(response.data.position);
          // setIsToggle(response.data.isToggled);
        } else {
          console.log("get facepop data: ", response);
          // toast.error(response.status);
        }
      } catch (err) {
        console.log(err);
        // toast.error(err.message);
      }
    };

    getFunnelData();
  }, []);

  console.log("onMobile: ", onMobile);
  console.log("onMobile isToggled: ", isToggled);

  const desktopStyles = {
    marginInline: `${horizontalMargin}px`,
    marginBlock: `${vertcalMargin}px`,
    width: `${isToggled ? width : playerWidth}px`,
    transition: "all 0.3s ease 0s",
  };

  const mobileStyles = {
    width: "100%",
    // height: "100vh",
    margin: 0,
    transition: "all 0.3s ease 0s",
  };

  const styles =
    inTabSize && onMobile && !isToggled ? mobileStyles : desktopStyles;

  return (
    <>
      {!isToggled && playerPosition === "center" && (
        <div className="fixed w-full h-full backdrop-blur-sm z-50"></div>
      )}
      <div>
        {video && (
          <div
            // onClick={onPlayHandler}
            style={styles}
            className={`fixed bottom-0 z-[999999999] ${
              isToggled && "hover:scale-110"
            } cursor-pointer ${
              videoPositon === "left" && playerPosition === "toggle"
                ? "left-0"
                : videoPositon === "right" && playerPosition === "toggle"
                ? "right-0"
                : playerPosition === "center" && !isToggled
                ? onMobile && inTabSize
                  ? "left-0 top-0"
                  : "left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                : videoPositon === "left"
                ? "left-0"
                : "right-0"
            }

          `}
          >
            {/* {!isToggled && videoPositon === "center" && (
            <div className="absolute w-[1500px] h-[1500px] top-[-100%] left-[-100%] backdrop-blur-sm"></div>
          )} */}
            <div className="relative w-full z-[99999999999">
              <VideoComponent
                playVideoRef={playVideoRef}
                video={video}
                thumbnail={thumbnail}
                isToggled={isToggled}
                shadow={shadow}
                isplaying={isplaying}
                playerHeight={playerHeight}
                height={height}
                position={playerPosition}
                radious={radious}
                playAgain={playAgain}
                onPlayHandler={onPlayHandler}
                bgColor9={bgColor9}
                muted={muted}
                handleVideoEnd={handleVideoEnd}
                onMobile={onMobile}
                inTabSize={inTabSize}
                thumbOption={thumbOption}
                thumbPlay={thumbPlay}
              />

              {/* Draggable div for adjusting position */}
              {/* <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                cursor: "move",
              }}
              onMouseDown={handleDragStart}
            ></div> */}

              {showCloseIcon && !isToggled && (
                <div className="absolute right-0 top-0 z-[99999]">
                  <button onClick={onCloseHandler}>
                    <MdCancel style={{ fontSize: "35px", color: bgColor11 }} />
                  </button>
                </div>
              )}

              {!isToggled && (
                <div className="absolute w-full bottom-5 left-2 flex justify-center items-center">
                  <div className="w-3/4">
                    <VideoInfo
                      setVideo={setVideo}
                      videoTitle={videoTitle}
                      videoDesc={videoDesc}
                      redirectUrl={redirectUrl}
                      bgColor={bgColor}
                      bgColor2={bgColor2}
                      redirectButtonText={redirectButtonText}
                      newWindow={newWindow}
                      redirectButtonHeight={redirectButtonHeight}
                      selectIcon={selectIcon}
                      redirectButtonUpdate={redirectButtonUpdate}
                      redirectButtonUpdate3={redirectButtonUpdate3}
                      textInputUpdate={textInputUpdate}
                      redirectButtonText3={redirectButtonText3}
                      exBgColor5={exBgColor5}
                      exBgColor4={exBgColor4}
                      selectIcon2={selectIcon2}
                      redirectButtonHeight3={redirectButtonHeight3}
                      redirectUrl3={redirectUrl3}
                      textInputType={textInputType}
                      textInputPlaceHolder={textInputPlaceHolder}
                      textInputbgColor={textInputbgColor}
                      textInputTextColor={textInputbgColor}
                      onCloseHandler={onCloseHandler}
                    />
                  </div>

                  <div className="w-1/4">
                    {/* Control Buttons (Play, Mute, Volume) */}
                    <PlayerController
                      playerController={playerController}
                      isplaying={isplaying}
                      onPlayHandler={onPlayHandler}
                      onToggleMute={onToggleMute}
                      isMuted={muted}
                    />
                  </div>
                </div>
              )}

              {!isToggled && (
                <VideoProgressBar
                  progress={videoProgress}
                  handleProgressBar={handleProgressBarClick}
                />
              )}

              {!isToggled && (
                <AuthorInfo
                  authorName={authorName}
                  authorPosition={authorPosition}
                  authorOccupation={authorOccupation}
                  imageUrl2={profileImage}
                  bgColor7={bgColor7}
                  bgColor8={bgColor8}
                />
              )}
            </div>
          </div>
        )}
        <div className="p-4">
          <h3 className="text-color1 text-xl font-bold">
            Embed FacePop in your Website
          </h3>
          <CodeEmbed user={user} pluginToken={pluginToken} />

          <div className="mt-10">
            <h2 className="text-color2 font-semibold text-2xl">FacePop</h2>

            <div className="flex lg:flex-row flex-col gap-4 mt-3">
              <div className="space-y-5 w-full">
                <div className="p-4 space-y-5  bg-color4">
                  <div>
                    <h3 className="text-color2 text-lg font-semibold">
                      Upload Video
                    </h3>
                    <div className="input-colors  w-full h-96 border rounded-md mt-3 border-color3 flex justify-center items-center">
                      <input
                        ref={videoRef}
                        className="hidden"
                        type="file"
                        accept=".mp4,.avi,.mov,.mkv,.m4v,.webm"
                        onChange={(e) => handleVideoUpload(e.target.files[0])}
                      />
                      <div className="flex flex-col items-center gap-3">
                        <button onClick={onVideoHandler}>
                          <MdOutlineFileUpload
                            style={{ fontSize: "24px", color: "black" }}
                          />
                        </button>
                        <h3 className="text-2xl text-black font-bold">
                          Upload Video
                        </h3>

                        <span className="text-gray-500 text-sm ">
                          MP4, AVI, MOV, MKV, M4V, WEBM Max File: 150 MB
                        </span>
                      </div>
                    </div>
                  </div>

                  <OptionInputField
                    styleProps="flex flex-col gap-2"
                    labelCss="text-color2 text-lg font-semibold"
                    title="Video Title"
                    value={videoTitle}
                    stateProp={setVideoTitle}
                  />
                  {/* <div className="flex flex-col gap-2">
                  <label className="text-color2 text-lg font-semibold">
                    Video Title
                  </label>
                  <input
                    onChange={(e) => setVideoTitle(e.target.value)}
                    type="text"
                    placeholder="This is facepop"
                    className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                  />
                </div> */}
                  <OptionInputField
                    styleProps="flex flex-col gap-2"
                    labelCss="text-color2 text-lg font-semibold"
                    title="Description"
                    value={videoDesc}
                    stateProp={setVideoDesc}
                  />
                </div>

                <div>
                  <div>
                    <h3 className="text-color2 text-lg font-semibold mb-2">
                      Choose thumbnail option to upload (image or video)
                    </h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => setThumbOption("image")}
                        className={`${
                          thumbOption === "image"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Image Thumbnail
                      </button>
                      <button
                        onClick={() => setThumbOption("video")}
                        className={`${
                          thumbOption === "video"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Short Video Thumbnail
                      </button>
                    </div>
                  </div>
                  {thumbOption === "video" ? (
                    <div className="mt-6">
                      <h3 className="text-color2 text-lg font-semibold mb-2">
                        Upload a short video as Thumbnail
                      </h3>
                      <div className="relative border rounded-md overflow-hidden">
                        <label
                          htmlFor="thumbnailUpload"
                          className="cursor-pointer w-full h-48 bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
                        >
                          <div className="flex flex-col items-center gap-2">
                            <MdOutlineFileUpload
                              style={{ fontSize: "24px", color: "black" }}
                            />
                            <p className="text-sm text-gray-500">
                              Click to Upload
                            </p>
                          </div>
                          <input
                            id="thumbnailUpload"
                            ref={thumbnailRef}
                            className="hidden"
                            type="file"
                            accept=".mp4,.avi,.mov,.mkv,.m4v,.webm"
                            onChange={(e) =>
                              handleThumbnailUpload(e.target.files[0])
                            }
                          />
                        </label>
                      </div>
                      <div className="mt-2 text-center">
                        <span className="text-gray-500 text-sm">
                          MP4, AVI, MOV, MKV, M4V, WEBM Max File: 30 MB
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-6">
                      <h3 className="text-color2 text-lg font-semibold mb-2">
                        Upload a Image Thumbnail
                      </h3>
                      <div className="relative border rounded-md overflow-hidden">
                        <label
                          htmlFor="thumbnailUpload"
                          className="cursor-pointer w-full h-48 bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
                        >
                          <div className="flex flex-col items-center gap-2">
                            <MdOutlineFileUpload
                              style={{ fontSize: "24px", color: "black" }}
                            />
                            <p className="text-sm text-gray-500">
                              Click to Upload
                            </p>
                          </div>
                          <input
                            id="thumbnailUpload"
                            ref={thumbnailRef}
                            className="hidden"
                            type="file"
                            accept=".jpg, .jpeg, .png, .webp"
                            onChange={(e) =>
                              handleThumbnailUpload(e.target.files[0])
                            }
                          />
                        </label>
                      </div>
                      <div className="mt-2 text-center">
                        <span className="text-gray-500 text-sm">
                          JPG, JPEG, PNG, WEBP Max File: 5 MB
                        </span>
                      </div>
                    </div>
                  )}

                  {thumbOption === "video" && (
                    <div>
                      <h3 className="text-color2 text-lg font-semibold mb-2">
                        Choose option for video thumbnail
                      </h3>
                      <div className="flex flex-wrap gap-2 mt-2">
                        <button
                          onClick={() => setThumbPlay("auto")}
                          className={`${
                            thumbPlay === "auto"
                              ? "bg-color8 font-semibold text-white"
                              : "bg-gray-300"
                          } text-black py-1 rounded-sm px-6`}
                        >
                          Loop
                        </button>
                        <button
                          onClick={() => setThumbPlay("onHover")}
                          className={`${
                            thumbPlay === "onHover"
                              ? "bg-color8 font-semibold text-white"
                              : "bg-gray-300"
                          } text-black py-1 rounded-sm px-6`}
                        >
                          On Mouse Over
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="p-4  bg-color4">
                  <CtaBuilder
                    value={showCTA}
                    value2={afterClick}
                    afterClick={afterClick}
                    user={user}
                    pluginId={pluginId}
                    setPopup={setPopup}
                    onCtaShow={onCtaShow}
                    onAfterClick={onAfterClick}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="bg-color4 flex flex-col gap-4 p-4">
                  <h3 className="text-black text-xl font-semibold">
                    Look and Feel
                  </h3>
                  <div>
                    <h3 className="text-sm text-color7">
                      Position in your website
                    </h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => {
                          return (
                            <>
                              <>{setSelectButton(1)} </>
                              <>{setVideoPosition("left")}</>
                            </>
                          );
                        }}
                        className={`${
                          selectButton == 1
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Show Left
                      </button>
                      <button
                        onClick={() => {
                          return (
                            <>
                              <>{setSelectButton(2)} </>
                              <>{setVideoPosition("right")}</>
                            </>
                          );
                        }}
                        className={`${
                          selectButton == 2
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Show Right
                      </button>
                      {/* <button
                        onClick={() => {
                          return (
                            <>
                              <>{setSelectButton(3)} </>
                              <>{setVideoPosition("center")}</>
                            </>
                          );
                        }}
                        className={`${
                          selectButton == 3
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Show Center
                      </button> */}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm text-color7">Shadow</h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => setShadow("")}
                        className={`${
                          shadow === ""
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        No Shadow
                      </button>
                      <button
                        onClick={() => setShadow("dim")}
                        className={`${
                          shadow === "dim"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Dim
                      </button>
                      <button
                        onClick={() => setShadow("medium")}
                        className={`${
                          shadow === "medium"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Medium
                      </button>
                      <button
                        onClick={() => setShadow("light")}
                        className={`${
                          shadow === "light"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Spotlight
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Vertical margin
                    </label>
                    <input
                      min={0}
                      max={150}
                      value={vertcalMargin}
                      onChange={(e) => setVerticleMargin(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Horizontal margin
                    </label>
                    <input
                      min={0}
                      max={150}
                      value={horizontalMargin}
                      onChange={(e) => setHorizontalMargin(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <h3 className="text-color7 font-semibold ">Toggle</h3>

                    <label className="text-color7 text-xs ">Width</label>
                    <input
                      min={0}
                      max={1000}
                      value={width}
                      type="number"
                      onChange={(e) => setWidth(e.target.value)}
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">Height</label>
                    <input
                      min={0}
                      max={1000}
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Corner radius
                    </label>
                    <input
                      min={0}
                      max={200}
                      value={radious}
                      onChange={(e) => setRadious(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Show Close Icon
                    </label>
                    <select
                      value={showCloseIcon ? "open" : "close"}
                      onChange={(e) =>
                        onCloseSelectButtonHandler(e.target.value)
                      }
                      className="border   border-color3 py-2 rounded-sm"
                    >
                      <option value="close" key="close">
                        No
                      </option>
                      <option value="open" key="open">
                        Yes
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-color7 font-semibold ">Player</h3>
                    <label className="text-color7 text-xs ">Width</label>
                    <input
                      value={playerWidth}
                      onChange={(e) => setPlayerWidth(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">Height</label>
                    <input
                      value={playerHeight}
                      onChange={(e) => setPlayerHeight(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      On mobile device
                    </label>
                    <select
                      className="border   border-color3 py-2 rounded-sm"
                      value={onMobile}
                      onChange={(e) => setOnMobile(e.target.value)}
                    >
                      <option value={true} key="">
                        Play video at full screen
                      </option>
                      <option value={false} key="">
                        Keep Desktop Size
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Corner radius
                    </label>
                    <input
                      min={0}
                      max={200}
                      value={playerRadius}
                      onChange={(e) => setPlayerRadious(e.target.value)}
                      type="number"
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                    />
                  </div>{" "}
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Seeker height
                    </label>
                    <input
                      type="number"
                      value={seekerHeight}
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                      onChange={(e) => setSeekerHeight(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Size of controls
                    </label>
                    <input
                      type="number"
                      value={controllerSize}
                      className="input-colors border border-color5 py-2 w-full rounded-sm text-black"
                      onChange={(e) => setControllerSize(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-color7 text-xs ">
                      Show controls
                    </label>
                    <select
                      value={playerController ? "Yes" : "No"}
                      className="border   border-color3 py-2 rounded-sm"
                      onChange={(e) => onPlayerControl(e.target.value)}
                    >
                      <option value="Yes" key="Yes">
                        Yes
                      </option>
                      <option value="No" key="No">
                        No
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">General</h3>
                    <label className="text-color7 text-xs ">Title</label>
                    <input
                      value={bgColor}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor }}
                      onClick={() => setOpen(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>{" "}
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">Description</label>
                    <input
                      value={bgColor2}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor2 }}
                      onClick={() => setOpen2(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>{" "}
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Gradient Overlay
                    </label>
                    <input
                      value={bgColor3}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor3 }}
                      onClick={() => setOpen3(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>{" "}
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">Shadow</label>
                    <input
                      value={bgColor4}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor4 }}
                      onClick={() => setOpen4(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">Player</h3>
                    <label className="text-color7 text-xs ">Controls</label>
                    <input
                      value={bgColor5}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor5 }}
                      onClick={() => setOpen5(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">Seeker</label>
                    <input
                      value={bgColor6}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor6 }}
                      onClick={() => setOpen6(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">Author name</label>
                    <input
                      value={bgColor7}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor7 }}
                      onClick={() => setOpen7(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>{" "}
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Author occupation
                    </label>
                    <input
                      value={bgColor8}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor8 }}
                      onClick={() => setOpen8(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">Toggle</h3>
                    <label className="text-color7 text-xs ">Play icon</label>
                    <input
                      value={bgColor9}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor9 }}
                      onClick={() => setOpen9(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Close background
                    </label>
                    <input
                      value={bgColor10}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor10 }}
                      onClick={() => setOpen10(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Close icon color
                    </label>
                    <input
                      value={bgColor11}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor11 }}
                      onClick={() => setOpen11(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Close icon border
                    </label>
                    <input
                      value={bgColor12}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor12 }}
                      onClick={() => setOpen12(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">
                      Loading screen
                    </h3>
                    <label className="text-color7 text-xs ">Background</label>
                    <input
                      value={bgColor13}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor13 }}
                      onClick={() => setOpen13(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">Spinner</label>
                    <input
                      value={bgColor14}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor14 }}
                      onClick={() => setOpen14(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">Other</h3>
                    <label className="text-color7 text-xs ">
                      Background overlay
                    </label>
                    <input
                      value={bgColor15}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor15 }}
                      onClick={() => setOpen15(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Back button inside frame
                    </label>
                    <input
                      value={bgColor16}
                      type="text"
                      className="input-colors  border border-color5 py-2 w-full rounded-sm text-black"
                    />
                    <div
                      style={{ backgroundColor: bgColor16 }}
                      onClick={() => setOpen16(true)}
                      className="w-8 h-8 absolute cursor-pointer right-1  bottom-1"
                    ></div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">Behavior</h3>
                    <label className="text-color7 text-xs ">
                      When video ends
                    </label>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => setPlayAgain(true)}
                        className={`${
                          playAgain
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Play again loop
                      </button>
                      <button
                        onClick={() => setPlayAgain(false)}
                        className={`${
                          !playAgain
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Stop video
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Default video sound
                    </label>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={muteHandler}
                        className={`${
                          muted === true
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Muted
                      </button>
                      <button
                        onClick={unMuteHandler}
                        className={`${
                          muted === false
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Unmuted
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Player position
                    </label>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => setPlayerPosition("center")}
                        className={`${
                          playerPosition === "center"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Center of the page
                      </button>
                      <button
                        onClick={() => setPlayerPosition("toggle")}
                        className={`${
                          playerPosition === "toggle"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        At the toggle
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <label className="text-color7 text-xs ">
                      Autoplay on toggle
                    </label>
                    <label className="toggle-label">
                      <input
                        className="toggle-nput"
                        type="checkbox"
                        defaultChecked={isToggled}
                        onClick={callback}
                      />
                      <span className="toggle-span" />
                    </label>
                  </div>
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">Author</h3>
                    <label className="text-color7 text-xs ">Author Image</label>
                    <div className="input-colors  w-full h-96 border rounded-md mt-3 border-color3 flex justify-center items-center">
                      <input
                        ref={imageRef}
                        className="hidden"
                        type="file"
                        onChange={(e) => handleAuthorImage(e)}
                        accept=".png, .jpeg, .jpg"
                      />
                      <div className="flex flex-col items-center gap-3">
                        <button onClick={onImageHandler}>
                          <MdOutlineFileUpload
                            style={{ fontSize: "24px", color: "black" }}
                          />
                        </button>
                        <h3 className="text-2xl text-black font-bold">
                          Upload Photo
                        </h3>

                        <span className="text-gray-500 text-sm ">
                          JPG, JPEG, PNG, PDF
                        </span>
                      </div>
                    </div>
                  </div>
                  <OptionInputField
                    value={authorName}
                    styleProps="flex flex-col gap-2"
                    labelCss="text-color2 text-lg font-semibold"
                    title="Full name"
                    stateProp={setAuthorName}
                  />
                  <OptionInputField
                    value={authorOccupation}
                    styleProps="flex flex-col gap-2"
                    labelCss="text-color2 text-lg font-semibold"
                    title="Occupation"
                    stateProp={setAuthorOccupation}
                  />
                  <div className="flex flex-col gap-1 relative ">
                    <h3 className="text-color7 font-semibold ">
                      Author Info Position
                    </h3>
                    <label className="text-color7 text-xs ">
                      Positon your info
                    </label>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <button
                        onClick={() => setAuthorPosition("left")}
                        className={`${
                          authorPosition === "left"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Left
                      </button>

                      <button
                        onClick={() => setAuthorPosition("center")}
                        className={`${
                          authorPosition === "center"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Center
                      </button>
                      <button
                        onClick={() => setAuthorPosition("right")}
                        className={`${
                          authorPosition === "right"
                            ? "bg-color8 font-semibold text-white"
                            : "bg-gray-300"
                        } text-black py-1 rounded-sm px-6`}
                      >
                        Right
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-4  border mt-5">
            <CopyToClipboard
              text={`${window.location.origin}/view/facepop/${pluginToken}`}
              onCopy={() => {
                toast.success("Copied to clipboard");
                toast.success("Please add the code into body tag");
              }}
            >
              <button className="flex text-center justify-center  items-center gap-3 font-semibold bg-gray-300   py-3 px-2 rounded-md w-full">
                <span>Share/Preview Calculator</span>
              </button>
            </CopyToClipboard>

            <button className="flex text-center justify-center  items-center gap-3 font-semibold bg-gray-300   py-3 px-2 rounded-md w-full">
              Reset
            </button>
          </div>

          <div className="space-y-3  border mt-5">
            {/* <div>
              <button className="flex text-center justify-center  items-center gap-3 font-semibold bg-gray-300   py-3 px-2 rounded-md w-full">
                Reset
              </button>
            </div> */}

            <div>
              {" "}
              <button
                id="sec"
                className="flex items-center gap-3 justify-center bg-color10 text-white font-semibold py-3 px-2 rounded-md w-full"
                onClick={handleOptionUpdate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <GradianModal
          open={open}
          open2={open2}
          open3={open3}
          open4={open4}
          open5={open5}
          open6={open6}
          open7={open7}
          open8={open8}
          open9={open9}
          open10={open10}
          open11={open11}
          open12={open12}
          open13={open13}
          open14={open14}
          open15={open15}
          open16={open16}
          handleClose={handleClose}
          setBgColor={setBgColor}
          setBgColor2={setBgColor2}
          setBgColor3={setBgColor3}
          setBgColor4={setBgColor4}
          setBgColor5={setBgColor5}
          setBgColor6={setBgColor6}
          setBgColor7={setBgColor7}
          setBgColor8={setBgColor8}
          setBgColor9={setBgColor9}
          setBgColor10={setBgColor10}
          setBgColor11={setBgColor11}
          setBgColor12={setBgColor12}
          setBgColor13={setBgColor13}
          setBgColor14={setBgColor14}
          setBgColor15={setBgColor15}
          setBgColor16={setBgColor16}
        />
        <CallToActionPopUp
          open={popup}
          setPopup={setPopup}
          handleActionpopUp={handleActionpopUp}
        />
      </div>
    </>
  );
};

export default FacePopUpOption;
