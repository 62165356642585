import React from 'react';

const Footer = ({ varbiage }) => {
  return (
    <>
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-center text-xs text-[#6e7c81] space-x-2">
          {/* {varbiage.map((dt, idx) => {
            if (dt.text) {
              return (
                <a
                  className="hover:mtge-underline"
                  rel="noreferrer"
                  key={idx}
                  target="_blank"
                  href={dt.link}
                >
                  {dt.text}
                </a>
              );
            }
          })} */}
        </div>

        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </>
  );
};

export default Footer;
