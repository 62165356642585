const mortgageCalculator = function () {
  // const token = this.model.get('attributes').token;
  const calculatorToken = localStorage.getItem('calculatorToken');

  const initMortgageWidget = function (el) {
    var d = document.createElement('div');
    d.classList.add('mtge-calc_embeded');
    d.setAttribute('id', 'mtge-calc_embeded');
    d.setAttribute(
      'data-sitekey',
      `${calculatorToken}`
      // 'OVBazy8m-tJJvug7baBHEjdh5ZnfYr3HEM3XFADdsP4'
    );
    el.appendChild(d);

    var qs = document.createElement('script');
    qs.src = `https://mortgage.shadman-software-development.website/static/js/plugin.js`;
    qs.defer = false;

    document.head.appendChild(qs);

    var l1 = document.createElement('link');
    l1.setAttribute('rel', 'stylesheet');
    l1.setAttribute('type', 'text/css');
    l1.setAttribute(
      'href',
      `https://mortgage.shadman-software-development.website/static/css/plugin.css`
    );
    document.head.appendChild(l1);
  };

  const el = this;
  // const token = el.getAttribute('data-token');
  if (typeof document.currentScript == 'undefined') {
    const scriptTag = document.createElement('script');
    scriptTag.onload = function () {
      initMortgageWidget(el);
    };
    scriptTag.src = `https://mortgage.shadman-software-development.website/static/js/plugin.js`;
    document.body.appendChild(scriptTag);
  } else {
    initMortgageWidget(el);
  }
};

export default mortgageCalculator;
