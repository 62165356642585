import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';

import ModalWrapper from './Modal';
import config from '../../../utils/config';

function Zestimates({ show, onClose, location }) {
  const [data, setData] = useState({
    zillow: null,
    attom: null,
  });

  useEffect(() => {
    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/zillow',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          zillow:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          zillow: 'Not Available',
        }));
      });

    axios({
      url: 'https://api.kvcore.com/v2/public/valuation/attom',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          attom:
            res.data.value > 0
              ? '$' + currencyWithCommas(res.data.value)
              : 'Not Available',
        }));
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          attom: 'Not Available',
        }));
      });
  }, []);

  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          id="mtge-calc_result-close"
          className="mtge-calc_modal-close"
          onClick={onClose}
        >
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="mtge-zestimates_container">
          <div className="mtge-zestimates">
            <div className="mtge-zestimates_items">
              <div className="mtge-zestimates_item">
                <img
                  src={`${config.baseUrl}/images/attom-100.0bdd301.png`}
                  alt="attom"
                />
                <h2> {data.attom ? data.attom : 'Loading...'} </h2>
              </div>
              <div className="mtge-zestimates_item-devider"></div>
              <div className="mtge-zestimates_item">
                <img
                  src={`${config.baseUrl}/images/zillow-100.3244ea1.png`}
                  alt="zillow"
                />
                <h2> {data.zillow ? data.zillow : 'Loading...'} </h2>
              </div>
            </div>

            <p className="mtge-zestimates_info">
              Independent price estimates are based on statistical models that
              aggregate available data to form an estimate of market value.
              Estimates are not local experts, they don`t walk through your home
              or the homes in your market to verify their data or account for
              condition. I do - ask me how I`d price your home in today`s
              market.
            </p>
          </div>
          <div className="mtge-zestimates_c-map">
            <h2> Is it a buyer’s market or seller’s market? </h2>
            <p>
              The law of supply and demand dictates the equilibrium price of a
              property, and I can show you if buyers are searching your area
              now. We keep a pulse on buyer interest and how that interest
              compares to the areas around you.
            </p>
            <div
              className="mtge-zestimates_g-map"
              style={{ height: '400px', width: '100%' }}
            >
              {/* AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw */}
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAyKUKlaQ4fGwv4EMk4rH0QiGb4lKGQZcw',
                }}
                defaultCenter={{
                  address: location.formatted_address,
                  lat: location.geometry.location.lat(),
                  lng: location.geometry.location.lng(),
                }}
                defaultZoom={14}
              >
                <div
                  className="mtge-zestimates_pin"
                  lat={location.geometry.location.lat()}
                  lng={location.geometry.location.lng()}
                >
                  <p>A</p>
                </div>
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function currencyWithCommas(x) {
  x = x.toFixed(2);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default Zestimates;
{
  /* <div className="mtge-zillow_images">
<img
  src="https://photos.zillowstatic.com/fp/39a07278effb8654236c3dcb6c213921-cc_ft_384.jpg"
  alt=""
/>
<img
  src="https://photos.zillowstatic.com/fp/39a07278effb8654236c3dcb6c213921-cc_ft_384.jpg"
  alt=""
/>
<img
  src="https://photos.zillowstatic.com/fp/39a07278effb8654236c3dcb6c213921-cc_ft_384.jpg"
  alt=""
/>
</div>
<div className="mtge-zillow_info">
<p> 2 bd | 1ba | 1,399sqr </p>
<p>
  <address> 108 W 2nd St APT 1012, Los Angeles, CA 90012 </address>
</p>
<p>
  {" "}
  <strong>Sold: $915,000</strong> | <span>Sold on 10/28/22</span> |{" "}
  <span>
    Zestimate®: <strong>$911,600</strong>
  </span>{" "}
</p>

<h1> Overview </h1>
<p>
  Experience a rich city lifestyle complimented with historic charm,
  chic style, & also a prime location within walking distance to
  Little Tokyo & The Arts District! Ideally situated in The Higgins
</p>
</div> */
}
