import axios from './axios';
import { initialState, reducer } from '../utils/reducer';
import ActionTypes from '../utils/actionTypes';

const services = {
  createPage: async (userId, name, pageId) => {
    try {
      const response = await axios.post(
        `/api/personal_site/${userId}/create-page/${pageId}`,
        {
          name: name,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(
          'Server responded with a non-200 status code:',
          response.status
        );
      }
    } catch (err) {
      console.log(err);
    }
  },

  pageList: async (userId) => {
    try {
      const response = await axios.get(`/api/personal_site/${userId}/page`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  renderPage: async (userId) => {
    try {
      const response = await axios.get(`/api/personal_site/${userId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  savePage: async (userId, pageId, html, css) => {
    try {
      const response = await axios.put(
        `/api/personal_site/${userId}/save-page/${pageId}`,
        { html, css },
        { headers: { 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  getPage: async (pageId) => {
    try {
      const response = await axios.get(`/api/personal_site/my-site/${pageId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deletePage: async (pageId) => {
    try {
      const response = await axios.delete(`/api/personal_site/${pageId}`);
      return response.data;
    } catch (err) {
      console.log(err.message);
    }
  },

  // savePageContent: async (userId, content) => {
  //   try {
  //     const response = await axios.post(
  //       `/api/personal_site/${userId}/content`,
  //       { content }
  //     );
  //     return response.data;
  //   } catch (err) {
  //     if (err.response && err.response.data) {
  //       throw new Error(err.response.data.error);
  //     } else {
  //       throw new Error(err.message);
  //     }
  //   }
  // },

  loadContent: async (userId, pageId) => {
    try {
      const response = await axios.get(
        `/api/personal_site/${userId}/content/${pageId}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  saveTokensToServer: async (pageId, selectedTokens) => {
    try {
      const response = await axios.put(
        `/api/personal_site/save-tokens/${pageId}`,
        {
          selectedTokens,
        }
      );

      console.log('resData pp: ', response);

      if (response.data.success) {
        return {
          success: true,
          status: response.data.status,
          data: response.data.data,
        };
      } else {
        console.error('Failed to save tokens:', response.data.message);
      }
    } catch (error) {
      console.error('Error saving tokens:', error.message);
    }
  },
};

export default services;
