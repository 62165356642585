import React from 'react';

const Card = ({ icon, children }) => {
  return (
    <div className="bg-[#f7f7f7] rounded-lg shadow-md p-4 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 items-center justify-center">
      {icon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={'w-12 h-12 pr-2'}
        >
          {icon}
        </svg>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Card;
