import React from 'react';
import { toast } from 'react-toastify';

function StepSeventeen({ value, onChange, handleNext }) {
  const verifyAndProceed = () => {
    var regx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(regx)) {
      handleNext();
    } else {
      toast.error('Invalid email address!');
    }
  };
  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        What is your email address?
      </h1>
      <div className="mt-3 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="email"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="text"
            name="email"
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="email"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            name@email.com
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
          onClick={verifyAndProceed}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepSeventeen;
