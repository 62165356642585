import { Avatar } from "@mui/material";
import React from "react";

const AuthorInfo = ({
  authorName,
  authorPosition,
  authorOccupation,
  imageUrl2,
  bgColor7,
  bgColor8,
}) => {
  const getPositionStyle = () => {
    switch (authorPosition) {
      case "center":
        return {
          position: "absolute",
          top: "4px",
          left: "50%",
          transform: "translate(-50%)",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        };
      case "right":
        return {
          position: "absolute",
          top: "4px",
          right: "35px",
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          textAlign: "right",
        };

      default:
        return {
          position: "absolute",
          top: "4px",
          left: "10px",
          display: "flex",
          alignItems: "center",
        };
    }
  };

  return (
    <>
      {(authorName || authorOccupation || imageUrl2) && (
        <div className="gap-1 transition-all" style={getPositionStyle()}>
          <div>
            <Avatar
              style={{ width: "30px", height: "30px", color: "#666666" }}
              alt={authorName}
              src={imageUrl2}
            />
          </div>
          <div className="flex flex-col">
            <h3 style={{ color: bgColor7 }} className="font-semibold text-lg">
              {authorName}
            </h3>
            <span style={{ color: bgColor8 }} className="text-xs">
              {authorOccupation}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthorInfo;
