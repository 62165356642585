import React, { useState } from 'react';
import useContext from '../../context/StateProvider';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';

// components
import Sidebar from './Sidebar';
import Notification from './Notification';
import useSocket from '../../context/Socket';

function Header({ ...props }) {
  const socket = useSocket();
  const [{ user }, dispatch] = useContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState('');

  const [showNavMenu, setShowNavMenu] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    toast.promise(
      axios({
        url: '/api/users/logout',
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            setLoading(false);
            socket.emit('disconnect_user', user._id);
            dispatch({
              type: ActionTypes.SET_USER,
              payload: null,
            });
            navigate('/');
          } else {
            toast.error(resData.status);
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        }),
      {
        pending: 'Loging out...',
        success: 'Log out successful',
        error: 'Log out failed. Please try again later.',
      }
    );
  };

  return (
    <>
      <div className="dashboard_header flex justify-between items-center px-4 py-1 md:px-8">
        <div className="w-12 md:w-16">
          <Link to="/">
            <img
              className="w-full"
              src="/images/logo.png"
              alt="Savage Sales - logo"
            />
          </Link>
        </div>
        <div className="flex items-center space-x-4">
          {user.accountType !== 'Admin' && (
            <div
              className="md:hidden cursor-pointer"
              onClick={() => setShowNavMenu((prev) => !prev)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                />
              </svg>
            </div>
          )}
          <div className="flex items-center space-x-4">
            <Notification />
            <p className="hidden md:block text-lg font-medium">
              {' '}
              Welcome, {user.firstName} {user.lastName}
            </p>
            {location.pathname === '/dashboard/admin' && (
              <button onClick={handleLogout}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {location.pathname !== '/dashboard/admin' && (
        <Sidebar
          handleLogout={handleLogout}
          showNavMenu={showNavMenu}
          setShowNavMenu={setShowNavMenu}
          handleSwitchExpand={props.handleSwitchExpand}
          isExpanded={props.isExpanded}
          showExpandIcon={props.showExpandIcon}
          loading={loading}
        />
      )}
    </>
  );
}

export default Header;
