import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';
import useContext from '../../context/StateProvider';

import ModalWrapper from '../Modal';
import Loader from '../Loader/Loader';

function AllViewProperties() {
  const params = useParams();
  const [{ user }, dispatch] = useContext();

  const [viewProperties, setViewProperties] = useState([]);

  const [isCreateNewPlugin, setIsCreateNewPlugin] = useState(false);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_CURRENT_PLUGIN,
      payload: null,
    });
    axios({
      url: `/api/viewProperties/${params.profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setViewProperties(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, []);

  if (viewProperties.length === 0) {
    return <Loader customClass="md:ml-[300px]" />;
  }

  return (
    <div className="w-full md:max-w-2xl mx-auto py-8 px-6 md:px-12 bg-white">
      <h1 className="text-center text-3xl text-[#39393bde] font-bold">
        {' '}
        Choose your View Properties{' '}
      </h1>
      <div className="mt-8">
        <div className="mt-4 grid grid-cols-1 itmes-center space-y-6">
          {user.accountType === 'Free' && (
            <button
              className="flex justify-center items-center p-6 rounded-lg border-2 shadow-lg"
              onClick={() => toast.error('Please upgrade your plan!')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 md:w-10 md:h-10 text-gray"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          )}
          {viewProperties.map((dt) => (
            <Card key={dt._id} data={dt} />
          ))}
        </div>
      </div>

      {isCreateNewPlugin && (
        <CreateOrEdit
          show={true}
          onClose={() => {
            setIsCreateNewPlugin(false);
          }}
          update={(dt) => setViewProperties(dt)}
        />
      )}
    </div>
  );
}

const Card = ({ data }) => {
  const params = useParams();
  return (
    <>
      <Link to={`/dashboard/${params.profileId}/viewProperties/${data._id}`}>
        <div className="text-center p-6 rounded-lg border-2 shadow-lg">
          <h3> {data.name} </h3>
        </div>
      </Link>
    </>
  );
};

const CreateOrEdit = ({ show, onClose, update }) => {
  const params = useParams();
  const [{ user }, dispatch] = useContext();
  const [name, setName] = useState('');

  const handleCreateNewPlugin = () => {
    axios({
      url: '/api/viewProperties/new',
      method: 'POST',
      data: {
        profileId: params.profileId,
        pluginName: name,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          update(resData.data);
          onClose();
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper py-8 w-full max-w-[560px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mtge-calc_modal-close" onClick={onClose}>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="w-full">
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="name"
                className="text-sm text-black font-semibold"
              >
                View Properties Name
              </label>
            </div>
            <div>
              <input
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-2 py-3 rounded-lg text-black text-base outline-none border-2"
                required
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="block w-full md:w-60 mx-auto px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleCreateNewPlugin}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AllViewProperties;
