import React, { useEffect, useState } from "react";
import axios from "axios";
import useContext from "../../context/StateProvider";
import { toast } from "react-toastify";
import { currencyWithCommas } from "../../utils/functions";
import moment from "moment";
import ActionTypes from "../../utils/actionTypes";

import Loader from "../Loader/Loader";
import ModalWrapper from "../Modal";
import { useParams } from "react-router-dom";
import useSocket from "../../context/Socket";

function ViewPropertiesLeads() {
  const [{ user, currentPlugin }, dispatch] = useContext();
  const { profileId } = useParams();

  const socket = useSocket();

  const [allPlugins, setAllPlugins] = useState([]);

  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [initalList, setInitialList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [showMortgageInfo, setShowMortgageInfo] = useState(false);
  const [mortgageInfo, setMortgageInfo] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [selectedPlugin, setSelectedPlugin] = useState("all");

  const [state, setState] = useState(false);

  const [order, setOrder] = useState(-1);

  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [leadId, setLeadId] = useState(null);

  useEffect(() => {
    axios({
      url: `/api/viewProperties/${profileId}/all`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setAllPlugins(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, []);

  useEffect(() => {
    socket.on("lead-viewproperties", sd);

    return () => {
      socket.off("lead-viewproperties", sd);
    };
  }, [initalList]);

  function sd(data) {
    console.log(data);
    if (selectedPlugin === "all" || data.token === selectedPlugin) {
      if (initalList.filter((dt) => dt._id === data._id).length > 0) {
        console.log("OLD");
        let fd = initalList.map((dt) => {
          if (dt._id === data._id) {
            return data;
          } else {
            return dt;
          }
        });
        setInitialList(fd);
        setLists(fd);
      } else {
        console.log("NEW");
        setInitialList((prev) => [data, ...prev]);
        setLists((prev) => [data, ...prev]);
      }
    }
  }

  useEffect(() => {
    if (allPlugins.length > 0) {
      if (selectedPlugin === "all") {
        let URL = "/api/viewProperties/leads/all";
        const pluginKeys = allPlugins.map((dt) => dt.token);

        axios({
          url: URL,
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.token,
          },
          data: {
            pluginKeys,
          },
        })
          .then((res) => {
            const resData = res.data;

            if (resData.success) {
              setLists(resData.data);
              setInitialList(resData.data);
              setState((prev) => !prev);
              setLoading(false);
            } else {
              toast.error(resData.status, {});
            }
          })
          .catch((err) => {
            let error = err.response.data.error;
            let status = err.response.data.status;
            toast.error(error?.message || status, {});
          });
      } else if (selectedPlugin !== null) {
        let URL = `/api/viewProperties/leads/${selectedPlugin}`;
        axios({
          url: URL,
          method: "get",
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
          .then((res) => {
            const resData = res.data;

            if (resData.success) {
              setLists(resData.data);
              setInitialList(resData.data);
              setState((prev) => !prev);
              setLoading(false);
            } else {
              toast.error(resData.status, {});
            }
          })
          .catch((err) => {
            let error = err.response.data.error;
            let status = err.response.data.status;
            toast.error(error?.message || status, {});
          });
      }
    }
  }, [selectedPlugin, allPlugins]);

  useEffect(() => {
    let tmpt = lists;
    if (order === -1) {
      tmpt.sort(function (x, y) {
        return new Date(x.createdAt) - new Date(y.createdAt);
      });
      setLists(tmpt);
    } else {
      tmpt.sort(function (x, y) {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      setLists(tmpt);
    }
  }, [order]);

  useEffect(() => {
    if (searchVal.length === 0) {
      setLists(initalList);
      return;
    }
  }, [searchVal]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchVal(value);
    if (selectedPlugin === "all") {
      let URL = "/api/viewProperties/leads/search";
      const pluginKeys = allPlugins.map((dt) => dt.token);

      axios({
        url: URL,
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
        },
        data: {
          pluginKeys,
          query: value,
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            setLists(resData.data);
          } else {
            toast.error(resData.status, {});
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    } else if (selectedPlugin !== null) {
      let URL = "/api/viewProperties/leads/search";
      axios({
        url: URL,
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
        },
        data: {
          pluginKeys: [selectedPlugin],
          query: value,
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            setLists(resData.data);
          } else {
            toast.error(resData.status, {});
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    }
  };

  const hanldeDownloadCSV = () => {
    if (selectedPlugin === "all") {
      let URL = "/api/viewProperties/leads/download";
      const pluginKeys = allPlugins.map((dt) => dt.token);

      axios({
        url: URL,
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
        },
        data: {
          pluginKeys,
        },
        responseType: "blob",
      })
        .then((res) => {
          const filename = Date.now() + "-leads.csv";
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          if (typeof window.navigator.msSaveBlob === "function") {
            window.navigator.msSaveBlob(res.data, filename);
          } else {
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    } else if (selectedPlugin !== null) {
      let URL = "/api/viewProperties/leads/download";
      axios({
        url: URL,
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
        },
        data: {
          pluginKeys: [selectedPlugin],
        },
        responseType: "blob",
      })
        .then((res) => {
          const filename = Date.now() + "-leads.csv";
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          if (typeof window.navigator.msSaveBlob === "function") {
            window.navigator.msSaveBlob(res.data, filename);
          } else {
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          console.log(err);
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    }
  };

  if (loading) {
    return <Loader customClass="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <div className="flex flex-col md:flex-row md:justify-between md:items-start items-center">
        <h1 className="self-start text-2xl text-[#39393bde] font-bold">
          Leads
        </h1>
        <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-4 space-y-4 md:space-y-0">
          <div>
            <select
              className="py-1 px-3 outline-1 outline-black outline-dashed"
              name="currentPlugin"
              value={selectedPlugin}
              onChange={(e) => setSelectedPlugin(e.target.value)}
            >
              <option value="all">All</option>
              {allPlugins.map((dt, idx) => (
                <option key={idx} value={dt.token}>
                  {" "}
                  {dt.name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="relative w-80 flex items-center px-2 py-2 rounded-full border-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 z-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>

            <input
              className="absolute left-[32px] right-0 px-1 border-none outline-none"
              type="text"
              value={searchVal}
              onChange={handleSearch}
              placeholder="Search by name or lead type"
            />
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-between items-center">
        <button
          className="px-3 py-2 bg-gray-500 text-white font-bold rounded-lg"
          onClick={hanldeDownloadCSV}
        >
          {" "}
          Export to CSV{" "}
        </button>
      </div>
      <div className="mt-12 overflow-auto">
        <table className="w-full min-w-[1500px]">
          <thead>
            <tr className="text-center border-b-2 border-collapse">
              <th className="px-2 py-4"> IP Address </th>
              <th className="px-2 py-4"> Live Address </th>
              <th className="px-2 py-4"> Name </th>

              <th className="px-2 py-4"> Email </th>
              <th className="px-2 py-4"> Phone </th>
              <th className="px-2 py-4 flex justify-center items-center space-x-2">
                {" "}
                Created At{" "}
                {order === -1 ? (
                  <button onClick={() => setOrder(1)}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                      />
                    </svg>
                  </button>
                ) : (
                  <button onClick={() => setOrder(-1)}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                      />
                    </svg>
                  </button>
                )}{" "}
              </th>
              <th className="px-2 py-4"> View Details </th>
              <th className="px-2 py-4"> Comments </th>
            </tr>
          </thead>
          <tbody>
            {lists.length > 0 ? (
              lists.map((dt, idx) => (
                <tr
                  key={idx}
                  className="text-center border-b-2 border-collapse"
                >
                  <td className="px-2 py-4"> {dt.ipAddress} </td>
                  <td className="px-2 py-4"> {dt.liveAddress} </td>
                  <td className="px-2 py-4"> {dt.fullName} </td>

                  <td className="px-2 py-4"> {dt.email} </td>
                  <td className="px-2 py-4"> {dt.phone} </td>

                  <td className="px-2 py-4">
                    {" "}
                    {moment(dt.createdAt).format("l, h:mm a")}{" "}
                  </td>
                  <td className="px-2 py-4">
                    {" "}
                    <button
                      className="underline"
                      onClick={() => {
                        setSearchDetails(dt.searchDetails);
                        setShowMortgageInfo(true);
                      }}
                    >
                      {" "}
                      View{" "}
                    </button>{" "}
                  </td>
                  <td className="px-2 py-4">
                    {" "}
                    <button
                      className="underline"
                      onClick={() => {
                        setLeadId(dt._id);
                        setShowCommentsModal(true);
                      }}
                    >
                      View comments
                    </button>{" "}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center border-b-2 border-collapse">
                <td colSpan={6} className="px-2 py-4">
                  {" "}
                  No data found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showMortgageInfo && (
        <MortgageInfo
          searchDetails={searchDetails}
          onClose={() => setShowMortgageInfo(false)}
        />
      )}

      {showCommentsModal && (
        <CommentsModal
          _id={leadId}
          onClose={() => setShowCommentsModal(false)}
        />
      )}
    </div>
  );
}

function MortgageInfo({ searchDetails, onClose }) {
  return (
    <ModalWrapper show={true} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper w-full max-w-700px py-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mtge-calc_modal-close" onClick={onClose}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <table className="mtge-calc_table">
          <tbody>
            <tr>
              <td> Location </td>
              <td className="text-right">{searchDetails.location}</td>
            </tr>

            <tr>
              <td>Bedrooms </td>
              <td className="text-right"> {searchDetails.bedrooms} </td>
            </tr>
            <tr>
              <td>Bathrooms </td>
              <td className="text-right"> {searchDetails.bathrooms} </td>
            </tr>
            <tr>
              <td> Condition </td>
              <td className="text-right">
                {searchDetails?.condition || "Undefined"}
              </td>
            </tr>
            <tr>
              <td>Approx Square Footage </td>
              <td className="text-right">
                {" "}
                {searchDetails?.squareFootage || 0}{" "}
              </td>
            </tr>
            <tr>
              <td> Minimum Price Range </td>
              <td className="text-right">${searchDetails.priceRange.min}</td>
            </tr>
            <tr>
              <td>Maximum Price Range </td>
              <td className="text-right"> ${searchDetails.priceRange.max} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ModalWrapper>
  );
}

function CommentsModal({ onClose, _id }) {
  const [{ user }] = useContext();

  const [comment, setComment] = useState("");
  const [allComments, setAllComments] = useState([]);

  useEffect(() => {
    axios({
      url: "/api/viewProperties/leads/comments/" + _id,
      method: "get",
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        setAllComments(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, []);

  const handleAddComment = () => {
    if (comment.length > 0) {
      axios({
        url: "/api/viewProperties/leads/add/comment",
        method: "put",
        headers: {
          Authorization: "Bearer " + user.token,
        },
        data: {
          _id,
          authorId: user._id,
          comment,
        },
      })
        .then((res) => {
          toast.success("Comment added!");
          setComment("");
          setAllComments(res.data.data.comments);
        })
        .catch((err) => {
          console.log(err);
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    } else {
      toast.info("Field is empty");
    }
  };

  const handleRemoveComment = (commentId) => {
    axios({
      url: "/api/viewProperties/leads/remove/comment",
      method: "put",
      headers: {
        Authorization: "Bearer " + user.token,
      },
      data: {
        _id,
        commentId: commentId,
      },
    })
      .then((res) => {
        toast.success("Comment removed!");

        setAllComments(res.data.data.comments);
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };
  return (
    <ModalWrapper show={true} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper w-full max-w-700px py-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mtge-calc_modal-close" onClick={onClose}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="mx-3">
          <h2 className="text-center text-lg font-medium"> All Comments </h2>
          <ul className="mt-2">
            {allComments.map((dt, idx) => (
              <li key={idx}>
                {" "}
                <h6 className="text-base font-semibold">
                  {" "}
                  <strong>Author:</strong>{" "}
                  {dt.author.firstName + " " + dt.author.lastName}{" "}
                </h6>
                <p className="text-base">
                  <strong>Comment:</strong> {dt.comment}
                </p>{" "}
                <button
                  className="block px-2 py-1 text-white text-xs font-bold rounded-lg bg-red-600"
                  onClick={() => handleRemoveComment(dt._id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-4">
          <h4 className="text-center text-lg font-medium"> Add Comment </h4>
          <div className="mx-3 flex flex-col space-y-2">
            <textarea
              name="comment"
              id="comment"
              className="p-2 w-full border-2 outline-none"
              rows="4"
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
            <button
              className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleAddComment}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

const months = {
  "Jan-1": "January",
  "Feb-2": "February",
  "Mar-3": "March",
  "Apr-4": "April",
  "May-5": "May",
  "Jun-6": "June",
  "Jul-7": "July",
  "Aug-8": "August",
  "Sep-9": "September",
  "Oct-10": "October",
  "Nov-11": "November",
  "Dec-12": "December",
};

export default ViewPropertiesLeads;
