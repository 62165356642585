import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import ActionTypes from '../../utils/actionTypes';
import Tooltip from '@mui/material/Tooltip';

import useContext from '../../context/StateProvider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MarketPlaceIcon from '../../assets/images/marketplace.svg';
import Loader from '../Loader/Loader';

function LockedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="absolute right-0 top-1/2 transform -translate-y-1/2 w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
      />
    </svg>
  );
}

function Sidebar({
  showNavMenu,
  setShowNavMenu,
  handleLogout,
  handleSwitchExpand,
  isExpanded,
  showExpandIcon,
  loading,
}) {
  const [{ user, admin, allProfiles, isOwnPlugin, isOwnProfile }, dispatch] =
    useContext();
  const { profileId } = useParams();
  const [openNav, setOpenNav] = useState(false);
  const [allowedFunnels, setAllowedFunnels] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  useEffect(() => {
    allProfiles.map((dt) => {
      if (dt._id === profileId) {
        let managers = dt.managers;
        managers.map((dst) => {
          if (dst.user._id === user._id) {
            setAllowedFunnels(dst.funnels);
          }
        });
      }
    });
  }, [profileId, isOwnProfile]);

  useEffect(() => {
    if (window.innerWidth < 768 && showNavMenu) {
      setOpenNav(true);
    } else setOpenNav(false);
  }, [showNavMenu]);

  useEffect(() => {
    setOpenNav(false);
  }, [location.pathname]);

  const handleSwitchToAdmin = () => {
    if (user.manager) {
      dispatch({
        type: ActionTypes.SET_USER,
        payload: user,
      });
      dispatch({
        type: ActionTypes.SET_ADMIN,
        payload: null,
      });
    } else {
      dispatch({
        type: ActionTypes.SET_USER,
        payload: admin,
      });
      dispatch({
        type: ActionTypes.SET_ADMIN,
        payload: null,
      });
    }

    navigate('/dashboard/admin');
  };

  return (
    <>
      <div
        className={`${
          isExpanded ? 'dashboard_sidebar' : 'dashboard_sidebar collapsed'
        } fixed overflow-visible ${
          openNav ? 'left-0 right-0' : '-left-[200%] right-[200%]'
        } md:left-0 bottom-0 top-0 md:top-[70px]`}
      >
        <div
          className="block md:hidden absolute right-4 top-4 cursor-pointer"
          onClick={() => {
            setShowNavMenu(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        {/* Add the expand/collapse icon here */}
        {showExpandIcon && (
          <div
            className="block absolute left-5 top-4 px-2 cursor-pointer"
            onClick={handleSwitchExpand}
          >
            {isExpanded ? <HighlightOffIcon /> : <MenuIcon />}
            {/* {!isExpanded && <TooltipArrow />} */}
          </div>
        )}

        <div className="flex flex-col justify-center py-8 px-6 space-y-3">
          <div className="block md:hidden text-lg font-medium">
            <span> Welcome, {user.email} </span>
          </div>

          <Link
            to={`/dashboard/${profileId}/accounts`}
            className={`${
              location.pathname === `/dashboard/${profileId}/accounts` ||
              pathname.startsWith(`/dashboard/${profileId}/accounts/`)
                ? 'dashboard_sidebar-active'
                : ''
            } flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `Account Settings`}
              placement="right"
              arrow
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </Tooltip>
            <span className="sidebar-text"> Account Settings </span>
          </Link>

          {(admin || user.manager) && (
            <button
              className={`flex items-center ${
                isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
              }`}
              onClick={handleSwitchToAdmin}
            >
              <Tooltip
                title={!isExpanded && `Switch to Admin`}
                placement="right"
                arrow
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                  />
                </svg>
              </Tooltip>

              <span className="sidebar-text"> Switch to Admin </span>
            </button>
          )}

          <Link
            to={`/dashboard/${profileId}/edit/profile/`}
            className={` ${
              location.pathname === `/dashboard/${profileId}/edit/profile` ||
              pathname.startsWith(`/dashboard/${profileId}/edit/profile/`)
                ? 'dashboard_sidebar-active'
                : ''
            }
            flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip title={!isExpanded && `Main Site`} placement="right" arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
                />
              </svg>
            </Tooltip>

            <span className="sidebar-text"> Main Site </span>
          </Link>

          <Link
            to={`/dashboard/${profileId}/custom-funnel`}
            className={` ${
              location.pathname === `/dashboard/${profileId}/custom-funnel` ||
              pathname.startsWith(`/dashboard/${profileId}/custom-funnel`)
                ? 'dashboard_sidebar-active'
                : ''
            }
            flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `Custom Funnel`}
              placement="right"
              arrow
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
                />
              </svg>
            </Tooltip>

            <span className="sidebar-text"> Custom Funnel </span>
          </Link>

          <Link
            to={`/dashboard/${profileId}/all-funnels/`}
            className={` ${
              location.pathname === `/dashboard/${profileId}/all-funnels` ||
              pathname.startsWith(`/dashboard/${profileId}/all-funnels/`)
                ? 'dashboard_sidebar-active'
                : ''
            }
            flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `All Funnels`}
              placement="right"
              arrow
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>
            </Tooltip>

            <span className="sidebar-text">All Funnels</span>
          </Link>

          {/* {(isOwnProfile || allowedFunnels.includes('Calculator')) && (
            <Link
              to={`/dashboard/${profileId}/calculators`}
              className={`${
                location.pathname === `/dashboard/${profileId}/calculators` ||
                pathname.startsWith(`/dashboard/${profileId}/calculators/`)
                  ? 'dashboard_sidebar-active'
                  : ''
              } flex items-center ${
                isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
              }`}
            >
              <Tooltip
                title={!isExpanded && `Calculator Settings`}
                placement="right"
                arrow
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </Tooltip>

              <span className="sidebar-text"> Calculator Settings </span>
            </Link>
          )} */}

          {/* <Link
          to={`/dashboard/${profileId}/minimize-button`}
          className={`${
            location.pathname === `/dashboard/${profileId}/minimize-button`
              ? "dashboard_sidebar-active"
              : ""
          } flex items-center px-3 py-2 space-x-4`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
            />
          </svg>

          <span> Minimize Button </span>
        </Link> */}

          <Link
            to={`/dashboard/${profileId}/leads`}
            className={`${
              location.pathname === `/dashboard/${profileId}/leads` ||
              pathname.startsWith(`/dashboard/${profileId}/leads/`)
                ? 'dashboard_sidebar-active'
                : ''
            } flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `Lead lists`}
              placement="right"
              arrow
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
            </Tooltip>
            <span className="sidebar-text"> Lead lists </span>
          </Link>

          <Link
            to={`/dashboard/${profileId}/emails`}
            className={`${
              location.pathname === `/dashboard/${profileId}/emails` ||
              pathname.startsWith(`/dashboard/${profileId}/emails/`)
                ? 'dashboard_sidebar-active'
                : ''
            } flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip title={!isExpanded && `Emails`} placement="right" arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </Tooltip>

            <span className="sidebar-text"> Emails </span>
          </Link>
          {isOwnProfile && (
            <Link
              to={`/dashboard/${profileId}/manage-access`}
              className={`${
                location.pathname === `/dashboard/${profileId}/manage-access` ||
                pathname.startsWith(`/dashboard/${profileId}/manage-access/`)
                  ? 'dashboard_sidebar-active'
                  : ''
              } flex items-center ${
                isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
              }`}
            >
              <Tooltip
                title={!isExpanded && `Manage Access`}
                placement="right"
                arrow
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>
              </Tooltip>

              <span className="sidebar-text"> Manage Access </span>
            </Link>
          )}

          {isOwnProfile && (
            <Link
              to={`/dashboard/${profileId}/plans`}
              className={`${
                location.pathname === `/dashboard/${profileId}/plans` ||
                pathname.startsWith(`/dashboard/${profileId}/plans/`)
                  ? 'dashboard_sidebar-active'
                  : ''
              } flex items-center ${
                isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
              }`}
            >
              <Tooltip
                title={!isExpanded && `Subscription Plans`}
                placement="right"
                arrow
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                  />
                </svg>
              </Tooltip>

              <span className="sidebar-text"> Subscription Plans </span>
            </Link>
          )}
          <Link
            to={`/dashboard/profiles`}
            className={`flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `Switch Profiles`}
              placement="right"
              arrow
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                />
              </svg>
            </Tooltip>
            <span className="sidebar-text"> Switch Profiles </span>
          </Link>
          <Link
            to={`/dashboard/${profileId}/marketplace`}
            className={`${
              location.pathname === `/dashboard/${profileId}/marketplace` ||
              pathname.startsWith(`/dashboard/${profileId}/marketplace/`)
                ? 'dashboard_sidebar-active'
                : ''
            }
            flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
          >
            <Tooltip
              title={!isExpanded && `Marketplace`}
              placement="right"
              arrow
            >
              <img
                src={MarketPlaceIcon}
                alt="marketplace"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              />
            </Tooltip>

            <span className="sidebar-text"> Marketplace </span>
          </Link>
          <button
            className={`flex items-center ${
              isExpanded ? 'px-3 py-2 space-x-4' : 'px-1 py-1'
            }`}
            onClick={handleLogout}
          >
            <Tooltip title={!isExpanded && `Log out`} placement="right" arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={isExpanded ? 'w-6 h-6' : 'w-7 h-7'}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </Tooltip>

            <span className="sidebar-text"> Log out </span>
          </button>
          {/* {loading && <Loader customClass="md:ml-[300px]" />} */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
