import React, { useState, useRef, useEffect, useReducer } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

import ActionTypes from '../../utils/actionTypes';
import useContext from '../../context/StateProvider';

import Loader from '../Loader/Loader';
import FlexiFramePreview from './FlexiFramePreview';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import config from '../../utils/config';
import FlexiFrameButton from './FlexiFrameButton';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function FlexiFramePlugin() {
  const [{ user, allPlugins, currentPlugin, isOwnPlugin }, dispatch] =
    useContext();
  const { pluginId, profileId } = useParams();
  const navigate = useNavigate();

  const [emailTemplate, setEmailTemplate] = useState('');

  const [pluginName, setPluginName] = useState('');
  const [pluginToken, setPluginToken] = useState('');
  const [webLink, setWebLink] = useState('');

  const [mediaType, setMediaType] = useState('website');
  const [minimizeButton, setMinimizeButton] = useState({
    styles: {},
    position: {},
  });
  const [behaviour, setBehaviour] = useState('withMinimize');
  const [state, setState] = useState(true);

  // const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showWidget, setShowWidget] = useState(true);
  const [webLinkValidationError, setWebLinkValidationError] = useState('');
  const [mediaTypeInput, setMediaTypeInput] = useState('');

  const handleShow = () => {
    // setShowWidget(show)
    setShowWidget((prev) => !prev);
  };

  useEffect(() => {
    if (!user) navigate('/');
    else if (!pluginId) navigate(`/dashboard/${profileId}/flexiframe`);

    axios({
      url: '/api/flexiframe/id/' + pluginId,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log('flexiframeplugin: ', resData.data);

        if (resData.success) {
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [pluginId]);

  useEffect(() => {
    if (currentPlugin !== null && currentPlugin) {
      console.log('currentPlugin: ', currentPlugin);

      setPluginName(currentPlugin.name);
      setPluginToken(currentPlugin.token);
      localStorage.setItem('flexiFrameToken', currentPlugin.token);

      setWebLink(currentPlugin.webLink);
      setMediaType(currentPlugin.mediaType);
      setBehaviour(currentPlugin.behaviour);
      setState(currentPlugin.state);
      setMinimizeButton(currentPlugin.button);
      setLoading(false);
    }
  }, [currentPlugin]);

  const handleSave = () => {
    if (user.accountType !== 'Pro') {
      toast.error('Please upgrade your plan');
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        name: pluginName,
        webLink,
        mediaType,
        behaviour,
        state,
      })
    );
    axios({
      url: '/api/flexiframe/update/' + pluginId,
      method: 'put',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log('saveFrame: ', resData.data);
        setLoading(false);
        if (resData.success) {
          toast.success('Updated successfully');
          setWebLink(resData.data.webLink);

          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleResetColors = () => {
    // setBackgroundColor(
    //   'linear-gradient(37deg, rgba(172,30,35,1) 5%, rgba(39,45,52,1) 74%)'
    // );
    toast.success('Colors reset!');
  };

  const handleLinkChange = (event) => {
    const inputLink = event.target.value;
    let validationError = '';

    // if (mediaType !== event.target.value) {
    //   setWebLinkValidationError('');
    // }

    if (mediaType === 'website') {
      if (inputLink && !isValidWebHostedLink(inputLink)) {
        validationError = 'Not a valid website link';
        setWebLink(inputLink);
      } else {
        setWebLink(inputLink);
      }
    } else if (mediaType === 'youtube') {
      setWebLink('');
      validationError = '';
      if (inputLink) {
        const videoId = youTubeGetID(inputLink);
        if (!videoId) {
          validationError = 'Not a valid YouTube video link';
          setWebLink(inputLink);
        } else {
          const embedUrl = `https://www.youtube.com/embed/${videoId}`;
          setWebLink(embedUrl);
        }
      }
    } else if (mediaType === 'vimeo') {
      setWebLink('');
      validationError = '';
      if (inputLink) {
        const vimeoId = getVimeoVideoID(inputLink);
        if (!vimeoId) {
          validationError = 'Not a valid Vimeo video link';
          setWebLink(inputLink);
        } else {
          const embedUrl = `https://player.vimeo.com/video/${vimeoId}`;
          setWebLink(embedUrl);
        }
      }
    } else if (mediaType === 'webHosted') {
      setWebLink('');
      validationError = '';
      if (inputLink && !isValidWebHostedLink(inputLink)) {
        validationError = 'Not a valid web-hosted video link';
        setWebLink(inputLink);
      } else {
        setWebLink(inputLink);
      }
    } else if (mediaType === 'drive') {
      setWebLink('');
      validationError = '';
      if (inputLink) {
        const driveVideoID = getDriveVideoID(inputLink);
        if (!driveVideoID) {
          validationError = 'Not a valid Drive video link';
          setWebLink(inputLink);
        } else {
          const embedUrl = `https://drive.google.com/file/d/${driveVideoID}/preview`;
          setWebLink(embedUrl);
        }
      }
    } else {
      validationError = '';
      setWebLink(inputLink);
    }

    if (mediaType !== event.target.name) {
      setWebLinkValidationError('');
    }

    setWebLinkValidationError(validationError);
  };

  function youTubeGetID(url) {
    const regex = /(?:[?&]v=|youtu\.be\/|\/embed\/)([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  function getVimeoVideoID(url) {
    const regex = /vimeo\.com\/(?:video\/|.*\/videos\/)?([0-9]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  function isValidWebHostedLink(url) {
    const excludedDomains = [
      'www.youtube.com',
      'youtu.be',
      'vimeo.com',
      'drive.google.com',
    ];

    try {
      const { hostname } = new URL(url);
      if (excludedDomains.includes(hostname.toLowerCase())) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  function getDriveVideoID(url) {
    const regex = /drive\.google\.com\/(?:file\/d\/|open\?id=)([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  const handleDeletePlugin = () => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    axios({
      url: '/api/flexiframe/del',
      method: 'DELETE',
      data: {
        userId: user._id,
        pluginId: pluginId,
        emailTemplateId: emailTemplate._id,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          navigate(`/dashboard/${profileId}/flexiframe`, { replace: true });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  if (loading) {
    return <Loader customClass="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <div>
        <h1 className="text-2xl text-[#39393bde] font-bold">
          Embed FlexiFrame in your website
        </h1>
        <div className="dashboard_plugin_embed flex justify-between mt-4">
          <div
            className={`relative flex-1 py-2 px-3 ${
              user.accountType === 'Pro'
                ? 'overflow-auto'
                : 'overflow-hidden select-none'
            }`}
          >
            {user.accountType !== 'Pro' && (
              <div
                className="absolute top-0 left-0 right-0 bottom-0 z-10"
                style={{
                  background: 'rgba( 255, 255, 255, 0.25 )',
                  boxShadow: ' 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                  backdropFilter: 'blur( 5.5px )',
                  WebkitBackdropFilter: 'blur( 5.5px )',

                  border: '1px solid rgba( 255, 255, 255, 0.18 )',
                }}
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <span className="text-base text-[#39393bde] font-bold">
                    Upgrade your plan to use this funnel
                  </span>{' '}
                </div>
              </div>
            )}
            <pre>
              <ol className="text-[#97AAC3]">
                <li>
                  <span className="text-gray-400">
                    {' '}
                    &lt;!-- Mortgage FlexiFrame Widget --&gt;{' '}
                  </span>
                </li>
                <li>
                  <span> &lt;script&gt;</span>
                </li>

                <li>
                  {' '}
                  <span>
                    {' '}
                    var d = document.createElement("div");var qs =
                    document.createElement("script");d.classList.add("flexiframe");d.setAttribute("id",
                    "flexiframe");
                  </span>
                </li>

                <li>
                  {'  '}
                  <span>
                    d.setAttribute("data-sitekey","{pluginToken}");qs.src ="
                    {config.baseUrl}/static/js/flexiframe.js";qs.defer= true;
                  </span>
                </li>
                <li>
                  <span>
                    {'  '}
                    var l1 =
                    document.createElement("link");l1.setAttribute("rel",
                    "stylesheet");l1.setAttribute("type",
                    "text/css");l1.setAttribute("href","{config.baseUrl}
                    /static/css/flexiframe.css");
                  </span>
                </li>
                <li>
                  {' '}
                  <span>
                    {' '}
                    document.head.appendChild(l1);
                    document.body.appendChild(d);document.body.appendChild(qs);{' '}
                  </span>
                </li>

                <li>
                  <span> &lt;/script&gt;</span>
                </li>

                <li>
                  <span className="text-gray-400">
                    {' '}
                    &lt;!-- End Mortgage FlexiFrame Widget --&gt;{' '}
                  </span>
                </li>
              </ol>
            </pre>
          </div>
          <div className="dashboard_plugin_embed-clipboard px-6 py-2 md:px-10 md:py-6">
            <CopyToClipboard
              text={`<!-- Mortgage FlexiFrame Widget --> 
  <script>
    var d = document.createElement("div");var qs = document.createElement("script");d.classList.add("flexiframe");var currentScriptTag = document.currentScript;d.setAttribute("id", "flexiframe");d.setAttribute("data-sitekey","${pluginToken}");qs.src = "${config.baseUrl}/static/js/flexiframe.js";qs.defer = true;
    var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/flexiframe.css");
    document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
  </script>
<!-- End Mortgage FlexiFrame Widget -->`}
              onCopy={() => {
                if (user.accountType === 'Pro') {
                  toast.success('Copied to clipboard');
                  toast.success('Please add the code into body tag');
                } else {
                  toast.error('Please upgrade your plan');
                }
              }}
            >
              <button className="w-full h-full text-center text-[#7B94B5] text-sm md:text-base">
                <span> Copy to </span> <br />
                <span> Clipboard </span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-12 space-y-4 md:space-y-0 md:space-x-6">
        <div className="md:mr-6">
          <h1 className="text-2xl text-[#39393bde] font-bold">
            Customize the plugin
          </h1>

          <div className="mt-6">
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="pluginName"
              >
                FlexiFrame Name
              </label>
              <input
                id="pluginName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="pluginName"
                value={pluginName}
                placeholder="Default"
                onChange={(e) => setPluginName(e.target.value)}
              />
            </div>
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="mediaType"
              >
                {' '}
                Choose Link Type{' '}
              </label>
              <div className="ml-2 md:flex items-center md:space-x-5">
                <Tooltip
                  title={`Must use a valid website link. Example: www.example.com`}
                  placement="top"
                  arrow
                >
                  <label
                    className="flex items-center space-x-2 cursor-pointer"
                    htmlFor="website"
                  >
                    <input
                      id="website"
                      name="mediaType"
                      type="radio"
                      value="website"
                      checked={mediaType === 'website'}
                      onChange={(e) => {
                        setMediaType(e.target.value);
                        setWebLink('');
                      }}
                    />
                    <span>Website</span>
                  </label>
                </Tooltip>

                <Tooltip
                  title={`Must use a valid Youtube video link. Example: www.youtube.com/watch?v=123`}
                  placement="top"
                  arrow
                >
                  <label
                    className="flex items-center space-x-2 cursor-pointer"
                    htmlFor="youtube"
                  >
                    <input
                      id="youtube"
                      name="mediaType"
                      type="radio"
                      value="youtube"
                      checked={mediaType === 'youtube'}
                      onChange={(e) => {
                        setMediaType(e.target.value);
                        setWebLink('');
                      }}
                    />
                    <span>Youtube</span>
                  </label>
                </Tooltip>

                <Tooltip
                  title={`Must use a valid Vimeo video link. Example: www.vimeo.com/videoid`}
                  placement="top"
                  arrow
                >
                  <label
                    className="flex items-center space-x-2 cursor-pointer"
                    htmlFor="vimeo"
                  >
                    <input
                      id="vimeo"
                      name="mediaType"
                      type="radio"
                      value="vimeo"
                      checked={mediaType === 'vimeo'}
                      onChange={(e) => {
                        setMediaType(e.target.value);
                        setWebLink('');
                      }}
                    />
                    <span>Vimeo</span>
                  </label>
                </Tooltip>
                <Tooltip
                  title={`Must use a valid website hosted/embeded video link. Example: www.website.com/videoid`}
                  placement="top"
                  arrow
                >
                  <label
                    className="flex items-center space-x-2 cursor-pointer"
                    htmlFor="webHosted"
                  >
                    <input
                      id="webHosted"
                      name="mediaType"
                      type="radio"
                      value="webHosted"
                      checked={mediaType === 'webHosted'}
                      onChange={(e) => {
                        setMediaType(e.target.value);
                        setWebLink('');
                      }}
                    />
                    <span>Web Hosted Video</span>
                  </label>
                </Tooltip>

                <Tooltip
                  title={`Must use a valid Google Drive stored video link. Example: www.drive.google.com/file/d/123/view`}
                  placement="top"
                  arrow
                >
                  <label
                    className="flex items-center space-x-2 cursor-pointer"
                    htmlFor="drive"
                  >
                    <input
                      id="drive"
                      name="mediaType"
                      type="radio"
                      value="drive"
                      checked={mediaType === 'drive'}
                      onChange={(e) => {
                        setMediaType(e.target.value);
                        setWebLink('');
                      }}
                    />
                    <span>Google Drive Video</span>
                  </label>
                </Tooltip>
              </div>
            </div>

            {/* <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Preview The FlexiFrame Here
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={showWidget}
                label={showWidget ? 'Show' : 'Off'}
                onChange={handleShow}
              />
            </div> */}

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="webLink"
              >
                Put the{' '}
                {mediaType === 'youtube'
                  ? 'Youtube'
                  : mediaType === 'vimeo'
                  ? 'Vimeo'
                  : mediaType === 'drive'
                  ? 'Google Drive'
                  : mediaType === 'webHosted'
                  ? 'Website Hosted Video'
                  : 'Website'}{' '}
                link
              </label>
              <input
                id="webLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="webLink"
                value={webLink}
                placeholder="https://www.example.com/"
                onChange={handleLinkChange}
              />
              {webLinkValidationError && (
                <p className="text-red-500 text-sm">{webLinkValidationError}</p>
              )}
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="behaviour"
              >
                {' '}
                Behavior{' '}
              </label>
              <div className="ml-2 md:flex items-center md:space-x-5">
                <label className="flex items-center space-x-2" htmlFor="left">
                  <input
                    id="left"
                    name="behaviour"
                    type="radio"
                    value="left"
                    checked={behaviour === 'left'}
                    onChange={(e) => setBehaviour(e.target.value)}
                  />
                  <span>Show left</span>
                </label>
                <label className="flex items-center space-x-2" htmlFor="right">
                  <input
                    id="right"
                    name="behaviour"
                    type="radio"
                    value="right"
                    checked={behaviour === 'right'}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Show right</span>
                </label>
              </div>
            </div>

            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Initial State
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={state}
                label={state ? 'Open' : 'Minimized'}
                onChange={(e) => setState((prev) => !prev)}
              />
            </div>

            <h3 className="mt-6 text-sm text-[#39393bde] font-bold">
              {' '}
              Customization Options:{' '}
            </h3>

            <Link
              to={`minimize-button`}
              className="mb-6 flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
            >
              <span>Customize Minimize Button</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </Link>
            <CopyToClipboard
              text={`${window.location.origin}/view/flexiframe/${currentPlugin.token}`}
              onCopy={() => {
                toast.success('Copied to clipboard');
                toast.success('Please add the code into body tag');
              }}
            >
              <button className="flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
                <span>Share/Preview FlexiFrame</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                  />
                </svg>
              </button>
            </CopyToClipboard>

            <div className="mt-6 flex items-center flex-wrap gap-3">
              <button
                className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
                onClick={handleSave}
                // disabled={!edited || loading}
              >
                Save
              </button>
              {isOwnPlugin && (
                <button
                  className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-red-600"
                  onClick={handleDeletePlugin}
                  // disabled={!edited || loading}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
        {showWidget ? (
          <FlexiFramePreview
            webPageLink={webLink}
            show={state}
            showWidget={showWidget}
            behaviour={behaviour}
            handleShow={handleShow}
            mediaType={mediaType}
          />
        ) : (
          ''
        )}

        <FlexiFrameButton
          handleShow={handleShow}
          show={showWidget}
          minimizeButton={currentPlugin.button}
        />
      </div>
    </div>
  );
}

export default FlexiFramePlugin;
