import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const StepForm = ({
  value,
  setNewQuestion,
  setRequiredField,
  options,
  handleOptionChange,
  addOption,
  handleQuestionSave,
}) => {
  const [isRequired, setIsRequired] = useState(false);

  const handleCheckboxChange = () => {
    setIsRequired((prev) => !prev);
    setRequiredField(!isRequired);
  };
  return (
    <>
      <div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Questions</h3>
          <TextField
            label="Enter Question"
            variant="outlined"
            fullWidth
            value={value}
            onChange={(e) => setNewQuestion(e.target.value)}
            className="mb-4"
          />
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Options</h3>

          {options.map((option, index) => (
            <div key={index} className="mb-2">
              <TextField
                label={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                fullWidth
              />
            </div>
          ))}

          <Button
            variant="contained"
            onClick={addOption}
            className="mb-4 w-full flex items-center justify-between"
          >
            Add Option
            <AddIcon />
          </Button>
        </div>

        <div className="mb-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={isRequired}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Required"
          />
        </div>

        <Button
          variant="contained"
          onClick={handleQuestionSave}
          className="w-full"
        >
          Save Question
        </Button>
      </div>
    </>
  );
};

export default StepForm;
