import React, { useState } from 'react';

function Widget({
  webPageLink,
  behaviour,
  mediaType,
  show,
  showWidget,
  handleShow,
}) {
  return (
    <div
      className={`${
        mediaType === 'youtube' ||
        mediaType === 'drive' ||
        mediaType === 'webHosted'
          ? 'flexiframe-iframe-main-media'
          : mediaType === 'vimeo'
          ? 'flexiframe-iframe-main-vimeo'
          : 'flexiframe-iframe-main'
      } ${
        behaviour === 'right'
          ? `rightshow ${
              showWidget
                ? 'scale-out-br-reversed'
                : 'scale-out-br ff-transition-max-height ff-duration-300 ff-ease-in-out ff-collapse'
            }`
          : `leftshow ${
              showWidget
                ? 'scale-out-bl-reversed'
                : 'scale-out-bl ff-transition-max-height ff-duration-300 ff-ease-in-out ff-collapse'
            }`
      } `}
    >
      <button
        className={`iframe-close-btn-flexi ${
          behaviour === 'right' ? 'leftclose' : 'rightclose'
        }`}
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          overflow="visible"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        >
          {' '}
          <line x2="12" y2="12"></line> <line x1="12" y2="12"></line>{' '}
        </svg>
      </button>

      <iframe
        className="relative h-full w-full border-none bg-transparent bottom-0 right-0 left-0 shadow-md block z-0 overflow-hidden"
        title="Embedded Content"
        src={webPageLink}
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    </div>
  );
}

export default Widget;
