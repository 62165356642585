import React from "react";
import axios from "axios";

function SharePDF({ calculatedData, show, onClose, sitekey }) {
  const handleShare = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const email = formData.get("email");

    axios({
      url: "/api/plugin/pdf/" + sitekey,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: calculatedData,
        email: email,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          alert(
            "Mail has been sent, you can check your spam folder if you don't see on primary mailbox"
          );
          onClose();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div
      className={`mtge-calc_modal-wrapper ${
        show ? "mtge-calc_modal-wrapper-show" : ""
      } mtge-calc_modal-wrapper-share`}
    >
      <div className="mtge-calc_modal-overlay"></div>
      <div
        className="mtge-calc_modal"
        id="mtge-calc_share-wrapper"
        onClick={onClose}
      >
        <div
          className="mtge-calc_modal_wrapper"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            id="mtge-share-close"
            className="mtge-calc_modal-close"
            onClick={onClose}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <h2 className="mtge-calc_newsletter-title"> Enter Your E-mail </h2>
          <form id="mtge-calc_share-form" onSubmit={handleShare}>
            <div className="mtge-calc_newsletter-field">
              <label> Email </label>
              <div className="mtge-calc_newsletter-field_input">
                <input type="email" name="email" />
              </div>
            </div>
            <div className="mtge-calc_newsletter-field">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SharePDF;
