import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';

import Loader from '../Loader/Loader';
import ModalWrapper from '../Modal';
import { useParams } from 'react-router-dom';

function Mails() {
  const [{ allProfiles }] = useContext();
  const { profileId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (profileId) {
      let current = allProfiles.find((dt) => dt._id === profileId);
      if (!current) return;
      axios({
        method: 'GET',
        url: '/api/profiles/emailTemplate/' + current.emailTemplate,
      })
        .then((res) => {
          const resData = res.data;
          if (resData.success) {
            setData(resData.data);
          } else {
            toast.error(resData.status, {});
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error?.message || status, {});
        });
    }
  }, [profileId]);

  if (loading) {
    return <Loader customclassName="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <h1 className="text-2xl text-[#39393bde] font-bold">
        System mail templates{' '}
      </h1>
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 space-x-3 space-y-3">
        <div>
          <Card
            id={data._id}
            title="E-mail report"
            info={
              'This is an automated system email used when a lead requests to send the mortgage calculator report to themselves or to another person.'
            }
            data={data.emailReport}
            name="emailReport"
            updateData={(dt) => setData(dt)}
          />
        </div>
        <div className="mt-8">
          <Card
            id={data._id}
            title="View Properties"
            info={
              'This is an automated system email that is sent once a lead completes the View Properties funnel.'
            }
            data={data.viewProperties}
            name="viewProperties"
            updateData={(dt) => setData(dt)}
          />
        </div>
        <div className="mt-8">
          <Card
            id={data._id}
            title="Request Callback"
            info={
              'This is an automated system email that is sent when a lead requests a call back.'
            }
            data={data.requestCallback}
            name="requestCallback"
            updateData={(dt) => setData(dt)}
          />
        </div>
        <div className="mt-8">
          <Card
            id={data._id}
            title="Schedule appointment"
            info={
              'This is an automated system email that is sent when a leads requests to schedule an appointment from the contact us button.'
            }
            data={data.scheduleAppointment}
            name="scheduleAppointment"
            updateData={(dt) => setData(dt)}
          />
        </div>
      </div>
    </div>
  );
}

function Card({ id, title, info, data, name, updateData }) {
  const [showEditor, setShowEditor] = useState(false);

  return (
    <div className="h-full w-full rounded-lg bg-[#f3f4f6] shadow-2xl">
      <div className="px-4 py-4 flex justify-between items-center bg-red bg-white border-b">
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
            />
          </svg>
          <span className="text-lg font-semibold"> {title} </span>
        </div>
        <div className="">
          <button
            className="text-green-500"
            onClick={() => setShowEditor(true)}
          >
            Edit
          </button>
        </div>
      </div>
      <div className="p-4 h-full max-h-40 overflow-y-auto">
        <span> {info}</span>
      </div>
      {showEditor && (
        <EditModal
          id={id}
          title={title}
          data={data}
          show={showEditor}
          onClose={() => setShowEditor(false)}
          name={name}
          updateData={updateData}
        ></EditModal>
      )}
    </div>
  );
}

function EditModal({ id, show, onClose, title, data, name, updateData }) {
  const [replyToName, setReplyToName] = useState('Savage Sales');
  const [replyToEmail, setReplyToEmail] = useState(
    'mortgage.calc729@gmail.com'
  );
  const [editSubject, setEditSubject] = useState('');
  const [editBody, setEditBody] = useState('');
  const [sendToClient, setSendToClient] = useState(true);
  const [sendToLead, setSendToLead] = useState(true);
  const [loader, setLoader] = useState('');

  useEffect(() => {
    setReplyToName(data.replyToName);
    setReplyToEmail(data.replyToEmail);
    setEditSubject(data.subject);
    setEditBody(data.html);
    setSendToClient(data.sendToClient);
    setSendToLead(data.sendToLead);
    console.log(data);
  }, [data]);

  const handleSave = () => {
    setLoader(true);
    axios({
      method: 'PUT',
      url: '/api/profiles/emailTemplate/' + id,
      data: {
        [name]: {
          replyToName,
          replyToEmail,
          subject: editSubject,
          html: editBody,
          sendToClient,
          sendToLead,
        },
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setLoader(false);
          updateData(resData.data);
          toast.success('Updated successfully', {});
        } else {
          toast.error(resData.status, {});
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div
        className="mtge-calc_modal_wrapper py-8 w-full max-w-[560px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mtge-calc_modal-close" onClick={onClose}>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <h2 className="my-4 text-lg text-center font-bold">{title}</h2>

        <div className="w-full">
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="replyToName"
                className="text-sm text-black font-semibold"
              >
                Reply to name
              </label>
            </div>
            <div>
              <input
                id="replyToName"
                type="text"
                name="replyToName"
                value={replyToName}
                onChange={(e) => setReplyToName(e.target.value)}
                className="w-full px-2 py-3 rounded-lg text-black text-base outline-none border-2"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="replyToEmail"
                className="text-sm text-black font-semibold"
              >
                Reply to email
              </label>
            </div>
            <div>
              <input
                id="replyToEmail"
                type="text"
                name="replyToEmail"
                value={replyToEmail}
                onChange={(e) => setReplyToEmail(e.target.value)}
                className="w-full px-2 py-3 rounded-lg text-black text-base outline-none border-2"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="subject"
                className="text-sm text-black font-semibold"
              >
                {' '}
                Subject{' '}
              </label>
            </div>
            <div>
              <input
                id="subject"
                type="text"
                name="subject"
                value={editSubject}
                onChange={(e) => setEditSubject(e.target.value)}
                className="w-full px-2 py-3 rounded-lg text-black text-base outline-none border-2"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="body"
                className="text-sm text-black font-semibold"
              >
                {' '}
                Main Body (HTML){' '}
              </label>
            </div>
            <div>
              <textarea
                name="body"
                id="body"
                cols="30"
                rows="10"
                value={editBody}
                onChange={(e) => setEditBody(e.target.value)}
                className="w-full px-2 py-3 rounded-lg outline-none border-2"
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col space-y-2 mb-4">
            <div>
              <label
                htmlFor="subject"
                className="text-sm text-black font-semibold"
              >
                Notifications
              </label>
            </div>
            <div className="flex space-x-6">
              <label
                htmlFor="sendToLead"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={sendToLead}
                  id="sendToLead"
                  className="sr-only peer"
                  onChange={(e) => setSendToLead((prev) => !prev)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900">
                  On/Off
                </span>
              </label>
              <label
                htmlFor="sendToClient"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={sendToClient}
                  id="sendToClient"
                  className="sr-only peer"
                  onChange={(e) => setSendToClient((prev) => !prev)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900">
                  CC me
                </span>
              </label>
            </div>
          </div>
          <div>
            <button
              className="block w-full md:w-60 mx-auto px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleSave}
            >
              Save
            </button>
            {loader && <Loader customClass=" md:mr-[60%]" />}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default Mails;
