import React from "react";

const VideoProgressBar = ({ progress, handleProgressBar }) => {
  const progressBar = 100 - progress;
  return (
    <div
      className="flex items-center relative cursor-pointer"
      style={{ width: "100%", height: "10px" }}
      onClick={handleProgressBar}
    >
      <div
        className="h-full bg-gray-300 rounded-full absolute left-0 z-9999"
        style={{ right: `${progressBar}%` }}
      ></div>
      <div
        className="h-full bg-green-500 rounded-full absolute left-0 z-99999"
        style={{
          right: `${progressBar}%`,
          transition: "width 1s linear,height .2s",
        }}
      ></div>
    </div>
  );
};

export default VideoProgressBar;
