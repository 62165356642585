import React, { useState } from 'react';
import FlexiFrameWidget from './FlexiFrameWidget';

function FlexiFramePreview({
  webPageLink,
  behaviour,
  mediaType,
  show,
  showWidget,
  handleShow,
}) {
  return (
    <>
    
        <div className="fixed">
          <FlexiFrameWidget
            webPageLink={webPageLink}
            behaviour={behaviour}
            mediaType={mediaType}
            show={show}
            showWidget={showWidget}
            handleShow={handleShow}
          />
        </div>
    </>
  );
}

export default FlexiFramePreview;
