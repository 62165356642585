import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { Slider, Stack } from '@mui/material';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function Widget({
  funnelTitle,
  homeSearchPage,
  calenderBookingPage,
  sitekey,
  varbiage,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');

  const [location, setLocation] = useState('');
  const [range, setRange] = useState({ min: 0, max: 0 });
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [condition, setCondition] = useState('');
  const [squareFootage, setSquareFootage] = useState(0);

  const [redirectLink, setRedirectLink] = useState('');

  useEffect(() => {
    if (currentStep === 1 && !uniqueId) {
      let uuid = new Date().getTime();
      setUniqueId(uuid);

      axios({
        url: `https://api.ipify.org/?format=json`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const resData = res.data;
          setIpAddress(resData.ip);

          return axios({
            url: `https://api.ipgeolocation.io/ipgeo`,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              apiKey: 'deb9a79bf3024e94865209fd35da0521',
              fields: 'city,country_name',
              output: 'json',
              ip: resData.ip,
            },
          });
        })
        .then((res) => {
          const resData = res.data;
          return axios({
            url: `/api/viewProperties/leads/add/${sitekey}`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              ref: uuid.toString(),
              ipAddress: resData.ip,
              liveAddress: resData.city + ', ' + resData.country_name,
            },
          });
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    }
  }, [currentStep]);

  const captureUserEvents = (data) => {
    axios({
      url: `/api/viewProperties/leads/add/${sitekey}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        ...data,
      },
    })
      .then((res) => {})
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  const handleNext = () => {
    captureUserEvents({
      searchDetails: {
        location,
        priceRange: range,
        bedrooms,
        bathrooms,
        condition,
        squareFootage,
      },
    });
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setUniqueId('');
    setIpAddress('');
    setLocation('');
    setBathrooms(0);
    setBedrooms(0);
    setCondition('');
    setSquareFootage(0);
    setRange({ min: 0, max: 0 });
  };

  return (
    <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl shadow-2xl">
      <header className="flex items-center justify-between">
        <div className="w-14 lg:w-16">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar currentStep={currentStep} />
      <main className="mt-4 overflow-hidden">
        {currentStep === 0 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="mb-4 text-xl md:text-3xl text-[#ac1e23] capitalize font-medium">
              {funnelTitle}
            </h1>
            <h1 className="text-center text-base md:text-xl font-medium">
              Enter your location
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="location"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="text"
                  name="location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label
                  htmlFor="location"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Location
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={() => {
                  if (location) {
                    handleNext();
                  } else {
                    toast.info('This field is required!');
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 1 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bedrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bedrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bedrooms"
                  required
                  value={bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                />
                <label
                  htmlFor="bedrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bedrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              How many bathrooms do you need?
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="bathrooms"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="bathrooms"
                  required
                  value={bathrooms}
                  onChange={(e) => setBathrooms(e.target.value)}
                />
                <label
                  htmlFor="bathrooms"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Bathrooms
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 3 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Approx. Square Footage
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="relative max-w-[220px] md:max-w-[320px]">
                <input
                  id="squareFootage"
                  className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
                  type="number"
                  name="squareFootage"
                  required
                  value={squareFootage}
                  onChange={(e) => setSquareFootage(e.target.value)}
                />
                <label
                  htmlFor="squareFootage"
                  className="mtge-rates_input-label text-xs md:text-sm"
                >
                  Square Footage
                </label>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 4 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Condition
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-2">
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('New');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'New'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  New
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Fully Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Fully Remodeled'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Fully Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Partially Remodeled');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Partially Remodeled'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Partially Remodeled
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Excellent');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Excellent'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Excellent
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Good');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Good'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Good
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Livable');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Livable'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Livable
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Needs TLC');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Needs TLC'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Needs TLC
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
              <button
                className="relative min-w-[270px] border-[1px] border-[var(--color-red)]"
                style={{
                  boxShadow: '0 6px 10px 0 rgb(0 0 0 / 15%)',
                }}
                onClick={() => {
                  setCondition('Poor');
                  handleNext();
                }}
              >
                <div
                  className={`w-full px-2 py-3 text-center text-sm font-[500] ${
                    condition === 'Poor'
                      ? 'text-[var(--color-red)] bg-white'
                      : 'text-white bg-[var(--color-red)]'
                  }`}
                >
                  Poor
                </div>
                <span className="absolute top-1/2 right-3 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[var(--color-red)]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        ) : currentStep === 5 ? (
          <div className="py-6 flex flex-col items-center">
            <h1 className="text-center text-base md:text-xl font-medium">
              Value Range Estimate
            </h1>
            <div className="mt-3 flex flex-col items-center space-y-3">
              <div className="w-full">
                <div className="my-4">
                  <div className="flex items-center justify-center space-x-4">
                    <div className="relative max-w-[100px] md:max-w-[125px]">
                      <input
                        id="valueRangEstimate"
                        className="mtge-rates_input-field border-none text-[#0f101a] text-lg md:text-xl font-semibold"
                        type="text"
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*)\./g, '$1'))
                        }
                        name="valueRangEstimateMin"
                        required
                        value={range.min}
                        onChange={(e) => {
                          setRange((prev) => ({
                            ...prev,
                            min: e.target.value,
                          }));
                        }}
                      />
                      <label
                        htmlFor="valueRangEstimate"
                        className="mtge-rates_input-label text-xs md:text-sm"
                      >
                        Min
                      </label>
                    </div>
                    <div className="relative max-w-[100px] md:max-w-[125px]">
                      <input
                        id="valueRangEstimate"
                        className="mtge-rates_input-field border-none text-[#0f101a] text-lg md:text-xl font-semibold"
                        type="text"
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*)\./g, '$1'))
                        }
                        name="valueRangEstimateMax"
                        required
                        value={range.max}
                        onChange={(e) => {
                          setRange((prev) => ({
                            ...prev,
                            max: e.target.value,
                          }));
                        }}
                      />
                      <label
                        htmlFor="valueRangEstimate"
                        className="mtge-rates_input-label text-xs md:text-sm"
                      >
                        Max
                      </label>
                    </div>
                  </div>
                </div>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1, px: 1 }}
                  alignItems="center"
                >
                  <Slider
                    aria-label="Volume"
                    value={[range.min, range.max]}
                    min={80000}
                    step={10000}
                    max={20000000}
                    name="valueRangEstimate"
                    onChange={(e) => {
                      const { value } = e.target;

                      setRange({
                        min: value[0],
                        max: value[1],
                      });
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={numberWithCommas}
                    sx={{
                      color: 'var(--color-red)',
                      '& .MuiSlider-track': {
                        border: 'none',
                      },
                      '& .MuiSlider-thumb': {
                        width: 24,
                        height: 24,
                        backgroundColor: '#fff',
                        '&:before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                </Stack>

                <div className="flex items-center justify-between">
                  <p className="text-xs font-semibold text-[var(--color-red)]">
                    80k
                  </p>
                  <p className="text-xs font-semibold text-[var(--color-red)]">
                    20M
                  </p>
                </div>
              </div>

              <button
                className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        ) : currentStep === 6 ? (
          <div className="mtge-calc_lead-container mt-2 px-6 md:px-10">
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(calenderBookingPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View in person
            </button>
            <button
              className="mtge-calc_btn"
              onClick={() => {
                setRedirectLink(homeSearchPage);
                setCurrentStep((prev) => prev + 1);
              }}
            >
              View Online
            </button>
          </div>
        ) : currentStep === 7 ? (
          <LeadForm
            ipAddress={ipAddress}
            uniqueId={uniqueId}
            onClose={() => {
              setCurrentStep(0);
            }}
            redirectLink={redirectLink}
            redirect={true}
            viewPropertiesDetails={{
              location,
              priceRange: range,
              bedrooms,
              bathrooms,
              condition,
              squareFootage,
            }}
            sitekey={sitekey}
            reset={handleReset}
          />
        ) : (
          ''
        )}
        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-center text-xs text-[#6e7c81] space-x-2">
          {varbiage.map((dt, idx) => {
            if (dt.text) {
              return (
                <a
                  className="hover:underline"
                  key={idx}
                  target="_blank"
                  href={dt.link}
                >
                  {dt.text}
                </a>
              );
            }
          })}
        </div>
        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

function LeadForm({
  ipAddress,
  uniqueId,
  onClose,
  redirectLink,
  sitekey,
  redirect,
  viewPropertiesDetails,
  reset,
}) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      url: `/api/viewProperties/leads/add/${sitekey}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        ipAddress,
        fullName,
        email,
        phone,

        searchDetails: viewPropertiesDetails || '',
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(res);
        console.log(resData);
        if (resData.success) {
          toast.success('Successfully done!', {});
          if (redirect) {
            window.open(redirectLink);
          }

          onClose();
          reset();
        } else {
          toast.error(resData.status, {});
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  return (
    <div className="px-6 md:px-10">
      <h2 className="mtge-calc_newsletter-title">View Properties</h2>
      <form onSubmit={handleSubmit}>
        <div className="mtge-calc_newsletter-field">
          <label> Full Name* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="text"
              name="full_name"
              placeholder="Full name"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Email* </label>
          <div className="mtge-calc_newsletter-field_input">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <label> Phone (verify you're human) </label>
          <div className="mtge-calc_newsletter-field_input">
            <PhoneInput
              international
              defaultCountry="US"
              value={phone}
              onChange={setPhone}
              required
            />
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <div className="mtge-calc_newsletter-field_checkbox">
            <span>
              <input
                defaultChecked
                type="checkbox"
                name="accept_privacy"
                required
              />
            </span>
            <span>
              I agree to receive marketing emails to the email address provided
            </span>
          </div>
        </div>
        <div className="mtge-calc_newsletter-field">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep > 0) {
      let totalStep = 8;
      let calculatedPercentage = ((currentStep + 1) / totalStep) * 100;
      setWidth(calculatedPercentage.toFixed(0));
    } else {
      setWidth(0);
    }
  }, [currentStep]);

  return (
    <div className="relative w-full text-white h-[1px] bg-gray-200">
      <div
        className="h-full bg-[var(--color-red)] transition-all duration-750 ease-in-out"
        style={{
          width: width + '%',
        }}
      ></div>
      <div
        className="absolute top-2 px-1 py-1 -mx-4 text-white text-[8px] bg-[var(--color-red)] rounded-sm after:content-[''] after:absolute after:-top-1 after:left-1/2 after:h-0 after:w-0 after:border-l-4 after:border-r-4 after:border-b-4 after:border-l-transparent after:border-r-transparent after:border-b-[var(--color-red)] after:transform after:-translate-x-1/2 transition-all duration-750 ease-in-out"
        style={{
          visibility: width === 0 ? 'hidden' : 'visible',
          left: width + '%',
          color: 'white',
        }}
      >
        {width}%
      </div>
    </div>
  );
};

const PrevButton = ({ handlePrev }) => {
  return (
    <button
      className="absolute top-1/2 left-1 p-1 border-2 border-gray-600 border-solid transform -translate-y-1/2"
      onClick={handlePrev}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3 h-3 text-[var(--color-red)]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

export default Widget;
