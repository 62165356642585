import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useContext from '../../context/StateProvider';
import axios from 'axios';
import ColorPicker from 'react-best-gradient-color-picker';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

// icons
import { IconContext } from 'react-icons';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillHome, AiOutlineSearch } from 'react-icons/ai';
import { FaFacebookF, FaFacebookMessenger } from 'react-icons/fa';
import { IoMdCall } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { BsCalculator, BsTelegram, BsWhatsapp } from 'react-icons/bs';
import { FaShareAlt } from 'react-icons/fa';
import { GiMagnifyingGlass } from 'react-icons/gi';
import config from '../../utils/config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Buttonizer() {
  const [{ user, currentPlugin, allPlugins, isOwnPlugin }, dispatch] =
    useContext();

  const { pluginId, profileId } = useParams();
  const [openIconPicker, setOpenIconPicker] = useState(false);

  const [openIconDialog, setOpenIconDialog] = useState(false);
  const [shouldPutOn, setShouldPutOn] = useState(null);

  const [pluginToken, setPluginToken] = useState('');
  const [pluginName, setPluginName] = useState('');

  const [layout, setLayout] = useState('vertical');
  const [isOpened, setIsOpened] = useState(false);
  const [visibleCount, setVisibleCount] = useState(0);

  const [menuIcon, setMenuIcon] = useState('');
  const [newMenuIcon, setNewMenuIcon] = useState('');

  const [getMenu, setGetMenu] = useState('');

  const [email, setEmail] = useState({
    email: 'example@gmail.com',
    type: 'email',
    visible: true,
    tooltip: 'Test',
  });
  const [phone, setPhone] = useState({
    phone: '+8801725182816',
    type: 'call',
    visible: true,
    tooltip: 'Test',
  });
  const [facebook, setFacebook] = useState({
    url: 'https://example.com',
    type: 'facebook',
    visible: true,
    tooltip: 'Test',
  });
  const [messenger, setMessenger] = useState({
    url: 'https://example.com',
    type: 'facebook',
    visible: true,
    tooltip: 'Test',
  });
  const [whatsapp, setWhatsapp] = useState({
    url: 'https://example.com',
    type: 'facebook',
    visible: true,
    tooltip: 'Test',
  });
  const [telegram, setTelegram] = useState({
    url: 'https://example.com',
    type: 'facebook',
    visible: true,
    tooltip: 'Test',
  });
  const [share, setShare] = useState({
    url: 'https://example.com',
    type: 'facebook',
    visible: true,
    tooltip: 'Test',
  });
  const [calculator, setCalculator] = useState({
    type: 'calculator',
    visible: true,
    tooltip: 'Test',
    sitekey: '',
  });
  const [homeValues, setHomeValues] = useState({
    type: 'homeValue',
    visible: true,
    tooltip: 'Test',
    sitekey: '',
  });
  const [viewProperties, setViewProperties] = useState({
    type: 'viewProperty',
    visible: true,
    tooltip: 'Test',
    sitekey: '',
  });
  const [custom1, setCustom1] = useState({
    url: '',
    type: 'custom-1',
    visible: true,
    tooltip: 'Test',
    icon: '',
  });
  const [custom2, setCustom2] = useState({
    url: '',
    type: 'custom-2',
    visible: false,
    tooltip: 'Test',
    icon: '',
  });
  const [custom3, setCustom3] = useState({
    url: '',
    type: 'custom-3',
    visible: false,
    tooltip: 'Test',
    icon: '',
  });
  const [custom4, setCustom4] = useState({
    url: '',
    type: 'custom-4',
    visible: false,
    tooltip: 'Test',
    icon: '',
  });

  const [data, setData] = useState([
    {
      email: 'https://example.com',
      type: 'email',
      visible: true,
      tooltip: 'Test',
    },
    {
      phone: 'https://example.com',
      type: 'call',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: 'https://example.com',
      type: 'facebook',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: 'https://example.com',
      type: 'messenger',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: 'https://example.com',
      type: 'whatsapp',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: 'https://example.com',
      type: 'telegram',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: 'https://example.com',
      type: 'share',
      visible: true,
      tooltip: 'Test',
    },
    {
      type: 'calculator',
      visible: true,
      tooltip: 'Test',
    },
    {
      type: 'homeValue',
      visible: true,
      tooltip: 'Test',
    },
    {
      type: 'viewProperty',
      visible: true,
      tooltip: 'Test',
    },
    {
      url: '',
      type: 'custom-1',
      visible: false,
      tooltip: 'Test',
      icon: '',
    },
    {
      url: '',
      type: 'custom-2',
      visible: false,
      tooltip: 'Test',
      icon: '',
    },
    {
      url: '',
      type: 'custom-3',
      visible: false,
      tooltip: 'Test',
      icon: '',
    },
    {
      url: '',
      type: 'custom-4',
      visible: false,
      tooltip: 'Test',
      icon: '',
    },

    // {
    //   type: 'menuIcon',
    //   defaultIcon: <GiHamburgerMenu className="mx-auto text-white text-3xl" />,
    //   hamburgerIcon: '',
    // },
  ]);
  // console.log(viewProperties);
  // console.log(calculator);
  // console.log(homeValues);

  const [behaviour, setBehaviour] = useState('fixed');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [backgroundColorPicker, setBackgroundColorPicker] = useState(false);
  const [hoverColorPicker, setHoverColorPicker] = useState(false);

  //
  const [calculators, setCalculators] = useState([]);
  const [checkHomeValues, setCheckHomeValues] = useState([]);
  const [mortgageRates, setMortgageRates] = useState([]);
  const [viewPropertiesD, setViewPropertiesD] = useState([]);

  const handleIconPickerOpen = () => {
    setOpenIconPicker(true);
  };

  const handleIconPickerClose = () => {
    setOpenIconPicker(false);
  };

  useEffect(() => {
    if (pluginId) {
      axios({
        url: '/api/buttonizer/' + pluginId,
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then((res) => {
          const resData = res.data;

          if (resData.success) {
            setPluginName(resData.data.name);
            setPluginToken(resData.data.token);

            setData(resData.data.buttons);
            setLayout(resData.data.layout);

            setMenuIcon(resData.data.menuIcon);

            setBackgroundColor(resData.data.backgroundColor);
            setHoverColor(resData.data.hoverColor);

            resData.data.buttons.map((dt) => {
              if (dt.visible) setVisibleCount((prev) => prev + 1);
              if (dt.type === 'email') {
                setEmail(dt);
              } else if (dt.type === 'call') {
                setPhone(dt);
              } else if (dt.type === 'facebook') {
                setFacebook(dt);
              } else if (dt.type === 'messenger') {
                setMessenger(dt);
              } else if (dt.type === 'whatsapp') {
                setWhatsapp(dt);
              } else if (dt.type === 'telegram') {
                setTelegram(dt);
              } else if (dt.type === 'share') {
                setShare(dt);
              } else if (dt.type === 'calculator') {
                setCalculator(dt);
              } else if (dt.type === 'homeValue') {
                setHomeValues(dt);
              } else if (dt.type === 'viewProperty') {
                setViewProperties(dt);
              } else if (dt.type === 'custom-1') {
                setCustom1(dt);
              } else if (dt.type === 'custom-2') {
                setCustom2(dt);
              } else if (dt.type === 'custom-3') {
                setCustom3(dt);
              } else if (dt.type === 'custom-4') {
                setCustom4(dt);
              }
            });
          } else {
            toast.error(resData.status);
          }
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    }
  }, [pluginId]);

  useEffect(() => {
    axios({
      url: `/api/plugin/${profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setCalculators(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [profileId]);

  useEffect(() => {
    axios({
      url: `/api/checkHomeValues/${profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setCheckHomeValues(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [profileId]);

  useEffect(() => {
    axios({
      url: `/api/mortgageRates/${profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setMortgageRates(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [profileId]);

  useEffect(() => {
    axios({
      url: `/api/viewProperties/${profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          setViewPropertiesD(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [profileId]);

  const handleSave = () => {
    if (user.accountType !== 'Pro') {
      toast.error('Please upgrade your plan');
      return;
    }
    axios({
      url: '/api/buttonizer/update/' + pluginId,
      method: 'put',
      data: {
        layout,
        behaviour,
        backgroundColor,
        hoverColor,
        menuIcon: newMenuIcon,
        buttons: data,
      },
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setMenuIcon(newMenuIcon);
          toast.success('Updated successfully');
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;

    setData((prev) => {
      if (name === 'visible') {
        prev[id].visible = !prev[id].visible;
      } else {
        prev[id][name] = value;
      }
      return prev;
    });
  };

  const handleMenuIconChange = (e) => {
    const newIcon = e.target.value;
    setNewMenuIcon(newIcon);
    setMenuIcon(newIcon);
  };

  const handleOpen = () => {
    setIsOpened((prev) => !prev);
  };

  const detectLayoutStyles = useMemo(() => {
    if (layout === 'vertical') {
      return 'layout-vertical';
    } else if (layout === 'horizontal') {
      return 'layout-horizontal';
    } else if (layout === 'cloud') {
      return 'layout-cloud';
    }
  }, [layout]);

  const textToBeCopied = useMemo(() => {
    let text = `<!-- Flexibuttons Widget --> 
   <script>
     var d = document.createElement("div");var qs = document.createElement("script");d.setAttribute("id", "buttonizer");d.setAttribute("data-sitekey","${pluginToken}");qs.src = "${config.baseUrl}/static/js/buttonizer.js";qs.defer = true;
     var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/buttonizer.css");
     document.head.appendChild(l1);document.body.appendChild(d);document.body.appendChild(qs);
   </script>
 <!-- End Flexibuttons Widget -->
 `;
    if (calculator.visible) {
      text += ` <!-- Mortgage Calculator Widget --> 
        <script>
          var d = document.createElement("div");var qs = document.createElement("script");d.classList.add("mtge-calc_embeded");d.setAttribute("id", "mtge-calc_embeded");d.setAttribute("data-sitekey","${calculator.sitekey}");d.setAttribute("data-buttonizer","true");qs.src = "${config.baseUrl}/static/js/plugin.js";qs.defer = true;
          var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/plugin.css");
          document.head.appendChild(l1);document.body.appendChild(d);document.body.appendChild(qs);
        </script>
      <!-- End Mortgage Calculator Widget -->`;
    }
    if (viewProperties.visible) {
      text += `<!-- View Properties Widget --> 
      <script>
        var d = document.createElement("div");var qs = document.createElement("script");var currentScriptTag = document.currentScript;d.classList.add("viewproperties");d.setAttribute("id", "viewproperties");
        d.setAttribute("data-sitekey","${viewProperties.sitekey}");d.setAttribute("data-buttonizer","true");qs.src = "${config.baseUrl}/static/js/viewproperties.js";qs.defer = true;
        var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/viewproperties.css");
        document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
      </script>
    <!-- End View Properties Widget -->`;
    }
    if (homeValues.visible) {
      text += `<!-- Check Home Values Widget --> 
      <script>
        var d = document.createElement("div");var qs = document.createElement("script");var currentScriptTag = document.currentScript;d.classList.add("checkHomeValues_embeded");d.setAttribute("id", "checkHomeValues_embeded");                       
        d.setAttribute("data-sitekey","${homeValues.sitekey}");d.setAttribute("data-buttonizer","true");qs.src = "${config.baseUrl}/static/js/checkHomeValues.js";qs.defer = true;
        var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/checkHomeValues.css");
        document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
      </script>
    <!-- End Check Home Values Widget -->`;
    }

    return text;
  }, [calculator, viewProperties]);

  const handleResetColors = () => {
    setBackgroundColor('#AB1E23');
    setHoverColor('#262C34');

    toast.success('Colors reset!');
  };

  const handleIconSelect = (imgUrl) => {
    switch (shouldPutOn) {
      case '1':
        setCustom1((prev) => ({
          ...prev,
          icon: imgUrl,
        }));
        handleChange(
          {
            target: {
              name: 'icon',
              value: imgUrl,
            },
          },
          10
        );
        break;
      case '2':
        setCustom2((prev) => ({
          ...prev,
          icon: imgUrl,
        }));
        handleChange(
          {
            target: {
              name: 'icon',
              value: imgUrl,
            },
          },
          11
        );
        break;

      case '3':
        setCustom3((prev) => ({
          ...prev,
          icon: imgUrl,
        }));
        handleChange(
          {
            target: {
              name: 'icon',
              value: imgUrl,
            },
          },
          12
        );
        break;

      case '4':
        setCustom4((prev) => ({
          ...prev,
          icon: imgUrl,
        }));
        handleChange(
          {
            target: {
              name: 'icon',
              value: imgUrl,
            },
          },
          13
        );
        break;

      case '5':
        setNewMenuIcon(imgUrl);
        setMenuIcon(imgUrl);

        break;

      default:
        break;
    }

    setOpenIconDialog(false);
  };

  return (
    <>
      <div className="px-6 py-8">
        <div>
          <h1 className="text-2xl text-[#39393bde] font-bold">
            Embed Flexibuttons in your website
          </h1>
          <div className="dashboard_plugin_embed flex justify-between mt-4">
            <div
              className={`relative flex-1 py-2 px-3 ${
                user.accountType === 'Pro'
                  ? 'overflow-auto'
                  : 'overflow-hidden select-none'
              }`}
            >
              {user.accountType !== 'Pro' && (
                <div
                  className="absolute top-0 left-0 right-0 bottom-0 z-10"
                  style={{
                    background: 'rgba( 255, 255, 255, 0.25 )',
                    boxShadow: ' 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                    backdropFilter: 'blur( 5.5px )',
                    WebkitBackdropFilter: 'blur( 5.5px )',

                    border: '1px solid rgba( 255, 255, 255, 0.18 )',
                  }}
                >
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span className="text-base text-[#39393bde] font-bold">
                      Upgrade your plan to use this funnel
                    </span>{' '}
                  </div>
                </div>
              )}
              <pre>
                <ol className="text-[#97AAC3]">
                  <li>
                    <span className="text-gray-400">
                      {' '}
                      &lt;!-- Flexibuttons Widget --&gt;{' '}
                    </span>
                  </li>
                  <li>
                    <span> &lt;script&gt;</span>
                  </li>

                  <li>
                    {' '}
                    <span>
                      {' '}
                      var d = document.createElement("div");var qs =
                      document.createElement("script");d.setAttribute("id","buttonizer");
                    </span>
                  </li>

                  <li>
                    {'  '}
                    <span>
                      d.setAttribute("data-sitekey","{' '}
                      {user.accountType === 'Pro'
                        ? pluginToken
                        : '*****************************'}
                      ");qs.src ="{config.baseUrl}
                      /static/js/buttonizer.js";qs.defer= true;
                    </span>
                  </li>
                  <li>
                    <span>
                      {'  '}
                      var l1 =
                      document.createElement("link");l1.setAttribute("rel",
                      "stylesheet");l1.setAttribute("type",
                      "text/css");l1.setAttribute("href","{config.baseUrl}
                      /static/css/buttonizer.css");
                    </span>
                  </li>
                  <li>
                    {' '}
                    <span>
                      {' '}
                      document.head.appendChild(l1);
                      document.body.appendChild(d);document.body.appendChild(qs);{' '}
                    </span>
                  </li>

                  <li>
                    <span> &lt;/script&gt;</span>
                  </li>

                  <li>
                    <span className="text-gray-400">
                      {' '}
                      &lt;!-- End Flexibuttons Widget --&gt;{' '}
                    </span>
                  </li>
                </ol>
              </pre>
            </div>
            <div className="dashboard_plugin_embed-clipboard px-6 py-2 md:px-10 md:py-6">
              <CopyToClipboard
                text={user.accountType === 'Pro' && textToBeCopied}
                onCopy={() => {
                  if (user.accountType === 'Pro') {
                    toast.success('Copied to clipboard');
                    toast.success('Please add the code into body tag');
                  } else {
                    toast.error('Please upgrade your plan');
                  }
                }}
              >
                <button className="w-full h-full text-center text-[#7B94B5] text-sm md:text-base">
                  <span> Copy to </span> <br />
                  <span> Clipboard </span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-2xl text-[#39393bde] font-bold">
            Set up your buttons
          </h2>

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Name</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Flexibutton name"
                name="name"
                value={pluginName}
                onChange={(e) => setPluginName(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-4 mb-6 flex flex-col space-y-1">
            <label
              className="text-base text-[#39393bde] font-extrabold"
              htmlFor="layout"
            >
              Choose a layout
            </label>
            <div className="ml-2 md:flex items-center md:space-x-5">
              <label className="flex items-center space-x-2" htmlFor="vertical">
                <input
                  id="vertical"
                  name="layout"
                  type="radio"
                  value="vertical"
                  checked={layout === 'vertical'}
                  onChange={(e) => setLayout(e.target.value)}
                />
                <span>Vertical</span>
              </label>
              <label
                className="flex items-center space-x-2"
                htmlFor="horizontal"
              >
                <input
                  id="horizontal"
                  name="layout"
                  type="radio"
                  value="horizontal"
                  checked={layout === 'horizontal'}
                  onChange={(e) => setLayout(e.target.value)}
                />
                <span>Horizontal</span>
              </label>
              <label className="flex items-center space-x-2" htmlFor="cloud">
                <input
                  id="cloud"
                  name="layout"
                  type="radio"
                  value="cloud"
                  checked={layout === 'cloud'}
                  onChange={(e) => setLayout(e.target.value)}
                />
                <span>Cloud</span>
              </label>
            </div>
          </div>
          <div className="mt-6 flex flex-col space-y-1">
            <label
              className="text-base text-[#39393bde] font-extrabold"
              htmlFor="behaviour"
            >
              Choose a behaviour
            </label>
            <div className="ml-2 md:flex items-center md:space-x-5">
              <label className="flex items-center space-x-2" htmlFor="fixed">
                <input
                  id="fixed"
                  name="behaviour"
                  type="radio"
                  value="fixed"
                  checked={behaviour === 'fixed'}
                  onChange={(e) => setBehaviour(e.target.value)}
                />
                <span>Fixed</span>
              </label>
              <label className="flex items-center space-x-2" htmlFor="floating">
                <input
                  id="floating"
                  name="behaviour"
                  type="radio"
                  value="floating"
                  checked={behaviour === 'floating'}
                  onChange={(e) => setBehaviour(e.target.value)}
                />
                <span>Floating</span>
              </label>
            </div>
          </div>

          {/* hamburger icon */}
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">
              Change Menu Icon
            </h3>
            <div className="mt-4 flex flex-col space-y-2">
              <div className="w-full flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3">
                <input
                  className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                  type="text"
                  placeholder="Provide icon url"
                  name="hamburgerIcon"
                  value={newMenuIcon}
                  onChange={handleMenuIconChange}
                />
                <button
                  className="px-3 py-2 bg-gray-600 rounded-md text-white"
                  onClick={() => {
                    setOpenIconDialog(true);
                    setShouldPutOn('5');
                  }}
                >
                  Search icon
                </button>
              </div>
            </div>
          </div>
          {/* hamburger icon */}

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">E-mail</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="email"
                placeholder="Email address"
                name="email"
                value={email.email}
                onChange={(e) => {
                  setEmail((prev) => ({ ...prev, email: e.target.value }));
                  handleChange(e, 0);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={email.tooltip}
                onChange={(e) => {
                  setEmail((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 0);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={email.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || email.visible) {
                        setVisibleCount((prev) => {
                          if (email.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setEmail((prev) => ({
                          ...prev,
                          visible: !email.visible,
                        }));
                        handleChange(e, 0);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Call</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="tel"
                placeholder="Phone number"
                name="phone"
                value={phone.phone}
                onChange={(e) => {
                  setPhone((prev) => ({ ...prev, phone: e.target.value }));
                  handleChange(e, 1);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={phone.tooltip}
                onChange={(e) => {
                  setPhone((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 1);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={phone.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || phone.visible) {
                        setVisibleCount((prev) => {
                          if (phone.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setPhone((prev) => ({
                          ...prev,
                          visible: !phone.visible,
                        }));
                        handleChange(e, 1);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Facebook</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Facebook url"
                name="facebook"
                value={facebook.url}
                onChange={(e) => {
                  setFacebook((prev) => ({ ...prev, url: e.target.value }));
                  handleChange(e, 2);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={facebook.tooltip}
                onChange={(e) => {
                  setFacebook((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 2);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={facebook.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || facebook.visible) {
                        setVisibleCount((prev) => {
                          if (facebook.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setFacebook((prev) => ({
                          ...prev,
                          visible: !facebook.visible,
                        }));
                        handleChange(e, 2);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Messenger</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Messenger url"
                name="messenger"
                value={messenger.url}
                onChange={(e) => {
                  setMessenger((prev) => ({ ...prev, url: e.target.value }));
                  handleChange(e, 3);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={messenger.tooltip}
                onChange={(e) => {
                  setMessenger((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 3);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={messenger.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || messenger.visible) {
                        setVisibleCount((prev) => {
                          if (messenger.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setMessenger((prev) => ({
                          ...prev,
                          visible: !messenger.visible,
                        }));
                        handleChange(e, 3);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Whatsapp</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Whatsapp url"
                name="whatsapp"
                value={whatsapp.url}
                onChange={(e) => {
                  setWhatsapp((prev) => ({ ...prev, url: e.target.value }));
                  handleChange(e, 4);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={whatsapp.tooltip}
                onChange={(e) => {
                  setWhatsapp((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 4);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={whatsapp.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || whatsapp.visible) {
                        setVisibleCount((prev) => {
                          if (whatsapp.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setWhatsapp((prev) => ({
                          ...prev,
                          visible: !whatsapp.visible,
                        }));
                        handleChange(e, 4);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Telegram</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Telegram url"
                name="telegram"
                value={telegram.url}
                onChange={(e) => {
                  setTelegram((prev) => ({ ...prev, url: e.target.value }));
                  handleChange(e, 5);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={telegram.tooltip}
                onChange={(e) => {
                  setTelegram((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 5);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={telegram.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || telegram.visible) {
                        setVisibleCount((prev) => {
                          if (telegram.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setTelegram((prev) => ({
                          ...prev,
                          visible: !telegram.visible,
                        }));
                        handleChange(e, 5);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Share</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                placeholder="Share url"
                name="share"
                value={share.url}
                onChange={(e) => {
                  setShare((prev) => ({ ...prev, url: e.target.value }));
                  handleChange(e, 6);
                }}
              />
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={share.tooltip}
                onChange={(e) => {
                  setShare((prev) => ({ ...prev, tooltip: e.target.value }));
                  handleChange(e, 6);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={share.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || share.visible) {
                        setVisibleCount((prev) => {
                          if (share.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setShare((prev) => ({
                          ...prev,
                          visible: !share.visible,
                        }));
                        handleChange(e, 6);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">
              Mortgage Calculator
            </h3>
            <div className="mt-4 flex flex-col space-y-2">
              <select
                className="max-w-md w-full px-2 py-1 border-2 border-[#c6c7ca] rounded-sm outline-none"
                name="sitekey"
                value={calculator.sitekey}
                onChange={(e) => {
                  setCalculator((prev) => ({
                    ...prev,
                    sitekey: e.target.value,
                  }));
                  handleChange(e, 7);
                }}
              >
                <option value="">None</option>
                {calculators.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={calculator.tooltip}
                onChange={(e) => {
                  setCalculator((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 7);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={calculator.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || calculator.visible) {
                        setVisibleCount((prev) => {
                          if (calculator.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setCalculator((prev) => ({
                          ...prev,
                          visible: !calculator.visible,
                        }));
                        handleChange(e, 7);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">
              Check Home Values
            </h3>
            <div className="mt-4 flex flex-col space-y-2">
              <select
                className="max-w-md w-full px-2 py-1 border-2 border-[#c6c7ca] rounded-sm outline-none"
                name="sitekey"
                value={homeValues.sitekey}
                onChange={(e) => {
                  setHomeValues((prev) => ({
                    ...prev,
                    sitekey: e.target.value,
                  }));
                  handleChange(e, 8);
                }}
              >
                <option value="">None</option>
                {checkHomeValues.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={homeValues.tooltip}
                onChange={(e) => {
                  setHomeValues((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 8);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={homeValues.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || homeValues.visible) {
                        setVisibleCount((prev) => {
                          if (homeValues.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setHomeValues((prev) => ({
                          ...prev,
                          visible: !homeValues.visible,
                        }));
                        handleChange(e, 8);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">
              View Properties
            </h3>
            <div className="mt-4 flex flex-col space-y-2">
              <select
                className="max-w-md w-full px-2 py-1 border-2 border-[#c6c7ca] rounded-sm outline-none"
                name="sitekey"
                value={viewProperties.sitekey}
                onChange={(e) => {
                  setViewProperties((prev) => ({
                    ...prev,
                    sitekey: e.target.value,
                  }));
                  handleChange(e, 9);
                }}
              >
                <option value="">None</option>
                {viewPropertiesD.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={viewProperties.tooltip}
                onChange={(e) => {
                  setViewProperties((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 9);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={viewProperties.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || viewProperties.visible) {
                        setVisibleCount((prev) => {
                          if (viewProperties.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setViewProperties((prev) => ({
                          ...prev,
                          visible: !viewProperties.visible,
                        }));
                        handleChange(e, 9);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Custom - 1</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="url"
                placeholder="Provide url"
                value={custom1.url}
                onChange={(e) => {
                  setCustom1((prev) => ({
                    ...prev,
                    url: e.target.value,
                  }));
                  handleChange(e, 10);
                }}
              />
              <div className="w-full flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3">
                <input
                  className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                  type="text"
                  name="icon"
                  placeholder="Provide icon url"
                  value={custom1.icon}
                  onChange={(e) => {
                    setCustom1((prev) => ({
                      ...prev,
                      icon: e.target.value,
                    }));
                    handleChange(e, 10);
                  }}
                />
                <button
                  className="px-3 py-2 bg-gray-600 rounded-md text-white"
                  onClick={() => {
                    setOpenIconDialog(true);
                    setShouldPutOn('1');
                  }}
                >
                  Search icon
                </button>
              </div>

              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={custom1.tooltip}
                onChange={(e) => {
                  setCustom1((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 10);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={custom1.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || custom1.visible) {
                        setVisibleCount((prev) => {
                          if (custom1.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setCustom1((prev) => ({
                          ...prev,
                          visible: !custom1.visible,
                        }));
                        handleChange(e, 10);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Custom - 2</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="url"
                placeholder="Provide url"
                value={custom2.url}
                onChange={(e) => {
                  setCustom2((prev) => ({
                    ...prev,
                    url: e.target.value,
                  }));
                  handleChange(e, 11);
                }}
              />
              <div className="w-full flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3">
                <input
                  className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                  type="text"
                  name="icon"
                  placeholder="Provide icon url"
                  value={custom2.icon}
                  onChange={(e) => {
                    setCustom2((prev) => ({
                      ...prev,
                      icon: e.target.value,
                    }));
                    handleChange(e, 11);
                  }}
                />
                <button
                  className="px-3 py-2 bg-gray-600 rounded-md text-white"
                  onClick={() => {
                    setOpenIconDialog(true);
                    setShouldPutOn('2');
                  }}
                >
                  Search icon
                </button>
              </div>

              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={custom2.tooltip}
                onChange={(e) => {
                  setCustom2((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 11);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={custom2.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || custom2.visible) {
                        setVisibleCount((prev) => {
                          if (custom1.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setCustom2((prev) => ({
                          ...prev,
                          visible: !custom2.visible,
                        }));
                        handleChange(e, 11);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Custom - 3</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="url"
                placeholder="Provide url"
                value={custom3.url}
                onChange={(e) => {
                  setCustom3((prev) => ({
                    ...prev,
                    url: e.target.value,
                  }));
                  handleChange(e, 12);
                }}
              />
              <div className="w-full flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3">
                <input
                  className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                  type="text"
                  name="icon"
                  placeholder="Provide icon url"
                  value={custom3.icon}
                  onChange={(e) => {
                    setCustom3((prev) => ({
                      ...prev,
                      icon: e.target.value,
                    }));
                    handleChange(e, 12);
                  }}
                />
                <button
                  className="px-3 py-2 bg-gray-600 rounded-md text-white"
                  onClick={() => {
                    setOpenIconDialog(true);
                    setShouldPutOn('3');
                  }}
                >
                  Search icon
                </button>
              </div>

              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={custom3.tooltip}
                onChange={(e) => {
                  setCustom3((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 12);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={custom3.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || custom3.visible) {
                        setVisibleCount((prev) => {
                          if (custom1.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setCustom3((prev) => ({
                          ...prev,
                          visible: !custom3.visible,
                        }));
                        handleChange(e, 12);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm text-[#39393bde] font-bold">Custom - 4</h3>
            <div className="mt-4 flex flex-col space-y-2">
              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="url"
                placeholder="Provide url"
                value={custom4.url}
                onChange={(e) => {
                  setCustom4((prev) => ({
                    ...prev,
                    url: e.target.value,
                  }));
                  handleChange(e, 13);
                }}
              />
              <div className="w-full flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3">
                <input
                  className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                  type="text"
                  name="icon"
                  placeholder="Provide icon url"
                  value={custom4.icon}
                  onChange={(e) => {
                    setCustom4((prev) => ({
                      ...prev,
                      icon: e.target.value,
                    }));
                    handleChange(e, 13);
                  }}
                />
                <button
                  className="px-3 py-2 bg-gray-600 rounded-md text-white"
                  onClick={() => {
                    setOpenIconDialog(true);
                    setShouldPutOn('4');
                  }}
                >
                  Search icon
                </button>
              </div>

              <input
                className="max-w-md w-full rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="tooltip"
                placeholder="Tooltip"
                value={custom4.tooltip}
                onChange={(e) => {
                  setCustom4((prev) => ({
                    ...prev,
                    tooltip: e.target.value,
                  }));
                  handleChange(e, 13);
                }}
              />
              <div className="flex items-center space-x-2">
                <label class="switch">
                  <input
                    checked={custom4.visible}
                    name="visible"
                    type="checkbox"
                    onChange={(e) => {
                      if (visibleCount < 7 || custom4.visible) {
                        setVisibleCount((prev) => {
                          if (custom4.visible) {
                            return prev - 1;
                          } else {
                            return prev + 1;
                          }
                        });
                        setCustom4((prev) => ({
                          ...prev,
                          visible: !custom4.visible,
                        }));
                        handleChange(e, 13);
                      } else {
                        toast.info(
                          "You can't visible more than 6 buttons at a time"
                        );
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                <span className="text-xs text-[#39393bde] font-bold">
                  Visible
                </span>
              </div>
            </div>
          </div>
        </div>
        <h3 className="mt-6 text-sm text-[#39393bde] font-bold"> Colors </h3>
        <div className="max-w-lg grid grid-cols-2 mt-2">
          <div
            className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
            onClick={() => setBackgroundColorPicker(true)}
          >
            {backgroundColorPicker && (
              <div
                className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="fixed bottom-0 left-0 right-0 top-0 bg-[#00000082]"
                  onClick={() => setBackgroundColorPicker(false)}
                ></div>
                <div className="relative bg-white z-[100]">
                  <ColorPicker
                    value={backgroundColor}
                    onChange={setBackgroundColor}
                  />
                </div>
              </div>
            )}

            <div className="relative">
              <div
                className="h-5 w-8 rounded-sm border-2"
                style={{ background: backgroundColor }}
              ></div>
            </div>
            <span className="text-sm select-none"> Background </span>
          </div>
          <div
            className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
            onClick={() => setHoverColorPicker(true)}
          >
            {hoverColorPicker && (
              <div
                className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="fixed bottom-0 left-0 right-0 top-0 bg-[#00000082]"
                  onClick={() => setHoverColorPicker(false)}
                ></div>
                <div className="relative bg-white z-[100]">
                  <ColorPicker value={hoverColor} onChange={setHoverColor} />
                </div>
              </div>
            )}

            <div className="relative">
              <div
                className="h-5 w-8 rounded-sm border-2"
                style={{ background: hoverColor }}
              ></div>
            </div>
            <span className="text-sm select-none"> Hover </span>
          </div>
        </div>

        <div className="mt-6 max-w-sm">
          <CopyToClipboard
            text={`${window.location.origin}/view/flexiButtons/${pluginToken}`}
            onCopy={() => {
              toast.success('Copied to clipboard');
            }}
          >
            <button className="flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
              <span>Share/Preview FlexiButton</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-6 flex items-center space-x-3">
          <button
            className="flex items-center justify-center w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
            onClick={handleResetColors}
          >
            <span>Reset Colors</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>
          <button
            className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>

      <div
        className="buttonizer_container"
        style={{
          '--bg-color': backgroundColor,
          '--hover-color': hoverColor,
        }}
      >
        <div className={`buttonizer_container-inner ${detectLayoutStyles}`}>
          {/* <button
            className={`buttonizer_opener ${isOpened ? 'opened' : ''}`}
            onClick={handleOpen}
          >
            <GiHamburgerMenu className="mx-auto text-white text-3xl" />
          </button> */}
          <button
            className={`buttonizer_opener ${isOpened ? 'opened' : ''}`}
            onClick={handleOpen}
          >
            {menuIcon ? (
              <img
                src={menuIcon}
                alt="Custom Hamburger Icon"
                className="mx-auto"
              />
            ) : (
              <GiHamburgerMenu className="mx-auto text-white text-3xl" />
            )}
          </button>

          {layout === 'vertical' ? (
            <>
              {data.map((dt, idx) => (
                <LayoutVertical
                  data={dt}
                  sr={idx}
                  key={idx}
                  isOpened={isOpened}
                />
              ))}
            </>
          ) : layout === 'horizontal' ? (
            <>
              {' '}
              {data.map((dt, idx) => (
                <LayoutHorizontal
                  data={dt}
                  sr={idx}
                  key={idx}
                  isOpened={isOpened}
                />
              ))}
            </>
          ) : layout === 'cloud' ? (
            <>
              {' '}
              {data
                .filter((dst) => dst.visible)
                .map((dt, idx, err) => {
                  return (
                    <LayoutCloud
                      data={dt}
                      sr={idx + 1}
                      total={err.length}
                      key={idx}
                      isOpened={isOpened}
                    />
                  );
                })}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <IconDialog
        open={openIconDialog}
        handleClose={() => {
          setOpenIconDialog(false);
        }}
        handleSelect={handleIconSelect}
      />
    </>
  );
}

const LayoutVertical = ({ data, sr, isOpened }) => {
  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto' + data.email}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ transitionDelay: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <img style={{ width: '60%' }} src={data.icon} alt="Icon" />
        {data.tooltip && <div className="tooltip">{data.tooltip}</div>}
      </a>
    );
  }
};

export const LayoutHorizontal = ({ data, sr, isOpened }) => {
  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto:' + data.email}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          toast.info('Will work when you embed the code.');
        }}
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ animationDuration: isOpened ? sr * 200 + 'ms' : '0ms' }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action ${isOpened ? 'opened' : ''}`}
        style={{ transitionDelay: isOpened ? sr * 100 + 'ms' : '0ms' }}
      >
        <img src={data.icon} alt="Icon" />
      </a>
    );
  }
};

export const LayoutCloud = ({ data, sr, total, isOpened }) => {
  const for7Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-156px, 20px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-140px, -48px)',
        opacity: '1',
      };
    } else if (sr === 6) {
      return {
        transform: 'translate(-90px, -102px)',
        opacity: '1',
      };
    } else if (sr === 7) {
      return {
        transform: 'translate(-24px, -140px)',
        opacity: '1',
      };
    }
  };

  const for6Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-136px, -16px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-120px, -80px)',
        opacity: '1',
      };
    } else if (sr === 6) {
      return {
        transform: 'translate(-60px, -110px)',
        opacity: '1',
      };
    }
  };

  const for5Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px, -10px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-20px, -50px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(-130px, -12px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(-100px, -76px)',
        opacity: '1',
      };
    } else if (sr === 5) {
      return {
        transform: 'translate(-40px, -110px)',
        opacity: '1',
      };
    }
  };
  const for4Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px,28px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-56px, -30px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(-8px, -60px)',
        opacity: '1',
      };
    } else if (sr === 4) {
      return {
        transform: 'translate(44px, -54px)',
        opacity: '1',
      };
    }
  };

  const for3Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-80px, 20px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-60px, -44px)',
        opacity: '1',
      };
    } else if (sr === 3) {
      return {
        transform: 'translate(0px, -80px)',
        opacity: '1',
      };
    }
  };

  const for2Items = () => {
    if (sr === 1) {
      return {
        transform: 'translate(-60px, -10px)',
        opacity: '1',
      };
    } else if (sr === 2) {
      return {
        transform: 'translate(-20px, -50px)',
        opacity: '1',
      };
    }
  };

  const for1Items = () => {
    return {
      transform: 'translate(-40px, -36px)',
      opacity: '1',
    };
  };

  const measurePosition = useMemo(() => {
    console.log(sr);
    switch (total) {
      case 1:
        return for1Items();
      case 2:
        return for2Items();
      case 3:
        return for3Items();
      case 4:
        return for4Items();
      case 5:
        return for5Items();
      case 6:
        return for6Items();
      case 7:
        return for7Items();
      default:
        return {};
    }
  }, [sr, total]);

  if (!data.visible) return <></>;
  if (data.type === 'call') {
    return (
      <a
        href={'tel:' + data.phone}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <IoMdCall className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'facebook') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaFacebookF className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'messenger') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaFacebookMessenger className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'email') {
    return (
      <a
        href={'mailto:' + data.email}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <MdEmail className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'telegram') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsTelegram className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'whatsapp') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsWhatsapp className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'share') {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <FaShareAlt className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'calculator') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          window.showMortgageCalculator();
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <BsCalculator className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'homeValue') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          window.toggleCheckHomeValues();
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <AiFillHome className="mx-auto text-white text-xl" />
      </a>
    );
  } else if (data.type === 'viewProperty') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          window.showViewProperties();
        }}
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          animationDuration: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <GiMagnifyingGlass className="mx-auto text-white text-xl" />
      </a>
    );
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button_action cloud ${isOpened ? 'opened' : ''}`}
        style={{
          transitionDelay: isOpened ? sr * 200 + 'ms' : '0ms',
          ...measurePosition,
        }}
      >
        <img src={data.icon} alt="Icon" />
      </a>
    );
  }
};

const specificIconsNames = [
  'phone',
  'hamburger',
  'facebook',
  'twitter',
  'whatsapp',
  'instagram',
  'mail',
  'google',
  'linkedin',
  'share',
  'website',
  'calculator',
  'link',
  'home',
  'location',
  'navigation',
  'rate',
];

const IconDialog = ({ open, handleClose, handleSelect }) => {
  const [query, setQuery] = useState('');
  const [icons, setIcons] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadSpecificIcons = (iconName) => {
    setLoading(true);
    axios
      .get(`/api/buttonizer/scraper/icons/specific/${iconName}`)
      .then((res) => {
        const resData = res.data;
        const specificIcons = resData.data.icons;
        setIcons((prevIcons) => [...prevIcons, ...specificIcons]);
        setLoading(false);
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      if (query.length === 0) {
        // Clear icons when clearing the search query
        setIcons([]);
        specificIconsNames.forEach((iconName) => {
          loadSpecificIcons(iconName);
        });
      }
    }
  }, [open, query]);

  const handleSearch = () => {
    setLoading(true);
    axios({
      url: `/api/buttonizer/scraper/icons/${query}`,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;
        setIcons(resData.data.icons);
        setLoading(false);
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
        setLoading(false);
      });
  };

  const handleSearchButton = () => {
    handleSearch();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'} onClose={handleClose}>
      <DialogContent>
        <div className="">
          <div className="px-3 py-2 flex items-center space-x-2 border-2 border-[#3a3a3a] rounded-sm">
            <input
              type="text"
              className="w-full border-none outline-none"
              placeholder="Search for icons"
              onChange={(e) => {
                let value = e.target.value;
                setQuery(value);
                if (value.length === 0) {
                  setIcons([]);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.which === 13 || e.code === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <button onClick={handleSearchButton}>
              <AiOutlineSearch className="h-5 w-5" />
            </button>
          </div>

          <div className="mt-6 flex flex-wrap gap-6">
            {loading ? (
              <div className="text-center text-lg"> Loading ... </div>
            ) : icons.length > 0 ? (
              <IconContext.Provider value={{ size: '4rem' }}>
                {icons.map((icon) => (
                  <button
                    key={icon.icon_id}
                    onClick={() =>
                      handleSelect(icon.raster_sizes[2].formats[0].preview_url)
                    }
                  >
                    <div>
                      <span>{icon.icon_name}</span>
                      <img
                        className="w-6"
                        key={icon.raster_sizes[0].size_height}
                        src={icon.raster_sizes[0].formats[0].preview_url}
                        alt={icon.icon_name}
                      />
                    </div>
                  </button>
                ))}
              </IconContext.Provider>
            ) : (
              <div className="text-center text-lg"> No icons found! </div>
            )}
          </div>

          {/* Close button */}
          <div className="mt-4 text-center">
            <button
              onClick={() => {
                handleClose();
                setQuery('');
                setIcons([]);
              }}
              className="px-4 py-2 bg-gray-200 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Buttonizer;
