import React, { useEffect, useState } from 'react';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import axios from 'axios';
import ActionTypes from '../../utils/actionTypes';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import profilePlaceholder from '../../assets/images/profile_placeholder.png';

import CalculatorPreview from '../Dashboard/Preview';
import MortgageRatesWidget from '../Mortgage_Rates/Widget/Widget';
import ViewPropertiesWidget from '../ViewProperties/Widget/Widget';
import CheckHomeValuesWidget from '../Check_Home_Values/Widget/Widget';

function MainSite() {
  const [{ user, currentPlugin }, dispatch] = useContext();
  const { userId } = useParams();

  const [calculatorToken, setCalculatorToken] = useState(
    'OVBazy8m-tJJvug7baBHEjdh5ZnfYr3HEM3XFADdsP4'
  );
  const [mortgageRatesToken, setMortgageRatesToken] = useState(
    'XJ6n-ZGT9eagR54P1R_Or6ujgJLc3IWFTl8bb9u8HU8'
  );
  const [viewPropertiesToken, setViewPropertiesToken] = useState(
    'fUOHBRIDmc4F_ErDrfnwGdja6549Z9Bry1VhJgTjptk'
  );

  const [checkHomeValuesToken, setCheckHomeValuesToken] = useState(
    'Gi7vFvro_RoJwjxV3Wi4QFqklbSnw4Gaozdrnl9dxLs'
  );

  const [mortgageRatesData, setMortgageRatesData] = useState(null);
  const [viewPropertiesData, setViewPropertiesData] = useState(null);
  const [checkHomeValuesData, setCheckHomeValuesData] = useState(null);

  const [data, setData] = useState({
    profileName: '',
    profileImage: '',
    backgroundImage: '',
    buttons: [],
    about: '',
    widgetCode: '',
    calculator: {
      token: '',
      visibility: false,
    },
    mortgageRates: {
      token: '',
      visibility: false,
    },
    viewProperties: {
      token: '',
      visibility: false,
    },
  });

  useEffect(() => {
    axios({
      url: '/api/users/standalone/' + userId,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          if (resData.data && Object.keys(resData.data).length > 0) {
            setData(resData.data);

            setCalculatorToken(resData.data.calculator.token);

            setMortgageRatesToken(resData.data.mortgageRates.token);

            setViewPropertiesToken(resData.data.viewProperties.token);

            setCheckHomeValuesToken(resData.data.checkHomeValues.token);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = `url('${data.backgroundImage}'), url('/images/1644964579788_V.png')`;

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, [data.backgroundImage]);

  useEffect(() => {
    if (!calculatorToken) return;
    // calc
    axios({
      url: '/api/plugin/standalone/' + calculatorToken,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          console.log(resData.data);
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
            isOwnPlugin: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [calculatorToken]);

  useEffect(() => {
    if (!mortgageRatesToken) return;
    axios({
      url: '/api/mortgageRates/token/' + mortgageRatesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          console.log('m', resData.data);
          setMortgageRatesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [mortgageRatesToken]);

  useEffect(() => {
    if (!viewPropertiesToken) return;
    axios({
      url: '/api/viewProperties/token/' + viewPropertiesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setViewPropertiesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [viewPropertiesToken]);

  useEffect(() => {
    if (!checkHomeValuesToken) return;
    axios({
      url: '/api/checkHomeValues/token/' + checkHomeValuesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setCheckHomeValuesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [checkHomeValuesToken]);

  const getFromLocalStorage = (name) => {
    const stringifyData = localStorage.getItem(name);

    if (stringifyData) {
      const data = JSON.parse(stringifyData);

      return data;
    } else {
      return null;
    }
  };

  return (
    <div className="relative h-full w-full bg-[#0000007d]">
      <header className="py-6 px-4">
        <div>
          <a href="/" className="flex items-center space-x-1">
            <img
              className="w-10 object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
            <p className="flex flex-col text-sm font-bold leading-none">
              {' '}
              <span className="text-[#AB1E23]">Savage</span>{' '}
              <span className="text-[#262C34]">Sales</span>
            </p>
          </a>
        </div>
      </header>
      <main className="my-12">
        <header className="mx-auto text-center">
          <div className="h-20 w-20 md:h-32 md:w-32 mx-auto bg-white rounded-full overflow-hidden transition-all duration-200 ease-in-out transform hover:scale-105">
            <label
              htmlFor="profileImage"
              className="reveal_edit-btn relative block h-full w-full cursor-pointer after:content-[''] hover:after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-black after:opacity-10 transition-all duration-200 ease-in-out"
            >
              <span className="block h-full w-full overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={data.profileImage || profilePlaceholder}
                  alt={data.name}
                />
              </span>
            </label>
          </div>
          <h1 className="mt-2 text-xl md:text-2xl font-bold text-[#ffffff]">
            {data.profileName}
          </h1>
        </header>

        <div className="mx-4 mt-8 flex flex-col items-center space-y-3">
          <div className="max-w-lg w-full mb-4">
            <p className="w-full text-center p-4 text-[#ffffff]">
              {data.about}
            </p>
          </div>

          <ul className="space-y-3">
            {data.buttons.map((dt, idx) => (
              <li key={idx}>
                <a
                  className="block max-w-xs w-96 p-2 text-center text-white font-medium rounded-md bg-[var(--bg-color)] hover:bg-[var(--hover-color)] hover:text-[#262C34] transition-all duration-200"
                  href={dt.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    '--bg-color': dt.bg || '#262C34',
                    '--hover-color': dt.hover || '#AB1E23',
                  }}
                >
                  {dt.label}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="mx-4 mt-14 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            {data.calculator.visibility && currentPlugin && (
              <CalculatorPreview
                pluginLogo={currentPlugin.logo.value}
                leadName={currentPlugin.leadName}
                leadLink={currentPlugin.leadLink}
                behaviour=""
                backgroundColor={currentPlugin.colors.backgroundColor}
                titleColor={currentPlugin.colors.titleColor}
                pluginTitle={currentPlugin.title}
                textColor={currentPlugin.colors.textColor}
                buttonColor={currentPlugin.colors.buttonColor}
                buttonTextColor={currentPlugin.colors.buttonTextColor}
                varbiage={currentPlugin.varbiage}
                homeSearchPage={currentPlugin.homeSearchPage}
                calenderBookingPage={currentPlugin.calenderBookingPage}
                showEmailPaymentDetails={
                  currentPlugin.leadButtons.emailPaymentDetails
                }
                showViewProperties={currentPlugin.leadButtons.viewProperties}
                showPreQualified={currentPlugin.leadButtons.preQualified}
                showCheckHomeValues={currentPlugin.leadButtons.checkHomeValues}
              />
            )}
          </div>

          <div>
            {checkHomeValuesData && (
              <CheckHomeValuesWidget
                funnelTitle={checkHomeValuesData.title}
                calenderBookingPage={viewPropertiesData.calenderBookingPage}
                sitekey={viewPropertiesData.token}
                varbiage={viewPropertiesData.varbiage}
              />
            )}
          </div>

          <div>
            {data.mortgageRates.visibility && mortgageRatesData && (
              <MortgageRatesWidget
                mortageRateTitle={mortgageRatesData.title}
                pluginToken={mortgageRatesData.token}
                varbiage={mortgageRatesData.varbiage}
              />
            )}
          </div>

          <div>
            {data.viewProperties.visibility && viewPropertiesData && (
              <ViewPropertiesWidget
                homeSearchPage={viewPropertiesData.homeSearchPage}
                funnelTitle={viewPropertiesData.title}
                calenderBookingPage={viewPropertiesData.calenderBookingPage}
                sitekey={viewPropertiesData.token}
                varbiage={viewPropertiesData.varbiage}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default MainSite;
