import React, { useEffect, useState } from "react";
import "./facepopuoption.css";
import Modal from "@mui/material/Modal";
import ColorPicker from "react-best-gradient-color-picker";
import { Backdrop } from "@mui/material";
const GradianModal = ({
  open,
  setOpen,
  open2,
  open3,
  open4,
  open5,
  open6,
  open7,
  open8,
  open9,
  open10,
  open11,
  open12,
  open13,
  open14,
  open15,
  open16,
  handleClose,
  setBgColor,
  setBgColor2,
  setBgColor3,
  setBgColor4,
  setBgColor5,
  setBgColor6,
  setBgColor7,
  setBgColor8,
  setBgColor9,
  setBgColor10,
  setBgColor11,
  setBgColor12,
  setBgColor13,
  setBgColor14,
  setBgColor15,
  setBgColor16,
}) => {
  const [color, setColor] = useState("#FF0000");

  useEffect(() => {
    if (open) {
      setBgColor(color);
    } else if (open2) {
      setBgColor2(color);
    } else if (open3) {
      setBgColor3(color);
    } else if (open4) {
      setBgColor4(color);
    } else if (open5) {
      setBgColor5(color);
    } else if (open6) {
      setBgColor6(color);
    } else if (open7) {
      setBgColor7(color);
    } else if (open8) {
      setBgColor8(color);
    } else if (open9) {
      setBgColor9(color);
    } else if (open10) {
      setBgColor10(color);
    } else if (open11) {
      setBgColor11(color);
    } else if (open12) {
      setBgColor12(color);
    } else if (open13) {
      setBgColor13(color);
    } else if (open14) {
      setBgColor14(color);
    } else if (open15) {
      setBgColor15(color);
    } else if (open16) {
      setBgColor16(color);
    }
  }, [color]);

  return (
    <Modal
      open={
        open ||
        open2 ||
        open3 ||
        open4 ||
        open5 ||
        open6 ||
        open7 ||
        open8 ||
        open9 ||
        open10 ||
        open11 ||
        open12 ||
        open13 ||
        open14 ||
        open15 ||
        open16
      }
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="w-fit mx-auto mt-14">
        <ColorPicker value={color} onChange={setColor} />
      </div>
    </Modal>
  );
};

export default GradianModal;
