import React, { useEffect, useState } from 'react';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import axios from 'axios';
import ActionTypes from '../../utils/actionTypes';
import { Link, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import profilePlaceholder from '../../assets/images/profile_placeholder.png';
import ColorPicker from 'react-best-gradient-color-picker';
import { IoIosKeypad } from 'react-icons/io';
import { AiFillCloseSquare } from 'react-icons/ai';

import CalculatorPreview from '../Dashboard/Preview';
import MortgageRatesWidget from '../Mortgage_Rates/Widget/Widget';
import ViewPropertiesWidget from '../ViewProperties/Widget/Widget';
import CheckHomeValuesWidget from '../Check_Home_Values/Widget/Widget';
import Loader from '../Loader/Loader';

function PersonalSite() {
  const [{ user, currentPlugin }, dispatch] = useContext();
  const params = useParams();

  const [calculators, setCalculators] = useState([]);
  const [mortgageRates, setMortgageRates] = useState([]);
  const [viewProperties, setViewProperties] = useState([]);
  const [checkHomeValues, setCheckHomeValues] = useState([]);

  const [calculatorToken, setCalculatorToken] = useState(
    'OVBazy8m-tJJvug7baBHEjdh5ZnfYr3HEM3XFADdsP4'
  );
  const [mortgageRatesToken, setMortgageRatesToken] = useState(
    'XJ6n-ZGT9eagR54P1R_Or6ujgJLc3IWFTl8bb9u8HU8'
  );
  const [viewPropertiesToken, setViewPropertiesToken] = useState(
    'fUOHBRIDmc4F_ErDrfnwGdja6549Z9Bry1VhJgTjptk'
  );
  const [checkHomeValuesToken, setCheckHomeValuesToken] = useState(
    'Gi7vFvro_RoJwjxV3Wi4QFqklbSnw4Gaozdrnl9dxLs'
  );
  const [calculatorVisibility, setCalculatorVisibility] = useState(true);
  const [mortgageRatesVisibility, setMortgageRatesVisibility] = useState(true);
  const [viewPropertiesVisibility, setViewPropertiesVisibility] =
    useState(true);

  const [checkHomeValuesVisibility, setCheckHomeValuesVisibility] =
    useState(true);

  const [mortgageRatesData, setMortgageRatesData] = useState(null);
  const [viewPropertiesData, setViewPropertiesData] = useState(null);
  const [checkHomeValuesData, setCheckHomeValuesData] = useState(null);
  const [canCreate, setCanCreate] = useState(true);
  const [loader, setLoader] = useState('');

  const [data, setData] = useState({
    profileName: '',
    profileImage: '',
    profileImageFile: '',
    backgroundImage: '',
    backgroundImageFile: '',
    buttons: [],
    about: '',
    widgetCode: '',
  });

  const [colorPickerStat, setColorPickerStat] = useState({
    state: false,
    index: null,
    value: '',
    type: '',
  });

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    fetchCalculators();
    fetchMortgageRates();
    fetchViewProperties();
    fetchCheckHomeValues();
    axios({
      url: '/api/users/standalone/' + user._id,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log('hhh ', resData.data);

        if (resData.success) {
          if (resData.data && Object.keys(resData.data).length > 0) {
            setData({
              profileImage: resData.data.profileImage,
              backgroundImage: resData.data.backgroundImage,
              buttons: resData.data.buttons,
              about: resData.data.about,
              widgetCode: resData.data.widgetCode,
            });

            setCalculatorToken(resData.data.calculator.token);
            setCalculatorVisibility(resData.data.calculator.visibility);

            setMortgageRatesToken(resData.data.mortgageRates.token);
            setMortgageRatesVisibility(resData.data.mortgageRates.visibility);

            setViewPropertiesToken(resData.data.viewProperties.token);
            setViewPropertiesVisibility(
              resData.data.checkHomeValues.visibility
            );

            setCheckHomeValuesToken(resData.data.checkHomeValues.token);
            setCheckHomeValuesVisibility(
              resData.data.checkHomeValues.visibility
            );

            setCanCreate(resData.data.canCreatePage);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = `url('${data.backgroundImage}'), url('/images/1644964579788_V.png')`;

    return () => {
      document.body.style.backgroundImage = '';
    };
  }, [data.backgroundImage, data.backgroundImageFile]);

  // useEffect(() => {
  //   document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";
  //   let getLocalData = getFromLocalStorage("personal_profile");

  //   if (getLocalData) {
  //     setData({
  //       profileImage: getLocalData.profileImage,
  //       buttons: getLocalData.buttons,
  //       about: getLocalData.about,
  //       widgetCode: getLocalData.widgetCode,
  //     });
  //     setCalculatorToken(getLocalData.calculator.token);
  //     setCalculatorVisibility(getLocalData.calculator.visibility);

  //     setMortgageRatesToken(getLocalData.mortgageRates.token);
  //     setMortgageRatesVisibility(getLocalData.mortgageRates.visibility);

  //     setViewPropertiesToken(getLocalData.viewProperties.token);
  //     setViewPropertiesVisibility(getLocalData.viewProperties.visibility);
  //   }

  //   fetchCalculators();
  //   fetchMortgageRates();
  //   fetchViewProperties();
  //   return () => {
  //     document.body.style.backgroundImage = "";
  //   };
  // }, []);

  useEffect(() => {
    // calc
    axios({
      url: '/api/plugin/standalone/' + calculatorToken,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          console.log(resData.data);
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
            isOwnPlugin: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [calculatorToken]);

  useEffect(() => {
    axios({
      url: '/api/mortgageRates/token/' + mortgageRatesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          console.log('m', resData.data);
          setMortgageRatesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [mortgageRatesToken]);

  useEffect(() => {
    axios({
      url: '/api/viewProperties/token/' + viewPropertiesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setViewPropertiesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [viewPropertiesToken]);

  useEffect(() => {
    axios({
      url: '/api/checkHomeValues/token/' + checkHomeValuesToken,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setCheckHomeValuesData(resData.data);
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        // toast.error(error.message || status, {});
      });
  }, [checkHomeValuesToken]);

  useEffect(() => {}, [
    data,
    calculatorToken,
    mortgageRatesToken,
    viewPropertiesToken,
    checkHomeValuesToken,
    calculatorVisibility,
    mortgageRatesVisibility,
    viewPropertiesVisibility,
    checkHomeValuesVisibility,
  ]);

  const fetchCalculators = () => {
    axios({
      url: `/api/plugin/${params.profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log('setCalculators ', resData.data);
        if (resData.success) {
          setCalculators(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const fetchMortgageRates = () => {
    axios({
      url: `/api/mortgageRates/${params.profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(`setMortgageRates :` + resData.data);

        if (resData.success) {
          setMortgageRates(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };
  const fetchViewProperties = () => {
    axios({
      url: `/api/viewProperties/${params.profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(`setViewProperties :` + resData.data);

        if (resData.success) {
          setViewProperties(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const fetchCheckHomeValues = () => {
    axios({
      url: `/api/checkHomeValues/${params.profileId}/all`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log(`setCheckHomeValues :` + resData.data);

        if (resData.success) {
          setCheckHomeValues(resData.data);
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === 'profileImage') {
      const base64 = await convertBase64(files[0]);
      const url = await objectURL(base64);
      setData((prev) => ({
        ...prev,
        [name]: url,
        profileImageFile: files[0],
      }));
    } else if (name === 'backgroundImage') {
      const base64 = await convertBase64(files[0]);
      const url = await objectURL(base64);
      setData((prev) => ({
        ...prev,
        [name]: url,
        backgroundImageFile: files[0],
      }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const objectURL = (bs64) => {
    return new Promise((resolve, reject) => {
      fetch(bs64)
        .then((res) => res.blob())
        .then((dt) => {
          let url = URL.createObjectURL(dt);
          resolve(url);
        })
        .catch((err) => reject(err));
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      data.buttons,
      result.source.index,
      result.destination.index
    );

    setData((prev) => ({ ...prev, buttons: items }));
  };

  const handleSave = () => {
    setLoader(true);
    const formData = new FormData();

    let dta = {
      profileName: data.profileName,
      buttons: data.buttons,
      about: data.about,
      widgetCode: data.widgetCode,
      calculator: {
        token: calculatorToken,
        visibility: calculatorVisibility,
      },
      mortgageRates: {
        token: mortgageRatesToken,
        visibility: mortgageRatesVisibility,
      },
      viewProperties: {
        token: viewPropertiesToken,
        visibility: viewPropertiesVisibility,
      },
      checkHomeValues: {
        token: checkHomeValuesToken,
        visibility: checkHomeValuesVisibility,
      },
      canCreatePage: false,
    };

    formData.append('profileImage', data.profileImageFile);
    formData.append('backgroundImage', data.backgroundImageFile);
    formData.append('data', JSON.stringify(dta));

    axios({
      url: '/api/users/standalone/' + user._id + '/update',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          if (resData.data && Object.keys(resData.data).length > 0) {
            setLoader(false);
            setData({
              profileImage: resData.data.profileImage,
              backgroundImage: resData.data.backgroundImage,
              buttons: resData.data.buttons,
              about: resData.data.about,
              widgetCode: resData.data.widgetCode,
            });

            setCalculatorToken(resData.data.calculator?.token);
            setCalculatorVisibility(resData.data.calculator?.visibility);

            setMortgageRatesToken(resData.data.mortgageRates?.token);
            setMortgageRatesVisibility(resData.data.mortgageRates?.visibility);

            setViewPropertiesToken(resData.data.viewProperties?.token);
            setViewPropertiesVisibility(
              resData.data.viewProperties?.visibility
            );
            setCheckHomeValuesToken(resData.data.checkHomeValues?.token);
            setCheckHomeValuesVisibility(
              resData.data.checkHomeValues?.visibility
            );
            setCanCreate(resData.data.canCreatePage);

            toast.success('Updated');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleColorChange = (value) => {
    let cp = data.buttons;
    setColorPickerStat((prev) => ({ ...prev, value }));
    if (colorPickerStat.type === 'bg') {
      cp[colorPickerStat.index]['bg'] = value;
    } else {
      cp[colorPickerStat.index]['hover'] = value;
    }

    setData((prev) => {
      return { ...prev, buttons: cp };
    });
  };

  return (
    <div className="relative h-full w-full">
      {loader && <Loader customClass="md:ml-[300px]" />}
      <header className="py-6 px-4">
        <div className="flex items-center space-x-1">
          <img
            className="w-10 object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
          <p className="flex flex-col text-sm font-bold leading-none">
            {' '}
            <span className="text-[#AB1E23]">Savage</span>{' '}
            <span className="text-[#262C34]">Sales</span>
          </p>
        </div>
      </header>
      <main className="my-12">
        <div className="ml-4 mb-6 flex items-center space-x-3">
          <CopyToClipboard
            text={`${window.location.origin}/view/profile/${user._id}`}
            onCopy={() => toast.success('Copied to clipboard')}
          >
            <button className="flex items-center justify-center px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
              <span>Copy URL</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
          </CopyToClipboard>
          <button className="flex items-center justify-center px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
            <label htmlFor="backgroundImage" className="cursor-pointer">
              <span> Change Background</span>
              <input
                hidden
                className=""
                id="backgroundImage"
                name="backgroundImage"
                type="file"
                onChange={handleChange}
              />
            </label>
          </button>
          <button
            onClick={handleSave}
            className="flex items-center justify-center px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
          >
            Save Changes
          </button>
          {/* {loader && <Loader customClass="md:!ml-[300px]" />} */}
        </div>
        <header className="mx-auto text-center">
          <div className="h-20 w-20 md:h-32 md:w-32 mx-auto bg-white rounded-full overflow-hidden transition-all duration-200 ease-in-out transform hover:scale-105">
            <label
              htmlFor="profileImage"
              className="reveal_edit-btn relative block h-full w-full cursor-pointer after:content-[''] hover:after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-black after:opacity-10 transition-all duration-200 ease-in-out"
            >
              <span className="block h-full w-full overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={data.profileImage || profilePlaceholder}
                  alt={data.name}
                />
              </span>
              <span className="edit-btn absolute left-1/2 top-[50%] transform -translate-x-1/2 -translate-y-1/2 z-50 p-2 rounded-full bg-[#00000080] opacity-0 transition-all duration-200 ease-in-out">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className=" w-4 h-4 text-white"
                >
                  <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                </svg>
              </span>

              <input
                hidden
                className=""
                id="profileImage"
                name="profileImage"
                type="file"
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="mt-2">
            <input
              onChange={(e) =>
                setData((prev) => ({ ...prev, profileName: e.target.value }))
              }
              type="text"
              value={data.profileName}
              className="text-xl md:text-2xl font-bold outline-none border-none bg-transparent text-center max-w-[400px] w-full"
              placeholder="Type your profile name"
            />
          </div>
        </header>

        <div className="mx-4 mt-8 flex flex-col items-center space-y-3">
          <div className="max-w-lg w-full mb-4">
            <textarea
              name="about"
              id="about"
              placeholder="Type something about yourself"
              className="w-full p-4 bg-transparent outline-none text-[#262C34]"
              rows="10"
              value={data.about}
              onChange={(e) => {
                const { value } = e.target;

                setData((prev) => ({ ...prev, about: value }));
              }}
            ></textarea>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data.buttons.map((dt, idx) => (
                    <Draggable
                      key={dt.id + ''}
                      draggableId={dt.id + ''}
                      index={idx}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="relative max-w-xs w-full my-3"
                        >
                          <button
                            className="absolute -right-6 top-2"
                            onClick={() => {
                              let cp = data.buttons.filter(
                                (_, index) => idx !== index
                              );

                              setData((prev) => {
                                return { ...prev, buttons: cp };
                              });
                            }}
                          >
                            <AiFillCloseSquare className="text-[#262C34] text-xl" />
                          </button>

                          <button
                            className="absolute -right-6 top-8"
                            onClick={() => {
                              setColorPickerStat({
                                state: true,
                                index: idx,
                                value: '',
                                type: 'bg',
                              });
                            }}
                          >
                            <div
                              className="h-5 w-5 rounded-full overflow-hidden"
                              style={{
                                background:
                                  'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 43%, rgba(0,212,255,1) 100%)',
                              }}
                            ></div>
                          </button>

                          <button
                            className="absolute -right-6 top-14"
                            onClick={() => {
                              setColorPickerStat({
                                state: true,
                                index: idx,
                                value: '',
                                type: 'hover',
                              });
                            }}
                          >
                            <div
                              className="h-5 w-5 rounded-full overflow-hidden"
                              style={{
                                background:
                                  'linear-gradient(90deg, rgba(181,181,181,1) 0%, rgba(55,55,79,1) 43%, rgba(136,172,180,1) 100%)',
                              }}
                            ></div>
                          </button>

                          <a
                            className="block w-full p-2 text-center text-white font-medium rounded-tl-md rounded-tr-md bg-[var(--bg-color)] hover:bg-[var(--hover-color)] hover:text-[#262C34] transition-all duration-200"
                            href={dt.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              '--bg-color': dt.bg || '#262C34',
                              '--hover-color': dt.hover || '#AB1E23',
                            }}
                          >
                            {dt.label}
                          </a>
                          <input
                            type="text"
                            value={dt.label}
                            placeholder="Type the label"
                            className="w-full p-2 text-xs outline-none"
                            onChange={(e) => {
                              const { value } = e.target;
                              let cp = data.buttons;
                              cp[idx].label = value;

                              setData((prev) => {
                                return { ...prev, buttons: cp };
                              });
                            }}
                          />
                          <input
                            type="text"
                            value={dt.url}
                            placeholder="paste your link here"
                            className="w-full p-2 text-xs rounded-bl-md rounded-br-md outline-none"
                            onChange={(e) => {
                              const { value } = e.target;
                              let cp = data.buttons;
                              cp[idx].url = value;

                              setData((prev) => {
                                return { ...prev, buttons: cp };
                              });
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="max-w-xs w-full">
            <button
              className="block w-full p-2 text-center text-white font-medium roundedmd bg-[#262C34] hover:bg-[#AB1E23] hover:text-[#262C34] transition-all duration-200"
              onClick={() => {
                let cp = data.buttons;
                cp.push({
                  id: Date.now(),
                  label: 'Untitled',
                  url: '',
                  bg: '',
                });

                setData((prev) => {
                  return { ...prev, buttons: cp };
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-auto w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="mx-4 mt-14 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <select
                className="p-2 rounded-md outline-none"
                name="calculatorToken"
                id="calculatorToken"
                onChange={(e) => {
                  setCalculatorToken(e.target.value);
                }}
              >
                {calculators.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <div>
                <label class="switch">
                  <input
                    name="visible"
                    type="checkbox"
                    checked={calculatorVisibility}
                    onChange={(e) => {
                      setCalculatorVisibility(!calculatorVisibility);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div
              className={`relative ${
                !calculatorVisibility
                  ? "after:content-[''] after:absolute after:z-[1] after:opacity-25 after:bg-slate-100 after:top-0 after:left-0 after:bottom-0 after:right-0"
                  : ''
              } `}
            >
              {currentPlugin && (
                <CalculatorPreview
                  pluginLogo={currentPlugin.logo.value}
                  leadName={currentPlugin.leadName}
                  leadLink={currentPlugin.leadLink}
                  pluginTitle={currentPlugin.title}
                  behaviour=""
                  backgroundColor={currentPlugin.colors.backgroundColor}
                  titleColor={currentPlugin.colors.titleColor}
                  textColor={currentPlugin.colors.textColor}
                  buttonColor={currentPlugin.colors.buttonColor}
                  buttonTextColor={currentPlugin.colors.buttonTextColor}
                  varbiage={currentPlugin.varbiage}
                  homeSearchPage={currentPlugin.homeSearchPage}
                  calenderBookingPage={currentPlugin.calenderBookingPage}
                  showEmailPaymentDetails={
                    currentPlugin.leadButtons.emailPaymentDetails
                  }
                  showViewProperties={currentPlugin.leadButtons.viewProperties}
                  showPreQualified={currentPlugin.leadButtons.preQualified}
                  showCheckHomeValues={
                    currentPlugin.leadButtons.checkHomeValues
                  }
                />
              )}
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <select
                className="p-2 rounded-md outline-none"
                name="mortgageRatesToken"
                id="mortgageRatesToken"
                onChange={(e) => {
                  setMortgageRates(e.target.value);
                }}
              >
                {mortgageRates.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <div>
                <label class="switch">
                  <input
                    name="visible"
                    type="checkbox"
                    checked={mortgageRatesVisibility}
                    onChange={(e) => {
                      setMortgageRatesVisibility(!mortgageRatesVisibility);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div
              className={`relative ${
                !mortgageRatesVisibility
                  ? "after:content-[''] after:absolute after:z-[1] after:opacity-25 after:bg-slate-100 after:top-0 after:left-0 after:bottom-0 after:right-0"
                  : ''
              } `}
            >
              {mortgageRatesData && (
                <MortgageRatesWidget
                  mortageRateTitle={mortgageRatesData.title}
                  pluginToken={mortgageRatesData.token}
                  varbiage={mortgageRatesData.varbiage}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <select
                className="p-2 rounded-md outline-none"
                name="viewPropertiesToken"
                id="viewPropertiesToken"
                onChange={(e) => {
                  console.log(e.target.value);
                  setViewPropertiesToken(e.target.value);
                }}
              >
                {viewProperties.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <div>
                <label class="switch">
                  <input
                    name="visible"
                    type="checkbox"
                    checked={viewPropertiesVisibility}
                    onChange={(e) => {
                      setViewPropertiesVisibility(!viewPropertiesVisibility);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div
              className={`relative ${
                !viewPropertiesVisibility
                  ? "after:content-[''] after:absolute after:z-[1] after:opacity-25 after:bg-slate-100 after:top-0 after:left-0 after:bottom-0 after:right-0"
                  : ''
              } `}
            >
              {viewPropertiesData && (
                <ViewPropertiesWidget
                  homeSearchPage={viewPropertiesData.homeSearchPage}
                  funnelTitle={viewPropertiesData.title}
                  calenderBookingPage={viewPropertiesData.calenderBookingPage}
                  sitekey={viewPropertiesData.token}
                  varbiage={viewPropertiesData.varbiage}
                />
              )}
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <select
                className="p-2 rounded-md outline-none"
                name="checkHomeValuesToken"
                id="checkHomeValuesToken"
                onChange={(e) => {
                  setCheckHomeValuesToken(e.target.value);
                }}
              >
                {checkHomeValues.map((dt, idx) => (
                  <option key={idx} value={dt?.token}>
                    {dt?.name}
                  </option>
                ))}
              </select>
              <div>
                <label class="switch">
                  <input
                    name="visible"
                    type="checkbox"
                    checked={checkHomeValuesVisibility}
                    onChange={(e) => {
                      setCheckHomeValuesVisibility(!checkHomeValuesVisibility);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div
              className={`relative ${
                !checkHomeValuesVisibility
                  ? "after:content-[''] after:absolute after:z-[1] after:opacity-25 after:bg-slate-100 after:top-0 after:left-0 after:bottom-0 after:right-0"
                  : ''
              } `}
            >
              {checkHomeValuesData && (
                <CheckHomeValuesWidget
                  funnelTitle={checkHomeValuesData.title}
                  calenderBookingPage={viewPropertiesData.calenderBookingPage}
                  sitekey={viewPropertiesData.token}
                  varbiage={viewPropertiesData.varbiage}
                />
              )}
            </div>
          </div>
        </div>

        <div className="mx-4 mt-14">
          <h3 className="text-lg font-medium"> Paste your widget code here </h3>
          <textarea
            name="widgetCode"
            id="widgetCode"
            className="p-4 w-full text-xs bg-[#e8e8e8] outline-none rounded-lg"
            rows="5"
            value={data.widgetCode}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({ ...prev, widgetCode: value }));
            }}
          ></textarea>
        </div>
      </main>
      {colorPickerStat.state && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="fixed bottom-0 left-0 right-0 top-0 bg-[#00000082]"
            onClick={() =>
              setColorPickerStat({ state: false, index: null, value: '' })
            }
          ></div>
          <div className="relative bg-white z-[100]">
            <ColorPicker
              onChange={handleColorChange}
              value={colorPickerStat.value}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PersonalSite;
