import React, { useEffect } from 'react';

const ModalMain = ({ show, top, onClose, children }) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);
  return (
    <div className="modal-scroll">
      <div className="absolute top-0 left-0 w-full h-full flex items-center  justify-center bg-black bg-opacity-80 z-[99] modal-scroll">
        <div
          className={`absolute bottom-0 modal-inner-container 
        }  max-h-[100%] place-items-center pt-6 overflow-auto slide-in modal-scroll`}
          onClick={onClose}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalMain;
