import React from 'react';
import { useParams } from 'react-router-dom';
import useContext from '../../context/StateProvider';
import 'grapesjs/dist/css/grapes.min.css';
import services from './../../services/pageBulder.service';

import EditorGs from '../Site_Builder/EditorGs';
import { toast } from 'react-toastify';

function PersonalSiteBuilder() {
  const { pageId } = useParams();
  const [{ user }] = useContext();

  const url = `/api/personal_site/${user._id}/content/${pageId}`;

  const handleSubmit = async (html, css) => {
    try {
      const response = await toast.promise(
        services.savePage(user._id, pageId, html, css),
        {
          pending: 'Please wait. The page is saving...',
          success: 'Page saved successfully',
          error: 'An error occurred while saving the page.',
        }
      );
      console.log('Page saved');
    } catch (error) {
      // toast.dismiss();
      toast.error(
        'An error occurred while saving the page. Please try again later.'
      );
      console.error('Error saving page');
    }
  };

  return (
    <div>
      <EditorGs
        pageId={pageId}
        userId={user._id}
        storageUrl={url}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default PersonalSiteBuilder;
