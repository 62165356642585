import React, { useEffect } from "react";

const VideoCtaButtons = ({
  setVideo = "",
  url = null,
  bgColor,
  btnText,
  textColor,
  btnHeight,
  icon,
  openUrlIn = null,
  onCloseHandler,
}) => {
  useEffect(() => {
    console.log("btnText", btnText);
  }, [btnText]);
  return (
    <div>
      <div className="mt-1">
        <a href={url} target={openUrlIn ? "_blank" : ""} rel="noreferrer">
          <button
            className="w-[95%] flex justify-between items-center px-2"
            style={{
              backgroundColor: bgColor,
              color: textColor,
              height: btnHeight,
              borderRadius: "5px",
            }}
            onClick={onCloseHandler}
          >
            {btnText}

            <span>{icon}</span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default VideoCtaButtons;
