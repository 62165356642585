import axios from './axios';

const services = {
  fetchCalculators: (profileId, token) => {
    return axios({
      url: `/api/plugin/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  fetchMortgageRates: (profileId, token) => {
    return axios({
      url: `/api/mortgageRates/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  fetchViewProperties: (profileId, token) => {
    return axios({
      url: `/api/viewProperties/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  fetchCheckHomeValues: (profileId, token) => {
    return axios({
      url: `/api/checkHomeValues/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  fetchFlexiFrame: (profileId, token) => {
    return axios({
      url: `/api/flexiframe/${profileId}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          status: resData.status,
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default services;
