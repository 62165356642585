import React, { useState } from "react";
import { MyConstextApi } from "../../../../context/ContextApi";
import VideoCtaButtons from "./VideoCtaButtons";

const VideoInfo = ({
  setVideo,
  videoTitle,
  videoDesc,
  redirectUrl,
  bgColor,
  bgColor2,
  redirectButtonText,
  newWindow,
  redirectButtonHeight,
  selectIcon,
  redirectButtonUpdate,
  redirectButtonUpdate3,
  textInputUpdate,
  redirectButtonText3,
  exBgColor5,
  exBgColor4,
  selectIcon2,
  redirectButtonHeight3,
  redirectUrl3,
  textInputType,
  textInputPlaceHolder,
  textInputbgColor,
  textInputTextColor,
  onCloseHandler,
}) => {
  const {
    // connectCta,
    // chatAi,
    // videoRec,
    redirectCta,
    redirectFullCta,
    calbook,
    inputText,
    runCode,
    closeCta,
  } = MyConstextApi();

  console.log("inputText: ", inputText);

  const [inputValues, setInputValues] = useState(
    Array(inputText.length).fill(""),
  );

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };
  return (
    <>
      {(videoTitle ||
        videoDesc ||
        redirectButtonUpdate ||
        redirectButtonUpdate3 ||
        textInputUpdate) && (
        <div
          className={`w-full overflow-hidden bottom-10 text-xl   font-semibold left-2 flex flex-col`}
        >
          <h3 style={{ color: bgColor }}>{videoTitle}</h3>

          <p style={{ color: bgColor2 }} className="text-xs block w-[90%]">
            {videoDesc}
          </p>
          {/* {connectCta &&
            connectCta.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.yourFacepop}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
                openUrlIn={cta.openUrlIn}
              />
            ))} */}
          {redirectCta &&
            redirectCta.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.url}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
                openUrlIn={cta.openUrlIn}
              />
            ))}
          {redirectFullCta &&
            redirectFullCta.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.url}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
                openUrlIn={cta.openUrlIn}
              />
            ))}

          {/* {videoRec &&
            videoRec.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
              />
            ))} */}
          {calbook &&
            calbook.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.url}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
                openUrlIn={cta.openUrlIn}
              />
            ))}
          {/* {chatAi &&
            chatAi.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.chatBotId}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
              />
            ))} */}

          {runCode &&
            runCode.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                url={cta.jsCode}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
              />
            ))}
          {inputText &&
            inputText.map((cta, index) => (
              <div className="mt-1" key={index}>
                <input
                  value={inputValues[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  type={cta.settings.inputType}
                  className="w-[95%] text-xs py-2 outline-none border-transparent px-2"
                  placeholder={cta.settings.placeholder}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "5px",
                  }}
                />
              </div>
            ))}

          {closeCta &&
            closeCta.map((cta) => (
              <VideoCtaButtons
                key={cta.id}
                onCloseHandler={onCloseHandler}
                bgColor={cta.colors.bgColor}
                btnText={cta.general.btnText}
                textColor={cta.colors.textColor}
                btnHeight={cta.general.btnHeight}
                icon={cta.icon}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default VideoInfo;
