import React from 'react';

import CustomFunnel from './CustomFunnel';

function Preview({ stepsData, pluginTitle, pluginToken, varbiage }) {
  return (
    <div className="mtge-rates_app">
      <CustomFunnel
        stepsData={stepsData}
        pluginTitle={pluginTitle}
        pluginToken={pluginToken}
        varbiage={varbiage}
      />
    </div>
  );
}

export default Preview;
