import React from "react";

import { Slider, Stack } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function StepThree({ value, onChange, handleNext }) {
  return (
    <div className="py-6 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">Total Baths</h1>
      <h1 className="text-sm md:text-lg font-medium">{value}</h1>
      <div className="w-full mt-3 px-10 flex flex-col items-center space-y-2">
        <div className="w-full">
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1, px: 1 }}
            alignItems="center"
          >
            <Slider
              aria-label="Volume"
              value={value}
              min={0}
              step={1}
              max={20}
              name="baths"
              onChange={onChange}
              sx={{
                color: "var(--color-red)",
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  width: 24,
                  height: 24,
                  backgroundColor: "#fff",
                  "&:before": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
              }}
            />
          </Stack>

          <div className="flex items-center justify-between">
            <p className="text-xs font-semibold text-[var(--color-red)]">0</p>
            <p className="text-xs font-semibold text-[var(--color-red)]">20</p>
          </div>
        </div>

        <button
          className="mtge-rates_next-btn px-12 py-2 text-lg md:text-xl"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepThree;
