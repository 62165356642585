import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { BsFacebook, BsGoogle } from 'react-icons/bs';

import Loader from '../Loader/Loader';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Register() {
  const query = useQuery();

  const [isRegistering, setIsRegistering] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState('');

  const [formErrors, setFormErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('bg-[#DFE3E8]');

    let referCode = query.get('referral');

    if (referCode) {
      (async () => {
        await axios({
          url:
            'https://partner.savagesales.com/api/refer/' + referCode + '/view',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      })();
    }

    return () => {
      document.body.classList.remove('bg-[#DFE3E8]');
    };
  }, []);

  const validateFields = (values) => {
    let validated = false;

    if (values.password !== values.confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Password didn't match with confirm password!",
        confirmPassword: "Confirm Password didn't match with password!",
      }));
      validated = false;
    } else {
      setFormErrors((prev) => ({
        ...prev,
        password: null,
        confirmPassword: null,
      }));
      validated = true;
    }

    return validated;
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (!validateFields({ password, confirmPassword })) return;

    if (firstName && lastName && phone && email) {
      setIsRegistering(true);
      setLoading(true);
      let referCode = query.get('referral');
      let URL = '/api/users/signup';

      if (referCode) {
        URL += '?referCode=' + referCode;
      }
      toast.promise(
        axios({
          url: URL,
          method: 'post',
          data: {
            firstName,
            lastName,
            phone,
            email: email.toLowerCase(),
            password,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => {
            const resData = res.data;
            setLoading(false);

            if (resData.success) {
              toast.info('Check your email...');
              navigate('/');
            } else {
              toast.error(resData.status, {});
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            let error = err.response.data.error;
            let status = err.response.data.status;
            toast.error(error.message || status, {});
          }),
        {
          pending: 'Registering...',
          success: 'Registration successful',
          error: 'Registration failed',
        }
      );
    } else {
      toast.error('Fill up all the fields', {});
    }
  };

  return (
    <div className="sm:container sm:mx-auto sm:max-w-lg pt-12 pb-16 sm:pt-24 min-h-[calc(100vh-142px)]">
      {' '}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          Register
        </div>
        {/*  */}
        {/* Reg with social */}
        <div className="RegWLink p-5">
          <h5 className="text-[#161C24] dark:text-white text-center">
            Register with one of the following:
          </h5>
          <div className="allLinks flex justify-center mt-3">
            <a href="#">
              <BsFacebook className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
            <a href="/api/users/auth/google">
              <BsGoogle className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
          </div>
        </div>

        {/* orr */}
        <div
          className="text-center  relative before:content-[''] before:block max-[415px]:before:w-[130px]  before:w-[200px] before:h-[2px] before:absolute before:left-0 before:top-1/2  before:bg-[#424250] before:ml-7 
 after:content-[''] after:block  max-[415px]:after:w-[130px] after:w-[200px] after:h-[2px] after:absolute after:right-0 after:top-1/2  after:bg-[#424250] after:mr-7"
        >
          <span className="text-white bg-[#33333D]">OR</span>
        </div>

        <div className="form pt-4">
          <form
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
            onSubmit={handleRegister}
          >
            <div>
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="email">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                value={email}
                placeholder="E-Mail Address"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="phone">
              {/* <label for="phone">E-Mail Address</label> */}
              <input
                type="phone"
                name="phone"
                value={phone}
                placeholder="Phone number"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="flex flex-col space-y-1">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={(e) => setPassword(e.target.value)}
              />
              {formErrors.password && (
                <span>
                  {' '}
                  <small className="text-red-600">
                    {' '}
                    {formErrors.password}{' '}
                  </small>{' '}
                </span>
              )}
            </div>

            <div className="flex flex-col space-y-1">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {formErrors.confirmPassword && (
                <span>
                  {' '}
                  <small className="text-red-600">
                    {' '}
                    {formErrors.confirmPassword}{' '}
                  </small>{' '}
                </span>
              )}
            </div>

            {/* buttton others */}
            <div className="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                disabled={isRegistering}
              >
                Register
              </button>{' '}
              <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#161C24] dark:text-[#CFCFD3] ">
                Already have an account?
                <Link
                  to="/login"
                  className="text-[#AD1418] no-underline hover:underline ml-1"
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function ConfirmEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      url: '/api/users/verifyUser/' + token,
      method: 'get',
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          toast.info('Account verified, you can login now.');
          navigate('/login');
        } else {
          toast.error(resData.status, {});
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error('Invalid', {});
        navigate('/');
      });
  }, []);

  return <Loader />;
}

export { Register, ConfirmEmail };
