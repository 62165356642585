import React from 'react';

import { Slider, Stack } from '@mui/material';

function StepFour({ value, onChange, handleNext }) {
  return (
    <div className="py-4 flex flex-col items-center">
      <h1 className="text-base md:text-xl font-medium">
        Approximate Square Footage
      </h1>

      <div className="mt-3 flex flex-col items-center space-y-3">
        <div className="relative max-w-[220px] md:max-w-[320px]">
          <input
            id="squareFootage"
            className="mtge-rates_input-field text-[#0f101a] text-lg md:text-xl font-semibold"
            type="number"
            name="squareFootage"
            required
            value={value}
            onChange={onChange}
          />
          <label
            htmlFor="squareFootage"
            className="mtge-rates_input-label text-xs md:text-sm"
          >
            Square Footage
          </label>
        </div>

        <button
          className="mtge-rates_next-btn px-16 py-2 text-base md:text-lg"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default StepFour;
