import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';

function ShowTemplates({ templateView, templateId, handleOpenModal }) {
  return (
    <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
      <h3 className="text-xl font-semibold mb-4">{templateView}</h3>
      <div className="flex justify-between items-center">
        <button
          className="px-3 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600"
          onClick={handleOpenModal}
        >
          <AddCircleIcon className="w-5 h-5" />
        </button>

        <button className="px-3 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600">
          <Link to={`/template/${templateId}`} className="text-white">
            <VisibilityIcon className="w-5 h-5" />
          </Link>
        </button>
      </div>
    </div>
  );
}

export default ShowTemplates;
