// modules
import { createTheme } from "@mui/material/styles";

export const filterSelect = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
          },
          "& .MuiSelect-select": {
            padding: 0,
            paddingRight: "0px !important",

            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
  },
});
