import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GrClose } from 'react-icons/gr';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// components
import StepOne from './Steps/One';
import StepTwo from './Steps/Two';
import StepThree from './Steps/Three';
import StepFour from './Steps/Four';
import StepFive from './Steps/Five';
import StepSix from './Steps/Six';
import StepSeven from './Steps/Seven';
import StepEight from './Steps/Eight';
import StepNine from './Steps/Nine';
import StepTen from './Steps/Ten';
import StepEleven from './Steps/Eleven';
import StepTwelve from './Steps/Twelve';
import StepThirdteen from './Steps/Thirteen';
import StepFourteen from './Steps/Fourteen';
import StepFifteen from './Steps/Fifteen';
import StepSixteen from './Steps/Sixteen';
import StepSeventeen from './Steps/Seventeen';
import StepEighteen from './Steps/Eighteen';
import Thankyou from './Steps/Thankyou';
// import config from '../../utils/config';
// import LeadForm from './../LeadForm';
import ProgressBar from './../common/ProgressBar';
import PrevButton from './../common/PrevButton';

function Widget() {
  const [swiper, setSwiper] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    zipCode: '',
    loanType: '',
    propertyType: '',
    creditScore: '',
    isFirstTimePurchasing: '',
    purchaseSituation: '',
    usesOfProperty: '',
    purchasePrice: 120000,
    downPayment: 5,
    typeOfRate: '',
    annualHouseHoldIncome: 75000,
    employmentStatus: '',
    isBankruptcy: '',
    hasProofOfIncome: '',
    isWorkingAsRealStateAgent: '',
    fullName: '',
    email: '',
    phone: '',
  });

  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentStep === 1 && !uniqueId) {
      let uuid = new Date().getTime();
      setUniqueId(uuid);

      axios({
        url: `https://api.ipify.org/?format=json`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const resData = res.data;
          setIpAddress(resData.ip);
          return axios({
            url: `https://api.ipgeolocation.io/ipgeo`,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              apiKey: 'deb9a79bf3024e94865209fd35da0521',
              fields: 'city,country_name',
              output: 'json',
              ip: resData.ip,
            },
          });
        })
        .then((res) => {
          const resData = res.data;
          return axios({
            url: `/api/mortgageRates/leads/add/-9AIY0KAdCfXLob3GTsnQt5W4yQNJvqQiXonkh0ABL8`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              ref: uuid,
              ipAddress: resData.ip,
              liveAddress: resData.city + ', ' + resData.country_name,
            },
          });
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    }
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const captureUserEvents = (data) => {
    axios({
      url: `/api/mortgageRates/leads/add/-9AIY0KAdCfXLob3GTsnQt5W4yQNJvqQiXonkh0ABL8`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        formValues: { ...data },
      },
    })
      .then((res) => {
        console.log('formvalues: ', formValues);
        console.log('formvalues.emal: ', formValues.email);
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  const handleNext = () => {
    if (currentStep === 0 && !formValues.zipCode) return;
    captureUserEvents(formValues);
    setCurrentStep((prev) => prev + 1);
    swiper.slideNext();
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
    swiper.slidePrev();
  };

  const handleFinish = () => {
    setLoading(true);
    axios({
      url: `/api/mortgageRates/leads/add/-9AIY0KAdCfXLob3GTsnQt5W4yQNJvqQiXonkh0ABL8`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ref: uniqueId, ipAddress: ipAddress, formValues },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setLoading(false);

          toast.success('Successfully done!', { className: 'z-toast' });
          handleNext();
          setFormValues({
            zipCode: '',
            loanType: '',
            propertyType: '',
            creditScore: '',
            isFirstTimePurchasing: '',
            purchaseSituation: '',
            usesOfProperty: '',
            purchasePrice: 120000,
            downPayment: 5,
            typeOfRate: '',
            annualHouseHoldIncome: 75000,
            employmentStatus: '',
            isBankruptcy: '',
            hasProofOfIncome: '',
            isWorkingAsRealStateAgent: '',
            fullName: '',
            email: '',
            phone: '',
          });
          setUniqueId('');
          setIpAddress('');
        } else {
          setLoading(false);

          toast.error(resData.status, {
            className: 'z-toast',
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, { className: 'z-toast' });
      });
  };

  if (currentStep === 18) {
    return (
      <div className="mtge-rates_modal-native-el">
        <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl">
          <Thankyou />
        </div>
      </div>
    );
  }

  return (
    <div className="mtge-rates_modal_wrapper max-w-full mt-6 p-0 px-4 md:px-8 bg-white slide-in-left">
      <header className="flex items-center justify-between">
        <div className="w-14 md:w-16">
          <img
            className="w-full object-contain"
            src={`/images/logo.png`}
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar currentStep={currentStep} totalStep={19} />
      <main className="min-h-[350px] mt-4 overflow-hidden">
        <Swiper
          cssMode={true}
          allowTouchMove={false}
          navigation={{
            nextEl: '.swiper-button-custom-next',
            prevEl: '.swiper-button-custom-prev',
          }}
          modules={[Navigation]}
          className="mySwiper"
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
          <SwiperSlide>
            {' '}
            <StepOne
              value={formValues.zipCode}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwo
              value={formValues.loanType}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThree
              value={formValues.propertyType}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFour
              value={formValues.creditScore}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFive
              value={formValues.isFirstTimePurchasing}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSix
              value={formValues.purchaseSituation}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSeven
              value={formValues.usesOfProperty}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepEight
              value={formValues.purchasePrice}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepNine
              value={formValues.downPayment}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepTen
              value={formValues.typeOfRate}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepEleven
              value={formValues.annualHouseHoldIncome}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwelve
              value={formValues.employmentStatus}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThirdteen
              value={formValues.isBankruptcy}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepFourteen
              value={formValues.hasProofOfIncome}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFifteen
              value={formValues.isWorkingAsRealStateAgent}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepSixteen
              value={formValues.fullName}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepSeventeen
              value={formValues.email}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepEighteen
              value={formValues.phone}
              onChange={handleChange}
              handleFinish={handleFinish}
              loading={loading}
            />
          </SwiperSlide>
        </Swiper>

        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-center text-xs text-[#6e7c81] space-x-2">
          {/* {varbiage.map((dt, idx) => {
              if (dt.text) {
                return (
                  <a
                    className="hover:mtge-underline"
                    rel="noreferrer"
                    key={idx}
                    target="_blank"
                    href={dt.link}
                  >
                    {dt.text}
                  </a>
                );
              }
            })} */}
        </div>

        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

export default Widget;
