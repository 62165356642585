import { createContext, useContext, useState } from "react";

import facepopService from "../services/facepop.service";

const StateContext = createContext();

export const ContextWrapper = ({ children }) => {
  // data2
  const [redirectUrl, setRedirectUrl] = useState("");
  const [newWindow, setNewWindow] = useState(true);
  const [redirectButtonText, setRedirectButtonText] = useState("Button Text");
  const [redirectButtonHeight, setRedirectButtonHeght] = useState(35);
  const [redirecticonIndex, setRedirectIconIndex] = useState();
  const [redirectButtonUpdate, setRedirectButtonUpdate] = useState(false);

  const [connectTextClr, setConnectTextClr] = useState("black");
  const [connectBgClr, setConnectBgClr] = useState("white");

  const [rdrctTextClr, setRdrctTextClr] = useState("black");
  const [rdrctBgClr, setRdrctBgClr] = useState("white");
  const [rdrctFullTextClr, setRdrctFullTextClr] = useState("black");
  const [rdrctFullBgClr, setRdrctFullBgClr] = useState("white");
  const [vdoRecTxtClr, setVdoRecTxtClr] = useState("black");
  const [vdoRecTBgClr, setVdoRecBgClr] = useState("white");
  const [calBookTxtClr, setcalBookTxtClr] = useState("black");
  const [calBookBgClr, setCalBookBgClr] = useState("white");
  const [emailTxtClr, setEmailTxtClr] = useState("black");
  const [emailBgClr, setEmailBgClr] = useState("white");
  const [chatAiTxtClr, setChatAiTxtClr] = useState("black");
  const [chatAiBgClr, setChatAiBgClr] = useState("white");
  const [runCodetxtClr, setRunCodetxtClr] = useState("black");
  const [runCodeBgClr, setRunCodeBgClr] = useState("white");
  const [clsFpTxtClr, setClsFpTxtClr] = useState("black");
  const [clsFpBgClr, setclsFpBgClr] = useState("white");

  // data3
  const [redirectUrl3, setRedirectUrl3] = useState("");
  const [redirectButtonText3, setRedirectButtonText3] = useState("Button Text");
  const [redirectButtonHeight3, setRedirectButtonHeght3] = useState(35);
  const [redirecticonIndex3, setRedirectIconIndex3] = useState();
  const [redirectButtonUpdate3, setRedirectButtonUpdate3] = useState(false);

  const [bgColor4, setBgColor4] = useState("black");
  const [bgColor5, setBgColor5] = useState("red");
  // data3

  // textinput
  const [textInputElementId, setTextInputElementId] = useState("");
  const [textInputType, setTextInputType] = useState("");
  const [textInputPlaceHolder, setTextInputPlaceHolder] = useState("");
  const [textInputbgColor, setTextInputbgColor] = useState("white");
  const [textInputTextColor, setTextInputTextColor] = useState("black");
  const [textInputUpdate, setTextInputUpdate] = useState(false);
  // textinput

  // connectCTA
  const [connectCta, setConnectCta] = useState([]);
  const [redirectCta, setRedirectCta] = useState([]);
  const [redirectFullCta, setRedirectFullCta] = useState([]);
  const [videoRec, setVideoRec] = useState([]);
  const [calbook, setCalBook] = useState([]);
  const [inputText, setInputText] = useState([]);
  const [chatAi, setChatAi] = useState([]);
  const [runCode, setRunCode] = useState([]);
  const [closeCta, setCloseCta] = useState([]);

  const fetchConnectCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allConnectCTA(pluginId, userToken);
      if (res.success) {
        setConnectCta(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRedirectCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allRedirectCTA(pluginId, userToken);
      if (res.success) {
        setRedirectCta(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRedirectFullCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allRedirectFullCTA(pluginId, userToken);
      if (res.success) {
        setRedirectFullCta(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVideoRecCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allVideoRecCTA(pluginId, userToken);
      if (res.success) {
        setVideoRec(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCalBookCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allCalenderBookCTA(pluginId, userToken);
      if (res.success) {
        setCalBook(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInputTextCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allInputTextCTA(pluginId, userToken);
      if (res.success) {
        setInputText(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChatAiCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allchatAiCTA(pluginId, userToken);
      if (res.success) {
        setChatAi(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRunCodeCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allRunCodeCTA(pluginId, userToken);
      if (res.success) {
        setRunCode(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCloseCta = async (pluginId, userToken) => {
    try {
      const res = await facepopService.allCloseCTA(pluginId, userToken);
      if (res.success) {
        setCloseCta(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendData = {
    redirectUrl,
    setRedirectUrl,
    newWindow,
    setNewWindow,
    redirectButtonText,
    setRedirectButtonText,
    redirectButtonHeight,
    setRedirectButtonHeght,
    connectTextClr,
    setConnectTextClr,
    connectBgClr,
    setConnectBgClr,
    rdrctTextClr,
    setRdrctTextClr,
    rdrctBgClr,
    setRdrctBgClr,
    rdrctFullTextClr,
    setRdrctFullTextClr,
    rdrctFullBgClr,
    setRdrctFullBgClr,
    vdoRecTxtClr,
    setVdoRecTxtClr,
    vdoRecTBgClr,
    setVdoRecBgClr,
    calBookTxtClr,
    setcalBookTxtClr,
    calBookBgClr,
    setCalBookBgClr,
    emailTxtClr,
    setEmailTxtClr,
    emailBgClr,
    setEmailBgClr,
    chatAiTxtClr,
    setChatAiTxtClr,
    chatAiBgClr,
    setChatAiBgClr,
    runCodetxtClr,
    setRunCodetxtClr,
    runCodeBgClr,
    setRunCodeBgClr,
    clsFpTxtClr,
    setClsFpTxtClr,
    clsFpBgClr,
    setclsFpBgClr,
    redirectButtonUpdate,
    setRedirectButtonUpdate,
    redirecticonIndex,
    setRedirectIconIndex,
    redirectUrl3,
    setRedirectUrl3,
    redirectButtonText3,
    setRedirectButtonText3,
    redirectButtonHeight3,
    setRedirectButtonHeght3,
    redirecticonIndex3,
    setRedirectIconIndex3,
    redirectButtonUpdate3,
    setRedirectButtonUpdate3,
    bgColor4,
    setBgColor4,
    bgColor5,
    setBgColor5,
    textInputElementId,
    setTextInputElementId,
    textInputType,
    setTextInputType,
    textInputPlaceHolder,
    setTextInputPlaceHolder,
    textInputbgColor,
    setTextInputbgColor,
    textInputTextColor,
    setTextInputTextColor,
    textInputUpdate,
    setTextInputUpdate,
    connectCta,
    fetchConnectCta,
    redirectCta,
    fetchRedirectCta,
    redirectFullCta,
    fetchRedirectFullCta,
    videoRec,
    fetchVideoRecCta,
    calbook,
    fetchCalBookCta,
    inputText,
    fetchInputTextCta,
    chatAi,
    fetchChatAiCta,
    runCode,
    fetchRunCodeCta,
    closeCta,
    fetchCloseCta,
  };
  return (
    <StateContext.Provider value={sendData}>{children}</StateContext.Provider>
  );
};

export const MyConstextApi = () => useContext(StateContext);
