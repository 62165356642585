import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '700px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ApiModal = ({ open, getApi, postApi, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="p-4 bg-white mx-auto">
            <h2 className="text-xl font-semibold mb-4 text-center">APIs</h2>
            <div className="mb-4">
              <p className="mb-2">GET API:</p>
              <pre className="bg-gray-200 p-2 rounded break-all overflow-auto">
                {getApi}
              </pre>
              <CopyToClipboard
                text={getApi}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <button className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-full">
                  Copy
                </button>
              </CopyToClipboard>
            </div>
            <div>
              <p className="mb-2">POST API:</p>
              <pre className="bg-gray-200 p-2 rounded break-all overflow-auto">
                {postApi}
              </pre>
              <CopyToClipboard
                text={postApi}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <button className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-full">
                  Copy
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ApiModal;
