import React from 'react';
import { useParams } from 'react-router-dom';
import 'grapesjs/dist/css/grapes.min.css';
import adminServices from '../../../services/adminBulder.service';
import EditorGs from '../../Site_Builder/EditorGs';
import { toast } from 'react-toastify';

function AdminEditor() {
  const { pageId } = useParams();

  const url = `/api/personal_site/templates/admin/content/${pageId}`;

  const handleSubmit = async (html, css) => {
    try {
      const response = await toast.promise(
        adminServices.saveTemplate(pageId, html, css),
        {
          pending: 'Please wait. The page is saving...',
          success: 'Page saved successfully',
          error: 'An error occurred while saving the page.',
        }
      );
      console.log('Page saved');
    } catch (error) {
      console.error('Error saving page');
      toast.error(
        'An error occurred while saving the page. Please try again later.'
      );
    }
  };

  return (
    <div>
      <EditorGs pageId={pageId} storageUrl={url} handleSubmit={handleSubmit} />
    </div>
  );
}

export default AdminEditor;
