import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// components
import StepOne from '../Steps/One';
import StepTwo from '../Steps/Two';
import StepThree from '../Steps/Three';
import StepFour from '../Steps/Four';
import StepFive from '../Steps/Five';
import StepSix from '../Steps/Six';
import StepSeven from '../Steps/Seven';
import StepEight from '../Steps/Eight';
import StepNine from '../Steps/Nine';
import StepTen from '../Steps/Ten';
import StepEleven from '../Steps/Eleven';
import StepTwelve from '../Steps/Twelve';
import StepThirdteen from '../Steps/Thirteen';
import StepFourteen from '../Steps/Fourteen';
import StepFifteen from '../Steps/Fifteen';
import StepSixteen from '../Steps/Sixteen';
import StepSeventeen from '../Steps/Seventeen';
import StepEighteen from '../Steps/Eighteen';
import Thankyou from '../Steps/Thankyou';

function Widget({ mortageRateTitle, pluginToken, varbiage }) {
  const [swiper, setSwiper] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [uniqueId, setUniqueId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  // const [mortageTitle, setMortageTitle] = useState('');

  const [formValues, setFormValues] = useState({
    zipCode: '',
    loanType: '',
    propertyType: '',
    creditScore: '',
    isFirstTimePurchasing: '',
    purchaseSituation: '',
    usesOfProperty: '',
    purchasePrice: 120000,
    downPayment: 5,
    typeOfRate: '',
    annualHouseHoldIncome: 75000,
    employmentStatus: '',
    isBankruptcy: '',
    hasProofOfIncome: '',
    isWorkingAsRealStateAgent: '',
    fullName: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    if (currentStep === 1 && !uniqueId) {
      let uuid = new Date().getTime();
      setUniqueId(uuid);

      axios({
        url: `https://api.ipify.org/?format=json`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const resData = res.data;
          setIpAddress(resData.ip);
          return axios({
            url: `https://api.ipgeolocation.io/ipgeo`,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              apiKey: 'deb9a79bf3024e94865209fd35da0521',
              fields: 'city,country_name',
              output: 'json',
              ip: resData.ip,
            },
          });
        })
        .then((res) => {
          const resData = res.data;
          return axios({
            url: `/api/mortgageRates/leads/add/${pluginToken}`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              ref: uuid,
              ipAddress: resData.ip,
              liveAddress: resData.city + ', ' + resData.country_name,
            },
          });
        })
        .catch((err) => {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        });
    }
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const captureUserEvents = (data) => {
    axios({
      url: `/api/mortgageRates/leads/add/${pluginToken}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ref: uniqueId,
        formValues: { ...data },
      },
    })
      .then((res) => {})
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error.message || status);
      });
  };

  const handleNext = () => {
    captureUserEvents(formValues);
    setCurrentStep((prev) => prev + 1);
    swiper.slideNext();
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
    swiper.slidePrev();
  };

  const handleFinish = () => {
    axios({
      url: `/api/mortgageRates/leads/add/${pluginToken}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ref: uniqueId, formValues },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          toast.success('Successfully done!', { className: 'z-toast' });
          handleNext();
          setFormValues({
            zipCode: '',
            loanType: '',
            propertyType: '',
            creditScore: '',
            isFirstTimePurchasing: '',
            purchaseSituation: '',
            usesOfProperty: '',
            purchasePrice: 120000,
            downPayment: 5,
            typeOfRate: '',
            annualHouseHoldIncome: 75000,
            employmentStatus: '',
            isBankruptcy: '',
            hasProofOfIncome: '',
            isWorkingAsRealStateAgent: '',
            fullName: '',
            email: '',
            phone: '',
          });
          setUniqueId('');
          setIpAddress('');
        } else {
          toast.error(resData.status, {
            className: 'z-toast',
          });
        }
      })
      .catch((err) => {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, { className: 'z-toast' });
      });
  };

  if (currentStep === 18) {
    return (
      <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl shadow-2xl">
        <Thankyou />
      </div>
    );
  }

  return (
    <div className="mtge-rates_modal_wrapper px-4 py-5 bg-white rounded-xl shadow-2xl">
      <header className="flex items-center justify-between">
        <div className="w-14 lg:w-16">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Savage Sales"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-[#6e7c81] text-xs">
            <a href="tel:+8801912345678">(+880) 19123-45678</a>
          </span>
          <span className="text-[#6e7c81] text-xs">
            <a href="mailto:info@savagesales.com">info@savagesales.com</a>
          </span>
        </div>
      </header>
      <ProgressBar currentStep={currentStep} />
      <main className="min-h-[350px] mt-4 overflow-hidden">
        <Swiper
          cssMode={true}
          allowTouchMove={false}
          navigation={{
            nextEl: '.swiper-button-custom-next',
            prevEl: '.swiper-button-custom-prev',
          }}
          modules={[Navigation]}
          className="mySwiper"
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
          <SwiperSlide>
            {' '}
            <StepOne
              mortageRateTitle={mortageRateTitle}
              value={formValues.zipCode}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwo
              value={formValues.loanType}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThree
              value={formValues.propertyType}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFour
              value={formValues.creditScore}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFive
              value={formValues.isFirstTimePurchasing}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSix
              value={formValues.purchaseSituation}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepSeven
              value={formValues.usesOfProperty}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepEight
              value={formValues.purchasePrice}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepNine
              value={formValues.downPayment}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepTen
              value={formValues.typeOfRate}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepEleven
              value={formValues.annualHouseHoldIncome}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepTwelve
              value={formValues.employmentStatus}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepThirdteen
              value={formValues.isBankruptcy}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepFourteen
              value={formValues.hasProofOfIncome}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <StepFifteen
              value={formValues.isWorkingAsRealStateAgent}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepSixteen
              value={formValues.fullName}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepSeventeen
              value={formValues.email}
              onChange={handleChange}
              handleNext={handleNext}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StepEighteen
              value={formValues.phone}
              onChange={handleChange}
              handleFinish={handleFinish}
            />
          </SwiperSlide>
        </Swiper>

        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main>
      {/* <main className="min-h-[350px] mt-4 overflow-hidden">
        {currentStep === 0 ? (
          <StepOne
            value={formValues.zipCode}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 1 ? (
          <StepTwo
            value={formValues.loanType}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 2 ? (
          <StepThree
            value={formValues.propertyType}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 3 ? (
          <StepFour
            value={formValues.creditScore}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 4 ? (
          <StepFive
            value={formValues.isFirstTimePurchasing}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 5 ? (
          <StepSix
            value={formValues.purchaseSituation}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 6 ? (
          <StepSeven
            value={formValues.usesOfProperty}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 7 ? (
          <StepEight
            value={formValues.purchasePrice}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 8 ? (
          <StepNine
            value={formValues.downPayment}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 9 ? (
          <StepTen
            value={formValues.typeOfRate}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 10 ? (
          <StepEleven
            value={formValues.annualHouseHoldIncome}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 11 ? (
          <StepTwelve
            value={formValues.employmentStatus}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 12 ? (
          <StepThirdteen
            value={formValues.isBankruptcy}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 13 ? (
          <StepFourteen
            value={formValues.hasProofOfIncome}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 14 ? (
          <StepFifteen
            value={formValues.isWorkingAsRealStateAgent}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 15 ? (
          <StepSixteen
            value={formValues.fullName}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 16 ? (
          <StepSeventeen
            value={formValues.email}
            onChange={handleChange}
            handleNext={handleNext}
          />
        ) : currentStep === 17 ? (
          <StepEighteen
            value={formValues.phone}
            onChange={handleChange}
            handleFinish={handleFinish}
          />
        ) : (
          ""
        )}

        {currentStep !== 0 && <PrevButton handlePrev={handlePrev} />}
      </main> */}
      <footer className="mt-8 pt-4 flex flex-col items-center space-y-1 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-center text-xs text-[#6e7c81] space-x-2">
          {varbiage.map((dt, idx) => {
            if (dt.text) {
              return (
                <a
                  className="hover:underline"
                  key={idx}
                  target="_blank"
                  href={dt.link}
                >
                  {dt.text}
                </a>
              );
            }
          })}
        </div>
        <span className="text-[#6e7c81] text-xs">Provided by Savage Sales</span>
        <span className="text-[#6e7c81] text-xs">
          Copyright © 2023 SavageSales. All Rights Reserved
        </span>
      </footer>
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep > 0) {
      let totalStep = 18;
      let calculatedPercentage = ((currentStep + 1) / totalStep) * 100;
      setWidth(calculatedPercentage.toFixed(0));
    } else {
      setWidth(0);
    }
  }, [currentStep]);

  return (
    <div className="relative w-full h-[1px] bg-gray-200">
      <div
        className="h-full bg-[var(--color-red)] transition-all duration-750 ease-in-out"
        style={{
          width: width + '%',
        }}
      ></div>
      <div
        className="absolute top-2 px-1 py-1 -mx-4 text-white text-[8px] bg-[var(--color-red)] rounded-sm after:content-[''] after:absolute after:-top-1 after:left-1/2 after:h-0 after:w-0 after:border-l-4 after:border-r-4 after:border-b-4 after:border-l-transparent after:border-r-transparent after:border-b-[var(--color-red)] after:transform after:-translate-x-1/2 transition-all duration-750 ease-in-out"
        style={{
          visibility: width === 0 ? 'hidden' : 'visible',
          left: width + '%',
        }}
      >
        {width}%
      </div>
    </div>
  );
};

const PrevButton = ({ handlePrev }) => {
  return (
    <button
      className="swiper-button-custom-prev absolute top-1/2 left-2 p-1 border-[1px] transform -translate-y-1/2 z-50"
      onClick={handlePrev}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3 h-3 text-[var(--color-red)]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

export default Widget;
