import React from "react";
import { BsFillLightbulbFill, BsWifi } from "react-icons/bs";
import {
  AiOutlineRight,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlineDown,
} from "react-icons/ai";
import { FiMonitor } from "react-icons/fi";
import { Link } from "react-router-dom";
import useContext from "../context/StateProvider";

function Home() {
  const [{ user }] = useContext();

  return (
    <div className="main">
      <div
        className="hero w-full h-auto bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url("/images/hero-banner3_tmfxHjby.jpg")`,
        }}
      >
        <div className="content mx-5 py-14 md:p-14">
          <div className="header">
            <p
              className="font-extrabold text-[#AC1E23] text-[30px] sm:text-6xl
              leading-snug tracking-normal"
            >
              {" "}
              Quickly Launch Your{" "}
            </p>

            <p className="text-[#000] font-normal tracking-normal text-[30px] sm:text-5xl leading-snug">
              Real Estate Business!
            </p>
          </div>
          <div className="description my-5 space-y-7 text-[#000] text-base sm:text-lg leading-tight tracking-normal font-light">
            <p className="md:w-2/4">
              Savage Sales is an easy to use, all-in-one platform that provides
              everything you need to market and grow your real estate business.
            </p>

            <p className="md:w-2/4">
              We've packed 14 services into 1 simple platform even the most
              technologically challenged people can easily navigate.{" "}
            </p>
          </div>

          {!user && (
            <div className="">
              <Link
                to="/register"
                class="px-5 py-3  mt-3 inline-block border border-transparent rounded-3xl text-base leading-6 font-medium  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
              >
                Create Free Account
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* cart option */}
      <div className="services bg-[#f5fafd] pb-16" id="service">
        <h1 className="text-center  pt-24 pb-10 font-normall text-[#47536B] text-[25px] sm:text-[34px] px-10 sm:px-14 md:px-24">
          Skyrocket Your Real Estate Business Using The Most Flipn Powerful
          Investor CRM On The Market!
        </h1>

        <div className="cart md:grid md:grid-cols-3 md:mx-[20px] lg:mx-[80px]">
          <div className="single-car rounded-lg m-5 text-center bg-white p-5">
            <div className="icon flex justify-center  ">
              <BsFillLightbulbFill className="bg-[#F5FAFD] text-[#AC1E23] w-[60px] h-[60px] p-4  text-base rounded-full" />
            </div>
            <h5 className="text-[#000] font-bold text-xl leading-normal my-5">
              A Complete Marketing CRM
            </h5>

            <p className="p-4 font-normal text-[15px] text-[#6B7B9F]">
              Run With Realty at its core is a “complete relationship
              management” software; handling ALL of your relationships and
              interactions with past, present and future buyer and seller
              clients. Easily keep track, see activity and even take notes of
              all of your leads and clients inside of this software.
            </p>
          </div>

          <div className="single-car rounded-lg m-5 text-center bg-white p-5">
            <div className="icon flex justify-center  ">
              <BsWifi className="bg-[#F5FAFD] text-[#AC1E23] w-[60px] h-[60px] p-4 text-base rounded-full" />
            </div>
            <h5 className="text-[#000] font-bold text-xl leading-normal my-5">
              Done For You Lead Capture Funnels
            </h5>

            <p className="p-4 font-normal text-[15px] text-[#6B7B9F]">
              Inside of this software you will find prebuilt “sales funnels”
              that enable you to generate and capture lead data in your local
              area, without the headache or expense of trying to build your own
              funnel and make sure it actually
            </p>
          </div>

          <div className="single-car rounded-lg m-5 text-center bg-white p-5">
            <div className="icon flex justify-center  ">
              <FiMonitor className="bg-[#F5FAFD] text-[#AC1E23] w-[60px] h-[60px] p-4 text-base rounded-full" />
            </div>
            <h5 className="text-[#000] font-bold text-xl leading-normal my-5">
              Recommended facebook & instagram Leads
            </h5>

            <p className="p-4 font-normal text-[15px] text-[#6B7B9F]">
              Sales funnels, websites, marketing pages won’t do you much good if
              you cannot get “eyeballs” on them, right? That’s why we GIVE YOU
              recommend FB & IG Ads you can run in your local area right through
              your own social media accounts to generate leads! We even walk you
              through, with training videos, exactly how to get your ads setup!
            </p>
          </div>
        </div>
      </div>
      {/* funnel */}
      <div
        className="funel md:grid md:grid-cols-2 px-14 py-20 mb-20"
        id="funnel"
      >
        <div className="left-side md:ml-6">
          <h2 className=" text-[#AA2A2A] text-[34px] md:text-[42px] font-medium leading-tight mb-6">
            How Will You Use A Funnel To Grow Your Business?
          </h2>
          <img
            src="/images/funnel-grow_C4P7yhFW.png"
            className="img-fluid object-contain h-[408px] w-full"
          />

          <div className="details text-xl font-light my-5 space-y-3">
            <p>
              Show us what type of client you want, and we'll show you the type
              of funnels you should be using AND we'll GIVE YOU our highest
              converting funnel templates!
            </p>
            <p>
              And much more... We continually release new funnel templates based
              on WHAT'S WORKING NOW in the real marketplace!
            </p>
          </div>
          {!user && (
            <Link to="/register" class="">
              <div className="btn h-[45px] w-[240px] flex justify-center items-center my-3 mt-3  border border-transparent rounded-3xl text-base leading-6 font-medium  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
                START YOUR FREE TRIAL
                <AiOutlineArrowRight className="ml-1 text-lg font-black" />
              </div>
            </Link>
          )}
        </div>

        <div className="right-side md:ml-6">
          <img
            src="/images/funne-gif_0k3Op8Tc.gif"
            className="img-fluid object-contain h-[737px] w-full max-md:mt-8"
          />
        </div>
      </div>

      {/* content */}
      <div className="content md:grid md:grid-cols-2 my-20 py-10 px-14">
        <div className="left">
          <img
            src="/images/ipad-bg_eWtTgmok.png"
            className="img-fluid object-contain h-[400px] w-full"
          />
        </div>

        <div className="right max-md:mt-10">
          <h2 className="text-[#AA2A2A] text-[32px] md:text-[42px] font-medium mb-3 leading-none">
            All-In-One Contact Management Tool
          </h2>
          <p className="mt-3 mb-2 font-light text-lg w-5/6">
            We are confident you've never experienced a real estate CRM program
            like this before... Imagine being able to:
          </p>

          <ul className="space-y-2">
            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">Store Unlimited Leads</p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">Tag and Organize Leads</p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">
                  Collect Important Contact Info
                </p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">
                  Easily Import Contact Lists
                </p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">and so much more!</p>{" "}
              </div>
            </li>
          </ul>
          {!user && (
            <Link to="/register" className="">
              <div className="btn h-[52px] w-[240px] text-[14px] font-black p-4 flex justify-center items-center mt-7  mb-5  border border-transparent rounded-3xl text-base leading-6  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
                <span>START YOUR FREE TRIAL</span>

                <AiOutlineArrowRight className="ml-2 text-[16px] font-black" />
              </div>
            </Link>
          )}
        </div>
      </div>

      {/* pipeline */}
      <div
        className="pipeline md:grid md:grid-cols-2 my-20 py-10 px-14"
        id="pipeline"
      >
        <div className="left md:ml-6">
          <h2 className="text-[#AA2A2A] text-[34px] md:text-[42px] font-medium mb-3 leading-none">
            Close More Deals Using Our Powerful Deal Pipeline
          </h2>
          <p className="mt-3 mb-2 font-light text-lg w-5/6 ">
            We are confident you've never experienced a real estate CRM program
            like this before... Imagine being able to:
          </p>

          <ul className="space-y-2">
            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">
                  Quickly move leads through your pipeline
                </p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">
                  Visualize how many deals are in the works
                </p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">
                  Never let a single deal fall through the cracks
                </p>{" "}
              </div>
            </li>

            <li>
              <div className="flex">
                {" "}
                <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                <p className="text-lg font-normal ">and so much more!</p>{" "}
              </div>
            </li>
          </ul>
          {!user && (
            <Link to="/register">
              <div className="btn h-[52px] w-[240px] text-[14px] font-black p-4 flex justify-center items-center mt-7  mb-5  border border-transparent rounded-3xl text-base leading-6  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
                <span>START YOUR FREE TRIAL</span>

                <AiOutlineArrowRight className="ml-2 text-[16px] font-black" />
              </div>
            </Link>
          )}
        </div>

        <div className="right max-md:mt-10">
          <img
            src="/images/samplecard5_21uoLaiC.png"
            className="img-fluid object-contain h-[400px] w-full"
          />
        </div>
      </div>

      {/* leads into client */}
      <div
        className="content md:grid md:grid-cols-2 my-20 py-10 px-14"
        id="client"
      >
        <div className="left">
          <img
            src="/images/as-chat_f6HiIGdV.png"
            className="img-fluid object-contain h-[400px] w-full"
          />
        </div>

        <div className="right max-md:mt-10">
          <div className="space-y-4">
            <h2 className="text-[#AA2A2A] text-[32px] md:text-[42px] font-medium mb-3 leading-none">
              Convert Leads Into Client{" "}
            </h2>

            <p className="text-lg font-light leading-tight">
              {" "}
              The real power of Savage Sales is in what you are able to
              accomplish after you capture the lead.
            </p>

            <h4 className="text-lg font-bold ">
              EASILY CUSTOMIZE YOUR FOLLOW-UP CAMPAIGNS
            </h4>

            <p className="text-lg font-light leading-tight">
              {" "}
              Our Multi-channel follow up campaigns allow you to automate
              engaging follow ups and capture meaningful responses from your
              leads.
            </p>

            <h4 className="text-lg font-bold ">
              CREATE MULTI-CHANNEL CAMPAIGNS
            </h4>
            <p className="text-lg font-light leading-tight">
              {" "}
              Savage Sales allows you to connect with your leads through Phone
              Connect, Voicemail Drops, SMS/MMS, Emails, and even Facebook
              Messenger
            </p>

            <h4 className="text-lg font-bold ">
              TWO-WAY COMMUNICATION ON ANY DEVICE
            </h4>

            <p className="text-lg font-light leading-tight">
              {" "}
              Our full featured mobile app allows you to communicate with your
              leads on all devices.
            </p>
          </div>
          {!user && (
            <Link to="/register">
              <div className="btn h-[52px] w-[240px] mt-10 text-[14px] font-black p-4 flex justify-center items-center  border border-transparent rounded-3xl text-base leading-6  text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out">
                <span>START YOUR FREE TRIAL</span>

                <AiOutlineArrowRight className="ml-2 text-[16px] font-black" />
              </div>
            </Link>
          )}
        </div>
      </div>

      {/* profile and customer */}

      <div className="full-pc my-20 ">
        <div className="profile md:grid md:grid-cols-3 px-14 py-20 bg-[#AD1E24] text-white">
          <div className="single-profile text-center space-y-6 px-4">
            <div className="img flex justify-center">
              <img
                src="/images/testimonial-11_jP3EJrH1.jpg"
                className="h-[130px] w-[130px] rounded-full"
              />
            </div>

            <p className="details font-normal text-base px-8">
              I just finished my trial period and was so amazed with the support
              and results that I purchased Revo.
            </p>
            <h5 className="name font-bold text-base">Jude Thorn - Designer</h5>
          </div>

          <div className="single-profile text-center space-y-6 px-4 max-md:mt-20">
            <div className="img flex justify-center">
              <img
                src="/images/testimonial-21_gebcDjIo.jpg"
                className="h-[130px] w-[130px] rounded-full"
              />
            </div>

            <p className="details font-normal text-base px-8">
              I don't know how I managed to get work done without Revo. The
              speed of this application is amazing!
            </p>
            <h5 className="name font-bold text-base">Roy Smith - Developer</h5>
          </div>

          <div className="single-profile text-center space-y-6 px-4 max-md:mt-20">
            <div className="img flex justify-center">
              <img
                src="/images/testimonial-31_mxTwZSeB.jpg"
                className="h-[130px] w-[130px] rounded-full"
              />
            </div>

            <p className="details font-normal text-base px-8">
              This app has the potential of becoming a mandatory tool in every
              developer's day to day operations
            </p>
            <h5 className="name font-bold text-base">
              Marsha Singer - Marketer
            </h5>
          </div>
        </div>

        {/* calender and customer */}
        <div className="customer md:grid md:grid-cols-2 px-14 py-20 bg-[#262D35] text-white">
          <div className="left">
            <img
              src="/images/statistics1_WJM5qnZ1.png"
              className="img-fluid object-contain h-[400px] w-full"
            />
          </div>
          <div className="right grid text-center">
            <div
              className="numbers md:items-end 
             md:grid md:grid-cols-3"
            >
              <div className="users my-5">
                <h3 className="text-[45px] font-bold">83</h3>
                <p className="text-lg font-normal">Happy users</p>
              </div>

              <div className="users my-5">
                <h3 className="text-[45px] font-bold">83</h3>
                <p className="text-lg font-normal">Happy users</p>
              </div>
              <div className="users my-5">
                <h3 className="text-[45px] font-bold">83</h3>
                <p className="text-lg font-normal">Happy users</p>
              </div>
            </div>

            <p className="caption mt-4 text-base font-normal">
              Analyse and evaluate your current status and productivity. Monitor
              your tasks and schedule then go ahead and download the app
            </p>
          </div>
        </div>
      </div>

      {/* pricing options */}

      <div className="pricing" id="price">
        <h2 className="text-center text-3xl font-semibold text-[#333333] my-5">
          Pricing Options Table
        </h2>

        <div className="cart-price lg:grid lg:grid-cols-3 py-10 px-5 lg:px-14 max-lg:space-y-10 lg:space-x-10 ">
          <div className="single-price-cart border border-[#CCD3DF] rounded-lg py-12 px-6">
            <div className="type text-center mb-5 space-y-4">
              <h4 className="type text-2xl font-bold text-[#AC1E23]">BASIC</h4>
              <h3 className="flex items-center justify-center price text-[40px] font-medium text-[#333333]">
                <small className="text-lg font-bold">$</small> <span>Free</span>
              </h3>
              <p className="validity text-sm font-light">14 days trial</p>
            </div>

            <div className="border"></div>

            <ul className="mt-16 space-y-3">
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    User Control Management
                  </p>{" "}
                </div>
              </li>
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">Email Marketing Module</p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineClose className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    List Building And Cleaning
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineClose className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">Collected Data Reports</p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineClose className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    Planning And Evaluation
                  </p>{" "}
                </div>
              </li>
            </ul>

            <div className="mt-6 text-center">
              <Link
                to="register"
                class="px-6 py-3 w-[150px] text-sm inline-block border border-transparent  leading-6 font-medium rounded-3xl text-white bg-[#AD1418] hover:bg-white hover:text-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
              >
                SIGN UP
              </Link>
            </div>
          </div>

          <div className="single-price-cart border border-[#CCD3DF] rounded-lg py-12 px-6">
            <div className="type text-center mb-5 space-y-4">
              <h4 className="type text-2xl font-bold text-[#AC1E23]">
                ADVANCED
              </h4>
              <h3 className="flex items-center justify-center price text-[40px] font-medium text-[#333333]">
                <small className="text-lg font-bold">$</small>{" "}
                <span>29.99</span>
              </h3>

              <p className="validity text-sm font-light">monthly</p>
            </div>

            <div className="border"></div>

            <ul className="mt-16 space-y-3">
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">Email Marketing Module</p>{" "}
                </div>
              </li>
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    User Control Management
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    List Building And Cleaning
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineClose className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    Collected Data Reports{" "}
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineClose className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    Planning And Evaluation
                  </p>{" "}
                </div>
              </li>
            </ul>

            <div className="mt-6 text-center">
              <Link
                to="/register"
                class="px-6 py-3 w-[150px] text-sm inline-block border border-transparent  leading-6 font-medium rounded-3xl text-white bg-[#AD1418] hover:bg-white hover:text-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
              >
                SIGN UP
              </Link>
            </div>
          </div>

          <div className="single-price-cart border border-[#CCD3DF] rounded-lg py-12 px-6">
            <div className="type text-center mb-5 space-y-4">
              <h4 className="type text-2xl font-bold text-[#AC1E23]">
                COMPLETE
              </h4>
              <h3 className="flex items-center justify-center price text-[40px] font-medium text-[#333333]">
                <small className="text-lg font-bold">$</small>{" "}
                <span>39.99</span>
              </h3>

              <p className="validity text-sm font-light">Monthly</p>
            </div>

            <div className="border"></div>

            <ul className="mt-16 space-y-3">
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">Email Marketing Module</p>{" "}
                </div>
              </li>
              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    User Control Management
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    List Building And Cleaning
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">Collected Data Reports</p>{" "}
                </div>
              </li>

              <li>
                <div className="flex max-md:pl-[20px]">
                  {" "}
                  <AiOutlineRight className="mt-2 mr-1 text-[#AA2A2A] font-bold text-lg" />
                  <p className="text-lg font-normal ">
                    Planning And Evaluation
                  </p>{" "}
                </div>
              </li>
            </ul>

            <div className="mt-6 text-center">
              <Link
                to="/register"
                class="px-6 py-3 w-[150px] text-sm inline-block border border-transparent  leading-6 font-medium rounded-3xl text-white bg-[#AD1418] hover:bg-white hover:text-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
              >
                SIGN UP
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}

      <div className="FAQ bg-[#F6FBFD] py-10 mt-20" id="faq">
        <h2 className="text-center text-4xl font-semibold text-[#AC1E23] my-5">
          FAQs
        </h2>

        <div className="faq-sec md:grid md:grid-cols-2 py-10 px-5 md:px-24 max-md:space-y-10">
          <div className="single-faq md:mx-5 py-7 border-b border-[#8A8B8C]">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                Guest reception, morning coffee
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              What better way to start the day and to enjoy an event than eating
              delicious cookies and drinking italian coffe?
            </p>
          </div>

          <div className="single-faq md:mx-5 py-7 border-b border-[#8A8B8C]">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                How to jump start your great online project
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              Kathy Blake which is now in the fifth year of managing the largest
              online retailer will deliver an awesome keynote
            </p>
          </div>

          <div className="single-faq md:mx-5 py-7 border-b border-[#8A8B8C]">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                The perfect team for developing apps
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              Sam Rowling will help you understand what it takes to build a
              great mobile apps development team from scratch
            </p>
          </div>

          <div className="single-faq md:mx-5 py-7 border-b border-[#8A8B8C]">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                Lunch, coffee and networking for everyone
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              What better way to start the day and to enjoy an event than eating
              delicious cookies and drinking italian coffe?
            </p>
          </div>

          <div className="single-faq md:mx-5 py-7 max-md:border-b border-[#8A8B8C]">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                Hybrid or native which is better for marketing
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              Kathy Blake which is now in the fifth year of managing the largest
              online retailer will deliver an awesome keynote
            </p>
          </div>

          <div className="single-faq md:mx-5 py-7 ">
            <div className="header-icon flex justify-between">
              <h6 className="text-lg font-bold my-4">
                {" "}
                How is UI/UX affecting online marketing efforts
              </h6>
              <AiOutlineDown className="text-[#AC1E23] text-2xl font-extrabold mt-5" />
            </div>
            <p className="des text-base font-normal text-[#666666] w-4/5">
              Sam Rowling will help you understand what it takes to build a
              great mobile apps development team from scratch
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
