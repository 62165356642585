import axios from './axios';

const services = {
  createTemplate: async (name, authToken) => {
    try {
      const response = await axios.post(
        '/api/personal_site/templates/admin/create-template',
        { name },
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
          },
        }
      );

      return response.data;
    } catch (err) {
      console.error('Error creating template:', err.response.data);
      throw err;
    }
  },

  templateList: async () => {
    try {
      const response = await axios.get(
        `/api/personal_site/templates/admin/templates`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  //   renderPage: async (userId) => {
  //     try {
  //       const response = await axios.get(`/api/personal_site/${userId}`);
  //       return response.data;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },

  saveTemplate: async (pageId, html, css) => {
    try {
      const response = await axios.put(
        `/api/personal_site/templates/admin/save-page/${pageId}`,
        { html, css },
        { headers: { 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  getPage: async (pageId) => {
    try {
      const response = await axios.get(
        `/api/personal_site/templates/admin/template/${pageId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteTemplate: async (pageId) => {
    try {
      const response = await axios.delete(
        `/api/personal_site/templates/${pageId}`
      );
      return response.data;
    } catch (err) {
      console.log(err.message);
    }
  },

  loadContent: async (pageId) => {
    try {
      const response = await axios.get(
        `/api/personal_site/templates/content/${pageId}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  //ads manager

  getAllAds: async () => {
    try {
      const response = await axios.get('/api/ads-manager/ads/all');

      return response.data;
    } catch (err) {
      console.error('Error fetching ads:', err.response.data);
      throw err;
    }
  },

  getAd: async (id) => {
    try {
      const response = await axios.get(`/api/ads-manager/ads/${id}`);

      return response.data;
    } catch (err) {
      console.error('Error getting ad:', err.response.data);
      throw err;
    }
  },

  getActivatedAd: async () => {
    try {
      const response = await axios.get(`/api/ads-manager/ads/activated/ad`);

      return response.data;
    } catch (err) {
      console.error('Error getting ad:', err.response.data);
      throw err;
    }
  },

  addAds: async (name, authToken) => {
    try {
      const response = await axios.post(
        '/api/ads-manager/ads/new-add',
        { name },
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
          },
        }
      );

      return response.data;
    } catch (err) {
      console.log(err);
      console.error('Error creating ads:', err.response.data);
      throw err;
    }
  },

  // adCreate: async (id, data) => {
  //   try {
  //     const response = await axios.put(
  //       `/api/ads-manager/ads/create-ad/${id}`,
  //       data
  //     );

  //     return response.data;
  //   } catch (err) {
  //     console.error('Error creating ad:', err.response.data);
  //     throw err;
  //   }
  // },

  adUpdate: async (id, data) => {
    try {
      const response = await axios.put(`/api/ads-manager/ads/${id}`, data);

      return response.data;
    } catch (err) {
      console.error('Error updating ad:', err.response.data);
      throw err;
    }
  },
  adActivate: async (id, data) => {
    try {
      const response = await axios.put(
        `/api/ads-manager/ads/${id}/activate`,
        data
      );

      return response.data;
    } catch (err) {
      console.error('Error updating ad:', err.response.data);
      throw err;
    }
  },

  updateAdPriority: async (adId, priority) => {
    try {
      const response = await axios.put(
        `/api/ads-manager/ads/${adId}/priority`,
        {
          priority,
        }
      );

      if (response.status === 200) {
        if (response.data.success) {
          // Priority update successful
          return response.data;
        } else {
          // Handle server-reported error
          console.error('Server reported an error:', response.data.error);
          return { error: response.data.error };
        }
      } else {
        // Handle non-200 status code (e.g., 400 Bad Request)
        console.error('Failed to update ad priority. Status:', response.status);
        return { error: 'Failed to update ad priority.' };
      }
    } catch (error) {
      // Handle network or other client-side errors
      console.error('Error updating ad priority:', error);
      return { error: 'An error occurred while updating ad priority.' };
    }
  },

  getPriorities: async () => {
    try {
      const response = await axios.get('/api/ads-manager/priorities');
      if (response.status === 200) {
        return response.data.priorities;
      }
      return [];
    } catch (error) {
      console.error('Error fetching priorities:', error);
      return [];
    }
  },

  uploadImage: async (formData) => {
    try {
      const response = await axios.post('/api/ads-manager/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (err) {
      console.error('Error uploading image:', err.response.data);
      throw err;
    }
  },

  uploadMedia: async (formData) => {
    try {
      const response = await axios.post('/api/ads-manager/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (err) {
      console.error('Error uploading image:', err.response.data);
      throw err;
    }
  },

  deleteAd: async (id) => {
    try {
      const response = await axios.delete(`/api/ads-manager/ads/${id}`);

      return response.data;
    } catch (err) {
      console.error('Error deleting ad:', err.response.data);
      throw err;
    }
  },
};

export default services;
