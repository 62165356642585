import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';
import Preview from '../Dashboard/Preview';
import Loader from '../Loader/Loader';

function StandAlone() {
  const params = useParams();

  const [{ currentPlugin }, dispatch] = useContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";

    axios({
      url: '/api/plugin/standalone/' + params.pluginToken,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          console.log('calccc', resData.data);
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
            isOwnPlugin: false,
          });
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setError(true);
      });

    return () => {
      document.body.style.backgroundImage = '';
      dispatch({
        type: ActionTypes.SET_CURRENT_PLUGIN,
        payload: null,
        isOwnPlugin: false,
      });
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!currentPlugin) return <></>;

  return (
    <div>
      <header className="py-6 px-4">
        <div className="mx-auto w-14 md:w-24">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
        </div>
      </header>

      <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
        <Preview
          pluginLogo={currentPlugin.logo.value}
          leadName={currentPlugin.leadName}
          leadLink={currentPlugin.leadLink}
          behaviour=""
          backgroundColor={currentPlugin.colors.backgroundColor}
          titleColor={currentPlugin.colors.titleColor}
          pluginTitle={currentPlugin.title}
          textColor={currentPlugin.colors.textColor}
          buttonColor={currentPlugin.colors.buttonColor}
          buttonTextColor={currentPlugin.colors.buttonTextColor}
          varbiage={currentPlugin.varbiage}
          homeSearchPage={currentPlugin.homeSearchPage}
          calenderBookingPage={currentPlugin.calenderBookingPage}
          showEmailPaymentDetails={
            currentPlugin.leadButtons.emailPaymentDetails
          }
          showViewProperties={currentPlugin.leadButtons.viewProperties}
          showPreQualified={currentPlugin.leadButtons.preQualified}
          showCheckHomeValues={currentPlugin.leadButtons.checkHomeValues}
        />
      </div>
    </div>
  );
}

export default StandAlone;
