import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";

import useSocket from "../../context/Socket";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import axios from "axios";
import useContext from "../../context/StateProvider";
import { useNavigate, useParams } from "react-router-dom";

function Notification() {
  const params = useParams();
  const navigate = useNavigate();
  const [{ user }] = useContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const socket = useSocket();

  const [notis, setNotis] = useState([]);
  const [isNew, setIsNew] = useState(false);
  useEffect(() => {
    axios({
      url: "/api/users/" + user._id + "/notifications",
      method: "get",
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          setNotis(resData.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    socket.on("notification", sd);

    return () => {
      socket.off("notification", sd);
    };
  }, []);

  function sd(data) {
    setIsNew(true);
    setNotis((prev) => [data, ...prev]);
  }

  const handleMenu = (event) => {
    setIsNew(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (h) => {
    handleClose();
    navigate("/dashboard/" + params.profileId + h);
  };
  return (
    <div className="relative">
      {isNew && (
        <div className="absolute top-1 right-1 w-3 h-3 bg-red-500 rounded-full z-50"></div>
      )}
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <NotificationsActiveIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notis.length > 0 ? (
          <div>
            {notis.map((dt, idx) => (
              <MenuItem onClick={() => handleClick(dt.href)} key={idx}>
                {dt.title}
              </MenuItem>
            ))}
          </div>
        ) : (
          <div className="px-4 py-2 h-64 flex items-center justify-center">
            {" "}
            No notifications yet!{" "}
          </div>
        )}
      </Menu>
    </div>
  );
}

export default Notification;
