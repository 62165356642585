import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import useContext from '../../context/StateProvider';
import ActionTypes from '../../utils/actionTypes';

import FlexiFrameWidget from './../FlexiFrame/FlexiFrameWidget';
import Loader from '../Loader/Loader';
import FlexiFrameButton from '../FlexiFrame/FlexiFrameButton';

function StandAloneFlexiFrame() {
  const params = useParams();
  const [{ currentPlugin }, dispatch] = useContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showWidget, setShowWidget] = useState(true);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/images/1644964579788_V.png')";
    axios({
      url: '/api/flexiframe/standalone/' + params.pluginToken,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const resData = res.data;
        setIsLoading(false);
        if (resData.success) {
          console.log('flexiframe', resData.data);
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
            isOwnPlugin: false,
          });
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setError(true);
      });

    return () => {
      document.body.style.backgroundImage = '';
      dispatch({
        type: ActionTypes.SET_CURRENT_PLUGIN,
        payload: null,
        isOwnPlugin: false,
      });
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <div>
        <header className="py-6 px-4">
          <div className="mx-auto w-14 md:w-24">
            <img
              className="w-full object-contain"
              src="/images/logo.png"
              alt="Logo"
            />
          </div>
        </header>

        <div className="max-w-full md:max-w-2xl mx-auto my-14 px-4 md:px-8">
          <h3 className="text-xl md:text-3xl font-bold text-center text-gray-800">
            {' '}
            You have entered a broken link!{' '}
          </h3>
        </div>
      </div>
    );
  } else if (!currentPlugin) return <></>;
  const handleShow = () => {
    setShowWidget((prev) => !prev);
  };
  return (
    <>
      <header className="py-6 px-4">
        <div className="mx-auto w-14 md:w-24">
          <img
            className="w-full object-contain"
            src="/images/logo.png"
            alt="Logo"
          />
        </div>
      </header>
      <div className="min-h-screen flex items-center justify-center">
        <FlexiFrameWidget
          webPageLink={currentPlugin.webLink}
          behaviour={currentPlugin.behaviour}
          mediaType={currentPlugin.mediaType}
          show={currentPlugin.state}
          showWidget={showWidget}
          handleShow={handleShow}
        />
        <FlexiFrameButton
          handleShow={handleShow}
          show={showWidget}
          minimizeButton={currentPlugin.button}
        />
      </div>
    </>
  );
}

export default StandAloneFlexiFrame;
