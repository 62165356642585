import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ModalComponent from './ModalComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import customFunnelService from '../../../services/customFunnel.service';
import useContext from '../../../context/StateProvider';
import StepForm from './StepForm';
import EditFunnel from './EditFunnel';
import CustomFunnelWidget from './../../Custom_funnel/CustomFunnel';

const CustomFunnel = () => {
  const [{ user }] = useContext();
  const { profileId, funnelId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [stepIndexForQuestion, setStepIndexForQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [name, setName] = useState('');
  const [pluginToken, setPluginToken] = useState('');
  const [varbiage, setVarbiage] = useState(null);
  const [requiredField, setRequiredField] = useState(false);

  const handleOpenModal = (index) => {
    setStepIndexForQuestion(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const addStep = () => {
    const stepName = prompt('Enter name of the step:');
    if (stepName) {
      const step = {
        name: stepName,
        questions: [],
      };
      setSteps([...steps, step]);
    }
  };

  const addQuestion = (stepIndex) => {
    handleOpenModal(stepIndex);
  };
  const handleQuestionSave = () => {
    const updatedSteps = [...steps];
    const question = {
      text: newQuestion,
      requiredField: requiredField,
      options: options.map((option) => ({ option })),
    };

    updatedSteps[stepIndexForQuestion].questions.push(question);
    // updatedSteps[stepIndexForQuestion].requiredStep = requiredField;

    setSteps(updatedSteps);
    setNewQuestion('');
    setOptions([]); // Ensure options are cleared after saving
    handleCloseModal();
    setRequiredField(false);
  };

  const handleGetFunnelData = async () => {
    setLoading(true);
    try {
      const response = await customFunnelService.getFunnelById(
        funnelId,
        user.token
      );

      if (response.success) {
        setLoading(false);
        const funnelData = response.data;
        setName(funnelData.name);
        setSteps(funnelData.steps);
        setPluginToken(funnelData.token);
        setVarbiage(funnelData.varbiage);
        console.log('Custom Funnel Data: ', funnelData.steps);
        console.log('token Funnel Data: ', funnelData.token);
      }
    } catch (error) {
      setLoading(false);
      console.log('Funnel Data not retriving successfully!');
    }
  };

  useEffect(() => {
    handleGetFunnelData();
    console.log(steps);
  }, []);

  const handleSaveSteps = async () => {
    try {
      const response = await customFunnelService.saveSteps(
        funnelId,
        steps,
        user.token
      );

      if (response.success) {
        toast.success('Steps, questions, and options saved successfully!');
      } else {
        toast.error(
          'Failed to save steps, questions, and options:',
          response.status
        );
      }
    } catch (error) {
      toast.error('Error saving steps, questions, and options:', error);
    }
  };

  const addOption = () => {
    if (options.length < 4) {
      setOptions((prevOptions) => [...prevOptions, '']); // Initially add an empty option
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const deleteStep = (stepIndex) => {
    const updatedSteps = [...steps];
    updatedSteps.splice(stepIndex, 1);
    setSteps(updatedSteps);
  };

  const deleteQuestion = (stepIndex, questionIndex) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].questions.splice(questionIndex, 1);
    setSteps(updatedSteps);
  };

  const deleteOption = (stepIndex, questionIndex, optionIndex) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].questions[questionIndex].options.splice(
      optionIndex,
      1
    );
    setSteps(updatedSteps);
  };

  const handleDeleteFunnel = async () => {
    try {
      const response = await customFunnelService.deleteFunnel(
        funnelId,
        user.token
      );

      if (response.success) {
        toast.success('Funnel deleted successfully!');
        navigate(`/dashboard/${profileId}/custom-funnel/`);
      } else {
        toast.error('Failed to delete the funnel:', response.status);
      }
    } catch (error) {
      console.log(error);
      toast.error('Error deleting the funnel:', error);
    }
  };

  return (
    <div className="px-6 py-8">
      <div>
        <h1 className="text-2xl font-semibold mb-2 text-center">{name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-12 space-y-4 md:space-y-0 md:space-x-6">
        <div className="md:mr-6">
          <EditFunnel
            steps={steps}
            deleteStep={deleteStep}
            deleteQuestion={deleteQuestion}
            deleteOption={deleteOption}
            addQuestion={addQuestion}
            addStep={addStep}
            handleSaveSteps={handleSaveSteps}
            handleDeleteFunnel={handleDeleteFunnel}
          />
          <ModalComponent openModal={openModal} onClose={handleCloseModal}>
            <StepForm
              value={newQuestion}
              setNewQuestion={setNewQuestion}
              setRequiredField={setRequiredField}
              options={options}
              handleOptionChange={handleOptionChange}
              addOption={addOption}
              handleQuestionSave={handleQuestionSave}
            />
          </ModalComponent>
        </div>

        <div>
          {loading ? (
            <h2>Loading...</h2>
          ) : (
            <CustomFunnelWidget
              stepsData={steps}
              pluginToken={pluginToken}
              varbiage={varbiage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomFunnel;
