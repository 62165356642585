import React from "react";
// import { GiBreakingChain } from "react-icons/gi";
import { MdDelete, MdModeEdit } from "react-icons/md";

const CTAButtons = ({ reactIcon, title, desc, handleDel, handleOpen }) => {
  return (
    <>
      <div className="relative group">
        <div className="flex items-center gap-1 mt-3 cursor-pointer">
          <div className="w-10 h-10 rounded-xl bg-color6 flex items-center justify-center">
            {reactIcon}
          </div>
          <div className="">
            <h3 className="text-color2 font-semibold">{title}</h3>
            <span className="text-color2 text-xs">{desc}</span>
          </div>
        </div>

        <div className="absolute top-0 right-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible">
          <button
            className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
            onClick={handleOpen}
          >
            {/* Edit Icon */}
            <MdModeEdit className="text-lg" />
          </button>
          <button
            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 ml-2"
            onClick={handleDel}
          >
            {/* Delete Icon */}
            <MdDelete className="text-lg" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CTAButtons;
