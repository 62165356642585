import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useDetectAdBlock } from '@renfei/adblock-detect-react';
import AdblockerDetect from './AdBlcoker';

const ShowAdModal = ({ adsData, loading, onClose, warningClose }) => {
  const adBlockDetected = useDetectAdBlock();

  const [showCrossButton, setShowCrossButton] = useState(false);
  const videoRef = useRef(null);
  const [countdown, setCountdown] = useState(5);
  const [innerLoading, setInnerLoading] = useState(true);

  const [currentAd, setCurrentAd] = useState(null);
  const [resourcesBlocked, setResourcesBlocked] = useState(false);
  const [detectAdBlocker, setDetectAdBlocker] = useState(false);
  const [randomIndex, setRandomIndex] = useState(null);

  const currentAdRef = useRef(currentAd);
  currentAdRef.current = currentAd;

  // console.log('data of ads', adsData);

  useEffect(() => {
    // const isBraveBrowser = navigator.brave && navigator.brave.isBrave();

    if (adBlockDetected) {
      setInnerLoading(false);
      setDetectAdBlocker(true);
      console.log('adBlockDetected: ', adBlockDetected);
    }
  }, [adBlockDetected, resourcesBlocked, detectAdBlocker]);

  useEffect(() => {
    // Generate the random index only when it's not set
    if (randomIndex === null) {
      const newIndex = Math.floor(Math.random() * adsData.media.length);
      setRandomIndex(newIndex);
      setCurrentAd(adsData.media[newIndex]);
      console.log('randomeIndex new: ', newIndex);
      console.log('randomeIndex: ', randomIndex);
    }
  }, [adsData, randomIndex]);

  useEffect(() => {
    setInnerLoading(true);
    console.log('current ad: ', currentAd);
    console.log('current ad ref : ', currentAdRef.current);
    if (currentAdRef.current === null) return;

    // Show the cross button after 5 seconds for image ads
    if (currentAdRef.current.type !== 'video') {
      console.log('Not vid ad');
      // setInnerLoading(false);

      const image = new Image();
      image.src = currentAdRef.current.filename;
      image.onload = () => {
        setInnerLoading(false); // Set loading to false when the image is loaded
      };

      image.onerror = () => {
        setInnerLoading(false);
        setResourcesBlocked(true);
        // console.log('image error resource blocked');
      };

      if (!innerLoading && !loading) {
        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) =>
            prevCountdown <= 0 ? 0 : prevCountdown - 1
          );
        }, 1000);
        const timeout = setTimeout(() => {
          setShowCrossButton(true);
        }, 5000);

        return () => {
          clearTimeout(timeout);
          clearInterval(countdownInterval);
        };
      }
    } else {
      // For video ads, set up event listeners to show the cross button
      const videoElement = videoRef.current;
      // console.log(videoElement.duration);
      console.log('vid ', videoElement, currentAdRef.current);
      if (videoElement && currentAdRef.current.type === 'video') {
        console.log('vid');
        videoElement.addEventListener('loadedmetadata', () => {
          const videoDuration = videoElement.duration;

          // Start a countdown timer for video ads
          const countdownInterval = setInterval(() => {
            const currentTime = videoElement.currentTime;
            const remainingTime = Math.max(0, videoDuration - currentTime);
            setCountdown(Math.round(remainingTime));
          }, 1000);
          return () => {
            clearInterval(countdownInterval);
          };
        });

        videoElement.onerror = () => {
          setInnerLoading(false);
          setResourcesBlocked(true);
          // console.log('video error resource blocked');
        };
      }
    }
  }, [currentAd, innerLoading, loading]);

  const handleCloseModal = () => {
    onClose();
    setCurrentAd(null);
  };

  const handleVidEnded = (e) => {
    console.log('ended');
    setShowCrossButton(true);
  };

  if (currentAdRef.current === null) return;

  return (
    <>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-[99]">
        {detectAdBlocker || resourcesBlocked ? (
          <AdblockerDetect warningClose={warningClose} />
        ) : !detectAdBlocker && !resourcesBlocked ? (
          <>
            {currentAdRef.current.type === 'video' ? (
              <div className="absolute bg-white p-4 bottom-0 rounded-lg shadow-lg w-[100%] sm:w-[100%] h-auto flex flex-col items-center justify-center slide-in">
                <div className="absolute top-0 left-0 bg-[#1E1E1E] rounded-full py-1 px-3 m-4">
                  <p className="text-sm text-white font-semibold">
                    Sponsored by{' '}
                    <span className="text-red-600">Savage Sales</span>
                  </p>
                </div>
                <a
                  href={currentAdRef.current.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                >
                  <video
                    ref={videoRef}
                    src={currentAdRef.current.filename}
                    controls={false}
                    autoPlay
                    className="w-[100%] h-[100%] object-cover rounded-md"
                    style={{ objectFit: 'contain' }}
                    onEnded={handleVidEnded}
                  />
                </a>

                {showCrossButton ? (
                  <button
                    onClick={handleCloseModal}
                    className="absolute top-2 right-2 py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
                  >
                    Close
                  </button>
                ) : (
                  <div className="absolute top-2 right-2 py-2 px-4 bg-[#2268ff] text-white rounded-lg">
                    {countdown} s
                  </div>
                )}
              </div>
            ) : (
              <>
                {innerLoading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div className="bg-white p-4 rounded-lg shadow-lg absolute flex flex-col items-center justify-center slide-in bottom-0">
                    <div className="absolute top-0 left-0 bg-[#1E1E1E] rounded-full py-1 px-3 m-4">
                      <p className="text-sm text-white font-semibold">
                        Sponsored by{' '}
                        <span className="text-red-600">Savage Sales</span>
                      </p>
                    </div>
                    <a
                      href={currentAdRef.current.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      <img
                        rel="preload"
                        src={currentAdRef.current.filename}
                        alt={currentAdRef.current.name}
                        className="w-[100%] h-auto object-cover rounded-md"
                        style={{ maxHeight: '75vh' }} // Limit the image height to 75% of the viewport height
                      />
                    </a>

                    {showCrossButton ? (
                      <button
                        onClick={handleCloseModal}
                        className="absolute top-2 right-2 py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
                      >
                        Close
                      </button>
                    ) : (
                      <div className="absolute top-2 right-2 py-2 px-4 bg-[#2268ff] text-white rounded-lg">
                        {countdown} s
                      </div>
                    )}
                  </div>
                )}
              </>
            )}{' '}
          </>
        ) : (
          ' '
        )}
      </div>
    </>
  );
};

export default ShowAdModal;
