import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// mui components & icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';

import adminService from '../../../services/adminBulder.service';
import useContext from './../../../context/StateProvider';
import { toast } from 'react-toastify';

const AdsCards = () => {
  const [{ user }] = useContext();

  const [name, setName] = useState('');
  const [adsList, setAdsList] = useState([]);
  const [isActivated, setIsActivated] = useState({});
  const [selectedPriorities, setSelectedPriorities] = useState({});

  const priorityOptions = [...Array(11).keys()].map((value) => value * 10);

  useEffect(() => {
    loadAllAds();
  }, []);

  const loadAllAds = useCallback(async () => {
    try {
      const response = await adminService.getAllAds();
      setAdsList(response.data);
    } catch (error) {
      console.error('Error loading templates:', error);
    }
  }, []);

  const handleSaveAd = async () => {
    if (!name) {
      toast.error('Please provide a name for the ad.');
      return;
    }
    try {
      const response = await adminService.addAds(name, user.token);
      toast.success('Ad saved successfully!');
      loadAllAds();
      setName('');
    } catch (error) {
      console.error('Error saving Ad:', error);
    }
  };

  const handlePrioritySelect = async (adId, priority) => {
    try {
      const response = await adminService.updateAdPriority(adId, priority);

      if (response && response.success) {
        // Priority update was successful
        console.log('priority submit: ', response);
        toast.success(response.status);
        // Update the selectedPriorities state
        setSelectedPriorities((prevSelectedPriorities) => ({
          ...prevSelectedPriorities,
          [adId]: priority,
        }));
      } else if (response && response.error) {
        // Handle error response
        toast.error(`Failed to update priority: ${response.error}`);
      }
    } catch (error) {
      console.error('Error updating priority:', error);
      toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    const fetchPriorities = async () => {
      try {
        const response = await adminService.getPriorities();
        const prioritiesData = response;

        // Transform the priorities data into an object with adIds as keys
        const newSelectedPriorities = {};
        prioritiesData.forEach((priorityObj) => {
          newSelectedPriorities[priorityObj._id] = priorityObj.priority;
        });
        setSelectedPriorities(newSelectedPriorities);
      } catch (error) {
        console.error('Error fetching priorities:', error);
      }
    };

    fetchPriorities();
  }, []);

  useEffect(() => {
    const initialIsActivatedState = {};
    adsList.forEach((ad) => {
      initialIsActivatedState[ad._id] = ad.activate;
    });
    setIsActivated(initialIsActivatedState);
  }, [adsList]);

  const handleToggleActivation = async (id, currentActivationState) => {
    try {
      const response = await adminService.adActivate(id, {
        activate: !currentActivationState,
      });

      setIsActivated({
        ...isActivated,
        [id]: response.data.activate,
      });

      toast.success('Ad activation status updated successfully!');
    } catch (error) {
      console.error('Error updating Ad activation status:', error);

      toast.error('You cannot activate more than one Ad.');
    }
  };

  const handleDeleteAd = async (id) => {
    const response = await toast.promise(adminService.deleteAd(id), {
      pending: 'Please wait. The ad is deleting...',
      success: 'Ad delete successfully',
      error: 'An error occurred while deleting the ad.',
    });
    loadAllAds();
  };
  console.log(adsList);

  return (
    <div className="p-4">
      <div className="mb-4 flex space-x-2">
        <input
          required
          type="text"
          className="border border-gray-300 rounded-lg px-4 py-2 flex-grow focus:outline-none focus:border-blue-500"
          placeholder="Enter Ad name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
          onClick={handleSaveAd}
        >
          Create New Ad
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {adsList && adsList.length === 0 ? (
          <h2 className="text-xl font-semibold">Loading...</h2>
        ) : (
          adsList?.map((ad) => (
            <div
              key={ad._id}
              className="border border-gray-300 rounded-lg p-4 mb-4 flex flex-col justify-between"
            >
              <div>
                <h3 className="text-xl font-semibold mb-2">{ad.name}</h3>
                <p className="text-gray-600">Created By: {ad.createdBy}</p>
              </div>
              <div className="mt-4 flex space-x-2">
                <button className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 focus:outline-none">
                  <Link to={`ads-manager/edit/${ad._id}`}>
                    <EditIcon className="w-5 h-5" />
                  </Link>
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 focus:outline-none"
                  onClick={() => handleDeleteAd(ad._id)}
                >
                  <DeleteIcon />
                </button>
                <label className="flex items-center">
                  <Switch
                    checked={isActivated[ad._id] || false}
                    onChange={() =>
                      handleToggleActivation(ad._id, isActivated[ad._id])
                    }
                  />
                  <span className="ml-2">Activate</span>
                </label>

                <div className="flex items-center">
                  <select
                    value={selectedPriorities[ad._id] || 0} // Default to 0 if no priority is selected
                    onChange={(e) =>
                      handlePrioritySelect(ad._id, parseInt(e.target.value))
                    }
                  >
                    {priorityOptions.map((option) => (
                      <option key={option} value={option}>
                        {`${option}%`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AdsCards;
