import React, { useEffect, useState } from 'react';
import useContext from '../../context/StateProvider';
import { toast } from 'react-toastify';
import ActionTypes from '../../utils/actionTypes';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
// components
import Header from './Header';
import Loader from '../Loader/Loader';
import BackButton from '../BackButton';

function Dashboard({ children }) {
  const [{ user, allProfiles }, dispatch] = useContext();
  const { profileId } = useParams();
  const navigate = useNavigate();

  const sideabarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [isExpanded, setIsExpanded] = useState(
    sideabarCollapsed ? false : true
  );
  const [showExpandIcon, setShowExpandIcon] = useState(true);

  // Local storage key for sidebar state
  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowExpandIcon(false);
      localStorage.removeItem('sidebar-collapsed');
    }
  }, []);

  const handleSwitchExpand = () => {
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem('sidebar-collapsed', true);
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem('sidebar-collapsed');
  };

  useEffect(() => {
    if (!user) navigate('/');
    else if (!profileId) navigate('/dashboard/profiles');

    allProfiles.map((dt) => {
      if (dt._id === profileId) {
        console.log('das');
        dispatch({
          type: ActionTypes.SET_IS_OWN_PROFILE,
          payload: dt.owner === user._id,
        });
      }
    });
  }, [profileId, allProfiles]);

  if (allProfiles.length === 0) {
    return <Loader />;
  }

  return (
    <div className="pt-[56px] md:pt-[72px]">
      <Header
        handleSwitchExpand={handleSwitchExpand}
        isExpanded={isExpanded}
        showExpandIcon={showExpandIcon}
      />
      <div
        className={`transition-all duration-400 ${
          isExpanded ? 'md:pl-[300px]' : 'md:pl-[100px]'
        }`}
      >
        <BackButton />

        {children}
      </div>
    </div>
  );
}

export default Dashboard;
