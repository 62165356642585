import axios from "axios";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import useContext from "../../context/StateProvider";
import ActionTypes from "../../utils/actionTypes";
import config from "../../utils/config";

import ColorPicker from "react-best-gradient-color-picker";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import ApiModal from "../common/APIModal";
import Preview from "./Preview";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function Plugin() {
  const [{ user, allPlugins, currentPlugin, isOwnPlugin }, dispatch] =
    useContext();

  const { pluginId, profileId } = useParams();
  const navigate = useNavigate();
  console.log("pluginId: ", pluginId);

  const [emailTemplate, setEmailTemplate] = useState("");
  const [pluginLogo, setPluginLogo] = useState("/images/logo.png");

  const [pluginName, setPluginName] = useState("");
  const [pluginTitle, setPluginTitle] = useState("");
  const [pluginToken, setPluginToken] = useState("");
  const [leadName, setLeadName] = useState("View properties");
  const [leadLink, setLeadLink] = useState("");
  const [behaviour, setBehaviour] = useState("withMinimize");
  const [state, setState] = useState(true);

  const [showEmailPaymentDetails, setShowEmailPaymentDetails] = useState(true);
  const [showViewProperties, setShowViewProperties] = useState(true);
  const [showPreQualified, setShowPreQualified] = useState(true);
  const [showCheckHomeValues, setShowCheckHomeValues] = useState(true);

  const [homeSearchPage, setHomeSearchPage] = useState("");
  const [calenderBookingPage, setCalenderBookingPage] = useState("");
  const [varbiage, setVarbiage] = useState({});
  const [varbiage1, setVarbiage1] = useState({
    text: "Varbiage1",
    link: "Virvas2",
  });
  const [varbiage2, setVarbiage2] = useState({
    text: "Varbiage1",
    link: "Virvas2",
  });

  const [backgroundColor, setBackgroundColor] = useState(
    "linear-gradient(37deg, rgba(172,30,35,1) 5%, rgba(39,45,52,1) 74%)",
  );
  const [titleColor, setTitleColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("#e9e2f1");
  const [buttonColor, setButtonColor] = useState("#ffffff");
  const [buttonTextColor, setButtonTextColor] = useState("#000000");

  const [domainNo, setDomainNo] = useState(1);
  const [focusNextInput, setFocusNextInput] = useState(false);
  const [domains, domainsDispatch] = useReducer((state, action) => {
    const { payload, type } = action;

    if (type === "add") {
      state[payload.pos] = payload.value;
    } else if (type === "del") {
      let tmpt = state.filter((t, idx) => idx !== payload.pos);
      state = tmpt;
    } else if ("initial") {
      setDomainNo(payload.total + 1);
      state = payload.value;
    }
    return state;
  }, []);

  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleAd, setVisibleAd] = useState(false);

  // refs
  const [backgroundColorPicker, setBackgroundColorPicker] = useState(false);
  const titleColorPicker = useRef(null);
  const textColorPicker = useRef(null);
  const buttonColorPicker = useRef(null);
  const buttonTextColorPicker = useRef(null);

  const [openApiModal, setOpenApiModal] = useState(false);

  const getApi = `${config.baseUrl}/api/funnels/v1/calculator/get/${pluginToken}`;
  const postApi = `${config.baseUrl}/api/funnels/v1/calculator/post/${pluginToken}`;

  const handleOpenApiModal = () => {
    setOpenApiModal(true);
  };

  const handleCloseApiModal = () => {
    setOpenApiModal(false);
  };

  useEffect(() => {
    console.log("plugin user: ", user);
    if (user.accountType === "Pro") {
      setVisibleAd(false);
    } else if (user.accountType === "Admin") {
      setVisibleAd(false);
    } else if (user.accountType === "admin") {
      setVisibleAd(false);
    } else {
      setVisibleAd(true);
    }

    console.log("true or false", visibleAd);
  }, []);

  useEffect(() => {
    if (!user) navigate("/");
    else if (!pluginId)
      navigate(`/dashboard/${profileId}/all-funnels/calculators`);

    axios({
      url: "/api/plugin/id/" + pluginId,
      method: "get",
      headers: {
        Authorization: "Bearer " + user?.token,
      },
    })
      .then((res) => {
        const resData = res.data;

        if (resData.success) {
          console.log("plugin data calc: ", resData.data);
          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });
        } else {
          toast.error(resData.status);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  }, [pluginId]);

  useEffect(() => {
    if (currentPlugin !== null && currentPlugin) {
      console.log("asdasd", currentPlugin);
      setPluginLogo(currentPlugin.logo.value);

      setPluginName(currentPlugin.name);
      setPluginTitle(currentPlugin.title);
      setPluginToken(currentPlugin.token);
      localStorage.setItem("calculatorToken", currentPlugin?.token);

      setLeadName(currentPlugin.leadName);
      setLeadLink(currentPlugin.leadLink);
      setBehaviour(currentPlugin.behaviour);
      setState(currentPlugin.state);

      setShowEmailPaymentDetails(currentPlugin.leadButtons.emailPaymentDetails);
      setShowViewProperties(currentPlugin.leadButtons.viewProperties);
      setShowPreQualified(currentPlugin.leadButtons.preQualified);
      setShowCheckHomeValues(currentPlugin.leadButtons.checkHomeValues);

      setVarbiage(currentPlugin.varbiage[0] || { text: "", link: "" });
      setVarbiage1(currentPlugin.varbiage[1] || { text: "", link: "" });
      setVarbiage2(currentPlugin.varbiage[2] || { text: "", link: "" });
      setHomeSearchPage(currentPlugin.homeSearchPage);
      setCalenderBookingPage(currentPlugin.calenderBookingPage);

      setBackgroundColor(currentPlugin.colors.backgroundColor);
      setTextColor(currentPlugin.colors.textColor);
      setTitleColor(currentPlugin.colors.titleColor);
      setButtonColor(currentPlugin.colors.buttonColor);
      setButtonTextColor(currentPlugin.colors.buttonTextColor);

      domainsDispatch({
        type: "initial",
        payload: {
          total: currentPlugin.domains.length,
          value: currentPlugin.domains,
        },
      });
      setLoading(false);
    }
  }, [currentPlugin]);

  const handleSave = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("logo", pluginLogo);
    formData.append(
      "data",
      JSON.stringify({
        name: pluginName,
        title: pluginTitle,
        leadName,
        leadLink,
        behaviour,
        colors: {
          backgroundColor,
          textColor,
          titleColor,
          buttonColor,
          buttonTextColor,
        },
        domains,
        varbiage: [varbiage, varbiage1, varbiage2],
        state,
        homeSearchPage,
        calenderBookingPage,
        leadButtons: {
          emailPaymentDetails: showEmailPaymentDetails,
          viewProperties: showViewProperties,
          preQualified: showPreQualified,
          checkHomeValues: showCheckHomeValues,
        },
      }),
    );

    console.log("formdatatata before: ", formData);

    axios({
      url: "/api/plugin/update/" + pluginId,
      method: "put",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        console.log("formdatatata: ", formData);
        setLoading(false);
        if (resData.success) {
          toast.success("Updated successfully");

          dispatch({
            type: ActionTypes.SET_CURRENT_PLUGIN,
            payload: resData.data,
          });

          domainsDispatch({
            type: "initial",
            payload: {
              total: resData.data.domains.length,
              value: resData.data.domains,
            },
          });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      });
  };

  const handleResetColors = () => {
    setBackgroundColor(
      "linear-gradient(37deg, rgba(172,30,35,1) 5%, rgba(39,45,52,1) 74%)",
    );
    setTextColor("#e9e2f1");
    setTitleColor("#ffffff");
    setButtonColor("#ffffff");
    setButtonTextColor("#000000");
    toast.success("Colors reset!");
  };

  const handleDeletePlugin = () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    axios({
      url: "/api/plugin/del",
      method: "DELETE",
      data: {
        userId: user._id,
        pluginId: pluginId,
        emailTemplateId: emailTemplate._id,
      },
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        const resData = res.data;
        if (resData.success) {
          navigate(`/dashboard/${profileId}/calculators`, { replace: true });
        } else {
          toast.error(resData.status);
        }
      })
      .catch((err) => {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      });
  };

  const newDomain = (idx) => {
    return (
      <input
        className="px-2 text-sm h-[2rem] rounded-md bg-[#FFFFFF80] shadow-sm w-full outline-none border-2"
        placeholder="Add domain"
        key={idx}
        disabled={idx + 1 < domainNo}
        autoFocus={focusNextInput && idx + 1 === domainNo}
        onChange={(e) =>
          domainsDispatch({
            type: "add",
            payload: { pos: idx, value: e.target.value },
          })
        }
        onKeyUp={(e) => {
          if (e.key === "Enter" || e.keyCode === 13 || e.code === "Enter") {
            if (domainNo <= 3 && domains[idx].length > 0) {
              setFocusNextInput(true);
            }
            setDomainNo((prev) => prev + 1);
          }
        }}
      />
    );
  };

  if (loading) {
    return <Loader customClass="md:left-[300px]" />;
  }

  return (
    <div className="px-6 py-8">
      <div>
        <h1 className="text-2xl text-[#39393bde] font-bold">
          Embed Calculator in your website
        </h1>
        <div className="dashboard_plugin_embed flex justify-between mt-4">
          <div className="flex-1 py-2 px-3 overflow-auto">
            <pre>
              <ol className="text-[#97AAC3]">
                <li>
                  <span className="text-gray-400">
                    {" "}
                    &lt;!-- Mortgage Calculator Widget --&gt;{" "}
                  </span>
                </li>
                <li>
                  <span> &lt;script&gt;</span>
                </li>

                <li>
                  {" "}
                  <span>
                    {" "}
                    var d = document.createElement("div");var qs =
                    document.createElement("script");d.classList.add("mtge-calc_embeded");d.setAttribute("id",
                    "mtge-calc_embeded");d.setAttribute("data-viewad","
                    {visibleAd}")
                  </span>
                </li>

                <li>
                  {"  "}
                  <span>
                    d.setAttribute("data-sitekey","{pluginToken}");qs.src = "
                    {config.baseUrl}/static/js/plugin.js"
                  </span>
                </li>

                <li>
                  <span>
                    {"  "}
                    var l1 =
                    document.createElement("link");l1.setAttribute("rel",
                    "stylesheet");l1.setAttribute("type",
                    "text/css");l1.setAttribute("href", "{config.baseUrl}
                    /static/css/plugin.css");
                  </span>
                </li>
                <li>
                  {" "}
                  <span>
                    {" "}
                    document.head.appendChild(l1);
                    document.body.appendChild(d);document.body.appendChild(qs);{" "}
                  </span>
                </li>

                <li>
                  <span> &lt;/script&gt;</span>
                </li>

                <li>
                  <span className="text-gray-400">
                    {" "}
                    &lt;!-- End Mortgage Calculator Widget --&gt;{" "}
                  </span>
                </li>
              </ol>
            </pre>
          </div>
          <div className="dashboard_plugin_embed-clipboard px-6 py-2 md:px-10 md:py-6">
            <CopyToClipboard
              text={`<!-- Mortgage Calculator Widget -->
  <script>
    var d = document.createElement("div");var qs = document.createElement("script");d.classList.add("mtge-calc_embeded");var currentScriptTag = document.currentScript;d.setAttribute("id", "mtge-calc_embeded");d.setAttribute("data-sitekey","${pluginToken}");d.setAttribute("data-viewad","${visibleAd}");qs.src = "${config.baseUrl}/static/js/plugin.js";qs.defer = true;
    var l1 = document.createElement("link");l1.setAttribute("rel", "stylesheet");l1.setAttribute("type", "text/css");l1.setAttribute("href","${config.baseUrl}/static/css/plugin.css");
    document.head.appendChild(l1);currentScriptTag.parentNode.insertBefore(d, currentScriptTag);document.body.appendChild(qs);
  </script>
<!-- End Mortgage Calculator Widget -->`}
              onCopy={() => toast.success("Copied to clipboard")}
            >
              <button className="w-full h-full text-center text-[#7B94B5] text-sm md:text-base">
                <span> Copy to </span> <br />
                <span> Clipboard </span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-12 space-y-4 md:space-y-0 md:space-x-6">
        <div className="md:mr-6">
          <h1 className="text-2xl text-[#39393bde] font-bold">
            Customize the plugin
          </h1>

          <div className="mt-6">
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="pluginName"
              >
                Calculator Name
              </label>
              <input
                id="pluginName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="pluginName"
                value={pluginName}
                placeholder="Default"
                onChange={(e) => setPluginName(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="pluginTitle"
              >
                Calculator title
              </label>
              <input
                id="pluginTitle"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="pluginTitle"
                value={pluginTitle}
                placeholder="Mortgage Calculator"
                onChange={(e) => setPluginTitle(e.target.value)}
              />
            </div>

            {currentPlugin.logo.allowed && (
              <div className="mb-6 flex flex-col space-y-1">
                <label
                  htmlFor="pluginLogo"
                  className="text-base text-[#39393bde] font-extrabold"
                >
                  Calculator Logo
                </label>
                <input
                  id="pluginLogo"
                  type="file"
                  name="pluginLogo"
                  onChange={(e) => {
                    setPluginLogo(e.target.files[0]);
                  }}
                />
              </div>
            )}

            {/* <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="leadName"
              >
                Lead name
              </label>
              <input
                id="leadName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="leadName"
                value={leadName}
                placeholder="View properies"
                onChange={(e) => setLeadName(e.target.value)}
              />
            </div> */}

            {/* <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="leadLink"
              >
                Lead link
              </label>
              <input
                id="leadLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="leadLink"
                value={leadLink}
                placeholder="https://example.com"
                onChange={(e) => setLeadLink(e.target.value)}
              />
            </div> */}

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="behaviour"
              >
                {" "}
                Behavior{" "}
              </label>
              <div className="ml-2 md:flex items-center md:space-x-5">
                <label
                  className="flex items-center space-x-2"
                  htmlFor="withMinimize"
                >
                  <input
                    id="withMinimize"
                    name="behaviour"
                    type="radio"
                    value="withMinimize"
                    checked={behaviour === "withMinimize"}
                    onChange={(e) => setBehaviour(e.target.value)}
                  />
                  <span>With minimize option</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="withoutMinimize"
                >
                  <input
                    id="withoutMinimize"
                    name="behaviour"
                    type="radio"
                    value="withoutMinimize"
                    checked={behaviour === "withoutMinimize"}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Without minimize option</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="saveAsNativeElement"
                >
                  <input
                    id="saveAsNativeElement"
                    name="behaviour"
                    type="radio"
                    value="saveAsNativeElement"
                    checked={behaviour === "saveAsNativeElement"}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Save as an element on your website</span>
                </label>
                <label
                  className="flex items-center space-x-2"
                  htmlFor="displayAsAFlexiFrame"
                >
                  <input
                    id="displayAsAFlexiFrame"
                    name="behaviour"
                    type="radio"
                    value="displayAsAFlexiFrame"
                    checked={behaviour === "displayAsAFlexiFrame"}
                    onChange={(e) => {
                      setBehaviour(e.target.value);
                      setState(true);
                    }}
                  />
                  <span>Display as a FlexiFrame</span>
                </label>
              </div>
            </div>

            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Initial State
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={state}
                label={state ? "Open" : "Minimized"}
                onChange={(e) => setState((prev) => !prev)}
              />
            </div>

            {/* Lead buttons */}
            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Email Payment Details
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={showEmailPaymentDetails}
                label={showEmailPaymentDetails ? "Show" : "Hide"}
                onChange={(e) => setShowEmailPaymentDetails((prev) => !prev)}
              />
            </div>
            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                View Properties
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={showViewProperties}
                label={showViewProperties ? "Show" : "Hide"}
                onChange={(e) => setShowViewProperties((prev) => !prev)}
              />
            </div>
            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Check Mortgage Rates
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={showPreQualified}
                label={showPreQualified ? "Show" : "Hide"}
                onChange={(e) => setShowPreQualified((prev) => !prev)}
              />
            </div>
            <div className="mb-6 flex flex-col space-y-2">
              <span className="text-base text-[#39393bde] font-extrabold">
                Check Home Values
              </span>
              <FormControlLabel
                control={<Android12Switch />}
                checked={showCheckHomeValues}
                label={showCheckHomeValues ? "Show" : "Hide"}
                onChange={(e) => setShowCheckHomeValues((prev) => !prev)}
              />
            </div>
            {/* End */}
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="homeSearchPage"
              >
                Home Search Page
              </label>
              <input
                id="homeSearchPage"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="homeSearchPage"
                value={homeSearchPage}
                placeholder="https://www.example.com/"
                onChange={(e) => setHomeSearchPage(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="calenderBookingPage"
              >
                Calendar Booking Page
              </label>
              <input
                id="calenderBookingPage"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="calenderBookingPage"
                value={calenderBookingPage}
                placeholder="https://www.example.com/"
                onChange={(e) => setCalenderBookingPage(e.target.value)}
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-1
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-1 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>

            {/* varbiage2 */}
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-2
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage1.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage1((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-2 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage1.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage1((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>

            {/* varbiage3 */}
            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageName"
              >
                Verbiage-3
              </label>
              <input
                id="varbiageName"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageName"
                value={varbiage2.text}
                placeholder="Savage Sales"
                onChange={(e) =>
                  setVarbiage2((prev) => ({ ...prev, text: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className="text-base text-[#39393bde] font-extrabold"
                htmlFor="varbiageLink"
              >
                Verbiage-3 Link
              </label>
              <input
                id="varbiageLink"
                className="rgtr_input py-2 px-2 text-sm text-[#39393bde] font-bold border-2 border-[#c6c7ca] rounded-sm outline-none placeholder:text-[#39393bde]"
                type="text"
                name="varbiageLink"
                value={varbiage2.link}
                placeholder="https://www.savagesales.com"
                onChange={(e) =>
                  setVarbiage2((prev) => ({ ...prev, link: e.target.value }))
                }
              />
            </div>

            <div className="mb-6 flex flex-col space-y-1">
              <label
                className=" text-base text-[#39393bde] font-extrabold"
                htmlFor="domains"
              >
                Domains{" "}
                <span className="text-sm text-gray-400">
                  {" "}
                  (eg. example.com){" "}
                </span>
              </label>
              <div className="flex flex-col space-y-3">
                {Array(domainNo)
                  .fill(newDomain)
                  .map((item, index) => {
                    if (index <= 2 && index + 1 === domainNo) {
                      return (
                        <span className="w-full flex space-x-2" key={index}>
                          <button
                            onClick={() => setDomainNo((prev) => prev + 1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                          {item(index)}
                        </span>
                      );
                    } else if (index <= 2) {
                      return (
                        <span className="w-full flex space-x-2" key={index}>
                          <button
                            onClick={(e) => {
                              console.log(e);
                              domainsDispatch({
                                type: "del",
                                payload: { pos: index },
                              });
                              setDomainNo((prev) => prev - 1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          <span className="text-sm h-[2rem] flex items-center rounded-md bg-[#FFFFFF80] shadow-sm w-full mr-1">
                            {domains[index]}
                          </span>
                        </span>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          <h3 className="mt-6 text-sm text-[#39393bde] font-bold"> Colors </h3>
          <div className="grid grid-cols-2 mt-2">
            <div
              className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => setBackgroundColorPicker(true)}
            >
              {backgroundColorPicker && (
                <div
                  className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center z-50"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="fixed bottom-0 left-0 right-0 top-0 bg-[#00000082]"
                    onClick={() => setBackgroundColorPicker(false)}
                  ></div>
                  <div className="relative bg-white z-[100]">
                    <ColorPicker
                      value={backgroundColor}
                      onChange={setBackgroundColor}
                    />
                  </div>
                </div>
              )}

              <div className="relative">
                <div
                  className="h-5 w-8 rounded-sm border-2"
                  style={{ background: backgroundColor }}
                ></div>

                {/* <input
                  className="w-8 h-1 border-none bg-none rounded-sm"
                  ref={backgroundColorPicker}
                  type="color"
                  onChange={(e) => setBackgroundColor(e.target.value)}
                /> */}
              </div>
              <span className="text-sm select-none"> Background </span>
            </div>
            <div
              className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => titleColorPicker.current.click()}
            >
              <div className="relative">
                <div
                  className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                  style={{ background: titleColor }}
                ></div>
                <input
                  className="w-8 h-1 border-none bg-none rounded-sm"
                  ref={titleColorPicker}
                  type="color"
                  onChange={(e) => setTitleColor(e.target.value)}
                />
              </div>

              <span className="text-sm select-none"> Title </span>
            </div>
            <div
              className=" flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => textColorPicker.current.click()}
            >
              <div className="relative">
                <div
                  className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                  style={{ background: textColor }}
                ></div>
                <input
                  className="w-8 h-1 border-none bg-none rounded-sm"
                  ref={textColorPicker}
                  color={textColor}
                  type="color"
                  onChange={(e) => setTextColor(e.target.value)}
                />
              </div>

              <span className="text-sm select-none"> Text </span>
            </div>
            <div
              className="flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => buttonColorPicker.current.click()}
            >
              <div className="relative">
                <div
                  className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                  style={{ background: buttonColor }}
                ></div>
                <input
                  className="w-8 h-1 border-none bg-none rounded-sm"
                  ref={buttonColorPicker}
                  color={buttonColor}
                  type="color"
                  onChange={(e) => setButtonColor(e.target.value)}
                />
              </div>
              <span className="text-sm select-none"> Button </span>
            </div>
            <div
              className=" flex items-center p-1 mb-4 mr-4 border-2 rounded-sm space-x-2 cursor-pointer"
              onClick={() => buttonTextColorPicker.current.click()}
            >
              <div className="relative">
                <div
                  className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-5 rounded-sm border-2"
                  style={{ background: buttonTextColor }}
                ></div>
                <input
                  className="w-8 h-1 border-none bg-none rounded-sm"
                  ref={buttonTextColorPicker}
                  color={buttonTextColor}
                  type="color"
                  onChange={(e) => setButtonTextColor(e.target.value)}
                />
              </div>

              <span className="text-sm select-none"> Button text </span>
            </div>
          </div>
          <Link
            to={`minimize-button`}
            className="mb-6 flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
          >
            <span>Customize Minimize Button</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
              />
            </svg>
          </Link>
          <CopyToClipboard
            text={`${window.location.origin}/view/calculators/${currentPlugin.token}`}
            onCopy={() => {
              toast.success("Copied to clipboard");
              toast.success("Please add the code into body tag");
            }}
          >
            <button className="flex items-center justify-center w-full px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2">
              <span>Share/Preview Calculator</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
          </CopyToClipboard>

          <div className="mt-6 flex items-center flex-wrap gap-3">
            <button
              className="flex items-center justify-center w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700 space-x-2"
              onClick={handleResetColors}
              // disabled={!edited || loading}
            >
              <span>Reset Colors</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
            {currentPlugin.logo.allowed && (
              <button
                className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
                onClick={() => setPluginLogo("")}
                // disabled={!edited || loading}
              >
                Reset Logo
              </button>
            )}
            <button
              className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleSave}
              // disabled={!edited || loading}
            >
              Save
            </button>

            <button
              className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-neutral-700"
              onClick={handleOpenApiModal}
              // disabled={!edited || loading}
            >
              Get API
            </button>

            <ApiModal
              open={openApiModal}
              getApi={getApi}
              postApi={postApi}
              handleClose={handleCloseApiModal}
            />
            {isOwnPlugin && (
              <button
                className="block w-full md:w-40 px-4 py-2 text-white font-bold rounded-lg bg-red-600"
                onClick={handleDeletePlugin}
                // disabled={!edited || loading}
              >
                Delete
              </button>
            )}
          </div>
        </div>
        <Preview
          pluginLogo={pluginLogo}
          leadName={leadName}
          leadLink={leadLink}
          behaviour={behaviour}
          backgroundColor={backgroundColor}
          titleColor={titleColor}
          pluginTitle={pluginTitle}
          textColor={textColor}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
          varbiage={[varbiage, varbiage1, varbiage2]}
          homeSearchPage={homeSearchPage}
          calenderBookingPage={calenderBookingPage}
          showEmailPaymentDetails={showEmailPaymentDetails}
          showViewProperties={showViewProperties}
          showPreQualified={showPreQualified}
          showCheckHomeValues={showCheckHomeValues}
        />
      </div>
    </div>
  );
}

export default Plugin;
