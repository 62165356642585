import React from 'react';
import { Link, useParams } from 'react-router-dom';

const BasicOrAdvance = () => {
  const params = useParams();

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 pt-8">
      <div className="text-center">
        <h2 className="text-3xl font-semibold mb-4">Create your page with:</h2>
        <div className="flex flex-col items-center">
          <Link to={`/dashboard/${params.profileId}/edit/profile/basic`}>
            <button className="w-64 px-4 py-4 rounded-md bg-white text-black mb-4 hover:bg-gray-200 focus:outline-none">
              Basic
            </button>
          </Link>
          <Link to={`/dashboard/${params.profileId}/edit/profile/advance`}>
            <button className="w-64 px-4 py-4 rounded-md bg-white text-black mb-4 hover:bg-gray-200 focus:outline-none">
              Advanced
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BasicOrAdvance;
